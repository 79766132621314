import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  Button
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const useStyles = makeStyles(theme => ({
  mobileStepper: {
    "& .Mui-disabled": {
      color: theme.palette.text.disabled
    },
    "& .MuiMobileStepper-progress": {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

const steps = [
  {
    label: "Business"
  },
  {
    label: "Billing"
  },
  {
    label: "Review"
  }
];

function Steps({
  activeStep = 0,
  handleBackBtn,
  formId,
  handleSubmit,
  isStripe,
  isChecked = true
}) {
  const classes = useStyles();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box
        sx={theme => ({
          [theme.breakpoints.down("lg")]: {
            display: "none"
          }
        })}
      >
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={theme => ({
            "& .MuiStep-root .MuiStepLabel-root": {
              padding: 0
            }
          })}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box
        sx={theme => ({
          display: "none",
          [theme.breakpoints.down("lg")]: {
            display: "block",
            width: "100%"
          }
        })}
      >
        <MobileStepper
          variant="progress"
          steps={3}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          sx={{
            bgcolor: "text.dark.primary",
            flexGrow: 1,
            padding: "24px 32px"
          }}
          nextButton={
            isStripe ? (
              <Button disabled={activeStep === 3} onClick={handleSubmit}>
                Next
                <KeyboardArrowRight />
              </Button>
            ) : (
              <Button
                disabled={activeStep === 3 || !isChecked}
                type="submit"
                form={formId}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            )
          }
          backButton={
            <Button
              onClick={handleBackBtn}
              disabled={activeStep === 0}
              sx={theme => ({
                "&.Mui-disabled": {
                  color: theme.palette.text.disabled
                }
              })}
            >
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        />
      </Box>
    </Box>
  );
}

export default Steps;
