import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateThemeMode } from "reducers/users";
import { LIGHT, DARK } from "utils/constants";
import { getLocalStorageItem, setLocalStorageItem } from "utils";
import { Drawer, Box, Stack, IconButton, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import SideMenuItems from "./SideMenuItems";

function ResponsiveMenu({ position, toggleMenu }) {
  const dispatch = useDispatch();
  const {
    data: { me },
    theme
  } = useSelector(state => state.user);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersLightMode = useMediaQuery("(prefers-color-scheme: light)");

  useEffect(() => {
    if (!prefersDarkMode && !prefersLightMode) {
      return;
    }

    const storedValue = getLocalStorageItem("theme");
    if (storedValue) {
      dispatch(updateThemeMode(storedValue));
      return;
    }

    if (prefersDarkMode) {
      dispatch(updateThemeMode(DARK));
      setLocalStorageItem("theme", DARK);
    } else if (prefersLightMode) {
      dispatch(updateThemeMode(LIGHT));
      setLocalStorageItem("theme", LIGHT);
    }
  }, [dispatch, prefersDarkMode, prefersLightMode]);

  const handleToggleTheme = () => {
    if (theme === LIGHT) {
      dispatch(updateThemeMode(DARK));
      setLocalStorageItem("theme", DARK);
    } else {
      dispatch(updateThemeMode(LIGHT));
      setLocalStorageItem("theme", LIGHT);
    }
  };

  return (
    <Drawer
      anchor={"left"}
      open={position["left"]}
      onClose={toggleMenu(false)}
      sx={{
        "& .MuiDrawer-paper": {
          maxWidth: 360,
          width: "100%",
          justifyContent: "space-between",
          bgcolor: "text.dark.primary"
        }
      }}
    >
      <Box
        sx={{
          maxWidth: 360,
          height: "auto",
          padding: "56px 32px",
          bgcolor: "text.dark.primary"
        }}
        role="presentation"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            component="img"
            display={{ xs: "block", lg: "none" }}
            sx={{
              height: "auto",
              width: "auto",
              maxHeight: { xs: 25, lg: 25 },
              maxWidth: { xs: 120, lg: 120 }
            }}
            alt="Blabble-logo"
            src="/app/assets/logo.svg"
          />
          <CloseIcon
            onClick={toggleMenu(false)}
            sx={{ color: "text.primary" }}
          />
        </Stack>
        {me?.type && <SideMenuItems userType={me?.type} isResponsive={true} />}
      </Box>
      <Box sx={{ padding: "0 16px 32px 32px" }}>
        <IconButton
          style={{ margin: "0 0 24px 12px" }}
          onClick={handleToggleTheme}
        >
          {theme === LIGHT ? (
            <DarkModeIcon
              sx={theme => ({ color: theme.palette.text.primary })}
            />
          ) : (
            <WbSunnyIcon
              sx={theme => ({ color: theme.palette.text.primary })}
            />
          )}
        </IconButton>
        <Stack direction="row" alignItems="center">
          <CopyrightIcon sx={{ color: "text.primary" }} />
          <Typography variant="body2" color="stext.primary">
            Blabble Tech Limited. All Rights Reserved
          </Typography>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default ResponsiveMenu;
