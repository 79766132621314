import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  updateInvoiceFilters,
  changePage,
  cancelInvoice
} from "reducers/company";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Link,
  Chip,
  Pagination,
  Stack
} from "@mui/material";
import {
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getSortingColor,
  getSortingIcon,
  paginationStyle,
  invoiceStatusColor,
  invoiceStatusBgColor,
  horizontalScrollBarStyle
} from "utils";
import DeletePopover from "./DeletePopover";
import { defaultInvoicesSorting, INVOICES_TYPE } from "utils/constants";

const useStyles = makeStyles(theme => ({
  borderRight: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "2px",
      height: "14px",
      background: theme.palette.grey[300],
      top: "50%",
      transform: "translateY(-50%)",
      right: "0"
    }
  }
}));

const chipStyle = (theme, status) => {
  return {
    color: invoiceStatusColor(status),
    backgroundColor: invoiceStatusBgColor(status),
    fontSize: "13px",
    fontWeight: "700",
    fontFamily: "'Nunito Sans', sans-serif",
    height: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      "& .MuiChip-label": {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    }
  };
};

function InvoiceTable() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { filtersInvoices, invoicesData, rowsPerPage, page } = useSelector(
    state => state.company
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [invoiceId, setInvoiceId] = useState("");

  const handleOnSortFilterInvoicesChange = sortingParam => {
    let filters_ = {
      ...filtersInvoices,
      sorting: { ...filtersInvoices.sorting }
    };
    filters_.sorting[sortingParam] = getNextSortingState(
      filters_.sorting[sortingParam]
    );
    filters_.sorting = clearOtherSortingParams(filters_.sorting, sortingParam);
    if (isNoSortingParams(filters_.sorting)) {
      filters_.sorting = defaultInvoicesSorting;
    }
    dispatch(updateInvoiceFilters(filters_));
  };

  const handleOnPageChange = (e, newPage) => {
    dispatch(changePage(newPage));
  };

  const handleChange = (e, id) => {
    setAnchorEl(e.currentTarget);
    setInvoiceId(id);
  };

  const handleCancelInvoice = id => {
    const cancelInvoiceDetail = {
      id,
      data: {
        status: "void"
      }
    };
    dispatch(cancelInvoice(cancelInvoiceDetail));
  };

  return (
    <>
      <Typography variant="h6" color="text.primary" marginBottom="16px">
        Invoices
      </Typography>
      <TableContainer
        component={Paper}
        sx={theme => horizontalScrollBarStyle(theme)}
      >
        <Table sx={{ minWidth: 550 }} stickyHeader>
          <TableHead>
            <TableRow
              sx={theme => ({
                bgcolor: theme.palette.background.default,
                "& td,th": {
                  borderBottom: `1px solid ${theme.palette.text.disabled}`,
                  zIndex: 0
                }
              })}
            >
              <TableCell className={classes.borderRight}>
                <Button
                  onClick={() => handleOnSortFilterInvoicesChange("created")}
                  variant="text"
                  sx={{
                    color: getSortingColor(filtersInvoices.sorting.created)
                  }}
                  startIcon={getSortingIcon(filtersInvoices.sorting.created)}
                >
                  Date
                </Button>
              </TableCell>
              <TableCell sx={{ width: "25%" }} className={classes.borderRight}>
                <Button
                  onClick={() => handleOnSortFilterInvoicesChange("status")}
                  variant="text"
                  sx={{
                    color: getSortingColor(filtersInvoices.sorting.status)
                  }}
                  startIcon={getSortingIcon(filtersInvoices.sorting.status)}
                >
                  Status
                </Button>
              </TableCell>
              <TableCell sx={{ width: "20%" }} className={classes.borderRight}>
                <Button
                  onClick={() => handleOnSortFilterInvoicesChange("amount")}
                  variant="text"
                  sx={{
                    color: getSortingColor(filtersInvoices.sorting.amount)
                  }}
                  startIcon={getSortingIcon(filtersInvoices.sorting.amount)}
                >
                  Amount
                </Button>
              </TableCell>
              <TableCell
                sx={{
                  width: "10%",
                  textTransform: "uppercase",
                  fontWeight: "600",
                  fontSize: "15px",
                  textAlign: "center"
                }}
                className={classes.borderRight}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicesData?.results?.map(data => (
              <TableRow
                key={data.id}
                sx={theme => ({
                  bgcolor: theme.palette.background.default,
                  "& td,th": {
                    borderBottom: `1px solid ${theme.palette.text.disabled}`
                  }
                })}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontSize: "16px",
                    color: "text.primary"
                  }}
                >
                  <Typography variant="body1">{data.created}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    width: "25%"
                  }}
                >
                  <Chip
                    label={data.status}
                    sx={theme => chipStyle(theme, data.status)}
                  />
                </TableCell>
                <TableCell sx={{ width: "20%" }}>{`${
                  data.amount ? `£${data.amount}` : "N/A"
                }`}</TableCell>
                <TableCell sx={{ width: "10%" }}>
                  <Stack direction="row">
                    <Link
                      variant="body1"
                      target="_blabble"
                      sx={{ textDecoration: "none" }}
                      href={data.url}
                    >
                      <Button variant="contained" sx={{ minWidth: "100px" }}>
                        {data.status === INVOICES_TYPE.OPEN ? "Pay" : "View"}
                      </Button>
                    </Link>
                    {data.status === INVOICES_TYPE.OPEN && (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={e => handleChange(e, data.id)}
                        sx={{ marginLeft: "10px" }}
                      >
                        Cancel
                      </Button>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(invoicesData.count / rowsPerPage)}
        page={page}
        onChange={handleOnPageChange}
        sx={theme => paginationStyle(theme)}
      />
      <DeletePopover
        text="invoice"
        anchorEl={anchorEl}
        invoiceId={invoiceId}
        handleClose={() => setAnchorEl(null)}
        handleCancelInvoice={handleCancelInvoice}
        bgColor={theme.palette.modalColorPalette.secondary}
      />
    </>
  );
}

export default InvoiceTable;
