import React from "react";
import { useSelector } from "react-redux";
import AutoCompleteField from "components/AutoCompleteField";
import CloseIcon from "@mui/icons-material/Close";
import { Drawer, Typography, Box, Stack, Button } from "@mui/material";

function FilterDrawer({
  position,
  setBroker,
  brokerValue,
  applyFilters,
  toggleDrawer,
  handleOnFilterChange
}) {
  const { data: userData } = useSelector(state => state.user);

  return (
    <Drawer
      anchor={"right"}
      open={position["right"]}
      onClose={toggleDrawer(false)}
      sx={theme => ({
        [theme.breakpoints.down("sm")]: {
          "& .MuiDrawer-paper": {
            width: "100%",
            bgcolor: theme.palette.text.dark.primary
          }
        }
      })}
    >
      <Box
        sx={theme => ({
          width: 400,
          padding: "56px 32px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0
          }
        })}
        role="presentation"
      >
        <Box
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              padding: "56px 32px"
            }
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: "24px" }}
          >
            <Typography variant="h4" color="text.primary" component="div">
              FILTER
            </Typography>
            <CloseIcon
              onClick={toggleDrawer(false)}
              sx={{ color: "text.primary" }}
            />
          </Stack>
          <Stack
            sx={theme => ({
              [theme.breakpoints.down("lg")]: {
                width: "100%"
              }
            })}
          >
            <Stack>
              <AutoCompleteField
                label={"Select Broker"}
                value={brokerValue?.email || ""}
                options={userData.brokers?.map(({ id, email }) => ({
                  value: id,
                  label: email
                }))}
                onChange={(e, value) => {
                  setBroker(value?.value || "");
                  handleOnFilterChange("broker", value?.value);
                }}
              />
            </Stack>
            <Button
              variant="contained"
              sx={{ marginTop: "16px" }}
              onClick={applyFilters}
            >
              Apply
            </Button>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
}

export default FilterDrawer;
