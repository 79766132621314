import React from "react";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import { Typography, Grid, Button, Box } from "@mui/material";
import LoginImage from "components/NonAuthenticateLoginImage";

const ForgotPasswordSuccess = props => {
  const { email } = useParams();

  return (
    <Box sx={{ marginBottom: "48px" }}>
      <Grid container spacing={6} sx={{ alignItems: "center" }}>
        <Grid item xs={12} lg={6}>
          <Box
            m={"auto"}
            sx={theme => ({
              maxWidth: 460,
              height: 200,
              padding: "56px",
              borderRadius: 4,
              bgcolor: "text.dark.primary",
              [theme.breakpoints.down("xl")]: {
                padding: "28px"
              },
              [theme.breakpoints.down("lg")]: {
                padding: 0,
                boxShadow: 0,
                maxWidth: "100%",
                height: "100%",
                bgcolor: "background.default"
              }
            })}
          >
            <Typography
              variant="h4"
              gutterBottom
              component="h4"
              color="text.primary"
              mb={4}
            >
              Forgotten password
            </Typography>
            <Typography variant="body1" gutterBottom color="text.primary">
              We've sent an email to{" "}
              <Typography variant="body1" component="span" color="primary.main">
                {email}
              </Typography>{" "}
              with instruction on resetting your password.
            </Typography>
            <Link
              to={ROUTES.FORGOT_PASSWORD}
              style={{ textDecoration: "none" }}
            >
              <Typography variant="subtitle1" color="primary.main">
                Didn't get the email?
              </Typography>
            </Link>
            <Link to={ROUTES.LOGIN} style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={theme => ({
                  marginTop: "24px",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                    marginBottom: "32px"
                  }
                })}
              >
                LOGIN
              </Button>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={0} lg={6} display={{ xs: "none", lg: "block" }}>
          <LoginImage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPasswordSuccess;
