import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "components/Modals/index";
import { createAdditionalSeat, updateBuySeatsModal } from "reducers/company";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentCard from "./PaymentCard";
import {
  Button,
  Stack,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@mui/material";
import { moneyFieldConverter } from "utils";

const BuySeatsCheckoutModal = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();

  const { additionalSeats, defaultCompanyData, buySeats } = useSelector(
    state => state.company
  );

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    let additionalSeatsDetail = {
      additionalSeats: buySeats
    };
    dispatch(createAdditionalSeat(additionalSeatsDetail));
    handleClose();
  };

  const handleBackBtn = () => {
    dispatch(updateBuySeatsModal(true));
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Buy Seats">
      <Button
        variant="text"
        sx={{ marginBottom: "16px" }}
        startIcon={<ArrowBackIcon />}
        onClick={handleBackBtn}
      >
        Change
      </Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              flexWrap: "wrap"
            }
          })}
        >
          <Box
            sx={theme => ({
              background: theme.palette.text.dark.primary,
              padding: "14px",
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              marginRight: "24px",
              [theme.breakpoints.down("sm")]: {
                background: theme.palette.background.default,
                marginRight: 0,
                marginBottom: "16px"
              }
            })}
          >
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
              }}
            >
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary="Additional Seats"
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary={additionalSeats?.additionalSeats}
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
              }}
            >
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary="Per Seat"
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary={moneyFieldConverter(additionalSeats?.perSeatAmount)}
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
              }}
            >
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary="Payable Today"
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary={moneyFieldConverter(additionalSeats?.payableToday)}
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
            </List>
          </Box>
          <Box
            sx={theme => ({
              background: theme.palette.text.dark.primary,
              padding: "14px",
              width: "100%",
              height: "100%",
              borderRadius: "8px",
              [theme.breakpoints.down("sm")]: {
                background: theme.palette.background.default
              }
            })}
          >
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
              }}
            >
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary="Total Seats"
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary={additionalSeats?.totalSeats}
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
              }}
            >
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary="Per Seat"
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary={moneyFieldConverter(additionalSeats?.perSeatAmount)}
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 0
              }}
            >
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary="Next Invoice"
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
              <ListItem disablePadding sx={{ width: "unset" }}>
                <ListItemText
                  primary={moneyFieldConverter(additionalSeats?.nextPayable)}
                  sx={{ color: "text.primary" }}
                />
              </ListItem>
            </List>
          </Box>
        </Stack>
        <Typography
          variant="h6"
          color="text.primary"
          marginTop="24px"
          gutterBottom
        >
          Billing Address
        </Typography>
        <Typography variant="body1" color="text.primary">
          {`${defaultCompanyData?.billingAddress?.address} ${defaultCompanyData?.billingAddress?.address2}, ${defaultCompanyData?.billingAddress?.town}, ${defaultCompanyData?.billingAddress?.postCode}`}
        </Typography>{" "}
        <Typography
          variant="h6"
          color="text.primary"
          marginTop="24px"
          gutterBottom
        >
          Payment Card
        </Typography>
        <PaymentCard cardDetail={defaultCompanyData} />
        <Button
          sx={theme => ({
            marginTop: 3,
            width: "100%"
          })}
          type="submit"
          variant="contained"
        >
          CHECKOUT
        </Button>
      </form>
    </Modal>
  );
};

export default BuySeatsCheckoutModal;
