import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import BuyCreditsModal from "./BuyCreditsModal";
import Header from "components/Header";
import Loader from "components/Loader";
import CreditsTable from "./CreditsTable";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Grid, Box, Button, Stack, Typography } from "@mui/material";
import {
  CREDITS_DURATION,
  CREDITS_DURATION_DROP_DOWN,
  SUBSCRIPTION_TYPE,
  USER_TYPE
} from "utils/constants";
import BuySeatsCheckoutModal from "./BuyCreditsCheckoutModal";
import ProcessingModal from "pages/Company/ProcessingModal";
import {
  updateBuyCreditsModal,
  getCreditsBundles,
  updateBuyCreditsCheckoutModal,
  updateProcessingModal,
  updatePurchasedModal
} from "reducers/credits";
import {
  getCompanies,
  resetCompanySorting,
  updateCompletePaymentModal
} from "reducers/company";
import PurchasedModal from "pages/Company/PerchasedModal";
import ChangeMembershipRequestModal from "pages/Company/ChangeMembershipRequestModal";
import ConfirmPaymentModal from "pages/Company/ConfirmPaymentModal";
import _ from "lodash";
import SelectField from "components/SelectField";
import { usedCreditsBasedOnMembership } from "utils";

const boxStyle = theme => {
  return {
    background: theme.palette.text.dark.primary,
    padding: "42px 24px",
    borderRadius: "16px"
  };
};

function Credits() {
  const dispatch = useDispatch();
  const {
    data: { me }
  } = useSelector(state => state.user);
  const {
    isBuyCreditsModal,
    isBuyCreditsCheckoutModal,
    isProcessingModal,
    isPurchasedModal,
    purchased
  } = useSelector(state => state.credits);
  const { defaultCompanyData, isConfirmPaymentModal } = useSelector(
    state => state.company
  );
  const [creditsCheckout, setCreditsCheckout] = useState({});
  const [isChangeMembershipModal, setIsChangeMembershipModal] = useState(false);
  const [creditsDuration, setCreditsDuration] = useState(
    CREDITS_DURATION.MONTHLY
  );

  const handleBuyCredits = () => {
    dispatch(updateBuyCreditsModal(true));
    dispatch(getCreditsBundles());
  };

  useEffect(() => {
    return () => dispatch(resetCompanySorting());
  }, [dispatch]);

  useEffect(() => {
    if (me?.type === USER_TYPE.COMPANY_OWNER) {
      dispatch(getCompanies(me.company));
    }
  }, [dispatch, me]);
  return (
    <>
      <Header
        componentName={
          me?.type === USER_TYPE.BROKER &&
          me?.currentMembershipType === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO
            ? "Used Credits"
            : "Credits"
        }
      />
      <Typography
        variant="h4"
        color="text.primary"
        marginTop="24px"
        component="h2"
        display={{ xs: "block", lg: "none" }}
      >
        {me?.type === USER_TYPE.BROKER &&
        me?.currentMembershipType === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO
          ? "Used Credits"
          : "Credits"}
      </Typography>
      {!isEmpty(defaultCompanyData) || me?.type === USER_TYPE.BROKER ? (
        <Box sx={{ marginTop: "32px" }}>
          {me?.currentMembershipType === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO &&
            me?.type !== USER_TYPE.BROKER && (
              <SelectField
                sx={theme => ({
                  width: 300,
                  marginBottom: "24px",
                  [theme.breakpoints.down("md")]: {
                    width: "100%"
                  }
                })}
                label="Duration"
                value={creditsDuration}
                onChange={e => setCreditsDuration(e.target.value)}
                options={CREDITS_DURATION_DROP_DOWN.map(({ value, label }) => ({
                  value,
                  label
                }))}
              />
            )}
          <Box display={{ xs: "none", md: "block" }}>
            <Grid container spacing={4}>
              {me?.type === USER_TYPE.BROKER &&
                me?.currentMembershipType ===
                  SUBSCRIPTION_TYPE.PAY_AS_YOU_GO && (
                  <Grid item md={4}>
                    <Box sx={theme => boxStyle(theme)}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        gutterBottom
                      >
                        All Time
                      </Typography>
                      <Typography variant="h4" color="text.primary">
                        {me?.usedCreditsThisMonth}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              {me?.currentMembershipType !==
                SUBSCRIPTION_TYPE.PAY_AS_YOU_GO && (
                <>
                  <Grid item md={4}>
                    <Box sx={theme => boxStyle(theme)}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        gutterBottom
                      >
                        Allocated
                      </Typography>
                      <Typography variant="h4" color="text.primary">
                        {me?.type === USER_TYPE.COMPANY_OWNER
                          ? defaultCompanyData.allocatedCredits
                          : me?.balance}
                      </Typography>
                    </Box>
                  </Grid>
                  {me?.type === USER_TYPE.COMPANY_OWNER && (
                    <>
                      <Grid item md={4}>
                        <Box sx={theme => boxStyle(theme)}>
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            gutterBottom
                          >
                            Unallocated
                          </Typography>
                          <Typography variant="h4" color="text.primary">
                            {defaultCompanyData.unallocatedCredits}
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Grid item md={4}>
                <Box sx={theme => boxStyle(theme)}>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                  >
                    {me?.type === USER_TYPE.BROKER &&
                    me?.currentMembershipType ===
                      SUBSCRIPTION_TYPE.PAY_AS_YOU_GO
                      ? "This Month"
                      : "Used"}
                  </Typography>
                  <Typography variant="h4" color="text.primary">
                    {usedCreditsBasedOnMembership(
                      defaultCompanyData,
                      creditsDuration,
                      me
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            display={{ xs: "block", md: "none" }}
            sx={theme => ({
              background: theme.palette.text.dark.primary,
              padding: "28px 24px",
              borderRadius: "16px"
            })}
          >
            {me?.currentMembershipType !== SUBSCRIPTION_TYPE.PAY_AS_YOU_GO && (
              <>
                {me?.type === USER_TYPE.COMPANY_OWNER && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Unallocated
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {defaultCompanyData.unallocatedCredits}
                    </Typography>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                  >
                    Allocated
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {me?.type === USER_TYPE.COMPANY_OWNER
                      ? defaultCompanyData.allocatedCredits
                      : me?.balance}
                  </Typography>
                </Stack>
              </>
            )}
            {me?.type === USER_TYPE.BROKER &&
              me?.currentMembershipType === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                  >
                    All Time
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    {me?.usedCreditsThisMonth}
                  </Typography>
                </Stack>
              )}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {me?.type === USER_TYPE.BROKER &&
                me?.currentMembershipType === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO
                  ? "This Month"
                  : "Used"}
              </Typography>
              <Typography variant="h6" color="text.primary">
                {me?.type === USER_TYPE.COMPANY_OWNER
                  ? defaultCompanyData.usedCredits
                  : me?.usedCredits}
              </Typography>
            </Stack>
          </Box>
          {me?.type === USER_TYPE.COMPANY_OWNER && (
            <>
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  margin: "28px 0",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap"
                }}
              >
                <Typography
                  variant="h6"
                  color="text.primary"
                  sx={{ marginRight: "16px" }}
                >
                  BROKERS
                </Typography>
                {me?.currentMembershipType !==
                  SUBSCRIPTION_TYPE.PAY_AS_YOU_GO && (
                  <>
                    {defaultCompanyData.membershipDetails.type ===
                    SUBSCRIPTION_TYPE.SUBSCRIPTION_ONLY ? (
                      <>
                        {_.isEmpty(
                          defaultCompanyData.membershipDetails.membershipRequest
                        ) && (
                          <Button
                            variant="contained"
                            size="large"
                            onClick={() => setIsChangeMembershipModal(true)}
                            sx={theme => ({
                              [theme.breakpoints.down("ssm")]: {
                                width: "100%",
                                marginTop: "24px"
                              }
                            })}
                          >
                            Change Membership
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        variant="outlined"
                        startIcon={<ShoppingCartIcon />}
                        onClick={handleBuyCredits}
                      >
                        BUY CREDITS
                      </Button>
                    )}
                  </>
                )}
              </Stack>
              <CreditsTable creditsDuration={creditsDuration} />
            </>
          )}
        </Box>
      ) : (
        <Loader />
      )}
      {isBuyCreditsModal && (
        <BuyCreditsModal
          isOpen={isBuyCreditsModal}
          handleClose={() => dispatch(updateBuyCreditsModal(false))}
          setCreditsCheckout={setCreditsCheckout}
        />
      )}
      {isBuyCreditsCheckoutModal && (
        <BuySeatsCheckoutModal
          isOpen={isBuyCreditsCheckoutModal}
          handleClose={() => dispatch(updateBuyCreditsCheckoutModal(false))}
          creditsCheckoutDetail={creditsCheckout}
        />
      )}
      {isProcessingModal && (
        <Box>
          <ProcessingModal
            isOpen={isProcessingModal}
            handleClose={() => dispatch(updateProcessingModal(false))}
          />
        </Box>
      )}
      {isPurchasedModal && (
        <Box>
          <PurchasedModal
            isOpen={isPurchasedModal}
            handleClose={() => dispatch(updatePurchasedModal(false))}
            purchased={purchased}
            isCreditsPage={true}
          />
        </Box>
      )}
      {isChangeMembershipModal && (
        <Box>
          <ChangeMembershipRequestModal
            isOpen={isChangeMembershipModal}
            handleClose={() => setIsChangeMembershipModal(false)}
          />
        </Box>
      )}
      {isConfirmPaymentModal && (
        <Box>
          <ConfirmPaymentModal
            isOpen={isConfirmPaymentModal}
            handleClose={() => dispatch(updateCompletePaymentModal(false))}
          />
        </Box>
      )}
    </>
  );
}

export default Credits;
