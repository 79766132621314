import React from "react";
import Alert from "@mui/material/Alert";

const ALERT_STYLE = theme => {
  return {
    color: theme.palette.text.dark.primary,
    backgroundColor: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "800",
    fontFamily: "'Nunito Sans', sans-serif"
  };
};

function AlertComponent({ productType }) {
  return (
    <Alert severity="info" variant="string" sx={theme => ALERT_STYLE(theme)}>
      New {productType} will be available across the entire site once submitted.
    </Alert>
  );
}

export default AlertComponent;
