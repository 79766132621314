import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Loader";
import { getDashboard } from "reducers/dashboard";
import {
  Box,
  Typography,
  Stack,
  Chip,
} from "@mui/material";
import BrokerDetails from "./BrokerDetails";

function BrokersDashboard({ boxStyle, chipStyle }) {
  const dispatch = useDispatch();
  let { data } = useSelector(state => state.dashboard);
  const [lead, setLead] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  return (
    <>
      {!!data.dashboard.personalLeadsEarnings ? (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            sx={{
              marginTop: "26px"
            }}
          >
            <Stack direction="row" alignItems="center">
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: "auto",
                  maxHeight: { xs: 22 },
                  maxWidth: { xs: 22 }
                }}
                alt="hand"
                src="/app/assets/wave-hand.svg"
              />
              <Typography
                variant="h6"
                color="text.primary"
                sx={{ margin: "0 14px 0 6px" }}
              >{`Hi ${data.dashboard.rank.firstName}`}</Typography>
              <Chip
                label={`Rank # ${data.dashboard.rank.rank}`}
                sx={theme => chipStyle(theme, data.dashboard.rank.rank)}
                style={{ marginBottom: 0 }}
              />
            </Stack>
          </Stack>
          <BrokerDetails
            data={data.dashboard}
            boxStyle={boxStyle}
            lead={lead}
            setLead={setLead}
            open={open}
            setOpen={setOpen}
          />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default BrokersDashboard;
