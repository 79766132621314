import React from "react";
import Footer from "./Footer";
import { Box } from "@mui/material";
import NavBar from "./NavBar";

function NonAuthenticatedWrapper({ children }) {
  return (
    <Box
      sx={theme => ({
        minHeight: "89vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bgcolor: "background.default",
        padding: "62px 52px 34px 52px",
        [theme.breakpoints.down("lg")]: {
          minHeight: "92vh",
          padding: "54px 32px",
          paddingBottom: "110px",
          "& >div:nth-of-type(1)": {
            marginBottom: "auto",
            marginTop: "48px",
            [theme.breakpoints.down("lg")]: {
              marginTop: 0
            }
          }
        },
        "& >.MuiGrid-root.MuiGrid-container": {
          minHeight: "78vh"
        }
      })}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
      }}
    >
      <NavBar />
      {children}
      <Footer isFooterMenu={false} />
    </Box>
  );
}

export default NonAuthenticatedWrapper;
