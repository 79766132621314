import React from "react";
import { useSelector } from "react-redux";
import Header from "components/Header";
import Loader from "components/Loader";
import { Typography, Box } from "@mui/material";
import CompanyOwnerDashboard from "./CompanyOwnerDashboard";
import BrokersDashboard from "./BrokersDashboard";
import { rankChipBgColor, rankChipColor } from "utils";
import { USER_TYPE } from "utils/constants";

const boxStyle = theme => {
  return {
    [theme.breakpoints.down("xl")]: {
      padding: "20px"
    },
    padding: "32px",
    borderRadius: "16px",
    background: theme.palette.text.dark.primary
  };
};

const chipStyle = (theme, rankNumber) => {
  return {
    color: rankChipColor(rankNumber),
    bgcolor: rankChipBgColor(rankNumber),
    fontSize: "13px",
    fontWeight: "700",
    fontFamily: "'Nunito Sans', sans-serif",
    height: "24px",
    marginBottom: "16px"
  };
};

const Dashboard = () => {
  const { data, loading } = useSelector(state => state.user);

  const getDashboard = userType => {
    if (userType === USER_TYPE.COMPANY_OWNER) {
      return (
        <CompanyOwnerDashboard boxStyle={boxStyle} chipStyle={chipStyle} />
      );
    } else if (userType === USER_TYPE.BROKER) {
      return <BrokersDashboard boxStyle={boxStyle} chipStyle={chipStyle} />;
    } else {
      return (
        <Typography variant="h6" color="text.primary">
          No dashboard for admin
        </Typography>
      );
    }
  };

  return (
    <>
      <Header componentName="Dashboard" />
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          display={{ xs: "block", lg: "none" }}
          color="text.primary"
          marginTop="24px"
          component="h2"
          variant="h4"
        >
          Dashboard
        </Typography>
        {loading ? <Loader /> : getDashboard(data?.me?.type)}
      </Box>
    </>
  );
};

export default Dashboard;
