import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  data: [],
  registrationCheckout: {},
  profileImage: "",
  isPaymentFailedModal: false,
  error: null
};

export const registration = createSlice({
  name: "registration",
  initialState,
  reducers: {
    updateUserRegistrationProcess: (state, action) => {
      state.loading = true;
    },
    updateUserRegistrationSucceed: (state, action) => {
      state.loading = false;
    },
    updateUserRegistrationFailed: state => {
      state.loading = false;
    },
    getRegistration: state => {
      state.loading = true;
    },
    getRegistrationSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getRegistrationFailed: state => {
      state.loading = false;
    },
    getRegistrationCheckout: state => {
      state.loading = true;
    },
    getRegistrationCheckoutSucceed: (state, action) => {
      state.loading = false;
      state.registrationCheckout = action.payload;
    },
    getRegistrationCheckoutFailed: state => {
      state.loading = false;
    },
    completeRegistration: state => {
      state.loading = true;
    },
    completeRegistrationSucceed: (state, action) => {
      state.loading = false;
      state.registrationCheckout = action.payload;
    },
    completeRegistrationFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    signUpRequest: state => {
      state.loading = true;
    },
    signUpRequestSucceed: state => {
      state.loading = true;
    },
    signUpRequestFailed: state => {
      state.loading = true;
    },
    updateCompletePaymentModal: (state, action) => {
      state.isPaymentFailedModal = action.payload;
    },
    getSignupRequest: state => {
      state.loading = true;
    },
    getSignupRequestSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getSignupRequestFailed: state => {
      state.loading = false;
    }
  }
});

export const {
  updateUserRegistrationProcess,
  updateUserRegistrationSucceed,
  updateUserRegistrationFailed,
  getRegistration,
  getRegistrationSucceed,
  getRegistrationFailed,
  getRegistrationCheckout,
  getRegistrationCheckoutSucceed,
  getRegistrationCheckoutFailed,
  completeRegistration,
  completeRegistrationSucceed,
  completeRegistrationFailed,
  updateProfileImage,
  signUpRequest,
  signUpRequestSucceed,
  signUpRequestFailed,
  updateCompletePaymentModal,
  getSignupRequest,
  getSignupRequestSucceed,
  getSignupRequestFailed,
} = registration.actions;

export default registration.reducer;
