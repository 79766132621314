import React from "react";
import { useDispatch } from "react-redux";
import {
  Drawer,
  Checkbox,
  Typography,
  Box,
  Stack,
  FormControlLabel
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { inDueDays, dueDaysDiff } from "utils";
import { updateTask } from "reducers/tasks";

function TaskDrawer({ position, toggleDrawer, drawerTasks }) {
  const dispatch = useDispatch();

  const handleOnCheckChange = (e, id) => {
    dispatch(updateTask({ id, data: { isCompleted: e.target.checked } }));
  };

  return (
    <Drawer
      anchor={"right"}
      open={position["right"]}
      onClose={toggleDrawer(false)}
      sx={theme => ({
        [theme.breakpoints.down("sm")]: {
          "& .MuiDrawer-paper": {
            width: "100%",
            bgcolor: theme.palette.text.dark.primary
          }
        }
      })}
    >
      <Box
        sx={theme => ({
          width: 400,
          padding: "56px 32px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0
          }
        })}
        role="presentation"
      >
        <Box
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              padding: "56px 32px"
            }
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="text.primary" component="div">
              Tasks
            </Typography>
            <CloseIcon
              onClick={toggleDrawer(false)}
              sx={{ color: "text.primary", cursor: "pointer" }}
            />
          </Stack>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            margin="16px 0"
          >
            ID : {drawerTasks.id}
          </Typography>
          {drawerTasks.tasks?.map(task => (
            <Box key={task.id}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="caption" color="text.secondary">
                  {moment(task.dueDate).format("ddd D MMM YYYY hh:mm A")}
                </Typography>
                <Typography
                  variant="caption"
                  sx={theme =>
                    dueDaysDiff(task.dueDate) >= 0
                      ? { color: "text.secondary" }
                      : { color: "error.main" }
                  }
                  style={{ fontStyle: "italic" }}
                >
                  {inDueDays(task.dueDate)}
                </Typography>
              </Stack>

              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ color: "text.primary" }}
                    defaultChecked={task.isCompleted}
                    onClick={e => handleOnCheckChange(e, task.id)}
                  />
                }
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  fontSize: "16px",
                  fontFamily: "'Nunito Sans', sans-serif",
                  wordBreak: "break-word",
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: "24px"
                  }
                })}
                label={task.name}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  );
}

export default TaskDrawer;
