import createSagaMiddleware from "redux-saga";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";

import user from "reducers/users";
import leads from "reducers/leads";
import cases from "reducers/cases";
import notifications from "reducers/notifications";
import tasks from "reducers/tasks";
import reports from "reducers/reports";
import credits from "reducers/credits";
import dashboard from "reducers/dashboard";
import registration from "reducers/registration";
import company from "reducers/company";
import clients from "reducers/clients";
import emailsContent from "reducers/emails";
import { rootSaga } from "../sagas";

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory({ basename: "/app" });

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const reducer = combineReducers({
  router: connectRouter(history),
  user,
  leads,
  cases,
  notifications,
  tasks,
  reports,
  credits,
  dashboard,
  registration,
  company,
  clients,
  emailsContent
});

const store = configureStore({
  reducer,
  sentryReduxEnhancer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: false
    }),
    sagaMiddleware,
    routerMiddleware(history)
  ]
});

sagaMiddleware.run(rootSaga);

export default store;
