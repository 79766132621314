import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "components/Header";
import { updateActiveTab, updateFilters, resetFilters } from "reducers/reports";
import { resetBroker, getBrokers, getCompanies } from "reducers/users";
import FilterListIcon from "@mui/icons-material/FilterList";
import LeadConversionReport from "./LeadConversionReport";
import MortgageSoldReport from "./MortgageSoldReport";
import InsuranceSoldReport from "./InsuranceSoldReport";
import ProductsEarningsReport from "./ProductsEarningsReport";
import CreditsUsedReport from "./CreditsUsedReport";
import ProductsSoldReport from "./ProductsSoldReport";
import ContactRateReport from "./ContactRateReport";
import FilterDrawer from "./FilterDrawer";
import { REPORTS_TABS, USER_TYPE } from "utils/constants";
import { Box, Tab, Tabs, Typography, Button, Stack } from "@mui/material";
import { firstDateOfCurrentMonth } from "utils";

function TabPanel(props) {
  const { children, index } = props;
  const { activeTab } = useSelector(state => state.reports);

  return <div>{activeTab === index && <Box> {children} </Box>}</div>;
}

const Reports = () => {
  const dispatch = useDispatch();
  const { filters, activeTab } = useSelector(state => state.reports);
  const { data: userData } = useSelector(state => state.user);

  const [startDate, setStartDate] = useState(filters.start);
  const [endDate, setEndDate] = useState(filters.end);
  const [selectedCompany, setSelectedCompany] = useState(filters.company);
  const [selectedBrokers, setSelectedBrokers] = useState([]);
  const [filterPosition, setFilterPosition] = useState({ right: false });

  const handleTabChange = (e, value) => {
    dispatch(updateActiveTab(value));
    dispatch(resetFilters());
    if (userData?.me?.type === USER_TYPE.ADMIN) {
      dispatch(resetBroker());
    }
    setStartDate(firstDateOfCurrentMonth());
    setEndDate(new Date());
    setSelectedBrokers([]);
    setSelectedCompany("");
  };

  const resetFilterBtn = () => {
    dispatch(resetFilters());
    if (userData?.me?.type === USER_TYPE.ADMIN) {
      dispatch(resetBroker());
    }
    setStartDate(firstDateOfCurrentMonth());
    setEndDate(new Date());
    setSelectedBrokers([]);
    setSelectedCompany("");
  };

  const handleCampanyChange = e => {
    setSelectedCompany(e.target.value);
    dispatch(getBrokers(e.target.value));
    dispatch(
      updateFilters({
        ...filters,
        company: e.target.value,
        brokers: null
      })
    );
    setSelectedBrokers([]);
  };

  const handleFilter = () => {
    dispatch(
      updateFilters({
        ...filters,
        start: startDate,
        end: endDate,
        company: selectedCompany,
        brokers: selectedBrokers
      })
    );
    toggleFilterDrawer(false)();
  };

  const handleBrokerChange = e => {
    setSelectedBrokers(e.target.value);
  };

  const toggleFilterDrawer = open => () => {
    setFilterPosition({ ...filterPosition, right: open });
  };

  useEffect(() => {
    if (userData?.me?.type === USER_TYPE.COMPANY_OWNER) {
      dispatch(getBrokers());
    }
    if (userData?.me?.type === USER_TYPE.ADMIN) {
      dispatch(getCompanies());
    }
  }, [dispatch, userData?.me, activeTab]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
      dispatch(resetBroker());
    };
  }, [dispatch]);

  return (
    <>
      <Header componentName="Reports" />
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginTop="24px"
          marginBottom="24px"
        >
          <Typography
            variant="h4"
            color="text.primary"
            component="h2"
            display={{ xs: "block", lg: "none" }}
          >
            Reports
          </Typography>
          <Stack display={{ xs: "block", lg: "none" }}>
            <Button
              variant="outlined"
              sx={{
                color: "text.primary",
                borderColor: "text.primary",
                opacity: 0.5,
                cursor: "pointer"
              }}
              onClick={toggleFilterDrawer(true)}
            >
              <FilterListIcon />
              FILTER
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ marginBottom: "40px" }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={theme => ({
              "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                color: `${theme.palette.primary.main} !important`
              }
            })}
          >
            <Tab
              label="LEAD CONVERSION"
              value={REPORTS_TABS.LEAD_CONVERSION}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "22px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="MORTGAGES SOLD"
              value={REPORTS_TABS.MORTGAGE_SOLD}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "22px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="INSURANCES SOLD"
              value={REPORTS_TABS.INSURANCE_SOLD}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "22px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="PRODUCTS SOLD"
              value={REPORTS_TABS.PRODUCTS_SOLD}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "22px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="CREDITS USED"
              value={REPORTS_TABS.CREDITS_USED}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "22px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="PRODUCTS EARNINGS"
              value={REPORTS_TABS.PRODUCTS_EARNINGS}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "22px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="CONTACT RATE %"
              value={REPORTS_TABS.CONTACT_RATE}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "22px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
          </Tabs>
        </Box>
        <TabPanel index={REPORTS_TABS.LEAD_CONVERSION}>
          <LeadConversionReport
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
          />
        </TabPanel>
        <TabPanel index={REPORTS_TABS.MORTGAGE_SOLD}>
          <MortgageSoldReport
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
          />
        </TabPanel>
        <TabPanel index={REPORTS_TABS.INSURANCE_SOLD}>
          <InsuranceSoldReport
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
          />
        </TabPanel>
        <TabPanel index={REPORTS_TABS.CREDITS_USED}>
          <CreditsUsedReport
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
          />
        </TabPanel>
        <TabPanel index={REPORTS_TABS.PRODUCTS_EARNINGS}>
          <ProductsEarningsReport
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
          />
        </TabPanel>
        <TabPanel index={REPORTS_TABS.PRODUCTS_SOLD}>
          <ProductsSoldReport
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
          />
        </TabPanel>
        <TabPanel index={REPORTS_TABS.CONTACT_RATE}>
          <ContactRateReport
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
          />
        </TabPanel>
      </div>
      <FilterDrawer
        position={filterPosition}
        toggleDrawer={toggleFilterDrawer}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedCompany={selectedCompany}
        handleCampanyChange={handleCampanyChange}
        selectedBrokers={selectedBrokers}
        handleBrokerChange={handleBrokerChange}
        handleFilter={handleFilter}
        resetFilterBtn={resetFilterBtn}
      />
    </>
  );
};

export default Reports;
