import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserRegistrationProcess } from "reducers/registration";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { Button, Stack, Box } from "@mui/material";
import Steps from "./Steps";
import { LIGHT } from "utils/constants";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";

function StripePaymentComponent({ billingData, activeStep }) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(history.location.search);
  const parsed = queryParams.get("token");
  const [token, setToken] = useState(parsed);

  const { theme } = useSelector(state => state.user);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });

    const billingDetails = {
      step: 2,
      billingDetails: {
        billingAddress: {
          address: billingData.address1,
          address2: billingData.address2,
          town: billingData.town,
          postCode: billingData.postCode
        },
        cardDetails: {
          paymentMethod: payload.paymentMethod.id
        }
      }
    };

    dispatch(
      updateUserRegistrationProcess({ data: billingDetails, params: { token } })
    );
  };

  const handleBackBtn = pathName => {
    history.push({
      pathname: `${pathName}`,
      search: `?token=${token}`
    });
  };

  const useOptions = () => {
    return {
      style: {
        base: {
          fontSize: "16px",
          color: theme === LIGHT ? "#010810" : "#C4D1FF",
          "::placeholder": {
            color:
              theme === LIGHT
                ? "rgba(7, 13, 19, 0.26)"
                : "rgba(196, 209, 255, 0.5)"
          }
        },
        invalid: {
          color: "#F44336"
        }
      }
    };
  };

  const options = useOptions();

  return (
    <>
      <Box
        className={
          theme === LIGHT ? "stripeLighthThemeStyles" : "stripeDarkThemeStyles"
        }
      >
        <Stack>
          <label>Card number *</label>
          <CardNumberElement options={options} />
        </Stack>
        <Stack direction="row" spacing={2} width="100%">
          <Stack width="160px">
            <label>Expiration date *</label>
            <CardExpiryElement options={options} />
          </Stack>
          <Stack width="160px">
            <label>CVC *</label>
            <CardCvcElement options={options} />
          </Stack>
        </Stack>
        <Stack display={{ xs: "none", lg: "block" }}>
          <Stack spacing={4} direction="row" marginTop="32px">
            <Button
              color="primary"
              onClick={() =>
                handleBackBtn(`${ROUTES.REGISTRATION_BUSINESS_DETAILS}`)
              }
              variant="contained"
            >
              BACK
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              disabled={!stripe}
              variant="contained"
            >
              NEXT
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Stack
        display={{ xs: "block", lg: "none" }}
        sx={{
          position: "absolute",
          width: "100%",
          left: 0,
          bottom: 0
        }}
      >
        <Steps
          activeStep={activeStep}
          handleSubmit={handleSubmit}
          isStripe={true}
          handleBackBtn={() =>
            handleBackBtn(`${ROUTES.REGISTRATION_BUSINESS_DETAILS}`)
          }
        />
      </Stack>
    </>
  );
}

export default StripePaymentComponent;
