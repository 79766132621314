import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getDashboardAPI = () =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_DASHBOARD
  });

export const getLeaderboardAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_LEADERBOARD,
    params
  });

export const getBrokerDashboardAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_DASHBOARD,
    params
  });
