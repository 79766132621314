import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as Api from "api/users";
import * as actions from "../../reducers/users";
import { ROUTES } from "../../routes/constants";
import { ONESIGNAL_PLAYER_ID, TOKEN } from "../../utils/constants";
import { toast } from "react-toastify";
import { getLocalStorageItem, removeLocalStorageItem } from "../../utils";

function* loginSaga({ payload }) {
  try {
    const resp = yield call(Api.loginAPI, payload);
    if (resp) {
      yield put(actions.loginSucceed());
      localStorage.setItem(TOKEN, resp.data.key);
      yield put(push(ROUTES.LEADS));
    }
  } catch (error) {
    yield put(actions.loginFailed(error));
  }
}

function* forgotPasswordSaga({ payload }) {
  try {
    const { email } = payload;
    yield call(Api.forgotPasswordAPI, payload);
    yield put(actions.forgotPasswordSucceed());
    yield put(push(`/forgot-password-success/${email}`));
  } catch (error) {
    yield put(actions.forgotPasswordFailed(error));
  }
}

function* confirmPasswordSaga({ payload }) {
  try {
    yield call(Api.confirmPasswordAPI, payload);
    yield put(actions.confirmPasswordSucceed());
    yield put(push(ROUTES.LOGIN));
    toast.success("You have successfully reset your password.");
  } catch (error) {
    yield put(actions.confirmPasswordFailed(error));
  }
}

function* changePasswordSaga({ payload }) {
  try {
    yield call(Api.changePasswordAPI, payload);
    yield put(actions.changePasswordSucceed());
    yield put(push(ROUTES.SETTINGS));
    toast.success("You have successfully changed your password.");
  } catch (error) {
    yield put(actions.changePasswordFailed(error));
  }
}

function* getUserSaga() {
  try {
    const resp = yield call(Api.getUserAPI);
    yield put(actions.getUserSucceed(resp.data));
  } catch (error) {
    yield put(actions.getUserFailed(error));
  }
}

function* getBrokersSaga({ payload }) {
  try {
    const resp = yield call(Api.getBrokersAPI, { company: payload });
    yield put(actions.getBrokersSucceed(resp.data));
  } catch (error) {
    yield put(actions.getBrokersFailed(error));
  }
}

function* logoutSaga() {
  try {
    const resp = yield call(Api.logoutAPI, {
      playerId: getLocalStorageItem(ONESIGNAL_PLAYER_ID)
    });
    yield put(actions.logoutSucceed(resp.data));
    removeLocalStorageItem(TOKEN);
    removeLocalStorageItem(ONESIGNAL_PLAYER_ID);
    yield put(push(ROUTES.LOGIN));
  } catch (error) {
    yield put(actions.logoutFailed(error));
  }
}

function* getCompaniesSaga() {
  try {
    const resp = yield call(Api.getCompaniesAPI);
    yield put(actions.getCompaniesSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCompaniesFailed(error));
  }
}

function* updateEmailPreferenceSaga({ payload }) {
  try {
    yield call(Api.updateEmailPreferenceAPI, payload);
    yield put(actions.updateEmailPreferenceSucceed());
    yield put(actions.getUser());
    toast.success("You have successfully updated email preference.");
  } catch (error) {
    yield put(actions.updateEmailPreferenceFailed(error));
  }
}

function* updateUserSaga({ payload }) {
  try {
    yield call(Api.updateUserAPI, payload);
    yield put(actions.updateUserSucceed());
    yield put(actions.getUser());
    toast.success("You have successfully updated your profile.");
  } catch (error) {
    yield put(actions.updateUserFailed(error));
  }
}

function* getUnreadLeadCountSaga() {
  try {
    const resp = yield call(Api.getUserAPI);
    yield put(actions.getUnreadLeadCountSucceed(resp.data.unreadLeadsCount));
  } catch (error) {
    yield put(actions.getUnreadLeadCountFailed(error));
  }
}

export function* usersSaga() {
  yield takeLatest(actions.login.type, loginSaga);
  yield takeLatest(actions.forgotPassword.type, forgotPasswordSaga);
  yield takeLatest(actions.confirmPassword.type, confirmPasswordSaga);
  yield takeLatest(actions.changePassword.type, changePasswordSaga);
  yield takeLatest(actions.getUser.type, getUserSaga);
  yield takeLatest(actions.getBrokers.type, getBrokersSaga);
  yield takeLatest(actions.logout.type, logoutSaga);
  yield takeLatest(actions.getCompanies.type, getCompaniesSaga);
  yield takeLatest(
    actions.updateEmailPreference.type,
    updateEmailPreferenceSaga
  );
  yield takeLatest(actions.updateUser.type, updateUserSaga);
  yield takeLatest(actions.getUnreadLeadCount.type, getUnreadLeadCountSaga);
}
