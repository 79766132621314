import React, { useState } from "react";
import { Box, Stack, Button, Pagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Task from "../Tasks/Task";
import AddEditStatusModal from "./AddEditStatusModal";
import { useHistory, useParams } from "react-router-dom";
import { ADD_PRODUCT, ADD_TASK, ADD_STATUS } from "utils/constants";
import { paginationStyle } from "utils";

function CaseDetailPagination({
  addNewBtn,
  page,
  rowsPerPage,
  data,
  handleOnPageChange,
  handleTask,
  handleCaseStatus,
  status
}) {
  const history = useHistory();
  const { id } = useParams();
  const [isTaskModal, setIsTaskModal] = useState(false);
  const [isStatusModal, setIsStatusModal] = useState(false);
  const [task, setTask] = useState({});

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
          position: "absolute",
          bottom: 0
        }}
      >
        <Button
          variant="text"
          size="large"
          sx={{
            marginRight: "24px"
          }}
          startIcon={<AddIcon />}
          onClick={
            addNewBtn === ADD_PRODUCT
              ? () => {
                  history.push(`/cases/${id}/add-product`);
                }
              : addNewBtn === ADD_TASK
              ? () => {
                  setIsTaskModal(true);
                }
              : addNewBtn === ADD_STATUS
              ? () => {
                  setIsStatusModal(true);
                }
              : ""
          }
        >
          {addNewBtn}
        </Button>
        {data.count > 0 && (
          <Pagination
            count={Math.ceil(data.count / rowsPerPage)}
            page={page}
            onChange={handleOnPageChange}
            sx={theme => paginationStyle(theme)}
            style={{ position: "unset", width: "unset" }}
          />
        )}
      </Stack>
      {isTaskModal && (
        <Box>
          <Task
            isOpen={isTaskModal}
            handleClose={() => setIsTaskModal(false)}
            handleTask={handleTask}
            task={task}
          />
        </Box>
      )}
      {isStatusModal && (
        <Box>
          <AddEditStatusModal
            add
            title="Add status"
            isOpen={isStatusModal}
            handleClose={() => setIsStatusModal(false)}
            handleCaseStatus={handleCaseStatus}
            status={status}
            isDisabled={false}
          />
        </Box>
      )}
    </>
  );
}

export default CaseDetailPagination;
