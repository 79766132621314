import React from "react";
import Modal from "components/Modals/index";
import { useSelector, useDispatch } from "react-redux";
import { LIGHT } from "utils/constants";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from "@stripe/react-stripe-js";
import { Button, Box, Stack } from "@mui/material";
import { createPaymentMethod } from "reducers/company";

const AddCardModal = ({ isOpen, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { theme } = useSelector(state => state.user);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });

    const cardDetails = {
      stripeId: payload.paymentMethod.id
    };

    dispatch(createPaymentMethod(cardDetails));
    handleClose();
  };

  const useOptions = () => {
    return {
      style: {
        base: {
          fontSize: "16px",
          color: theme === LIGHT ? "#010810" : "#C4D1FF",
          "::placeholder": {
            color:
              theme === LIGHT
                ? "rgba(7, 13, 19, 0.26)"
                : "rgba(196, 209, 255, 0.5)"
          }
        },
        invalid: {
          color: "#F44336"
        }
      }
    };
  };

  const options = useOptions();

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Add Card">
      <Box
        className={
          theme === LIGHT ? "stripeLighthThemeStyles" : "stripeDarkThemeStyles"
        }
      >
        <Stack>
          <label>Card number</label>
          <CardNumberElement options={options} />
        </Stack>
        <Stack direction="row" spacing={2} width="100%">
          <Stack width="160px">
            <label>Expiration date</label>
            <CardExpiryElement options={options} />
          </Stack>
          <Stack width="160px">
            <label>CVC</label>
            <CardCvcElement options={options} />
          </Stack>
        </Stack>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          })}
        >
          ADD
        </Button>
      </Box>
    </Modal>
  );
};

export default AddCardModal;
