import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { profileImageUploaderStyles } from "utils";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { updateCompanies, updateProfileImage } from "reducers/company";
import {
  Typography,
  Box,
  Button,
  FormControl,
  TextField,
  Stack,
  Card
} from "@mui/material";
import { LIGHT } from "utils/constants";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);

function InfoTab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [legalText, setLegalText] = useState("");
  const [infoDetails, setInfoDetails] = useState({});
  const [isError, setIsError] = useState(false);

  const { data, theme } = useSelector(state => state.user);
  const { defaultCompanyData, profileImage } = useSelector(
    state => state.company
  );

  const handleRichTextChange = value => setLegalText(value);

  const uploadProfilePhoto = e => {
    let imgWrapper = document.querySelector(".profilePhoto");
    let img = imgWrapper.querySelector("img");
    if (img) {
      img.remove();
    }
    img = document.createElement("img");
    img.src = URL.createObjectURL(e.target.files[0]);
    imgWrapper.appendChild(img);
    setIsError(false);

    dispatch(updateProfileImage(e.target.files[0]));
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = value => {
    if (!profileImage && !defaultCompanyData.profileImage) {
      setIsError(true);
      return;
    }

    const formData = new FormData();
    formData.append("profileImage", profileImage);
    formData.append("email", value.email);
    formData.append("telephone", value.telephone);
    formData.append("website", value.website);
    formData.append("otherLeadsName", value.otherLeadsName);
    formData.append("legalText", legalText);

    const emailsData = {
      id: data.me.company,
      data: formData
    };

    dispatch(updateCompanies(emailsData));
  };

  const onError = () => {
    if (!profileImage && !defaultCompanyData.profileImage) {
      setIsError(true);
    }
  };

  useEffect(() => {
    setInfoDetails({
      ...infoDetails,
      email: defaultCompanyData?.email,
      telephone: defaultCompanyData?.telephone,
      website: defaultCompanyData?.website,
      otherLeadsName: defaultCompanyData?.otherLeadsName
    });
    reset({
      email: defaultCompanyData?.email,
      telephone: defaultCompanyData?.telephone,
      website: defaultCompanyData?.website,
      otherLeadsName: defaultCompanyData?.otherLeadsName
    });
    setLegalText(defaultCompanyData?.legalText);
  }, [defaultCompanyData]);

  const handleChange = (key, value) => {
    setInfoDetails({ ...infoDetails, [key]: value });
  };

  return (
    <Box
      sx={theme => ({
        [theme.breakpoints.down("lg")]: {
          marginTop: "24px"
        }
      })}
    >
      <form onSubmit={handleSubmit(onSubmit, () => onError(null))}>
        <Stack
          spacing={2}
          sx={theme => ({
            width: "170px",
            marginBottom: "24px",
            [theme.breakpoints.down("lg")]: {
              marginLeft: "auto",
              marginRight: "auto"
            }
          })}
        >
          <Card
            className={`profilePhoto ${(defaultCompanyData.profileImage ||
              profileImage) &&
              "upload"} ${isError && "required"}`}
            sx={theme => profileImageUploaderStyles(theme)}
          >
            {defaultCompanyData.profileImage && (
              <img src={`${defaultCompanyData.profileImage}`} alt="" />
            )}
          </Card>
          {isError && <div className="validation-error">Image required!</div>}
          <Button variant="text" component="label" startIcon={<PanoramaIcon />}>
            Upload
            <input hidden type="file" onChange={uploadProfilePhoto} />
          </Button>
        </Stack>
        <Stack spacing={3}>
          <FormControl>
            <TextField
              fullWidth
              type="email"
              label="Email"
              placeholder="Email"
              error={!!errors.email}
              className={classes.textField}
              defaultValue={defaultCompanyData?.email || ""}
              {...register("email", {
                required: true,
                onChange: e => handleChange("email", e.target.value)
              })}
              InputLabelProps={{
                shrink: infoDetails.email ? true : false
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              fullWidth
              type="tel"
              label="Telephone"
              placeholder="Telephone"
              error={!!errors.telephone}
              className={classes.textField}
              defaultValue={defaultCompanyData?.telephone || ""}
              {...register("telephone", {
                required: true,
                onChange: e => handleChange("telephone", e.target.value)
              })}
              InputLabelProps={{
                shrink: infoDetails.telephone ? true : false
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              fullWidth
              type="text"
              label="Website"
              placeholder="Website"
              error={!!errors.website}
              className={classes.textField}
              defaultValue={defaultCompanyData?.website || ""}
              {...register("website", {
                required: true,
                onChange: e => handleChange("website", e.target.value)
              })}
              InputLabelProps={{
                shrink: infoDetails.website ? true : false
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              fullWidth
              type="text"
              label="Other Leads Name"
              placeholder="Other Leads Name"
              error={!!errors.otherLeadsName}
              className={classes.textField}
              defaultValue={defaultCompanyData?.otherLeadsName || ""}
              {...register("otherLeadsName", {
                required: true,
                onChange: e => handleChange("otherLeadsName", e.target.value)
              })}
              InputLabelProps={{
                shrink: infoDetails.otherLeadsName ? true : false
              }}
            />
          </FormControl>
        </Stack>
        <Stack>
          <Typography color="text.primary" margin="16px 0 8px 0">
            Legal Text
          </Typography>
          <ReactQuill
            value={legalText}
            onChange={handleRichTextChange}
            placeholder="Legal Text"
            className={
              theme === LIGHT ? "light-theme-quill" : "dark-theme-quill"
            }
          />
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={theme => ({
            margin: "24px 0",
            [theme.breakpoints.down("lg")]: {
              width: "100%"
            }
          })}
        >
          UPDATE
        </Button>
      </form>
    </Box>
  );
}

export default InfoTab;
