import React from "react";
import {
  Drawer,
  Typography,
  TextField,
  Box,
  Stack,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  ListItemButton,
  FormControl
} from "@mui/material";
import { useSelector } from "react-redux";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { LEAD_CATEGORY_FILTER_DROPDOWN, PROPERTY_TYPE_FILTER_DROPDOWN, USER_TYPE } from "utils/constants";
import SelectField from "components/SelectField";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.text.dark.primary)
);

function FilterDrawer({
  filters,
  position,
  toggleDrawer,
  getSortingIcon,
  getSortingColor,
  handleOnSearchChange,
  handleOnSortFilterChange,
  handleOnRangeFilterChange,
  leadCategory,
  propertyType,
  handleLeadCategoryChange,
  handlePropertyTypeChange,
  drawerFilterSearch,
  handleDrawerFilterSearchChange,
  min,
  max,
  setMin,
  setMax
}) {
  const classes = useStyles();
  const { me: user } = useSelector(state => state.user.data);

  return (
    <Drawer
      anchor={"right"}
      open={position["right"]}
      onClose={toggleDrawer(false)}
      sx={theme => ({
        [theme.breakpoints.down("sm")]: {
          "& .MuiDrawer-paper": {
            width: "100%",
            bgcolor: theme.palette.text.dark.primary
          }
        }
      })}
    >
      <Box
        sx={theme => ({
          width: 400,
          padding: "56px 32px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0
          }
        })}
        role="presentation"
      >
        <Box
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              padding: "56px 32px"
            }
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: "24px" }}
          >
            <Typography variant="h4" color="text.primary" component="div">
              FILTER
            </Typography>
            <CloseIcon
              onClick={toggleDrawer(false)}
              sx={{ color: "text.primary" }}
            />
          </Stack>
          <Stack display={{ xs: "block", lg: "none" }}>
            <Stack sx={{ marginBottom: "24px" }}>
              <TextField
                className={classes.textField}
                variant="outlined"
                label="Search"
                value={drawerFilterSearch}
                onChange={handleDrawerFilterSearchChange}
              />
              <Button
                variant="contained"
                sx={{ marginTop: "24px" }}
                onClick={() => handleOnSearchChange(drawerFilterSearch)}
              >
                Search
              </Button>
            </Stack>
            <Stack sx={{ marginBottom: "24px" }}>
              <Typography>Sort & Filters:</Typography>
              <List dense disablePadding>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => handleOnSortFilterChange("modified")}
                    sx={{ padding: 0, margin: "12px 0" }}
                    disableRipple
                  >
                    <ListItemIcon sx={{ minWidth: "25px" }}>
                      {getSortingIcon(filters.sorting.modified)}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="link2"
                          color={getSortingColor(filters.sorting.modified)}
                          ml={1}
                        >
                          DATE
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding style={{ width: "auto" }}>
                  <ListItemButton
                    onClick={() => handleOnSortFilterChange("property_amount")}
                    sx={{ padding: 0, margin: "12px 0" }}
                    disableRipple
                  >
                    <ListItemIcon style={{ minWidth: "25px" }}>
                      {getSortingIcon(filters.sorting.property_amount)}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="link2"
                          color={getSortingColor(
                            filters.sorting.property_amount
                          )}
                          ml={1}
                        >
                          PROPERTY VALUE
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding style={{ width: "auto" }}>
                  <ListItemButton
                    onClick={() => handleOnSortFilterChange("mortgage_amount")}
                    sx={{ padding: 0, margin: "12px 0" }}
                    disableRipple
                  >
                    <ListItemIcon style={{ minWidth: "25px" }}>
                      {getSortingIcon(filters.sorting.mortgage_amount)}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="link2"
                          color={getSortingColor(
                            filters.sorting.mortgage_amount
                          )}
                          ml={1}
                        >
                          MORTGAGE VALUE
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                {user?.type !== USER_TYPE.ADMIN && (
                  <ListItem disablePadding style={{ width: "auto" }}>
                    <ListItemButton
                      onClick={() => handleOnSortFilterChange("is_favourite")}
                      sx={{ padding: 0, margin: "12px 0" }}
                      disableRipple
                    >
                      <ListItemIcon style={{ minWidth: "25px" }}>
                        {getSortingIcon(filters.sorting.is_favourite)}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="link2"
                            color={getSortingColor(
                              filters.sorting.is_favourite
                            )}
                            ml={1}
                          >
                            FAVOURITE
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {user?.type === USER_TYPE.ADMIN && (
                  <ListItem disablePadding style={{ width: "auto" }}>
                    <ListItemButton
                      onClick={() => handleOnSortFilterChange("is_active")}
                      sx={{ padding: 0, margin: "12px 0" }}
                      disableRipple
                    >
                      <ListItemIcon style={{ minWidth: "25px" }}>
                        {getSortingIcon(filters.sorting.is_active)}
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="link2"
                            color={getSortingColor(filters.sorting.is_active)}
                            ml={1}
                          >
                            ACTIVE
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Stack>
          </Stack>
          <Stack margin="16px 0">
            <FormControl sx={{ width: "100%" }}>
              <SelectField
                label="Lead Category"
                value={leadCategory}
                onChange={handleLeadCategoryChange}
                options={LEAD_CATEGORY_FILTER_DROPDOWN.map(
                  ({ value, name }) => ({
                    value: value,
                    label: name
                  })
                )}
              />
            </FormControl>
          </Stack>
          <Stack margin="16px 0">
            <FormControl sx={{ width: "100%" }}>
              <SelectField
                label="Property Type"
                value={propertyType}
                onChange={handlePropertyTypeChange}
                options={PROPERTY_TYPE_FILTER_DROPDOWN.map(
                  ({ value, name }) => ({
                    value: value,
                    label: name
                  })
                )}
              />
            </FormControl>
          </Stack>
          <Typography sx={{ fontWeight: "600" }}>Range</Typography>
          <Stack direction="row" spacing={1} sx={{ marginTop: "24px" }}>
            <TextField
              className={classes.textField}
              type="number"
              sx={{ flex: "0 0 50%" }}
              label="Min"
              onChange={e => setMin(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                )
              }}
              value={min}
            />
            <TextField
              className={classes.textField}
              type="number"
              sx={{ flex: "0 0 50%" }}
              label="Max"
              onChange={e => setMax(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                )
              }}
              value={max}
            />
          </Stack>
          <Stack direction="row" spacing={1} sx={{ marginTop: "24px" }}>
            <Button
              size="large"
              variant="contained"
              sx={{ flex: "0 0 50%" }}
              onClick={() => handleOnRangeFilterChange(min, max)}
            >
              Apply
            </Button>
            <Button
              color="error"
              variant="outlined"
              sx={{ flex: "0 0 50%" }}
              onClick={() => {
                setMin("");
                setMax("");
              }}
            >
              Clear
            </Button>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
}

export default FilterDrawer;
