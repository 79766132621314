import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCases, changePage } from "reducers/cases";
import { FIRST_PAGE } from "utils/constants";
import CaseListing from "./CaseListing";

const Cases = () => {
  const dispatch = useDispatch();
  const {
    location: { state }
  } = useHistory();

  const { data, page, activeTab, search, filters } = useSelector(
    state => state.cases
  );

  useEffect(() => {
    dispatch(
      changePage(
        state?.page && state?.page !== 1 ? state?.page : page || FIRST_PAGE
      )
    );
    dispatch(getCases());
  }, [dispatch, activeTab, page, search, filters]);

  window.onbeforeunload = function(e) {
    window.history.replaceState({}, document.title);
  };

  return <CaseListing data={data} />;
};

export default Cases;
