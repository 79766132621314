import { call, put, takeLatest, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actions from "reducers/notifications";
import * as userActions from "reducers/users";
import * as Api from "api/notifications";

function* getNotificationsSaga() {
  try {
    const {
      notifications: { filters, page, activeTab }
    } = yield select();
    const resp = yield call(Api.getNotificationsAPI, {
      ...filters,
      page,
      activeTab
    });
    yield put(actions.getNotificationsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getNotificationsFailed(error));
  }
}

function* removeNotificationsSaga({ payload }) {
  try {
    yield call(Api.removeNotificationAPI, payload);
    yield put(actions.removeNotificationsSucceed());
    yield put(actions.getNotifications());
    yield put(actions.getDrawerNotifications());
    toast.success("You have successfully deleted notification(s).");
  } catch (error) {
    yield put(actions.removeNotificationsFailed(error));
  }
}

function* updateNotificationsSaga({ payload }) {
  try {
    yield call(Api.updateNotificationAPI, payload);
    yield put(actions.updateNotificationsSucceed());
    yield put(actions.getNotifications());
    toast.success("You have successfully updated the notification(s).");
  } catch (error) {
    yield put(actions.updateNotificationsFailed(error));
  }
}

function* getDrawerNotificationsSaga() {
  try {
    const {
      notifications: { notificationDrawerPage }
    } = yield select();
    const resp = yield call(Api.getDrawerNotificationsAPI, {
      page: notificationDrawerPage
    });
    yield put(actions.getDrawerNotificationsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getDrawerNotificationsFailed(error));
  }
}

function* postOnesignalPlayerIdSaga({ payload }) {
  try {
    yield call(Api.postOnesignalPlayerIdAPI, payload);
    yield put(actions.postOnesignalPlayerIdSucceed());
  } catch (error) {
    yield put(actions.postOnesignalPlayerIdFailed(error));
  }
}

function* patchPushNotificationPreferenceSaga({ payload }) {
  try {
    yield call(Api.patchPushNotificationPreferenceAPI, payload);
    yield put(actions.patchPushNotificationPreferenceSucceed());
    yield put(userActions.getUser());
    toast.success(
      "You have successfully updated push notification preference."
    );
  } catch (error) {
    yield put(actions.patchPushNotificationPreferenceFailed(error));
  }
}

function* patchSmsPreferenceSaga({ payload }) {
  try {
    yield call(Api.patchSmsPreferenceAPI, payload);
    yield put(actions.patchSmsPreferenceSucceed());
    yield put(userActions.getUser());
    toast.success("You have successfully updated sms preference.");
  } catch (error) {
    yield put(actions.patchSmsPreferenceFailed(error));
  }
}

export function* notificationsSaga() {
  yield takeLatest(actions.getNotifications.type, getNotificationsSaga);
  yield takeLatest(actions.removeNotifications.type, removeNotificationsSaga);
  yield takeLatest(actions.updateNotifications.type, updateNotificationsSaga);
  yield takeLatest(
    actions.getDrawerNotifications.type,
    getDrawerNotificationsSaga
  );
  yield takeLatest(
    actions.postOnesignalPlayerId.type,
    postOnesignalPlayerIdSaga
  );
  yield takeLatest(
    actions.patchPushNotificationPreference.type,
    patchPushNotificationPreferenceSaga
  );
  yield takeLatest(actions.patchSmsPreference.type, patchSmsPreferenceSaga);
}
