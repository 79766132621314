import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Stack, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NonAuthenticateResponsiveMenu from "./NonAuthenticatedResponsiveMenu";
import { ROUTES } from "routes/constants";
import { Link } from "react-router-dom";

function NavBar() {
  const [responsiveMenuPosition, setResponsiveMenuPosition] = useState({
    left: false
  });

  const openResponsiveMenu = open => () => {
    setResponsiveMenuPosition({ responsiveMenuPosition, left: open });
  };
  return (
    <>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          boxShadow: "unset",
          marginBottom: "26px"
        }}
      >
        <Toolbar
          sx={{ padding: "0 !important", justifyContent: "space-between" }}
        >
          <Link to={ROUTES.LOGIN}>
            <Box component="div" sx={{ flexGrow: 1 }}>
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: "auto",
                  maxHeight: { xs: 25, lg: 50 },
                  maxWidth: { xs: 120, lg: 240 }
                }}
                alt="Blabble"
                src="/app/assets/logo.svg"
              />
            </Box>
          </Link>
          <Stack display={{ xs: "block", lg: "none" }}>
            <IconButton onClick={openResponsiveMenu(true)}>
              <MenuIcon sx={{ color: "text.primary", fontSize: "32px" }} />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <NonAuthenticateResponsiveMenu
        position={responsiveMenuPosition}
        toggleMenu={openResponsiveMenu}
      />
    </>
  );
}

export default NavBar;
