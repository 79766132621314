export const ROUTES = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_PASSWORD_SUCCESS: "/forgot-password-success/:email",
  CONFIRM_PASSWORD: "/confirm-password/:token",
  SET_PASSWORD: "/set-password/:token",
  SETTINGS: "/settings",
  DASHBOARD: "/dashboard",
  BROKER_DASHBOARD: "/dashboard/:brokerId",
  LEADS: "/leads",
  CASES: "/cases",
  ADD_NEW_CASE: "/add-new-case",
  EDIT_CASE: "/edit-case/",
  EDIT_CASE_ID: "/edit-case/:id",
  CASE: "/cases/:id",
  NOTIFICATIONS: "/notifications",
  TASKS: "/tasks",
  REPORTS: "/reports",
  ADD_PRODUCT: "/cases/:caseId/add-product",
  EDIT_PRODUCT: "/cases/:caseId/edit-product/:productId",
  LEAD: "/leads/:id",
  CREDITS: "/credits",
  REGISTRATION_BUSINESS_DETAILS: "/registration/business-details",
  REGISTRATION_BILLING_DETAILS: "/registration/billing-details",
  REGISTRATION_REVIEW_DETAILS: "/registration/review-details",
  REGISTRATION_PURCHASED_DETAILS: "/registration/purchased-details",
  SIGNUP_REQUEST: "/registration/signup-request",
  SIGNUP_REQUEST_SUCCESS: "/registration/signup-request-success",
  COMPANY: "/company",
  CLIENTS: "/clients",
  CASE_CLIENT: "/clients/:id",
  EMAILS: "/emails-content",
  EMAIL_CONTENT: "/emails-content/:id",
  LOGOUT: "/logout"
};
