import { createSlice } from "@reduxjs/toolkit";
import { firstDateOfCurrentMonth } from "utils";

const initialState = {
  loading: true,
  data: {
    leadConversion: [],
    mortgageSold: [],
    insuranceSold: [],
    creditsUsed: [],
    productsEarnings: [],
    productsSold: [],
    contactRate: []
  },
  filters: {
    start: firstDateOfCurrentMonth(),
    end: new Date(),
    brokers: null,
    company: ""
  },
  isFilterApplied: false,
  error: null,
  activeTab: "leadConversion"
};

export const reports = createSlice({
  name: "reports",
  initialState,
  reducers: {
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
      state.loading = true;
    },
    getLeadConversions: state => {
      state.loading = true;
    },
    getLeadConversionsSucceed: (state, action) => {
      state.loading = false;
      state.data.leadConversion = action.payload;
    },
    getLeadConversionsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMortgageSold: state => {
      state.loading = true;
    },
    getMortgageSoldSucceed: (state, action) => {
      state.loading = false;
      state.data.mortgageSold = action.payload;
    },
    getMortgageSoldFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getInsuranceSold: state => {
      state.loading = true;
    },
    getInsuranceSoldSucceed: (state, action) => {
      state.loading = false;
      state.data.insuranceSold = action.payload;
    },
    getInsuranceSoldFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters.start = action.payload.start;
      state.filters.end = action.payload.end;
      state.filters.brokers = action.payload.brokers;
      state.filters.company = action.payload.company;
      state.isFilterApplied = true;
    },
    getCreditsUsed: state => {
      state.loading = true;
    },
    getCreditsUsedSucceed: (state, action) => {
      state.loading = false;
      state.data.creditsUsed = action.payload;
    },
    getCreditsUsedFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getProductsEarnings: state => {
      state.loading = true;
    },
    getProductsEarningsSucceed: (state, action) => {
      state.loading = false;
      state.data.productsEarnings = action.payload;
    },
    getProductsEarningsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getProductsSold: state => {
      state.loading = true;
    },
    getProductsSoldSucceed: (state, action) => {
      state.loading = false;
      state.data.productsSold = action.payload;
    },
    getProductsSoldFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getContactRate: state => {
      state.loading = true;
    },
    getContactRateSucceed: (state, action) => {
      state.loading = false;
      state.data.contactRate = action.payload;
    },
    getContactRateFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetFilters: state => {
      state.filters.start = firstDateOfCurrentMonth();
      state.filters.end = new Date();
      state.filters.brokers = null;
      state.filters.company = "";
      state.isFilterApplied = false;
    }
  }
});

export const {
  updateActiveTab,
  getLeadConversions,
  getLeadConversionsSucceed,
  getLeadConversionsFailed,
  getMortgageSold,
  getMortgageSoldSucceed,
  getMortgageSoldFailed,
  getInsuranceSold,
  getInsuranceSoldSucceed,
  getInsuranceSoldFailed,
  updateFilters,
  getCreditsUsed,
  getCreditsUsedSucceed,
  getCreditsUsedFailed,
  getProductsEarnings,
  getProductsEarningsSucceed,
  getProductsEarningsFailed,
  getProductsSold,
  getProductsSoldSucceed,
  getProductsSoldFailed,
  getContactRate,
  getContactRateSucceed,
  getContactRateFailed,
  resetFilters
} = reports.actions;

export default reports.reducer;
