import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Stack, Button, Popover } from "@mui/material";

const popoverStyle = {
  borderRadius: "8px",
  padding: "16px",
  width: 220
};

const DeletePopover = ({
  text,
  anchorEl,
  handleClose,
  itemId,
  removeItem,
  bgColor,
  setSelectedItems
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dispatch = useDispatch();

  const handleRemove = () => {
    if (setSelectedItems) {
      dispatch(removeItem({ ids: [...itemId] }));
      setSelectedItems([]);
    } else {
      dispatch(removeItem({ id: itemId }));
    }
    handleClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    >
      <Box sx={{ ...popoverStyle, bgcolor: bgColor }}>
        <Typography
          variant="subtitle1"
          color="text.primary"
          component="h2"
          gutterBottom
        >
          Are you sure you want to remove this {text}?
        </Typography>
        <Stack spacing={2} direction="row">
          <Button variant="contained" color="error" onClick={handleRemove}>
            delete
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "text.primary",
              borderColor: "text.primary"
            }}
            onClick={() => handleClose()}
          >
            cancel
          </Button>
        </Stack>
      </Box>
    </Popover>
  );
};

export default DeletePopover;
