import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/emails";
import * as actions from "reducers/emails";
import { toast } from "react-toastify";

function* getEmailsContentTypesSaga() {
  try {
    const {
      emailsContent: { search }
    } = yield select();
    const resp = yield call(Api.getEmailsContentTypesAPI, { search });
    yield put(actions.getEmailsContentTypesSucceed(resp.data));
  } catch (error) {
    yield put(actions.getEmailsContentTypesFailed(error));
  }
}

function* getEmailContentSaga({ payload }) {
  try {
    const resp = yield call(Api.getEmailContentAPI, payload);
    yield put(actions.getEmailContentSucceed(resp.data));
  } catch (error) {
    yield put(actions.getEmailContentFailed(error));
  }
}

function* updateEmailContentSaga({ payload }) {
  try {
    yield call(Api.updateEmailContentAPI, payload);
    yield put(actions.updateEmailContentSucceed());
    toast.success("You have successfully updated email content.");
  } catch (error) {
    yield put(actions.uupdateEmailContentFailed(error));
  }
}

export function* emailsContentSaga() {
  yield takeLatest(
    actions.getEmailsContentTypes.type,
    getEmailsContentTypesSaga
  );
  yield takeLatest(actions.getEmailContent.type, getEmailContentSaga);
  yield takeLatest(actions.updateEmailContent.type, updateEmailContentSaga);
}
