import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { Paper } from "@mui/material";

const useStyles = makeStyles(theme => textFieldStyle(theme));

const CustomPaper = props => {
  return (
    <Paper
      {...props}
      sx={theme => ({
        bgcolor: theme.palette.text.dark.primary,
        color: theme.palette.text.primary,
        minWidth: "350px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "100%"
        }
      })}
    />
  );
};

const AutoCompleteField = ({
  fullWidth,
  sx,
  label,
  options,
  value,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl
      fullWidth={fullWidth || null}
      sx={sx || null}
      className={classes.textField}
    >
      <Autocomplete
        clearIcon={false}
        value={value}
        PaperComponent={CustomPaper}
        options={options.map(({ value, label }) => ({
          value,
          label
        }))}
        renderInput={params => <TextField {...params} label={label} />}
        {...props}
      />
    </FormControl>
  );
};

export default AutoCompleteField;
