import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes/constants";
import _ from "lodash";
import {
  getRegistration,
  getRegistrationCheckout,
  completeRegistration
} from "reducers/registration";
import CreateIcon from "@mui/icons-material/Create";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Typography,
  Button,
  Stack,
  Box,
  Grid,
  Chip,
  List,
  ListItem
} from "@mui/material";
import ProcessingModal from "./ProcessingModal";
import PaymentFailedModal from "./PaymentFailedModal";

const chipStyle = theme => {
  return {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    fontSize: "13px",
    fontWeight: "700",
    fontFamily: "'Nunito Sans', sans-serif",
    height: "24px",
    width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      "& .MuiChip-label": {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    }
  };
};

const boxStyle = theme => {
  return {
    width: "465px",
    padding: "32px",
    marginLeft: "auto",
    backgroundColor: theme.palette.text.dark.primary,
    [theme.breakpoints.down("lg")]: {
      width: "unset",
      margin: 0,
      marginBottom: "32px"
    }
  };
};

const ReviewDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(history.location.search);
  const parsed = queryParams.get("token");
  const [token, setToken] = useState(parsed);
  const [isProcessingModal, setIsProcessingModal] = useState(false);

  const {
    data,
    registrationCheckout,
    error,
    isPaymentFailedModal
  } = useSelector(state => state.registration);

  const completeRegistrationProcess = () => {
    setIsProcessingModal(true);
    const registrationDetail = { ...data, profileImage: null };
    dispatch(
      completeRegistration({ data: registrationDetail, params: { token } })
    );
  };

  const handleEditBtn = pathName => {
    history.push({
      pathname: `${pathName}`,
      search: `?token=${token}`
    });
  };

  useEffect(() => {
    if (token) {
      dispatch(getRegistration({ token }));
      dispatch(getRegistrationCheckout({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    setIsProcessingModal(false);
  }, [error]);

  return (
    <>
      <Box>
        {!_.isEmpty(data.businessDetails) && (
          <>
            <Typography
              variant="h4"
              color="text.primary"
              gutterBottom
              margin="26px 0"
              sx={theme => ({
                [theme.breakpoints.down("sm")]: {
                  fontSize: "28px"
                }
              })}
            >
              Review
            </Typography>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Box marginBottom="32px">
                  <Typography
                    variant="h6"
                    color="text.primary"
                    gutterBottom
                    marginBottom="16px"
                  >
                    Business
                  </Typography>
                  <Stack
                    sx={theme => ({
                      flexDirection: "row",
                      [theme.breakpoints.down("sm")]: {
                        flexDirection: "column"
                      }
                    })}
                  >
                    <Stack>
                      <Box
                        sx={{
                          marginRight: "44px",
                          height: "150px",
                          width: "150px"
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: "100%",
                            width: "100%"
                          }}
                          alt="Company logo"
                          src={`${data.profileImage}`}
                        />
                      </Box>
                      <Button
                        disableRipple
                        variant="text"
                        startIcon={<CreateIcon />}
                        onClick={() =>
                          handleEditBtn(
                            `${ROUTES.REGISTRATION_BUSINESS_DETAILS}`
                          )
                        }
                        sx={theme => ({
                          marginTop: "24px",
                          justifyContent: "flex-start",
                          "&:hover": {
                            backgroundColor: "unset",
                            opacity: 0.7
                          },
                          [theme.breakpoints.down("sm")]: {
                            display: "none"
                          }
                        })}
                      >
                        EDIT
                      </Button>
                    </Stack>
                    <Stack
                      sx={theme => ({
                        [theme.breakpoints.down("sm")]: {
                          marginTop: "24px"
                        }
                      })}
                    >
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        sx={{
                          fontWeight: "700"
                        }}
                      >
                        {data.businessDetails.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                      >
                        {`${data.businessDetails?.address?.address}, ${data.businessDetails?.address?.address2}, ${data.businessDetails?.address?.town}, ${data.businessDetails?.address?.postCode}`}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                      >
                        {data.businessDetails.fcaNumber}
                      </Typography>
                      <Chip
                        label="FCA REGULATED"
                        sx={theme => chipStyle(theme)}
                      />
                    </Stack>
                  </Stack>
                  <Box
                    sx={theme => ({
                      display: "none",
                      [theme.breakpoints.down("sm")]: {
                        display: "block"
                      }
                    })}
                  >
                    <Button
                      disableRipple
                      variant="text"
                      startIcon={<CreateIcon />}
                      onClick={() =>
                        handleEditBtn(`${ROUTES.REGISTRATION_BUSINESS_DETAILS}`)
                      }
                      sx={theme => ({
                        marginTop: "24px",
                        justifyContent: "flex-start",
                        "&:hover": {
                          backgroundColor: "unset",
                          opacity: 0.7
                        }
                      })}
                    >
                      EDIT
                    </Button>
                  </Box>
                </Box>
                <Box marginBottom="32px">
                  <Typography
                    variant="h6"
                    color="text.primary"
                    gutterBottom
                    marginBottom="16px"
                  >
                    Billing
                  </Typography>
                  <Stack spacing={4} direction="row">
                    <Stack>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                      >
                        {`${data.billingDetails?.billingAddress?.address}, ${data.billingDetails?.billingAddress?.address2}, ${data.billingDetails?.billingAddress?.town}, ${data.billingDetails?.billingAddress?.postCode}`}
                      </Typography>

                      <Stack
                        direction="row"
                        sx={theme => ({
                          padding: "20px 16px",
                          backgroundColor: theme.palette.text.dark.primary,
                          marginTop: "16px",
                          justifyContent: "space-between",
                          [theme.breakpoints.down("sm")]: {
                            width: "270px"
                          }
                        })}
                      >
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={theme => ({
                            marginRight: "20px",
                            extTransform: "uppercase",
                            [theme.breakpoints.down("sm")]: {
                              marginRight: "10px"
                            }
                          })}
                        >
                          {data.billingDetails?.cardDetails?.brand}
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0
                          }}
                        >
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 5px 0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 5px 0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 5px 0 2px"
                            }}
                          ></ListItem>
                        </List>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={theme => ({
                            marginRight: "20px",
                            [theme.breakpoints.down("sm")]: {
                              marginRight: "10px"
                            }
                          })}
                        >
                          {data.billingDetails?.cardDetails?.last4}
                        </Typography>
                        <Typography variant="body1" color="text.primary">
                          {data.billingDetails?.cardDetails?.expMonth} /{" "}
                          {data.billingDetails?.cardDetails?.expYear}
                        </Typography>
                      </Stack>
                      <Button
                        disableRipple
                        variant="text"
                        startIcon={<CreateIcon />}
                        onClick={() =>
                          handleEditBtn(
                            `${ROUTES.REGISTRATION_BILLING_DETAILS}`
                          )
                        }
                        sx={{
                          marginTop: "24px",
                          justifyContent: "flex-start",
                          "&:hover": {
                            backgroundColor: "unset",
                            opacity: 0.7
                          }
                        }}
                      >
                        EDIT
                      </Button>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box sx={theme => boxStyle(theme)}>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    paddingBottom="26px"
                    gutterBottom
                    sx={theme => ({
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      fontWeight: "700"
                    })}
                  >
                    Monthly Pay
                  </Typography>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      variant="body1"
                      color="text.primary"
                      marginBottom="16px"
                      gutterBottom
                    >
                      Total Amount
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      sx={{
                        fontWeight: "700"
                      }}
                    >
                      £{registrationCheckout?.totalAmount}
                    </Typography>
                  </Stack>
                  <Stack direction="row">
                    <CheckCircleIcon color="success" />
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      sx={{
                        fontWeight: "600",
                        marginLeft: "12px"
                      }}
                    >
                      I accept terms and conditions
                    </Typography>
                  </Stack>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      marginTop: "16px"
                    }}
                    onClick={completeRegistrationProcess}
                  >
                    CHECKOUT
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {isProcessingModal && (
        <Box>
          <ProcessingModal isOpen={isProcessingModal} />
        </Box>
      )}
      {isPaymentFailedModal && (
        <Box>
          <PaymentFailedModal isOpen={isPaymentFailedModal} />
        </Box>
      )}
    </>
  );
};

export default ReviewDetails;
