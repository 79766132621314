import React from "react";
import { Stack, List, ListItem, Typography } from "@mui/material";

function PaymentCard({ cardDetail }) {
  return (
    <Stack
      direction="row"
      sx={theme => ({
        background: theme.palette.text.dark.primary,
        padding: "24px",
        [theme.breakpoints.down("sm")]: {
          background: theme.palette.background.default,
          padding: "24px 12px"
        }
      })}
    >
      <Typography
        variant="body1"
        color="text.primary"
        sx={theme => ({
          marginRight: "20px",
          textTransform: "uppercase",
          [theme.breakpoints.down("sm")]: {
            marginRight: "10px"
          }
        })}
      >
        {cardDetail.defaultPaymentMethod?.type}
      </Typography>
      <List
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 0
        }}
      >
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 5px 0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 5px 0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 2px"
          }}
        ></ListItem>
        <ListItem
          disablePadding
          sx={{
            height: "5px",
            width: "5px",
            borderRadius: "50%",
            bgcolor: "text.primary",
            margin: "0 5px 0 2px"
          }}
        ></ListItem>
      </List>
      <Typography
        variant="body1"
        color="text.primary"
        sx={theme => ({
          margin: "0 20px 0 10px",
          [theme.breakpoints.down("sm")]: {
            margin: "0 10px"
          }
        })}
      >
        {cardDetail.defaultPaymentMethod?.last4}
      </Typography>
      <Typography variant="body1" color="text.primary">
        {cardDetail.defaultPaymentMethod?.expMonth} /{" "}
        {cardDetail.defaultPaymentMethod?.expYear}
      </Typography>
    </Stack>
  );
}

export default PaymentCard;
