import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "components/Modals/index";
import { changeMembershipRequest } from "reducers/company";
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Stack,
  Box
} from "@mui/material";

const ChangeMembershipRequestModal = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();

  const {
    defaultCompanyData: { membershipDetails }
  } = useSelector(state => state.company);

  const [value, setValue] = useState(
    membershipDetails?.eligibleMembershipRequest
      ? membershipDetails?.eligibleMembershipRequest[0].key
      : ""
  );

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    const changeMembershipRequestDetail = {
      type: value
    };
    dispatch(changeMembershipRequest(changeMembershipRequestDetail));
    handleClose();
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Change Membership">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          {membershipDetails?.eligibleMembershipRequest?.map((data, i) => (
            <FormControl key={i}>
              <RadioGroup value={value} onChange={handleChange}>
                <FormControlLabel
                  sx={{ color: "text.primary" }}
                  value={data.key}
                  control={<Radio />}
                  label={data.value}
                />
              </RadioGroup>
            </FormControl>
          ))}
          <Box>
            <Button type="submit" variant="contained" size="large">
              Confirm
            </Button>
          </Box>
        </Stack>
      </form>
    </Modal>
  );
};

export default ChangeMembershipRequestModal;
