import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/registration";
import * as actions from "reducers/registration";
import { push } from "connected-react-router";
import { ROUTES } from "routes/constants";
import { ERROR } from "utils/constants";

function* updateUserRegistrationProcessSaga({ payload }) {
  try {
    const { params } = payload;
    const resp = yield call(Api.updateRegistrationProcessAPI, payload);
    yield put(actions.updateUserRegistrationSucceed(resp.data));

    switch (resp.data.step) {
      case 1:
        yield put(
          push({
            pathname: `${ROUTES.REGISTRATION_BILLING_DETAILS}`,
            search: `?token=${params.token}`
          })
        );
        break;
      case 2:
        yield put(
          push({
            pathname: `${ROUTES.REGISTRATION_REVIEW_DETAILS}`,
            search: `?token=${params.token}`
          })
        );
        break;
      default:
        yield put(
          push({
            pathname: `${ROUTES.REGISTRATION_BUSINESS_DETAILS}`,
            search: `?token=${params.token}`
          })
        );
    }
  } catch (error) {
    yield put(actions.updateUserRegistrationFailed(error));
  }
}

function* getRegistrationProcessSaga({ payload }) {
  try {
    const resp = yield call(Api.getRegistrationProcessAPI, payload);
    yield put(actions.getRegistrationSucceed(resp.data));
  } catch (error) {
    yield put(actions.getRegistrationFailed(error));
  }
}

function* getRegistrationCheckoutSaga({ payload }) {
  try {
    const resp = yield call(Api.getRegistrationCheckoutAPI, payload);
    yield put(actions.getRegistrationCheckoutSucceed(resp.data));
  } catch (error) {
    yield put(actions.getRegistrationCheckoutFailed(error));
  }
}

function* completeRegistrationSaga({ payload }) {
  const {
    registration: {
      data: {
        signupRequest: { state }
      }
    }
  } = yield select();
  try {
    const resp = yield call(Api.completeRegistrationAPI, payload);
    yield put(actions.completeRegistrationSucceed(resp.data));
    if (resp.data.status === ERROR) {
      yield put(actions.updateCompletePaymentModal(true));
    } else {
      yield put(push(ROUTES.REGISTRATION_PURCHASED_DETAILS));
    }
  } catch (error) {
    yield put(actions.completeRegistrationFailed(error));
  }
}

function* signUpRequestSaga({ payload }) {
  try {
    yield call(Api.signUpRequestAPI, payload);
    yield put(actions.signUpRequestSucceed());
    yield put(push(ROUTES.SIGNUP_REQUEST_SUCCESS));
  } catch (error) {
    yield put(actions.signUpRequestFailed(error));
  }
}

function* getSignupRequestSaga({ payload }) {
  try {
    const resp = yield call(Api.getSignupRequestAPI, payload);
    yield put(actions.getSignupRequestSucceed(resp.data));
  } catch (error) {
    yield put(actions.getSignupRequestFailed(error));
  }
}

export function* registrationSaga() {
  yield takeLatest(
    actions.updateUserRegistrationProcess.type,
    updateUserRegistrationProcessSaga
  );
  yield takeLatest(actions.getRegistration.type, getRegistrationProcessSaga);
  yield takeLatest(
    actions.getRegistrationCheckout.type,
    getRegistrationCheckoutSaga
  );
  yield takeLatest(actions.completeRegistration.type, completeRegistrationSaga);
  yield takeLatest(actions.signUpRequest.type, signUpRequestSaga);
  yield takeLatest(actions.getSignupRequest.type, getSignupRequestSaga);
}
