import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  List,
  ListItem,
  Typography,
  Stack,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Button
} from "@mui/material";
import Loader from "components/Loader";
import LeadModal from "./LeadModal";
import ResetFilterAlert from "components/ResetFilterAlert";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getSortingColor,
  getSortingIcon,
  paginationStyle,
  setLocalStorageItem,
  getAccessToken
} from "utils";
import {
  changePage,
  updateFilters,
  getLeads,
  changeSearch,
  resetLeadsFilters
} from "reducers/leads";
import {
  defaultLeadsSorting,
  FIRST_PAGE,
  ONESIGNAL_PLAYER_ID,
  USER_TYPE
} from "utils/constants";
import SearchBar from "./SearchBar";
import LeadCard from "./LeadCard";
import Header from "components/Header";
import FilterDrawer from "./FilterDrawer";
import { postOnesignalPlayerId } from "../../reducers/notifications";
import OneSignal from "react-onesignal";
import { getUser } from "reducers/users";

const Leads = () => {
  const [open, setOpen] = useState(false);
  const [lead, setLead] = useState({});
  const [filterPosition, setFilterPosition] = useState({ right: false });
  const [leadCategory, setLeadCategory] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [drawerFilterSearch, setDrawerFilterSearch] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");

  const { loading, data, rowsPerPage, page, search, filters } = useSelector(
    state => state.leads
  );
  const { me: user } = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const token = getAccessToken();

  useEffect(() => {
    if (token) {
      dispatch(getLeads());
    }
  }, [dispatch, page, filters, search]);

  useEffect(() => {
    OneSignal.SERVICE_WORKER_PARAM = { scope: "/push/onesignal/" };
    OneSignal.SERVICE_WORKER_PATH = "push/onesignal/OneSignalSDKWorker.js";
    if (token) {
      OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        allowLocalhostAsSecureOrigin: true
      }).then(() => {
        OneSignal.on("subscriptionChange", isSubscribed => {
          if (isSubscribed) {
            OneSignal.getUserId().then(userId => {
              setLocalStorageItem(ONESIGNAL_PLAYER_ID, userId);
              dispatch(postOnesignalPlayerId({ playerId: userId }));
            });
          }
        });
        OneSignal.isPushNotificationsEnabled(isEnabled => {
          if (isEnabled) {
            OneSignal.getUserId().then(userId => {
              setLocalStorageItem(ONESIGNAL_PLAYER_ID, userId);
              dispatch(postOnesignalPlayerId({ playerId: userId }));
            });
          }
        });
      });
    }

    return () => {
      dispatch(changePage(FIRST_PAGE));
      dispatch(resetLeadsFilters());
    };
  }, [dispatch]);

  const handleOnRangeFilterChange = (min, max) => {
    if (min || max) {
      dispatch(updateFilters({ ...filters, min, max }));
      toggleFilterDrawer(false)();
    }
  };

  const handleLeadCategoryChange = e => {
    dispatch(updateFilters({ ...filters, category: e.target.value }));
    toggleFilterDrawer(false)();
  };

  const handlePropertyTypeChange = e => {
    dispatch(updateFilters({ ...filters, propertyType: e.target.value }));
    toggleFilterDrawer(false)();
  };

  const handleOnResetFilter = () => {
    setDrawerFilterSearch("");
    setMin("");
    setMax("");
    setPropertyType("");
    dispatch(resetLeadsFilters());
  };

  const handleOnPageChange = (e, newPage) => {
    dispatch(changePage(newPage));
    dispatch(getUser());
  };

  const handleOnSortFilterChange = sortingParam => {
    let filters_ = { ...filters, sorting: { ...filters.sorting } };
    filters_.sorting[sortingParam] = getNextSortingState(
      filters_.sorting[sortingParam]
    );
    filters_.sorting = clearOtherSortingParams(filters_.sorting, sortingParam);
    if (isNoSortingParams(filters_.sorting)) {
      filters_.sorting = defaultLeadsSorting;
    }
    dispatch(updateFilters(filters_));
    toggleFilterDrawer(false)();
  };

  const toggleFilterDrawer = open => () => {
    setFilterPosition({ ...filterPosition, right: open });
  };

  const handleOnSearchChange = search => {
    if (search) {
      dispatch(changeSearch(search));
      toggleFilterDrawer(false)();
    }
  };

  const handleDrawerFilterSearchChange = e => {
    setDrawerFilterSearch(e.target.value);
  };

  return (
    <>
      <Header componentName="Leads" />
      <Grid container mt={4}>
        <Typography
          variant="body1"
          sx={theme => ({
            color: theme.palette.text.secondary,
            fontWeight: "600",
            [theme.breakpoints.down("lg")]: {
              display: "none"
            }
          })}
        >
          Sort & filters:
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          marginBottom="28px"
        >
          <List
            sx={theme => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              [theme.breakpoints.down("lg")]: {
                display: "none"
              }
            })}
            dense
            disablePadding
          >
            <ListItem disablePadding style={{ width: "auto" }}>
              <ListItemButton
                onClick={() => handleOnSortFilterChange("modified")}
                sx={{ paddingLeft: 0 }}
                disableRipple
              >
                <ListItemIcon style={{ minWidth: "25px" }}>
                  {getSortingIcon(filters.sorting.modified)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="link2"
                      color={getSortingColor(filters.sorting.modified)}
                      ml={1}
                    >
                      DATE
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding style={{ width: "auto" }}>
              <ListItemButton
                onClick={() => handleOnSortFilterChange("property_amount")}
                sx={{ paddingLeft: 0 }}
                disableRipple
              >
                <ListItemIcon style={{ minWidth: "25px" }}>
                  {getSortingIcon(filters.sorting.property_amount)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="link2"
                      color={getSortingColor(filters.sorting.property_amount)}
                      ml={1}
                    >
                      PROPERTY VALUE
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding style={{ width: "auto" }}>
              <ListItemButton
                onClick={() => handleOnSortFilterChange("mortgage_amount")}
                sx={{ paddingLeft: 0 }}
                disableRipple
              >
                <ListItemIcon style={{ minWidth: "25px" }}>
                  {getSortingIcon(filters.sorting.mortgage_amount)}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="link2"
                      color={getSortingColor(filters.sorting.mortgage_amount)}
                      ml={1}
                    >
                      MORTGAGE VALUE
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            {user?.type !== USER_TYPE.ADMIN && (
              <ListItem disablePadding style={{ width: "auto" }}>
                <ListItemButton
                  onClick={() => handleOnSortFilterChange("is_favourite")}
                  sx={{ paddingLeft: 0 }}
                  disableRipple
                >
                  <ListItemIcon style={{ minWidth: "25px" }}>
                    {getSortingIcon(filters.sorting.is_favourite)}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="link2"
                        color={getSortingColor(filters.sorting.is_favourite)}
                        ml={1}
                      >
                        FAVOURITE
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
            {user?.type === USER_TYPE.ADMIN && (
              <ListItem disablePadding style={{ width: "auto" }}>
                <ListItemButton
                  onClick={() => handleOnSortFilterChange("is_active")}
                  sx={{ paddingLeft: 0 }}
                  disableRipple
                >
                  <ListItemIcon style={{ minWidth: "25px" }}>
                    {getSortingIcon(filters.sorting.is_active)}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="link2"
                        color={getSortingColor(filters.sorting.is_active)}
                        ml={1}
                      >
                        ACTIVE
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          <Typography
            variant="h4"
            color="text.primary"
            component="h2"
            display={{ xs: "block", lg: "none" }}
          >
            Leads
          </Typography>
          <Stack direction="row">
            <Stack display={{ xs: "none", lg: "block" }}>
              <SearchBar handleOnSearchChange={handleOnSearchChange} />
            </Stack>
            <Button
              variant="outlined"
              size="large"
              sx={{
                color: "text.primary",
                borderColor: "text.primary",
                opacity: 0.5,
                cursor: "pointer",
                marginLeft: "8px",
                height: "45px"
              }}
              onClick={toggleFilterDrawer(true)}
            >
              <FilterListIcon sx={{ marginRight: "14px" }} />
              FILTER
            </Button>
          </Stack>
        </Stack>
        {(filters.min || filters.max || filters.category || filters.propertyType || search) && (
          <Grid item xs={12}>
            <ResetFilterAlert
              data={data?.results}
              text="leads"
              handleOnResetFilter={handleOnResetFilter}
            />
          </Grid>
        )}
        {loading ? (
          <Loader />
        ) : data.results?.length ? (
          <>
            <Grid container spacing={3}>
              {data.results?.map(lead => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  xl={4}
                  key={lead.id}
                  marginBottom="32px"
                >
                  <LeadCard
                    lead={lead}
                    setLead={setLead}
                    openModal={() => setOpen(true)}
                  />
                </Grid>
              ))}
            </Grid>
            <Pagination
              count={Math.ceil(data.count / rowsPerPage)}
              page={page}
              onChange={handleOnPageChange}
              sx={theme => paginationStyle(theme)}
            />
          </>
        ) : (
          <Typography variant="h5" color="text.primary" component="h5">
            There are no leads yet
          </Typography>
        )}
      </Grid>
      <FilterDrawer
        filters={filters}
        position={filterPosition}
        getSortingIcon={getSortingIcon}
        getSortingColor={getSortingColor}
        toggleDrawer={toggleFilterDrawer}
        handleOnSearchChange={handleOnSearchChange}
        handleOnSortFilterChange={handleOnSortFilterChange}
        handleOnRangeFilterChange={handleOnRangeFilterChange}
        leadCategory={leadCategory}
        setLeadCategory={setLeadCategory}
        propertyType={propertyType}
        setPropertyType={setPropertyType}
        handleLeadCategoryChange={handleLeadCategoryChange}
        handlePropertyTypeChange={handlePropertyTypeChange}
        drawerFilterSearch={drawerFilterSearch}
        handleDrawerFilterSearchChange={handleDrawerFilterSearchChange}
        min={min}
        max={max}
        setMin={setMin}
        setMax={setMax}
      />
      {open && (
        <LeadModal
          isOpen={open}
          lead={lead}
          handleClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default Leads;
