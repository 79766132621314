import React from "react";
import Modal from "components/Modals/index";
import { Box } from "@mui/material";
import { scrollBarStyle } from "utils";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  signatureModal: {
    "& p": {
      color: theme.palette.text.primary
    }
  }
}));

function SignatureModal({ isOpen, handleClose, signature }) {
  const classes = useStyles();
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Signature">
      <Box>
        <Box
          className={classes.signatureModal}
          sx={theme => scrollBarStyle(theme)}
          style={{ maxHeight: "500px" }}
          dangerouslySetInnerHTML={{ __html: signature }}
        />
      </Box>
    </Modal>
  );
}

export default SignatureModal;
