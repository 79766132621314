import React from "react";
import { useSelector } from "react-redux";
import Modal from "components/Modals/index";
import "react-quill/dist/quill.snow.css";
import { Typography, Stack } from "@mui/material";

const EmailContentModal = ({ isOpen, handleClose }) => {
  const { emailContent } = useSelector(state => state.emailsContent);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Email Content">
      <Stack spacing={2}>
        <Typography
          color="text.primary"
          sx={{ fontWeight: "700" }}
          gutterBottom
        >
          Subject
        </Typography>
        <Typography
          color="text.primary"
          sx={{ fontWeight: "500", margin: "0 !important" }}
          gutterBottom
        >
          {emailContent.subject}
        </Typography>
        <Typography
          color="text.primary"
          sx={{ fontWeight: "700" }}
          gutterBottom
        >
          Body
        </Typography>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ margin: "0 !important" }}
          dangerouslySetInnerHTML={{ __html: emailContent.body }}
        />
        <Typography
          color="text.primary"
          sx={{ fontWeight: "700", marginTop: "0 !important" }}
          gutterBottom
        >
          Help Text
        </Typography>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ margin: "0 !important" }}
          dangerouslySetInnerHTML={{ __html: emailContent.helpText }}
        />
      </Stack>
    </Modal>
  );
};

export default EmailContentModal;
