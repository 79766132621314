import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { getRegistration } from "reducers/registration";
import Steps from "./Steps";
import "../../App.scss";
import {
  Typography,
  TextField,
  FormControl,
  Stack,
  Box,
  Grid,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import StripePaymentComponent from "./StripePaymentComponent";
import _ from "lodash";
import { REGISTRATION_URL, STEPS } from "utils/constants";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);

const formStyle = theme => {
  return {
    margin: "0 48px 48px 0",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginTop: "24px"
    }
  };
};

const BillingDetails = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(history.location.search);
  const parsed = queryParams.get("token");
  const [token, setToken] = useState(parsed);
  const { data } = useSelector(state => state.registration);
  const [billingDetails, setBillingDetail] = useState({});
  const [activeStep, setActiveStep] = useState(STEPS.BILLING_DETAILS);
  const [isChecked, setIsChecked] = useState(false);

  const {
    register,
    watch,
    reset,
    formState: { errors }
  } = useForm();

  const watchAdressLine1 = watch("addressLine1");
  const watchAdressLine2 = watch("addressLine2");
  const watchTown = watch("town");
  const watchPostCode = watch("postCode");

  const BILLING_ADDRESS_DETAILS = {
    address1: watchAdressLine1,
    address2: watchAdressLine2,
    town: watchTown,
    postCode: watchPostCode
  };

  useEffect(() => {
    const billingAddress = data.billingDetails?.billingAddress;
    const businessDetails = data?.businessDetails?.address;
    setBillingDetail({
      ...billingDetails,
      addressLine1: isChecked
        ? businessDetails?.address
        : billingAddress?.address || reset(),
      addressLine2: isChecked
        ? businessDetails?.address2
        : billingAddress?.address2 || reset(),
      town: isChecked ? businessDetails?.town : billingAddress?.town || reset(),
      postCode: isChecked
        ? businessDetails?.postCode
        : billingAddress?.postCode || reset()
    });
    reset({
      addressLine1: isChecked
        ? businessDetails?.address
        : billingAddress?.address || reset(),
      addressLine2: isChecked
        ? businessDetails?.address2
        : billingAddress?.address2 || reset(),
      town: isChecked ? businessDetails?.town : billingAddress?.town || reset(),
      postCode: isChecked
        ? businessDetails?.postCode
        : billingAddress?.postCode || reset()
    });
  }, [data, isChecked]);

  const handleChange = (key, value) => {
    setBillingDetail({ ...billingDetails, [key]: value });
  };

  const handleCheckbox = e => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    let pathName = location?.pathname.split("/")[2];
    if (pathName === REGISTRATION_URL.BILLING_DETAIL) {
      setActiveStep(STEPS.BILLING_DETAILS);
    }
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getRegistration({ token }));
    }
  }, [dispatch, token]);

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Box sx={theme => formStyle(theme)}>
          <form>
            <Typography
              variant="h4"
              color="text.primary"
              gutterBottom
              margin="26px 0"
              sx={theme => ({
                [theme.breakpoints.down("sm")]: {
                  fontSize: "28px"
                }
              })}
            >
              Billing
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              marginBottom="18px"
              color="text.primary"
            >
              Billing Address
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ marginBottom: "16px" }}
            >
              <FormControlLabel
                sx={{
                  margin: "12px 0",
                  color: "text.secondary"
                }}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckbox}
                    sx={{ padding: 0, marginRight: "8px" }}
                  />
                }
                label="Same as business detail"
              />
            </Stack>
            <Stack spacing={3}>
              <FormControl>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Address Line 1"
                  type="text"
                  placeholder="Address Line 1"
                  defaultValue={
                    data?.billingDetails?.billingAddress?.address || ""
                  }
                  error={!!errors.addressLine1}
                  {...register("addressLine1", {
                    required: true,
                    onChange: e => handleChange("addressLine1", e.target.value)
                  })}
                  InputLabelProps={{
                    shrink: billingDetails.addressLine1 ? true : false
                  }}
                  disabled={isChecked}
                />
                {errors.addressLine1 && (
                  <div className="validation-error">
                    Address Line 1 required!
                  </div>
                )}
              </FormControl>
              <FormControl>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Address Line 2"
                  type="text"
                  placeholder="Address Line 2"
                  defaultValue={
                    data?.billingDetails?.billingAddress?.address2 || ""
                  }
                  error={!!errors.addressLine2}
                  {...register("addressLine2", {
                    required: true,
                    onChange: e => handleChange("addressLine2", e.target.value)
                  })}
                  InputLabelProps={{
                    shrink: billingDetails.addressLine2 ? true : false
                  }}
                  disabled={isChecked}
                />
                {errors.addressLine2 && (
                  <div className="validation-error">
                    Address Line 2 required!
                  </div>
                )}
              </FormControl>
              <FormControl>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="City"
                  type="text"
                  placeholder="City"
                  defaultValue={
                    data?.billingDetails?.billingAddress?.town || ""
                  }
                  error={!!errors.town}
                  {...register("town", {
                    required: true,
                    onChange: e => handleChange("town", e.target.value)
                  })}
                  InputLabelProps={{
                    shrink: billingDetails.town ? true : false
                  }}
                  disabled={isChecked}
                />
                {errors.town && (
                  <div className="validation-error">City required!</div>
                )}
              </FormControl>
              <FormControl
                sx={theme => ({
                  width: "350px",
                  [theme.breakpoints.down("sm")]: {
                    width: "100%"
                  }
                })}
              >
                <TextField
                  className={classes.textField}
                  label="Postcode"
                  type="text"
                  placeholder="Postcode"
                  defaultValue={
                    data?.billingDetails?.billingAddress?.postCode || ""
                  }
                  error={!!errors.postCode}
                  {...register("postCode", {
                    required: true,
                    onChange: e => handleChange("postCode", e.target.value)
                  })}
                  InputLabelProps={{
                    shrink: billingDetails.postCode ? true : false
                  }}
                  disabled={isChecked}
                />
                {errors.postCode && (
                  <div className="validation-error">Postcode required!</div>
                )}
              </FormControl>
            </Stack>
            <Typography
              variant="h6"
              gutterBottom
              marginBottom="18px"
              color="text.primary"
              marginTop="40px"
            >
              Payment Card
            </Typography>
            <StripePaymentComponent
              billingData={BILLING_ADDRESS_DETAILS}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          </form>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={theme => ({
          [theme.breakpoints.down("lg")]: {
            display: "none"
          }
        })}
      >
        <Steps activeStep={activeStep} formId="ownerDetailForm" />
      </Grid>
    </Grid>
  );
};

export default BillingDetails;
