import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/leads";
import * as actions from "../../reducers/leads";
import { push } from "connected-react-router";
import { toast } from "react-toastify";

function* getLeadsSaga() {
  try {
    const {
      leads: { filters, search, page }
    } = yield select();
    const resp = yield call(Api.getLeadsAPI, { ...filters, search, page });
    yield put(actions.getLeadsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getLeadsFailed(error));
  }
}

function* buyLeadSaga({ payload }) {
  try {
    const resp = yield call(Api.buyLeadAPI, payload);
    yield put(actions.buyLeadSucceed());
    toast.success("You have successfully purchased the lead.");
    yield put(actions.getLeads());
    yield put(push(`/cases/${resp.data.id}`));
  } catch (error) {
    yield put(actions.buyLeadFailed(error));
  }
}

function* getLeadDetailSaga({ payload }) {
  try {
    const resp = yield call(Api.getLeadDetailAPI, payload);
    yield put(actions.getLeadDetailSucceed(resp.data));
  } catch (error) {
    yield put(actions.getLeadDetailFailed(error));
  }
}

function* updateLeadSaga({ payload }) {
  try {
    yield call(Api.updateLeadAPI, payload);
    yield put(actions.updateLeadSucceed());
    yield put(actions.getLeads());
    toast.success(
      `You have successfully ${
        payload.data.isActive ? "activated" : "deactivated"
      } the lead # ${payload.id}.`
    );
  } catch (error) {
    yield put(actions.updateLeadFailed(error));
  }
}

function* updateFavouriteLeadSaga({ payload }) {
  try {
    const resp = yield call(Api.updateFavouriteLeadAPI, payload);
    yield put(actions.updateFavouriteLeadSucceed(resp.data));
    toast.success(
      `You have successfully ${
        resp.data.isFavourite ? "favourited" : "unfavourited"
      } a lead # ${resp.data.id}.`
    );
  } catch (error) {
    yield put(actions.updateFavouriteLeadFailed(error));
  }
}

export function* leadsSaga() {
  yield takeLatest(actions.getLeads.type, getLeadsSaga);
  yield takeLatest(actions.buyLead.type, buyLeadSaga);
  yield takeLatest(actions.getLeadDetail.type, getLeadDetailSaga);
  yield takeLatest(actions.updateLead.type, updateLeadSaga);
  yield takeLatest(actions.updateFavouriteLead.type, updateFavouriteLeadSaga);
}
