import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "components/Modals/index";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { moneyFieldConverter } from "utils";
import {
  getAdditionalSeats,
  updateBuySeatsCheckoutModal,
  updateBuySeats
} from "reducers/company";

import { Button, TextField, Typography } from "@mui/material";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const BuySeatsModal = ({ isOpen, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { defaultCompanyData, buySeats } = useSelector(state => state.company);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      additionalSeats: buySeats || ""
    }
  });

  const onSubmit = value => {
    dispatch(getAdditionalSeats(value.additionalSeats));
    dispatch(updateBuySeatsCheckoutModal(true));
    dispatch(updateBuySeats(value.additionalSeats));
    handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Buy Seats">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h6"
          color="text.primary"
          display="flex"
          alignItems="center"
          marginBottom="10px"
        >
          Additional Seats{" "}
          <Typography variant="body1" color="text.primary" marginLeft="8px">
            {`(${moneyFieldConverter(
              defaultCompanyData.perSeatMonthlyAmount
            )} / seat)`}
          </Typography>
        </Typography>
        <TextField
          fullWidth
          type="number"
          className={classes.textField}
          label="Additional Seats"
          placeholder="Additional Seats"
          error={!!errors.additionalSeats}
          {...register("additionalSeats", {
            required: true,
            maxLength: 2,
            min: 0
          })}
        />
        <Button
          sx={theme => ({
            marginTop: 3,
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          })}
          type="submit"
          variant="contained"
        >
          Next
        </Button>
      </form>
    </Modal>
  );
};

export default BuySeatsModal;
