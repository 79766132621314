import React, { useState } from "react";
import { Divider, Stack, Typography, Box, Link } from "@mui/material";
import { useSelector } from "react-redux";
import CopyrightIcon from "@mui/icons-material/Copyright";
import ResponsiveMenu from "./ResponsiveMenu";
import ResponsiveBottomNavigation from "./ResponsiveBottomNavigation";

function Footer({ isFooterMenu = true }) {
  const {
    data: { me }
  } = useSelector(state => state.user);
  const [responsiveMenuPosition, setResponsiveMenuPosition] = useState({
    left: false
  });

  const openResponsiveMenu = open => () => {
    setResponsiveMenuPosition({ responsiveMenuPosition, left: open });
  };

  return (
    <>
      <Box>
        <Divider sx={{ marginBottom: "24px" }} />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={theme => ({
              [theme.breakpoints.down("sm")]: {
                marginBottom: "22px"
              }
            })}
          >
            <CopyrightIcon sx={theme => ({ color: "text.primary" })} />
            <Typography variant="body2" color="text.primary">
              Copyright Blabble Tech Limited, {new Date().getFullYear()}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={4} alignItems="center">
            <Link
              href="mailto:hello@blabble.co.uk"
              underline="none"
              variant="link2"
              color="text.primary"
            >
              SUPPORT
            </Link>
          </Stack>
        </Stack>
        {isFooterMenu && (
          <Stack
            sx={theme => ({
              paddingTop: "26px",
              display: "none",
              [theme.breakpoints.down("lg")]: {
                display: "block",
                position: "fixed",
                width: "90%",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 9
              }
            })}
          >
            <ResponsiveBottomNavigation
              openResponsiveMenu={openResponsiveMenu}
              userType={me?.type}
            />
          </Stack>
        )}
      </Box>
      <ResponsiveMenu
        position={responsiveMenuPosition}
        toggleMenu={openResponsiveMenu}
      />
    </>
  );
}

export default Footer;
