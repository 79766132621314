import { createSlice } from "@reduxjs/toolkit";
import { initialLeadSorting } from "../../utils/constants";

const initialState = {
  loading: true,
  data: { results: [] },
  lead: {},
  filters: {
    sorting: {
      ...initialLeadSorting
    },
    min: null,
    max: null,
    category: ""
  },
  search: "",
  page: 1,
  rowsPerPage: 12,
  error: null
};

export const leads = createSlice({
  name: "leads",
  initialState,
  reducers: {
    getLeads: (state, action) => {
      state.loading = true;
    },
    getLeadsSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getLeadsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
      state.page = 1;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    buyLead: (state, action) => {
      state.loading = true;
    },
    buyLeadSucceed: (state, action) => {
      state.loading = false;
    },
    buyLeadFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
      state.page = 0;
    },
    changeSearch: (state, action) => {
      state.search = action.payload;
      state.page = 1;
    },
    getLeadDetail: (state, action) => {
      state.loading = true;
    },
    getLeadDetailSucceed: (state, action) => {
      state.loading = false;
      state.lead = action.payload;
    },
    getLeadDetailFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetLeadsFilters: (state, action) => {
      state.filters.min = null;
      state.filters.max = null;
      state.filters.category = "";
      state.filters.propertyType = null
      state.search = "";
      state.filters.sorting = initialLeadSorting;
    },
    updateLead: (state, action) => {
      state.loading = true;
    },
    updateLeadSucceed: (state, action) => {
      state.loading = false;
    },
    updateLeadFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateFavouriteLead: (state, action) => { },
    updateFavouriteLeadSucceed: (state, action) => {
      const { id, isFavourite } = action.payload;
      const index = state.data.results.findIndex(i => i.id === id);
      state.data.results[index].isFavourite = isFavourite;
    },
    updateFavouriteLeadFailed: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const {
  getLeads,
  getLeadsSucceed,
  getLeadsFailed,
  updateFilters,
  changePage,
  buyLead,
  buyLeadSucceed,
  buyLeadFailed,
  changeRowsPerPage,
  changeSearch,
  getLeadDetail,
  getLeadDetailSucceed,
  getLeadDetailFailed,
  resetLeadsFilters,
  updateLead,
  updateLeadSucceed,
  updateLeadFailed,
  updateFavouriteLead,
  updateFavouriteLeadSucceed,
  updateFavouriteLeadFailed
} = leads.actions;

export default leads.reducer;
