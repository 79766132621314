import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/dashboard";
import * as actions from "reducers/dashboard";

function* getDashboardSaga({ payload }) {
  try {
    const resp = yield call(Api.getDashboardAPI, payload);
    yield put(actions.getDashboardSucceed(resp.data));
  } catch (error) {
    yield put(actions.getDashboardFailed(error));
  }
}

function* getLeaderboardSaga({ payload }) {
  const {
    dashboard: { page }
  } = yield select();
  try {
    const resp = yield call(Api.getLeaderboardAPI, { interval: payload, page });
    yield put(actions.getLeaderboardSucceed(resp.data));
  } catch (error) {
    yield put(actions.getLeaderboardFailed(error));
  }
}

function* getBrokerDashboardSaga({ payload }) {
  try {
    const resp = yield call(Api.getBrokerDashboardAPI, payload);
    yield put(actions.getBrokerDashboardSucceed(resp.data));
  } catch (error) {
    yield put(actions.getBrokerDashboardFailed(error));
  }
}

export function* dashboardSaga() {
  yield takeLatest(actions.getDashboard.type, getDashboardSaga);
  yield takeLatest(actions.getLeaderboard.type, getLeaderboardSaga);
  yield takeLatest(actions.getBrokerDashboard.type, getBrokerDashboardSaga);
}
