import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const Loader = () => (
  <Box sx={{ width: "100%", marginTop: "24px" }}>
    <LinearProgress />
  </Box>
);

export default Loader;
