import { createSlice } from "@reduxjs/toolkit";
import { defaultCompanySorting, initialInvoicesSorting } from "utils/constants";

const initialState = {
  loading: true,
  stripeInvoiceLoading: true,
  isBuySeatsCheckoutModal: false,
  isProcessingModal: false,
  isPurchasedModal: false,
  isBuySeatsModal: false,
  isRemoveSeatModal: false,
  isRemoveSeatsConfirmModal: false,
  isRemoveSuccessfullModal: false,
  isConfirmPaymentModal: false,
  paymentConfirmationResponse: {},
  profileImage: "",
  data: [],
  defaultCompanyData: [],
  paymentMethodDetails: [],
  invoicesData: {},
  additionalSeats: {},
  removeSeats: {},
  buySeats: "",
  removeSeatsQuantity: "",
  purchased: {},
  cancelSubscription: {},
  activeTab: "brokers",
  filtersBrokers: {
    sorting: {
      ...defaultCompanySorting
    }
  },
  filtersInvoices: {
    sorting: {
      ...initialInvoicesSorting
    }
  },
  page: 1,
  rowsPerPage: 12,
  error: null
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateBrokersFilters: (state, action) => {
      state.filtersBrokers = action.payload;
    },
    updateInvoiceFilters: (state, action) => {
      state.filtersInvoices = action.payload;
    },
    getCompanyBrokers: (state, action) => {
      state.loading = true;
    },
    getCompanyBrokersSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getCompanyBrokersFailed: (state, action) => {
      state.loading = false;
    },
    updateBrokers: (state, action) => {
      state.loading = true;
    },
    updateBrokersSucceed: (state, action) => {
      state.loading = false;
    },
    updateBrokersFailed: (state, action) => {
      state.loading = false;
    },
    updateCompanies: (state, action) => {
      state.loading = true;
    },
    updateCompaniesSucceed: (state, action) => {
      state.loading = false;
    },
    updateCompaniesFailed: (state, action) => {
      state.loading = false;
    },
    getCompanies: (state, action) => {},
    getCompaniesSucceed: (state, action) => {
      state.defaultCompanyData = action.payload;
    },
    getCompaniesFailed: (state, action) => {},
    createBrokers: (state, action) => {
      state.loading = true;
    },
    createBrokersSucceed: (state, action) => {
      state.loading = false;
    },
    createBrokersFailed: (state, action) => {
      state.loading = false;
    },
    createPaymentMethod: (state, action) => {
      state.loading = true;
    },
    createPaymentMethodSucceed: (state, action) => {
      state.loading = false;
    },
    createPaymentMethodFailed: (state, action) => {
      state.loading = false;
    },
    getPaymentMethod: state => {
      state.loading = true;
    },
    getPaymentMethodSucceed: (state, action) => {
      state.loading = false;
      state.paymentMethodDetails = action.payload;
    },
    getPaymentMethodFailed: (state, action) => {
      state.loading = false;
    },
    removePaymentMethod: (state, action) => {
      state.loading = true;
    },
    removePaymentMethodSucceed: (state, action) => {
      state.loading = false;
    },
    removePaymentMethodFailed: (state, action) => {
      state.loading = false;
    },
    updatePaymentMethod: (state, action) => {
      state.loading = true;
    },
    updatePaymentMethodSucceed: (state, action) => {
      state.loading = false;
    },
    updatePaymentMethodFailed: (state, action) => {
      state.loading = false;
    },
    getStripeInvoices: (state, action) => {
      state.stripeInvoiceLoading = true;
    },
    getStripeInvoicesSucceed: (state, action) => {
      state.stripeInvoiceLoading = false;
      state.invoicesData = action.payload;
    },
    getStripeInvoicesFailed: (state, action) => {
      state.stripeInvoiceLoading = false;
    },
    getAdditionalSeats: () => {},
    getAdditionalSeatsSucceed: (state, action) => {
      state.additionalSeats = action.payload;
      state.isBuySeatsCheckoutModal = true;
    },
    getAdditionalSeatsFailed: () => {},
    updateBuySeatsCheckoutModal: (state, action) => {
      state.isBuySeatsCheckoutModal = action.payload;
    },
    createAdditionalSeat: (state, action) => {
      state.isProcessingModal = true;
      state.isPurchasedModal = false;
      state.buySeats = "";
    },
    createAdditionalSeatSucceed: (state, action) => {
      state.purchased = action.payload;
      state.isProcessingModal = false;
      state.isPurchasedModal = true;
    },
    createAdditionalSeatFailed: (state, action) => {
      state.isProcessingModal = false;
      state.isPurchasedModal = false;
    },
    updateBuySeats: (state, action) => {
      state.buySeats = action.payload;
    },
    cancelSubscription: (state, action) => {
      state.loading = true;
    },
    cancelSubscriptionSucceed: (state, action) => {
      state.loading = false;
    },
    cancelSubscriptionFailed: (state, action) => {
      state.loading = false;
    },
    updateprocessingModal: (state, action) => {
      state.isProcessingModal = action.payload;
    },
    updatePurchasedModal: (state, action) => {
      state.isPurchasedModal = action.payload;
      state.filtersInvoices.sorting = initialInvoicesSorting;
    },
    updateBuySeatsModal: (state, action) => {
      state.isBuySeatsModal = action.payload;
    },
    updateProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    getCancelSubscription: () => {},
    getCancelSubscriptionSucceed: (state, action) => {
      state.cancelSubscription = action.payload;
    },
    getCancelSubscriptionFailed: () => {},
    updateRemoveSeats: (state, action) => {
      state.removeSeatsQuantity = action.payload;
    },
    removeSeatsModal: (state, action) => {
      state.isRemoveSeatModal = action.payload;
    },
    getRemoveSeats: (state, action) => {
      state.loading = true;
    },
    getRemoveSeatsSucceed: (state, action) => {
      state.loading = false;
      state.removeSeats = action.payload;
      state.isRemoveSeatsConfirmModal = true;
    },
    getRemoveSeatsFailed: (state, action) => {
      state.loading = false;
    },
    updateRemoveSeatsConfirmModal: (state, action) => {
      state.isRemoveSeatsConfirmModal = action.payload;
    },
    removeSeat: (state, action) => {
      state.isProcessingModal = true;
      state.isPurchasedModal = false;
      state.removeSeatsQuantity = "";
    },
    removeSeatSucceed: (state, action) => {
      state.isProcessingModal = false;
      state.isRemoveSuccessfullModal = true;
    },
    removeSeatFailed: (state, action) => {
      state.isProcessingModal = false;
    },
    updateRemoveSeatsModal: (state, action) => {
      state.isRemoveSeat.Modal = action.payload;
    },
    updateRemoveSuccessModal: (state, action) => {
      state.isRemoveSuccessfullModal = action.payload;
    },
    changeMembershipRequest: (state, action) => {
      state.loading = true;
    },
    changeMembershipRequestSucceed: (state, action) => {
      state.loading = false;
    },
    changeMembershipRequestFailed: (state, action) => {
      state.loading = false;
    },
    updateConfirmationPaymentModal: (state, action) => {
      state.paymentConfirmationResponse = action.payload;
      state.isProcessingModal = false;
    },
    updateCompletePaymentModal: (state, action) => {
      state.isConfirmPaymentModal = action.payload;
      state.filtersInvoices.sorting = initialInvoicesSorting;
    },
    cancelInvoice: state => {
      state.loading = true;
    },
    cancelInvoiceSucceed: state => {
      state.loading = false;
    },
    cancelInvoiceFailed: state => {
      state.loading = false;
    },
    resetCompanySorting: state => {
      state.filtersBrokers.sorting = defaultCompanySorting;
    },
    resetInvoiceSorting: state => {
      state.filtersInvoices.sorting = initialInvoicesSorting;
    }
  }
});

export const {
  updateActiveTab,
  updateBrokersFilters,
  updateInvoiceFilters,
  getCompanyBrokers,
  getCompanyBrokersSucceed,
  getCompanyBrokersFailed,
  updateBrokers,
  updateBrokersSucceed,
  updateBrokersFailed,
  updateCompanies,
  updateCompaniesSucceed,
  updateCompaniesFailed,
  getCompanies,
  getCompaniesSucceed,
  getCompaniesFailed,
  createBrokers,
  createBrokersSucceed,
  createBrokersFailed,
  createPaymentMethod,
  createPaymentMethodSucceed,
  createPaymentMethodFailed,
  getPaymentMethod,
  getPaymentMethodSucceed,
  getPaymentMethodFailed,
  removePaymentMethod,
  removePaymentMethodSucceed,
  removePaymentMethodFailed,
  updatePaymentMethod,
  updatePaymentMethodSucceed,
  updatePaymentMethodFailed,
  getStripeInvoices,
  getStripeInvoicesSucceed,
  getStripeInvoicesFailed,
  getAdditionalSeats,
  getAdditionalSeatsSucceed,
  getAdditionalSeatsFailed,
  updateBuySeatsCheckoutModal,
  createAdditionalSeat,
  createAdditionalSeatSucceed,
  createAdditionalSeatFailed,
  updateBuySeats,
  cancelSubscription,
  cancelSubscriptionSucceed,
  cancelSubscriptionFailed,
  updateprocessingModal,
  updatePurchasedModal,
  updateBuySeatsModal,
  updateProfileImage,
  changePage,
  getCancelSubscription,
  getCancelSubscriptionSucceed,
  getCancelSubscriptionFailed,
  removeSeatsModal,
  getRemoveSeats,
  getRemoveSeatsSucceed,
  getRemoveSeatsFailed,
  updateRemoveSeatsConfirmModal,
  updateRemoveSeats,
  removeSeat,
  removeSeatSucceed,
  removeSeatFailed,
  updateRemoveSeatsModal,
  updateRemoveSuccessModal,
  changeMembershipRequest,
  changeMembershipRequestSucceed,
  changeMembershipRequestFailed,
  updateConfirmationPaymentModal,
  updateCompletePaymentModal,
  cancelInvoice,
  cancelInvoiceSucceed,
  cancelInvoiceFailed,
  resetCompanySorting,
  resetInvoiceSorting
} = company.actions;

export default company.reducer;
