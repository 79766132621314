import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/tasks";
import * as actions from "../../reducers/tasks";
import * as casesActions from "reducers/cases";
import { toast } from "react-toastify";

function* getTasksSaga() {
  try {
    const {
      tasks: { filters, page, activeTab }
    } = yield select();
    const resp = yield call(Api.getTasksAPI, { ...filters, activeTab, page });
    yield put(actions.getTasksSucceed(resp.data));
  } catch (error) {
    yield put(actions.getTasksFailed(error));
  }
}

function* updateTaskSaga({ payload }) {
  try {
    yield call(Api.updateTaskAPI, payload);
    yield put(actions.updateTaskSucceed());
    yield put(actions.getTasks());
    yield put(actions.getDrawerTasks());
    yield put(casesActions.getCaseTask());
    yield put(actions.getDrawerTaskOnChangeTab());
    toast.success("You have successfully updated task(s).");
  } catch (error) {
    yield put(actions.updateTaskFailed(error));
  }
}

function* removeTaskSaga({ payload }) {
  try {
    yield call(Api.removeTaskAPI, payload);
    yield put(actions.removeTaskSucceed());
    yield put(actions.getTasks());
  } catch (error) {
    yield put(actions.removeTaskFailed(error));
  }
}

function* updateTasksBulkSaga({ payload }) {
  try {
    yield call(Api.updateTasksBulkAPI, payload);
    yield put(actions.updateTasksBulkSucceed());
    yield put(actions.getTasks());
    yield put(actions.getDrawerTasks());
    toast.success(
      `You have successfully moved task(s) to ${
        payload.isCompleted ? "completed" : "todo"
      }.`
    );
  } catch (error) {
    yield put(actions.updateTasksBulkFailed(error));
  }
}

function* removeTasksBulkSaga({ payload }) {
  try {
    yield call(Api.removeTasksBulkAPI, payload);
    yield put(actions.removeTasksBulkSucceed());
    yield put(actions.getTasks());
    yield put(actions.getDrawerTasks());
    toast.success("You have successfully deleted task(s).");
  } catch (error) {
    yield put(actions.removeTasksBulkFailed(error));
  }
}

function* getDrawerTasksSaga() {
  const {
    tasks: { taskDrawerPage, drawerActiveTab }
  } = yield select();
  try {
    const resp = yield call(Api.getTasksDrawerAPI, {
      activeTab: drawerActiveTab,
      page: taskDrawerPage
    });
    yield put(actions.getDrawerTasksSucceed(resp.data));
  } catch (error) {
    yield put(actions.getDrawerTasksFailed(error));
  }
}

function* getDrawerTaskOnChangeTabSaga() {
  const {
    tasks: { taskDrawerPage, drawerActiveTab }
  } = yield select();
  try {
    const resp = yield call(Api.getTasksDrawerAPI, {
      activeTab: drawerActiveTab,
      page: taskDrawerPage
    });
    yield put(actions.getDrawerTaskOnChangeTabSucceed(resp.data));
  } catch (error) {
    yield put(actions.getDrawerTaskOnChangeTabFailed(error));
  }
}
export function* tasksSaga() {
  yield takeLatest(actions.getTasks.type, getTasksSaga);
  yield takeLatest(actions.updateTask.type, updateTaskSaga);
  yield takeLatest(actions.removeTask.type, removeTaskSaga);
  yield takeLatest(actions.updateTasksBulk.type, updateTasksBulkSaga);
  yield takeLatest(actions.removeTasksBulk.type, removeTasksBulkSaga);
  yield takeLatest(actions.getDrawerTasks.type, getDrawerTasksSaga);
  yield takeLatest(
    actions.getDrawerTaskOnChangeTab.type,
    getDrawerTaskOnChangeTabSaga
  );
}
