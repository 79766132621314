import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  data: [],
  search: "",
  error: null,
  emailContent: {}
};

export const emailsContent = createSlice({
  name: "emailsContent",
  initialState,
  reducers: {
    getEmailsContentTypes: state => {
      state.loading = true;
    },
    getEmailsContentTypesSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getEmailsContentTypesFailed: (state, action) => {
      state.loading = false;
    },
    handleSearch: (state, action) => {
      state.search = action.payload;
    },
    resetSearch: (state, action) => {
      state.search = "";
    },
    getEmailContent: state => {
      state.loading = true;
    },
    getEmailContentSucceed: (state, action) => {
      state.loading = false;
      state.emailContent = action.payload;
    },
    getEmailContentFailed: (state, action) => {
      state.loading = false;
    },
    updateEmailContent: (state, action) => {
      state.loading = true;
    },
    updateEmailContentSucceed: (state, action) => {
      state.loading = false;
    },
    uupdateEmailContentFailed: (state, action) => {
      state.loading = false;
    }
  }
});

export const {
  getEmailsContentTypes,
  getEmailsContentTypesSucceed,
  getEmailsContentTypesFailed,
  handleSearch,
  resetSearch,
  getEmailContent,
  getEmailContentSucceed,
  getEmailContentFailed,
  updateEmailContent,
  updateEmailContentSucceed,
  uupdateEmailContentFailed
} = emailsContent.actions;

export default emailsContent.reducer;
