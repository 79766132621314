import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "routes/constants";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Stack,
  Box,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useHistory } from "react-router-dom";
import { addNewCase } from "reducers/cases";
import Header from "components/Header";
import SelectField from "components/SelectField";
import BackBtn from "./BackBtn";
import { FINANCE_TYPE, LEAD_TYPES, PROPERTY_TYPE_FILTER_DROPDOWN, USER_TYPE } from "utils/constants";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);
const formStyle = theme => {
  return {
    width: "450px",
    marginBottom: "48px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "24px"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  };
};

function NewCase() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: userData } = useSelector(state => state.user);
  const [leadId, setLeadId] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [financeId, setFinanceId] = useState("");
  const [isError, setIsError] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      sendIntroductoryEmail: false,
      mobilePhone: "+44"
    }
  });

  const watchSendIntroductoryEmail = watch("sendIntroductoryEmail");

  const onSubmit = data => {
    let leadType = LEAD_TYPES.find(item => item.id === leadId);
    let propertyType = PROPERTY_TYPE_FILTER_DROPDOWN.find(item => item.id === propertyId);
    let finance = FINANCE_TYPE.find(item => item.id === financeId);
    if (!leadType) {
      setIsError(true);
      return;
    }
    let addCaseUserObj = {
      data: {
        client: {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          mobilePhone: data.mobilePhone,
          age: data.age || null
        },
        address: {
          postCode: data.postCode,
          address: data.address
        },
        lead: {
          propertyAmount: data.propertyAmount || null,
          mortgageAmount: data.mortgageAmount || null,
          message: data.message,
          type: leadType.value,
          propertyType: propertyType.value,
          financing: finance.value
        },
        sendIntroductoryEmail: data.sendIntroductoryEmail
      }
    };
    if (userData?.me?.type !== USER_TYPE.COMPANY_OWNER) {
      delete addCaseUserObj.data["sendIntroductoryEmail"];
    }
    dispatch(addNewCase(addCaseUserObj));
  };

  const onError = (error, item) => {
    if ([undefined, null, ""].includes(item)) {
      setIsError(true);
    }
  };

  const handleLeadType = e => {
    setLeadId(e.target.value);
    setIsError(false);
  };

  const handlePropertyType = e => {
    setPropertyId(e.target.value);
    setIsError(false);
  };

  const handleFinance = e => {
    setFinanceId(e.target.value);
    setIsError(false);
  };

  return (
    <>
      <Header
        componentName="New Case"
        backBtn={true}
        routePage={ROUTES.CASES}
      />
      <Box sx={theme => formStyle(theme)}>
        <Stack display={{ xs: "block", lg: "none" }} marginBottom="24px">
          <Stack direction="row" alignItems="center">
            <BackBtn routePage={ROUTES.CASES} />
            <Typography variant="h3" color="text.primary" fontSize="38px">
              New Case
            </Typography>
          </Stack>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit, () => onError(null, leadId))}>
          <Typography
            variant="h4"
            color="text.primary"
            gutterBottom
            marginTop="26px"
            sx={theme => ({
              [theme.breakpoints.down("md")]: {
                fontSize: "28px"
              }
            })}
          >
            Client
          </Typography>
          <Stack spacing={3}>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="First name *"
                type="text"
                placeholder="First name"
                autoComplete="off"
                error={!!errors.firstName}
                {...register("firstName", {
                  required: true
                })}
              />
              {errors.firstName && (
                <div className="validation-error">First Name required!</div>
              )}
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Last name"
                type="text"
                placeholder="Last name"
                autoComplete="off"
                {...register("lastName", {
                  required: false
                })}
              />
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Email *"
                type="email"
                placeholder="Email"
                autoComplete="off"
                error={!!errors.email}
                {...register("email", {
                  required: true
                })}
              />
              {errors.email && (
                <div className="validation-error">email required!</div>
              )}
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Address"
                type="text"
                placeholder="Address"
                autoComplete="off"
                {...register("address", {
                  required: false
                })}
              />
            </FormControl>
            <FormControl
              sx={theme => ({
                width: "160px",
                [theme.breakpoints.down("md")]: {
                  width: "300px"
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              })}
            >
              <TextField
                className={classes.textField}
                fullWidth
                label="Post code"
                type="text"
                placeholder="Post code"
                autoComplete="off"
                {...register("postCode", {
                  required: false
                })}
              />
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Mobile *"
                type="tel"
                placeholder="Mobile"
                autoComplete="off"
                error={!!errors.mobilePhone}
                {...register("mobilePhone", {
                  required: true,
                  minLength: 4
                })}
              />
              {errors.mobilePhone && (
                <div className="validation-error">mobile Phone required!</div>
              )}
            </FormControl>
            <FormControl
              sx={theme => ({
                width: "160px",
                [theme.breakpoints.down("md")]: {
                  width: "300px"
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              })}
            >
              <TextField
                className={classes.textField}
                fullWidth
                label="Age"
                type="number"
                placeholder="Age"
                autoComplete="off"
                {...register("age", {
                  required: false
                })}
              />
            </FormControl>
          </Stack>
          <Typography
            variant="h4"
            color="text.primary"
            gutterBottom
            marginTop="26px"
          >
            Lead
          </Typography>
          <Stack spacing={3}>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Property value *"
                type="number"
                placeholder="Property value"
                autoComplete="off"
                error={!!errors.propertyAmount}
                {...register("propertyAmount", {
                  required: true
                })}
              />
              {errors.propertyAmount && (
                <div className="validation-error">Property value required!</div>
              )}
            </FormControl>
            <FormControl>
              <SelectField
                sx={theme => ({
                  width: 450,
                  [theme.breakpoints.down("lg")]: {
                    width: "100%"
                  }
                })}
                label="Property Type *"
                value={propertyId}
                error={isError}
                onChange={handlePropertyType}
                options={PROPERTY_TYPE_FILTER_DROPDOWN.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </FormControl>
            <FormControl>
              <SelectField
                sx={theme => ({
                  width: 450,
                  [theme.breakpoints.down("lg")]: {
                    width: "100%"
                  }
                })}
                label="Financing *"
                value={financeId}
                error={isError}
                onChange={handleFinance}
                options={FINANCE_TYPE.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Mortgage value *"
                type="number"
                placeholder="Mortgage value"
                autoComplete="off"
                error={!!errors.mortgageAmount}
                {...register("mortgageAmount", {
                  required: true
                })}
              />
              {errors.mortgageAmount && (
                <div className="validation-error">Mortgage value required!</div>
              )}
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Notes"
                type="text"
                placeholder="Notes"
                autoComplete="off"
                {...register("message", {
                  required: false
                })}
                multiline
                rows={6}
              />
            </FormControl>
          </Stack>
          <Stack sx={{ margin: "26px 0" }}>
            <Typography variant="h4" color="text.primary" gutterBottom>
              Additional Info
            </Typography>
            <FormControl>
              <SelectField
                sx={theme => ({
                  width: 450,
                  [theme.breakpoints.down("md")]: {
                    width: "100%"
                  }
                })}
                label="Lead Type *"
                error={isError}
                value={leadId}
                onChange={handleLeadType}
                options={LEAD_TYPES.map(({ id, name }) => ({
                  value: id,
                  label: id === 3 ? name.replace("Other", `${userData.me?.otherLeadsName}`) : name
                }))}
              />
              {isError && (
                <div className="validation-error">Lead Type required!</div>
              )}
            </FormControl>
            {userData?.me?.type === USER_TYPE.COMPANY_OWNER && (
              <FormControlLabel
                label="Send introductory email"
                control={
                  <Checkbox
                    sx={{ color: "text.secondary" }}
                    checked={watchSendIntroductoryEmail}
                  />
                }
                {...register("sendIntroductoryEmail")}
                sx={{
                  color: "text.secondary",
                  marginTop: "16px"
                }}
              />
            )}
          </Stack>
          <Stack spacing={4} direction="row">
            <Button type="submit" variant="contained">
              SUBMIT
            </Button>
            <Button
              sx={{ color: "text.primary" }}
              variant="text"
              onClick={() => {
                history.push(ROUTES.CASES);
              }}
            >
              CANCEL
            </Button>
          </Stack>
        </form>
      </Box>
    </>
  );
}

export default NewCase;
