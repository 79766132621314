import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Stack,
  Tabs,
  Tab,
  Typography
} from "@mui/material";
import {
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getSortingColor,
  getSortingIcon,
  dueDays,
  showNote,
  dueDaysDiff,
  toggleSelectDeselectAll,
  horizontalScrollBarStyle
} from "utils";
import {
  updateFilters,
  changecaseTaskPage,
  getCaseTask,
  updateCaseTask,
  createCaseTask,
  updateCaseTaskTab,
  updateCaseTasksBulk,
  removeCaseTasksBulk
} from "reducers/cases";
import CaseDetailPagination from "./CaseDetailPagination";
import { makeStyles } from "@mui/styles";
import {
  ADD_TASK,
  defaultTasksSorting,
  TASK_TABS,
  WORDS_30,
  WORDS_45,
  DESELECT_ALL,
  DARK
} from "utils/constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Task from "../Tasks/Task";
import DeletePopover from "components/DeletePopover";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  borderRight: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "2px",
      height: "14px",
      background: theme.palette.grey[300],
      top: "50%",
      transform: "translateY(-50%)",
      right: "0"
    },
    "& button": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Montserrat', sans-serif"
    }
  }
}));

const CHIP_STYLE_LATEST = theme => {
  return {
    color: theme.palette.text.primary,
    bgcolor: theme.palette.active.selected
  };
};

const CHIP_STYLE_LATE = theme => {
  return {
    color: theme.palette.text.dark.primary,
    bgcolor: theme.palette.error.main
  };
};

function TaskTab() {
  const classes = useStyles();
  const { id } = useParams();
  const [isTaskModal, setIsTaskModal] = useState(false);
  const [task, setTask] = useState({});
  const {
    filters,
    caseTaskPage,
    rowsPerPage,
    caseTasks,
    caseTasktabs
  } = useSelector(state => state.cases);
  const { theme } = useSelector(state => state.user);
  const [selectedItems, setSelectedItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      let filters_ = { ...filters, case: id };
      dispatch(updateFilters(filters_));
      dispatch(getCaseTask());
    }
  }, [dispatch, id, caseTaskPage, caseTasktabs]);

  useEffect(() => {
    return () => dispatch(updateCaseTaskTab(TASK_TABS.TODO));
  }, [dispatch]);

  const handleOnSortFilterChange = sortingParam => {
    let filters_ = { ...filters, sorting: { ...filters.sorting } };
    filters_.sorting[sortingParam] = getNextSortingState(
      filters_.sorting[sortingParam]
    );
    filters_.sorting = clearOtherSortingParams(filters_.sorting, sortingParam);
    if (isNoSortingParams(filters_.sorting)) {
      filters_.sorting = defaultTasksSorting;
    }
    dispatch(updateFilters(filters_));
    dispatch(getCaseTask());
  };

  const handleOnPageChange = (e, newPage) => {
    dispatch(changecaseTaskPage(newPage));
  };

  const handleRemoveCaseTask = (e, id) => {
    setSelectedItems([id]);
    setAnchorEl(e.currentTarget);
  };

  const handleUpdateCaseTask = data => {
    dispatch(updateCaseTask({ id: task.id, data }));
    setIsTaskModal(false);
  };

  const handleCreateCaseTask = data => {
    dispatch(createCaseTask({ id: task.id, data }));
    setIsTaskModal(false);
  };

  const handleOnActiveTabChange = (e, value) => {
    dispatch(updateCaseTaskTab(value));
    setSelectedItems([]);
  };

  const selectAllCaseTasks = () => {
    if (toggleSelectDeselectAll(selectedItems, caseTasks) === DESELECT_ALL) {
      return setSelectedItems([]);
    }
    setSelectedItems(caseTasks.results.map(item => item.id));
  };

  const markHandler = isRead => {
    dispatch(
      updateCaseTasksBulk({
        ids: selectedItems,
        isCompleted: isRead
      })
    );
    setSelectedItems([]);
  };

  const onRemoveCaseTasks = e => {
    setAnchorEl(e.currentTarget);
  };

  const selectCaseTasks = (e, id) => {
    setSelectedItems(state => {
      const idx = state.indexOf(id);
      if (idx === -1) return [...state, id];
      else return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  };

  const actionButton = !!selectedItems.length && (
    <Stack direction="row" sx={{ margin: "32px 0" }} flexWrap="wrap">
      {(caseTasktabs === TASK_TABS.ALL || caseTasktabs === TASK_TABS.TODO) && (
        <Button
          variant="contained"
          onClick={() => {
            markHandler(true);
          }}
          sx={theme => ({
            marginRight: "16px",
            [theme.breakpoints.down("lg")]: {
              marginBottom: "16px"
            }
          })}
        >
          Mark Complete
        </Button>
      )}

      {(caseTasktabs === TASK_TABS.ALL ||
        caseTasktabs === TASK_TABS.COMPLETED) && (
        <Button
          variant="contained"
          onClick={() => {
            markHandler(false);
          }}
          sx={theme => ({
            marginRight: "16px",
            [theme.breakpoints.down("lg")]: {
              marginBottom: "16px"
            }
          })}
        >
          Mark incomplete
        </Button>
      )}
      <Button
        variant="contained"
        color="error"
        onClick={e => onRemoveCaseTasks(e)}
        sx={theme => ({
          [theme.breakpoints.down("lg")]: {
            marginBottom: "16px"
          }
        })}
      >
        Remove
      </Button>
    </Stack>
  );

  return (
    <>
      <Box sx={{ marginTop: "28px", marginBottom: "24px" }}>
        <Tabs
          value={caseTasktabs}
          onChange={handleOnActiveTabChange}
          textColor="primary"
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={theme => ({
            "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
              color: `${theme.palette.primary.main} !important`
            }
          })}
        >
          <Tab
            label="ALL"
            value={TASK_TABS.ALL}
            sx={{
              color: "text.secondary",
              fontSize: "16px",
              marginRight: "16px",
              fontWeight: "600"
            }}
          />
          <Tab
            label="TO DO"
            value={TASK_TABS.TODO}
            sx={{
              color: "text.secondary",
              fontSize: "16px",
              marginRight: "16px",
              fontWeight: "600"
            }}
          />
          <Tab
            label="COMPLETED"
            value={TASK_TABS.COMPLETED}
            sx={{
              color: "text.secondary",
              fontSize: "16px",
              fontWeight: "600"
            }}
          />
        </Tabs>
      </Box>
      {caseTasks.count > 0 ? (
        <>
          <Button
            variant="contained"
            onClick={selectAllCaseTasks}
            startIcon={<AddIcon />}
            sx={theme => ({
              padding: "14px 26px",
              [theme.breakpoints.down("lg")]: {
                marginTop: "24px"
              }
            })}
          >
            {toggleSelectDeselectAll(selectedItems, caseTasks)}
          </Button>
          {!_.isEmpty(caseTasks.results) && actionButton}
          <TableContainer
            component={Paper}
            sx={theme => horizontalScrollBarStyle(theme)}
          >
            <Table sx={{ minWidth: 550 }} stickyHeader>
              <TableHead>
                <TableRow
                  sx={theme => ({
                    "& td,th": {
                      borderBottom: `1px solid ${theme.palette.text.disabled}`
                    }
                  })}
                >
                  <TableCell
                    sx={{ width: "25%" }}
                    className={classes.borderRight}
                  >
                    <Button
                      onClick={() => handleOnSortFilterChange("name")}
                      variant="text"
                      sx={{ color: getSortingColor(filters?.sorting?.name) }}
                      endIcon={getSortingIcon(filters?.sorting?.name)}
                    >
                      Tasks
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "20%" }}
                    className={classes.borderRight}
                    align="center"
                  >
                    <Button
                      onClick={() => handleOnSortFilterChange("due_date")}
                      variant="text"
                      sx={{
                        color: getSortingColor(filters?.sorting?.due_date)
                      }}
                      endIcon={getSortingIcon(filters?.sorting?.due_date)}
                    >
                      Due date
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button variant="text" sx={{ color: "text.primary" }}>
                      Notes
                    </Button>
                  </TableCell>
                  <TableCell sx={{ width: "5%" }}></TableCell>
                  <TableCell
                    sx={{ width: "5%" }}
                    className={classes.borderRight}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={theme => ({
                  bgcolor: theme.palette.background.default,
                  "& td,th": {
                    borderBottom: `1px solid ${theme.palette.text.disabled}`
                  }
                })}
              >
                {caseTasks?.results?.map(row => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: "16px",
                        color: "text.primary",
                        width: "25%",
                        wordBreak: "break-word"
                      }}
                    >
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          checked={selectedItems.includes(row.id)}
                          sx={theme => ({
                            color: "text.secondary",
                            marginRight: "12px",
                            wordBreak: "break-word",
                            [theme.breakpoints.down("lg")]: {
                              marginRight: 0
                            }
                          })}
                          onChange={e => {
                            selectCaseTasks(e, row.id);
                          }}
                        />
                        <Typography className="singleLineTruncate">
                          {showNote(row.name, WORDS_30)}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontSize: "14px",
                        color: "text.disabled",
                        width: "20%"
                      }}
                    >
                      <Chip
                        label={dueDays(row.dueDate)}
                        sx={theme =>
                          dueDaysDiff(row.dueDate) >= 0
                            ? CHIP_STYLE_LATEST(theme)
                            : CHIP_STYLE_LATE(theme)
                        }
                        style={{ marginBottom: "5px" }}
                      />
                      <div>{moment(row.dueDate).format("ddd D MMM YYYY")}</div>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        color: "text.secondary",
                        wordBreak: "break-word"
                      }}
                    >
                      <Typography
                        className="singleLineTruncate"
                        sx={{ width: "365px" }}
                      >
                        {showNote(row.note, WORDS_45)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "5%" }} align="right">
                      <IconButton
                        sx={{ color: "text.primary" }}
                        onClick={() => {
                          setIsTaskModal(true);
                          setTask(row);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      sx={{ width: "5%", paddingRight: "0" }}
                      align="right"
                    >
                      <IconButton
                        color="error"
                        onClick={e => handleRemoveCaseTask(e, row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isTaskModal && (
            <Box>
              <Task
                edit
                isOpen={isTaskModal}
                handleClose={() => setIsTaskModal(false)}
                handleTask={handleUpdateCaseTask}
                task={task}
              />
            </Box>
          )}
        </>
      ) : (
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          color="text.secondary"
          textAlign="center"
        >
          There is no task yet
        </Typography>
      )}
      <CaseDetailPagination
        addNewBtn={ADD_TASK}
        data={caseTasks}
        page={caseTaskPage}
        rowsPerPage={rowsPerPage}
        handleOnPageChange={handleOnPageChange}
        handleTask={handleCreateCaseTask}
        task={task}
      />
      <DeletePopover
        text="task(s)"
        anchorEl={anchorEl}
        itemId={selectedItems}
        handleClose={() => setAnchorEl(null)}
        removeItem={removeCaseTasksBulk}
        bgColor={theme === DARK ? "#010810" : "#FFFFFF"}
        setSelectedItems={setSelectedItems}
      />
    </>
  );
}

export default TaskTab;
