import { formatFilters } from "utils";
import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getEmailsContentTypesAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.GET_EMAILS_CONTENT_TYPES}`,
    params: formatFilters(params)
  });

export const getEmailContentAPI = id =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.EMAIL_CONTENT}${id}/`
  });

export const updateEmailContentAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.EMAIL_CONTENT}${id}/`,
    data
  });
