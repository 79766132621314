import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { moneyFieldConverter } from "utils";
import {
  removePaymentMethod,
  updatePaymentMethod,
  updateBuySeatsCheckoutModal,
  updateprocessingModal,
  updatePurchasedModal,
  updateBuySeatsModal,
  getCancelSubscription,
  getStripeInvoices,
  getPaymentMethod,
  changePage,
  removeSeatsModal,
  updateRemoveSeatsConfirmModal,
  updateRemoveSuccessModal,
  updateCompletePaymentModal
} from "reducers/company";
import {
  Typography,
  Box,
  Stack,
  Grid,
  Chip,
  Radio,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Button
} from "@mui/material";
import AddCardModal from "./AddCardModal";
import InvoiceTable from "./InvoiceTable";
import BuySeatsModal from "./BuySeatsModal";
import BuySeatsCheckoutModal from "./BuySeatsCheckoutModal";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import ProcessingModal from "./ProcessingModal";
import EditBillingAddressModal from "./EditBillingAddressModal";
import PurchasedModal from "./PerchasedModal";
import { FIRST_PAGE } from "utils/constants";
import Loader from "components/Loader";
import RemoveSeatsModal from "./RemoveSeatsModal";
import RemoveSeatsConfirmModal from "./RemoveSeatsConfirmModal";
import RemoveSuccessModal from "./RemoveSuccessModal";
import ConfirmPaymentModal from "./ConfirmPaymentModal";

function BillingTab() {
  const dispatch = useDispatch();
  const [isAddCardModal, setIsAddCardModal] = useState(false);
  const [isBillingModal, setIsBillingModal] = useState(false);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);

  const {
    defaultCompanyData,
    invoicesData,
    paymentMethodDetails,
    isBuySeatsCheckoutModal,
    isProcessingModal,
    isPurchasedModal,
    isBuySeatsModal,
    purchased,
    filtersInvoices,
    page,
    loading,
    isRemoveSeatModal,
    isRemoveSeatsConfirmModal,
    isRemoveSuccessfullModal,
    removeSeats,
    isConfirmPaymentModal,
    stripeInvoiceLoading
  } = useSelector(state => state.company);

  const handleChange = (e, id) => {
    const cardDefaultChecked = {
      id,
      data: {
        isDefault: e.target.checked
      }
    };
    dispatch(updatePaymentMethod(cardDefaultChecked));
  };

  const handleDelete = id => {
    dispatch(removePaymentMethod(id));
  };

  const cancelSubscription = () => {
    setIsSubscriptionModal(true);
    dispatch(getCancelSubscription());
  };

  useEffect(() => {
    dispatch(getPaymentMethod());

    return () => dispatch(changePage(FIRST_PAGE));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStripeInvoices());
  }, [dispatch, filtersInvoices, page]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={theme => ({
            [theme.breakpoints.down("md")]: {
              marginTop: "24px"
            }
          })}
        >
          <Grid container>
            <Grid
              item
              llg={8}
              lg={12}
              md={8}
              xs={12}
              sx={theme => ({
                paddingRight: "74px",
                [theme.breakpoints.down("xll")]: {
                  paddingRight: "24px"
                },
                [theme.breakpoints.down("llg")]: {
                  paddingRight: 0
                },
                [theme.breakpoints.down("lg")]: {
                  paddingRight: "24px"
                },
                [theme.breakpoints.down("md")]: {
                  order: 1,
                  paddingRight: 0
                }
              })}
            >
              <Typography variant="h6" color="text.primary" marginBottom="8px">
                Billing Address
              </Typography>
              <Typography variant="body1" color="text.primary">
                {`${defaultCompanyData?.billingAddress?.address} ${defaultCompanyData?.billingAddress?.address2}, ${defaultCompanyData?.billingAddress?.town}, ${defaultCompanyData?.billingAddress?.postCode}`}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "primary.main",
                  margin: "16px 0 32px",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={() => setIsBillingModal(true)}
              >
                Edit
              </Typography>
              <Typography variant="h6" color="text.primary" marginBottom="16px">
                Payment Card
              </Typography>
              <Stack spacing={1}>
                {paymentMethodDetails.map(card => (
                  <Stack
                    key={card.id}
                    direction="row"
                    alignItems="center"
                    sx={theme => ({
                      padding: "22px 18px",
                      backgroundColor: theme.palette.text.dark.primary,
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      [theme.breakpoints.down("xl")]: {
                        padding: "18px 8px"
                      }
                    })}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Radio
                        checked={card.isDefault}
                        onChange={e => handleChange(e, card.id)}
                        value={card.isDefault}
                        name="radio-buttons"
                        sx={{
                          padding: "5px"
                        }}
                      />
                      <Stack direction="row" margin="0 6px">
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={theme => ({
                            marginRight: "20px",
                            textTransform: "uppercase",
                            [theme.breakpoints.down("xl")]: {
                              marginRight: "5px"
                            },
                            [theme.breakpoints.down("ssm")]: {
                              fontSize: "12px"
                            }
                          })}
                        >
                          {card.type}
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: 0
                          }}
                        >
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 5px 0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 5px 0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 2px"
                            }}
                          ></ListItem>
                          <ListItem
                            disablePadding
                            sx={{
                              height: "5px",
                              width: "5px",
                              borderRadius: "50%",
                              bgcolor: "text.primary",
                              margin: "0 5px 0 2px"
                            }}
                          ></ListItem>
                        </List>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={theme => ({
                            margin: "0 20px 0 10px",
                            [theme.breakpoints.down("xl")]: {
                              margin: "0 10px 0 2px"
                            },
                            [theme.breakpoints.down("ssm")]: {
                              fontSize: "12px"
                            }
                          })}
                        >
                          {card.last4}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          sx={theme => ({
                            [theme.breakpoints.down("ssm")]: {
                              fontSize: "12px"
                            }
                          })}
                        >
                          {card.expMonth} / {card.expYear}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={theme => ({
                        alignItems: "center",
                        [theme.breakpoints.down("xl")]: {
                          width: "100%",
                          justifyContent: "flex-end"
                        }
                      })}
                    >
                      {card.isDefault && (
                        <Chip label="Default" color="primary" />
                      )}
                      {!card.isDefault && (
                        <IconButton
                          onClick={() => handleDelete(card.id)}
                          sx={{
                            marginLeft: "12px"
                          }}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
              <Typography
                variant="body1"
                sx={{
                  color: "primary.main",
                  margin: "16px 0 32px",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={() => setIsAddCardModal(true)}
              >
                Add Card
              </Typography>
            </Grid>
            {defaultCompanyData.planPrice && (
              <Grid item llg={4} lg={12} md={4} xs={12}>
                <Box
                  sx={theme => ({
                    padding: "32px 24px",
                    background: theme.palette.text.dark.primary,
                    [theme.breakpoints.down("xl")]: {
                      marginBottom: "40px"
                    }
                  })}
                >
                  <Typography variant="h6" color="text.primary" gutterBottom>
                    Your Plan
                  </Typography>
                  <List
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <ListItem disablePadding sx={{ width: "unset" }}>
                      <ListItemText
                        primary="Seats"
                        sx={{ color: "text.primary" }}
                      />
                    </ListItem>
                    <ListItem disablePadding sx={{ width: "unset" }}>
                      <ListItemText
                        primary={defaultCompanyData.seatsCount}
                        sx={{ color: "text.primary" }}
                      />
                    </ListItem>
                  </List>
                  <List
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <ListItem disablePadding sx={{ width: "unset" }}>
                      <ListItemText
                        primary="Per Seat"
                        sx={{ color: "text.primary" }}
                      />
                    </ListItem>
                    <ListItem disablePadding sx={{ width: "unset" }}>
                      <ListItemText
                        primary={moneyFieldConverter(
                          defaultCompanyData.perSeatMonthlyAmount
                        )}
                        sx={{ color: "text.primary" }}
                      />
                    </ListItem>
                  </List>
                  <List
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <ListItem disablePadding sx={{ width: "unset" }}>
                      <ListItemText
                        primary="Total"
                        sx={{
                          color: "text.primary",
                          "& span": {
                            fontWeight: "700"
                          }
                        }}
                      />
                    </ListItem>
                    <ListItem disablePadding sx={{ width: "unset" }}>
                      <ListItemText
                        primary={moneyFieldConverter(
                          defaultCompanyData.planPrice
                        )}
                        sx={{
                          color: "text.primary",
                          "& span": {
                            fontWeight: "700"
                          }
                        }}
                      />
                    </ListItem>
                  </List>
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "34px",
                      width: "100%"
                    }}
                    onClick={() => dispatch(updateBuySeatsModal(true))}
                  >
                    BUY SEATS
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{
                      marginTop: "12px",
                      width: "100%"
                    }}
                    onClick={() => dispatch(removeSeatsModal(true))}
                  >
                    REMOVE SEATS
                  </Button>
                  {defaultCompanyData.subscriptionDetails.cancelAt ? (
                    <Typography
                      color="error"
                      variant="body1"
                      textAlign="center"
                      marginTop="24px"
                    >{`Cancelled Subscription (${
                      defaultCompanyData.subscriptionDetails.cancelAt.split(
                        "T"
                      )[0]
                    })`}</Typography>
                  ) : (
                    <Button
                      variant="text"
                      color="error"
                      sx={{
                        width: "100%",
                        marginTop: "12px"
                      }}
                      onClick={cancelSubscription}
                    >
                      CANCEL SUBSCRIPTION
                    </Button>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
          {stripeInvoiceLoading ? (
            <Loader />
          ) : (
            invoicesData?.results?.length > 0 && <InvoiceTable />
          )}
        </Box>
      )}
      {isBillingModal && (
        <Box>
          <EditBillingAddressModal
            isOpen={isBillingModal}
            handleClose={() => setIsBillingModal(false)}
          />
        </Box>
      )}
      {isAddCardModal && (
        <Box>
          <AddCardModal
            isOpen={isAddCardModal}
            handleClose={() => setIsAddCardModal(false)}
          />
        </Box>
      )}
      {isBuySeatsModal && (
        <Box>
          <BuySeatsModal
            isOpen={isBuySeatsModal}
            handleClose={() => dispatch(updateBuySeatsModal(false))}
          />
        </Box>
      )}
      {isBuySeatsCheckoutModal && (
        <Box>
          <BuySeatsCheckoutModal
            isOpen={isBuySeatsCheckoutModal}
            handleClose={() => dispatch(updateBuySeatsCheckoutModal(false))}
          />
        </Box>
      )}
      {isProcessingModal && (
        <Box>
          <ProcessingModal
            isOpen={isProcessingModal}
            handleClose={() => dispatch(updateprocessingModal(false))}
          />
        </Box>
      )}
      {isPurchasedModal && (
        <Box>
          <PurchasedModal
            isOpen={isPurchasedModal}
            handleClose={() => dispatch(updatePurchasedModal(false))}
            purchased={purchased}
          />
        </Box>
      )}
      {isSubscriptionModal && (
        <Box>
          <CancelSubscriptionModal
            isOpen={isSubscriptionModal}
            handleClose={() => setIsSubscriptionModal(false)}
          />
        </Box>
      )}
      {isRemoveSeatModal && (
        <Box>
          <RemoveSeatsModal
            isOpen={isRemoveSeatModal}
            handleClose={() => dispatch(removeSeatsModal(false))}
          />
        </Box>
      )}
      {isRemoveSeatsConfirmModal && (
        <Box>
          <RemoveSeatsConfirmModal
            isOpen={isRemoveSeatsConfirmModal}
            handleClose={() => dispatch(updateRemoveSeatsConfirmModal(false))}
          />
        </Box>
      )}
      {isRemoveSuccessfullModal && (
        <Box>
          <RemoveSuccessModal
            isOpen={isRemoveSuccessfullModal}
            handleClose={() => dispatch(updateRemoveSuccessModal(false))}
            removeSeats={removeSeats}
          />
        </Box>
      )}
      {isConfirmPaymentModal && (
        <Box>
          <ConfirmPaymentModal
            isOpen={isConfirmPaymentModal}
            handleClose={() => dispatch(updateCompletePaymentModal(false))}
          />
        </Box>
      )}
    </>
  );
}

export default BillingTab;
