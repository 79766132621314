import { formatFilters } from "utils";
import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getCompanyBrokersAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_COMPANY_BROKERS,
    params: formatFilters({ ...params })
  });

export const updateBrokersAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.GET_COMPANY_BROKERS}${id}/`,
    data
  });

export const updateCompaniesAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.GET_COMPANIES}${id}/`,
    data
  });

export const getCompaniesAPI = id =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.GET_COMPANIES}${id}/`
  });

export const createBrokersAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.GET_COMPANY_BROKERS}`,
    data
  });

export const createPaymentMethodAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.PAYMENT_METHOD}`,
    data
  });

export const getPaymentMethodsAPI = () =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.PAYMENT_METHOD}`
  });

export const removePaymentMethodsAPI = id =>
  apiCaller({
    method: REQUEST_TYPES.DELETE,
    url: `${ENDPOINTS.PAYMENT_METHOD}${id}/`
  });

export const updatePaymentMethodAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.PAYMENT_METHOD}${id}/`,
    data
  });

export const getStripeInvoicesAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.STRIPE_INVOICES}`,
    params: formatFilters({ ...data })
  });

export const getAdditionalSeatsAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.ADDITIONAL_SEATS}`,
    params: formatFilters({ ...data })
  });

export const updateAdditionalSeatAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.ADDITIONAL_SEATS}`,
    data
  });

export const cancelSubscriptionAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.CANCEL_SUBSCRIPTION}`,
    data
  });

export const getCancelSubscriptionAPI = () =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.CANCEL_SUBSCRIPTION}`
  });

export const getRemoveSeatAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.REMOVE_SEAT}`,
    params: formatFilters({ ...data })
  });

export const updateRemoveSeatAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.REMOVE_SEAT}`,
    data
  });

export const changeMembershipRequestAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.CHANGE_MEMBERSHIP_REQUEST}`,
    data
  });

export const cancelInvoiceAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.STRIPE_INVOICES}${id}/`,
    data
  });
