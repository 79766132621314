import { formatFilters } from "utils";
import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getTasksAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_TASKS,
    params: formatFilters({ ...params })
  });

export const updateTaskAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.TASK}${id}/`,
    data: { ...data }
  });

export const removeTaskAPI = ({ id }) =>
  apiCaller({
    method: REQUEST_TYPES.DELETE,
    url: `${ENDPOINTS.TASK}${id}/`
  });

export const updateTasksBulkAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.UPDATE_TASKS,
    data
  });

export const removeTasksBulkAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.REMOVE_TASKS,
    data
  });

export const getTasksDrawerAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_TASKS,
    params: formatFilters({ ...params })
  });
