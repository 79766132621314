import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function BackBtn({ routePage, fontSize }) {
  return (
    <Link to={routePage}>
      <ArrowBackIosIcon
        sx={theme => ({
          fontSize: "34px",
          marginRight: "16px",
          color: "text.primary",
          [theme.breakpoints.down("lg")]: {
            fontSize: fontSize || "26px",
            marginRight: "6px",
            marginTop: "3px"
          }
        })}
      />
    </Link>
  );
}

export default BackBtn;
