import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Modal from "components/Modals/index";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getStatuses } from "reducers/cases";
import AutoCompleteField from "components/AutoCompleteField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { dateStyle } from "utils/DatePickerStyle";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  REASON_FILED_STATUSES,
  DUE_DATE_FIELD_STATUSES
} from "utils/constants";
import { scrollBarStyle } from "utils";

const AddEditStatusModal = ({
  title,
  status,
  isOpen,
  handleClose,
  handleCaseStatus,
  edit,
  isDisabled
}) => {
  const useStyles = makeStyles(theme =>
    textFieldStyle(theme, theme.palette.background.default)
  );
  const { statuses } = useSelector(state => state.cases);
  const { theme } = useSelector(state => state.user);
  const [caseStatus, setCaseStatus] = useState(status?.status?.id || "");
  const [caseStatusValue, setCaseStatusValue] = useState({});
  const [isStatuses, setIsStatuses] = useState(false);
  const [isReasonField, setIsReasonField] = useState(false);
  const [isDueDateField, setIsDueDateField] = useState(false);
  const [date, setDate] = useState(null);
  const [isError, setIsError] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getStatuses());
  }, [dispatch]);

  useEffect(() => {
    setCaseStatusValue(statuses?.find(item => item.id === caseStatus));
    if (statuses.length) {
      setIsStatuses(true);
    }
  }, [statuses]);

  const classes = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onError = (error, item) => {
    if ([undefined, null, ""].includes(item)) {
      setIsError(true);
    }
  };

  const handleDateTimepicker = value => {
    setDate(value);
    setIsError(false);
  };

  const onStatusSubmit = value => {
    if (isDueDateField) {
      if (!date) {
        setIsError(true);
        return;
      }
    }
    let data = {
      case: id,
      status: caseStatus ? caseStatus : status?.status?.id,
      reason: value.reason,
      dueDatetime: date ? new Date(moment.utc(date)).toISOString() : null,
      note: value.note
    };

    if (isReasonField) {
      delete data["dueDatetime"];
    } else if (isDueDateField) {
      delete data["reason"];
    } else {
      delete data["dueDatetime"];
      delete data["reason"];
    }
    handleCaseStatus(data);
    handleClose();
  };

  const handleCaseStatusChange = (e, selectedItem) => {
    setCaseStatus(selectedItem?.value || "");

    if (REASON_FILED_STATUSES.includes(selectedItem?.value)) {
      setIsReasonField(true);
    } else {
      setIsReasonField(false);
    }
    if (DUE_DATE_FIELD_STATUSES.includes(selectedItem?.value)) {
      setIsDueDateField(true);
    } else {
      setIsDueDateField(false);
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title={title}>
      <Box
        sx={theme => scrollBarStyle(theme)}
        style={{ paddingRight: "20px", maxHeight: "500px" }}
      >
        <form
          style={{ width: "100%" }}
          onSubmit={handleSubmit(onStatusSubmit, () => onError(null, date))}
        >
          {isStatuses && (
            <>
              {edit && (
                <Typography
                  variant="subtitle2"
                  marginBottom="16px"
                  color="text.secondary"
                >
                  Add note to status
                </Typography>
              )}
              <AutoCompleteField
                fullWidth
                label="Status Type"
                disabled={isDisabled}
                value={caseStatusValue?.value}
                options={statuses?.map(({ id, value }) => ({
                  value: id,
                  label: value
                }))}
                onChange={(e, value) => handleCaseStatusChange(e, value)}
                style={{ paddingTop: "12px" }}
              />
            </>
          )}
          {isReasonField && (
            <TextField
              fullWidth
              className={classes.textField}
              sx={{ marginTop: "24px" }}
              label="Reason"
              type="text"
              placeholder="Reason"
              error={!!errors.reason}
              defaultValue={edit ? status.reason : ""}
              {...register("reason", { required: true })}
            />
          )}
          {isDueDateField && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Due Datetime Picker"
                ampm={false}
                value={date}
                onChange={handleDateTimepicker}
                disablePast
                renderInput={params => (
                  <TextField
                    fullWidth
                    {...params}
                    className={classes.textField}
                    style={{ marginTop: "24px" }}
                    error={isError}
                  />
                )}
                PopperProps={{
                  sx: dateStyle(theme)
                }}
              />
            </LocalizationProvider>
          )}
          {isStatuses && (
            <TextField
              className={classes.textField}
              sx={{ marginTop: "24px" }}
              label="Notes"
              type="text"
              multiline
              placeholder="Notes"
              rows={3}
              error={!!errors.note}
              defaultValue={edit ? status.note : ""}
              {...register("note", { required: false })}
              fullWidth
            />
          )}
          <Box sx={{ marginTop: 3 }}>
            <Button
              sx={{ marginTop: 3, display: "block" }}
              type="submit"
              variant="contained"
            >
              {edit ? "SAVE" : "CREATE"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddEditStatusModal;
