import { darkTheme, lightTheme } from "styles/theme";
import { DARK } from "./constants";

export const dateStyle = themeName => {
  let theme = null;
  themeName === DARK ? (theme = darkTheme) : (theme = lightTheme);
  return {
    "& .MuiCalendarPicker-root": {
      "& .PrivatePickersFadeTransitionGroup-root": {
        color: theme.palette.text.primary
      },
      "& .MuiButtonBase-root": {
        color: theme.palette.text.primary
      },
      "& .MuiTypography-root.MuiTypography-caption": {
        color: theme.palette.text.primary
      },
      "& .css-17new5e-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)": {
        border: `1px solid ${theme.palette.primary.light}`
      }
    },
    "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    "& .MuiButtonBase-root.Mui-disabled.MuiPickersDay-root": {
      color: theme.palette.textFieldPalette.disabled
    },
    "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.Mui-disabled": {
      color: theme.palette.text.disabled
    },
    "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall": {
      color: theme.palette.text.primary
    },
    "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium": {
      color: theme.palette.text.primary
    }
  };
};
