import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const updateRegistrationProcessAPI = ({ data, params }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.REGISTRATION_PROCESS}`,
    params,
    data
  });

export const getRegistrationProcessAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.REGISTRATION_PROCESS}`,
    params
  });

export const getRegistrationCheckoutAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.REGISTRATION_CHECKOUT_BILL}`,
    params
  });

export const completeRegistrationAPI = ({ data, params }) =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.REGISTRATION_COMPLETE}`,
    params,
    data
  });

export const signUpRequestAPI = ({ data }) =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.SIGNUP_REQUEST}`,
    data
  });

export const getSignupRequestAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.SIGNUP_REQUEST}`,
    params
  });
