import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCreditsUsed } from "reducers/reports";
import Loader from "components/Loader";
import LineGraph from "./LineGraph";
import Filters from "./Filters";
import {
  horizontalScrollBarStyle,
  thirdReportTransformerLineChart
} from "utils";
import { Paper, Box, Typography } from "@mui/material";

function CreditsUsedReport({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCompany,
  handleCampanyChange,
  selectedBrokers,
  handleBrokerChange,
  handleFilter,
  resetFilterBtn
}) {
  const { loading, data, filters } = useSelector(state => state.reports);
  const { theme } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCreditsUsed());
  }, [dispatch, filters]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Filters
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
            reportData={data.creditsUsed}
          />
          {data.creditsUsed.length > 0 ? (
            <>
              <Paper
                sx={theme => ({ bgcolor: theme.palette.background.default })}
                elevation={0}
              >
                <Box
                  height={500}
                  style={{ overflowY: "hidden", overflowX: "scroll" }}
                  sx={theme => horizontalScrollBarStyle(theme)}
                >
                  <LineGraph
                    theme={theme}
                    data={thirdReportTransformerLineChart(data?.creditsUsed)}
                  />
                </Box>
              </Paper>
            </>
          ) : (
            <Typography variant="h5" color="text.primary" component="h5">
              There is no report yet
            </Typography>
          )}
        </>
      )}
    </>
  );
}

export default CreditsUsedReport;
