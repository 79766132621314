import { createSlice } from "@reduxjs/toolkit";
import { defaultTasksSorting } from "../../utils/constants";
import _ from "lodash";

const initialState = {
  loading: true,
  data: { results: [] },
  drawerTask: {
    count: null,
    todoCount: null,
    results: []
  },
  filters: {
    case: null,
    broker: null,
    sorting: {
      ...defaultTasksSorting
    }
  },
  activeTab: "todo",
  drawerActiveTab: "todo",
  page: 1,
  taskDrawerPage: 1,
  rowsPerPage: 12,
  error: null
};

export const tasks = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    getTasks: (state, action) => {
      state.loading = true;
    },
    getTasksSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getTasksFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    updateTask: (state, action) => {},
    updateTaskSucceed: (state, action) => {},
    updateTaskFailed: (state, action) => {
      state.error = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changeTaskDrawerPage: (state, action) => {
      state.taskDrawerPage = action.payload;
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
      state.page = 1;
    },
    removeTask: (state, action) => {
      state.loading = true;
    },
    removeTaskSucceed: (state, action) => {
      state.loading = false;
    },
    removeTaskFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateTasksBulk: (state, action) => {
      state.loading = true;
    },
    updateTasksBulkSucceed: (state, action) => {
      state.loading = false;
    },
    updateTasksBulkFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeTasksBulk: (state, action) => {
      state.loading = true;
    },
    removeTasksBulkSucceed: (state, action) => {
      state.loading = false;
    },
    removeTasksBulkFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateDrawerActiveTab: (state, action) => {
      state.drawerActiveTab = action.payload;
      state.taskDrawerPage = 1;
    },
    getDrawerTasks: (state, action) => {
      state.loading = false;
    },
    getDrawerTasksSucceed: (state, action) => {
      state.loading = false;
      state.drawerTask.count = action.payload.count;
      state.drawerTask.todoCount = action.payload.todoCount;
      state.drawerTask.results = _.uniqBy(
        [...state.drawerTask.results, ...action.payload.results],
        "id"
      );
    },
    getDrawerTasksFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getDrawerTaskOnChangeTab: state => {
      state.loading = false;
    },
    getDrawerTaskOnChangeTabSucceed: (state, action) => {
      state.loading = false;
      state.drawerTask.results = action.payload.results;
    },
    getDrawerTaskOnChangeTabFailed: state => {
      state.loading = false;
    },
    resetBrokerFilter: state => {
      state.filters = {
        ...state.filters,
        broker: null,
        sorting: defaultTasksSorting
      };
    }
  }
});

export const {
  getTasks,
  getTasksSucceed,
  getTasksFailed,
  updateFilters,
  updateActiveTab,
  changePage,
  updateTask,
  updateTaskSucceed,
  updateTaskFailed,
  removeTask,
  removeTaskSucceed,
  removeTaskFailed,
  updateTasksBulk,
  updateTasksBulkSucceed,
  updateTasksBulkFailed,
  removeTasksBulk,
  removeTasksBulkSucceed,
  removeTasksBulkFailed,
  updateDrawerActiveTab,
  getDrawerTasks,
  getDrawerTasksSucceed,
  getDrawerTasksFailed,
  changeTaskDrawerPage,
  getDrawerTaskOnChangeTab,
  getDrawerTaskOnChangeTabSucceed,
  getDrawerTaskOnChangeTabFailed,
  resetBrokerFilter
} = tasks.actions;

export default tasks.reducer;
