import React from "react";
import { Typography, List, ListItem, ListItemIcon, Stack } from "@mui/material";
import Modal from "components/Modals/index";
import { makeStyles } from "@mui/styles";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import _ from "lodash";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

export default function ClientContactModal({
  clientDetail,
  isOpen,
  handleClose
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Modal isOpen={isOpen} handleClose={handleClose}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            id="modal-modal-title"
            variant="h5"
            color="text.primary"
            component="h2"
            sx={theme => ({
              [theme.breakpoints.down("sm")]: {
                fontSize: "28px"
              }
            })}
          >
            Contact Details
          </Typography>
        </Stack>
        <List>
          <ListItem style={{ padding: 0 }}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              marginBottom="24px"
            >
              {`ID: ${clientDetail.lead.client.id}`}
            </Typography>
          </ListItem>
          <ListItem style={{ padding: 0, marginBottom: "16px" }}>
            <Typography variant="h6" color="text.primary" component="h2">
              {_.isEmpty(clientDetail.lead.client.fullName)
                ? "N/A"
                : clientDetail.lead.client.fullName}
            </Typography>
          </ListItem>
          <ListItem style={{ padding: 0, marginBottom: "24px" }}>
            <ListItemIcon>
              <MailIcon sx={{ color: "text.secondary" }} />
            </ListItemIcon>
            <Typography variant="body1" color="primary" component="h3">
              {clientDetail.lead.client.email}
            </Typography>
          </ListItem>
          <ListItem style={{ padding: 0, marginBottom: "24px" }}>
            <ListItemIcon>
              <LocalPhoneIcon sx={{ color: "text.secondary" }} />
            </ListItemIcon>
            <Typography variant="body1" color="text.primary" component="h3">
              {`${
                clientDetail.lead.client.mobilePhone
                  ? clientDetail.lead.client.mobilePhone
                  : "N/A"
              }`}
            </Typography>
          </ListItem>
          <ListItem style={{ padding: 0, marginBottom: "24px" }}>
            <ListItemIcon>
              <LocationOnIcon sx={{ color: "text.secondary" }} />
            </ListItemIcon>
            <Typography variant="body1" color="text.primary" component="h3">
              {`${
                clientDetail.lead.client.address?.address
                  ? clientDetail.lead.client.address?.address
                  : "N/A"
              }`}
            </Typography>
          </ListItem>
          {clientDetail.lead.client.isAdverseCredit && (
            <ListItem style={{ padding: 0 }}>
              <ListItemIcon>
                <GppMaybeIcon sx={{ color: "error.main" }} />
              </ListItemIcon>
              <Typography variant="body1" color="error.main" component="h3">
                Adverse Credit
              </Typography>
            </ListItem>
          )}
        </List>
      </Modal>
    </>
  );
}
