import React from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../routes/constants";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ChangeMembershipRequestModal from "pages/Company/ChangeMembershipRequestModal";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip,
  Stack
} from "@mui/material";
import { sideMenuListing, NameFormatterWithDot } from "utils";
import { getCreditsBundles, updateBuyCreditsModal } from "reducers/credits";
import { SUBSCRIPTION_TYPE, USER_TYPE } from "utils/constants";
import { isEmpty } from "lodash";

const useStyles = makeStyles(theme => ({
  sideMenu: {
    height: "calc(100% - 100px)",
    "& li a": {
      width: "100%",
      borderRadius: "30px",
      textDecoration: "none",
      "& .Mui-selected": {
        borderRadius: "32px",
        backgroundColor: theme.palette.sideMenuPalette.dark,
        "& svg": {
          color: theme.palette.sideMenuPalette.main
        },
        "& span": {
          color: theme.palette.sideMenuPalette.main
        },
        "& .MuiChip-outlined": {
          border: `1px solid ${theme.palette.sideMenuPalette.main}`
        },
        "&:hover": {
          backgroundColor: theme.palette.sideMenuPalette.dark,
          opacity: ".7"
        }
      },
      "& .MuiListItemButton-root": {
        borderRadius: "32px",
        "&:hover": {
          backgroundColor: "unset"
        }
      },
      "& .MuiListItemIcon-root": {
        minWidth: "auto",
        marginRight: "16px"
      },
      "& .MuiListItemText-root": {
        marginTop: "8px",
        marginBottom: "8px",
        color: theme.palette.text.primary
      },
      "& .MuiListItemText-root span": {
        fontFamily: "'Montserrat',sans-serif !important"
      }
    }
  }
}));

function SideMenuItems({ userType, isResponsive }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { me: user, unreadLeadCount } = useSelector(state => state.user.data);
  const { defaultCompanyData } = useSelector(state => state.company);
  const history = useHistory();
  const [isChangeMembershipModal, setIsChangeMembershipModal] = React.useState(
    false
  );
  const handleLogout = () => {
    history.replace(ROUTES.LOGOUT);
  };

  const handleBuyCredits = () => {
    dispatch(updateBuyCreditsModal(true));
    dispatch(getCreditsBundles());
  };

  return (
    <>
      <List className={classes.sideMenu}>
        {sideMenuListing(userType)?.map((item, id) => (
          <ListItem key={id}>
            <Link to={item.pageRoute}>
              <ListItemButton
                selected={pathname.includes(item.pageRoute)}
                disableRipple
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText sx={{ flex: "unset" }} primary={item.name} />
                {item.showUnreadLeadsCount && (
                  <Chip
                    label={unreadLeadCount !== "" ? unreadLeadCount : user?.unreadLeadsCount}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: "12px", cursor: "pointer" }}
                  />
                )}
                {[
                  SUBSCRIPTION_TYPE.SUBSCRIPTION_ONLY,
                  SUBSCRIPTION_TYPE.SUBSCRIPTION_AND_CREDITS
                ].includes(defaultCompanyData.membershipDetails?.type) && (
                  <>
                    {item.showRemainingCredits && (
                      <Chip
                        label={user && `${user?.balance} remaining`}
                        variant="outlined"
                        color="primary"
                        sx={{ marginLeft: "12px", cursor: "pointer" }}
                      />
                    )}
                  </>
                )}
              </ListItemButton>
            </Link>
          </ListItem>
        ))}

        {user?.type === USER_TYPE.COMPANY_OWNER &&
          defaultCompanyData.membershipDetails?.type !==
            SUBSCRIPTION_TYPE.PAY_AS_YOU_GO && (
            <Stack display={{ xs: "none", lg: "block", xl: "none" }}>
              {defaultCompanyData.membershipDetails?.type ===
              SUBSCRIPTION_TYPE.SUBSCRIPTION_ONLY ? (
                <>
                  {isEmpty(
                    defaultCompanyData.membershipDetails?.membershipRequest
                  ) && (
                    <ListItem onClick={() => setIsChangeMembershipModal(true)}>
                      <ListItemButton disableRipple>
                        <ListItemIcon
                          sx={{ minWidth: "auto", marginRight: "16px" }}
                        >
                          <ShoppingCartIcon sx={{ color: "text.primary" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Buy Credits"} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </>
              ) : (
                <ListItem onClick={handleBuyCredits}>
                  <ListItemButton disableRipple>
                    <ListItemIcon
                      sx={{ minWidth: "auto", marginRight: "16px" }}
                    >
                      <ShoppingCartIcon sx={{ color: "text.primary" }} />
                    </ListItemIcon>
                    <ListItemText primary={"Buy Credits"} />
                  </ListItemButton>
                </ListItem>
              )}
            </Stack>
          )}

        {isResponsive && (
          <>
            {(defaultCompanyData.membershipDetails?.type !==
            SUBSCRIPTION_TYPE.PAY_AS_YOU_GO && user?.type === USER_TYPE.COMPANY_OWNER) && (
              <>
                {(defaultCompanyData.membershipDetails?.type ===
                  SUBSCRIPTION_TYPE.SUBSCRIPTION_ONLY) ? (
                    <>
                      {isEmpty(
                        defaultCompanyData.membershipDetails?.membershipRequest
                      ) && (
                        <ListItem onClick={() => setIsChangeMembershipModal(true)}>
                          <ListItemButton disableRipple>
                            <ListItemIcon
                              sx={{ minWidth: "auto", marginRight: "16px" }}
                            >
                              <ShoppingCartIcon sx={{ color: "text.primary" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Buy Credits"} />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </>
                  ) : (
                    <ListItem onClick={handleBuyCredits}>
                      <ListItemButton disableRipple>
                        <ListItemIcon
                          sx={{ minWidth: "auto", marginRight: "16px" }}
                        >
                          <ShoppingCartIcon sx={{ color: "text.primary" }} />
                        </ListItemIcon>
                        <ListItemText primary={"Buy Credits"} />
                      </ListItemButton>
                    </ListItem>
                )}
              </>
            )}
            <ListItem>
              <Link to={ROUTES.SETTINGS}>
                <ListItemButton
                  selected={pathname.includes(ROUTES.SETTINGS)}
                  disableRipple
                >
                  <ListItemIcon>
                    <AccountCircleIcon sx={{ color: "text.primary" }} />
                  </ListItemIcon>
                  <ListItemText primary={NameFormatterWithDot(user)} />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem onClick={handleLogout}>
              <Link to="">
                <ListItemButton disableRipple>
                  <ListItemIcon>
                    <PowerSettingsNewIcon sx={{ color: "text.primary" }} />
                  </ListItemIcon>
                  <ListItemText primary={"Logout"} />
                </ListItemButton>
              </Link>
            </ListItem>
          </>
        )}
      </List>
      {isChangeMembershipModal && (
        <ChangeMembershipRequestModal
          isOpen={isChangeMembershipModal}
          handleClose={() => setIsChangeMembershipModal(false)}
        />
      )}
    </>
  );
}

export default SideMenuItems;
