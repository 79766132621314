import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/company";
import * as actions from "reducers/company";
import { toast } from "react-toastify";
import moment from "moment";
import { ERROR } from "utils/constants";

function* getCompanyBrokersSaga() {
  try {
    const {
      company: { filtersBrokers, page }
    } = yield select();
    const resp = yield call(Api.getCompanyBrokersAPI, {
      ...filtersBrokers,
      page
    });
    yield put(actions.getCompanyBrokersSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCompanyBrokersFailed(error));
  }
}

function* updateBrokersSaga({ payload }) {
  try {
    const resp = yield call(Api.updateBrokersAPI, payload);
    yield put(actions.updateBrokersSucceed());
    yield put(actions.getCompanyBrokers());
    yield put(actions.getCompanies(resp.data.company));
    toast.success("You have successfully updated the broker.")
  } catch (error) {
    yield put(actions.updateBrokersFailed(error));
  }
}

function* updateCompaniesSaga({ payload }) {
  try {
    const resp = yield call(Api.updateCompaniesAPI, payload);
    yield put(actions.updateCompaniesSucceed());
    yield put(actions.getCompanyBrokers());
    yield put(actions.getCompanies(resp.data.id));
    toast.success("You have successfully updated your profile.");
  } catch (error) {
    yield put(actions.updateCompaniesFailed(error));
  }
}

function* getCompaniesSaga({ payload }) {
  try {
    const resp = yield call(Api.getCompaniesAPI, payload);
    yield put(actions.getCompaniesSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCompaniesFailed(error));
  }
}

function* createBrokersSaga({ payload }) {
  try {
    const resp = yield call(Api.createBrokersAPI, payload);
    yield put(actions.createBrokersSucceed());
    yield put(actions.getCompanyBrokers());
    yield put(actions.getCompanies(resp.data.company));
    toast.success("Login link successfully emailed to the broker.");
  } catch (error) {
    yield put(actions.createBrokersFailed(error));
  }
}

function* createPaymentMethodSaga({ payload }) {
  try {
    yield call(Api.createPaymentMethodAPI, payload);
    yield put(actions.createPaymentMethodSucceed());
    yield put(actions.getPaymentMethod());
    toast.success("You have successfully added your payment card.");
  } catch (error) {
    yield put(actions.createPaymentMethodFailed(error));
  }
}

function* getPaymentMethodSaga({ payload }) {
  try {
    const resp = yield call(Api.getPaymentMethodsAPI, payload);
    yield put(actions.getPaymentMethodSucceed(resp.data));
  } catch (error) {
    yield put(actions.getPaymentMethodFailed(error));
  }
}

function* removePaymentMethodSaga({ payload }) {
  try {
    yield call(Api.removePaymentMethodsAPI, payload);
    yield put(actions.removePaymentMethodSucceed());
    yield put(actions.getPaymentMethod());
    toast.success("You have successfully removed your payment card.");
  } catch (error) {
    yield put(actions.removePaymentMethodFailed(error));
  }
}

function* updatePaymentMethodSaga({ payload }) {
  const {
    user: { data }
  } = yield select();
  try {
    yield call(Api.updatePaymentMethodAPI, payload);
    yield put(actions.updatePaymentMethodSucceed());
    yield put(actions.getPaymentMethod());
    yield put(actions.getCompanies(data.me.company));
  } catch (error) {
    yield put(actions.updatePaymentMethodFailed(error));
  }
}

function* getStripeInvoicesSaga() {
  try {
    const {
      company: { filtersInvoices, page }
    } = yield select();
    const resp = yield call(Api.getStripeInvoicesAPI, {
      ...filtersInvoices,
      page
    });
    yield put(actions.getStripeInvoicesSucceed(resp.data));
  } catch (error) {
    yield put(actions.getStripeInvoicesFailed(error));
  }
}

function* getAdditionalSeatsSaga({ payload }) {
  try {
    const resp = yield call(Api.getAdditionalSeatsAPI, {
      additionalSeats: payload
    });
    yield put(actions.getAdditionalSeatsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getAdditionalSeatsFailed(error));
  }
}

function* createAdditionalSeatSaga({ payload }) {
  const {
    user: { data }
  } = yield select();
  try {
    const resp = yield call(Api.updateAdditionalSeatAPI, payload);
    yield put(actions.getCompanies(data.me.company));
    if (resp.data.status === ERROR) {
      yield put(actions.updateCompletePaymentModal(true));
      yield put(actions.updateConfirmationPaymentModal(resp.data));
    } else {
      yield put(actions.createAdditionalSeatSucceed(resp.data));
      yield put(actions.getStripeInvoices());
    }
  } catch (error) {
    yield put(actions.createAdditionalSeatFailed(error));
  }
}

function* cancelSubscriptionSaga({ payload }) {
  const {
    user: { data }
  } = yield select();
  try {
    const resp = yield call(Api.cancelSubscriptionAPI, payload);
    yield put(actions.cancelSubscriptionSucceed());
    yield put(actions.getCompanies(data.me.company));
    toast.success(
      `You Subscription will be cancelled at ${moment(
        resp.data.cancelAt
      ).format(moment.HTML5_FMT.DATE)}.`
    );
  } catch (error) {
    yield put(actions.cancelSubscriptionFailed(error));
  }
}

function* getCancelSubscriptionSaga({ payload }) {
  try {
    const resp = yield call(Api.getCancelSubscriptionAPI, payload);
    yield put(actions.getCancelSubscriptionSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCancelSubscriptionFailed(error));
  }
}

function* getRemoveSeatsSaga({ payload }) {
  try {
    const resp = yield call(Api.getRemoveSeatAPI, {
      removeSeats: payload
    });
    yield put(actions.getRemoveSeatsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getRemoveSeatsFailed(error));
  }
}

function* removeSeatSaga({ payload }) {
  const {
    user: { data }
  } = yield select();
  try {
    yield call(Api.updateRemoveSeatAPI, payload);
    yield put(actions.removeSeatSucceed());
    yield put(actions.getStripeInvoices());
    yield put(actions.getCompanies(data.me.company));
  } catch (error) {
    yield put(actions.removeSeatFailed(error));
  }
}

function* changeMembershipRequestSaga({ payload }) {
  const {
    user: { data }
  } = yield select();
  try {
    yield call(Api.changeMembershipRequestAPI, payload);
    yield put(actions.changeMembershipRequestSucceed());
    yield put(actions.getCompanies(data.me.company));
  } catch (error) {
    yield put(actions.changeMembershipRequestFailed(error));
  }
}

function* cancelInvoiceSaga({ payload }) {
  try {
    yield call(Api.cancelInvoiceAPI, payload);
    yield put(actions.cancelInvoiceSucceed());
    yield put(actions.getStripeInvoices());
    toast.success("You have successfully cancelled this invoice.");
  } catch (error) {
    yield put(actions.cancelInvoiceFailed(error));
  }
}

export function* companySaga() {
  yield takeLatest(actions.getCompanyBrokers.type, getCompanyBrokersSaga);
  yield takeLatest(actions.updateBrokers.type, updateBrokersSaga);
  yield takeLatest(actions.updateCompanies.type, updateCompaniesSaga);
  yield takeLatest(actions.getCompanies.type, getCompaniesSaga);
  yield takeLatest(actions.createBrokers.type, createBrokersSaga);
  yield takeLatest(actions.createPaymentMethod.type, createPaymentMethodSaga);
  yield takeLatest(actions.getPaymentMethod.type, getPaymentMethodSaga);
  yield takeLatest(actions.removePaymentMethod.type, removePaymentMethodSaga);
  yield takeLatest(actions.updatePaymentMethod.type, updatePaymentMethodSaga);
  yield takeLatest(actions.getStripeInvoices.type, getStripeInvoicesSaga);
  yield takeLatest(actions.getAdditionalSeats.type, getAdditionalSeatsSaga);
  yield takeLatest(actions.createAdditionalSeat.type, createAdditionalSeatSaga);
  yield takeLatest(actions.cancelSubscription.type, cancelSubscriptionSaga);
  yield takeLatest(
    actions.getCancelSubscription.type,
    getCancelSubscriptionSaga
  );
  yield takeLatest(actions.getRemoveSeats.type, getRemoveSeatsSaga);
  yield takeLatest(actions.removeSeat.type, removeSeatSaga);
  yield takeLatest(
    actions.changeMembershipRequest.type,
    changeMembershipRequestSaga
  );
  yield takeLatest(actions.cancelInvoice.type, cancelInvoiceSaga);
}
