import { createSlice } from "@reduxjs/toolkit";
import { defaultClientSorting } from "utils/constants";

const initialState = {
  loading: true,
  data: {},
  filters: {
    brokers: null,
    sorting: {
      ...defaultClientSorting
    }
  },
  search: "",
  error: null,
  rowsPerPage: 12,
  page: 1
};

export const clients = createSlice({
  name: "clients",
  initialState,
  reducers: {
    getClients: state => {
      state.loading = true;
    },
    getClientsSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getClientsFailed: (state, action) => {
      state.loading = false;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetClientsFilters: (state, action) => {
      state.filters.brokers = null;
      state.page = 1;
      state.search = "";
      state.filters.sorting = defaultClientSorting;
    },
    handleSearch: (state, action) => {
      state.page = 1;
      state.search = action.payload;
    },
    updateAdverseCredit: (state, action) => {},
    updateAdverseCreditSucceed: (state, action) => {},
    updateAdverseCreditFailed: (state, action) => {}
  }
});

export const {
  getClients,
  getClientsSucceed,
  getClientsFailed,
  changePage,
  updateFilters,
  resetClientsFilters,
  handleSearch,
  updateAdverseCredit,
  updateAdverseCreditSucceed,
  updateAdverseCreditFailed
} = clients.actions;

export default clients.reducer;
