import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Link, useHistory } from "react-router-dom";
import { ROUTES } from "../routes/constants";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Stack, IconButton, Box, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NotificationDrawer from "components/Modals/NotificationDrawer";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import TaskDrawer from "components/Modals/TaskDrawer";
import { updateThemeMode } from "reducers/users";
import { NameFormatterWithDot } from "utils";
import { getLocalStorageItem, setLocalStorageItem } from "utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import { LIGHT, DARK, USER_TYPE, SUBSCRIPTION_TYPE } from "utils/constants";
import BackBtn from "pages/Cases/BackBtn";
import CaseInformativePopup from "pages/Cases/CaseInformativePopup";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  updateBuyCreditsModal,
  getCreditsBundles,
  updateBuyCreditsCheckoutModal,
  updateProcessingModal,
  updatePurchasedModal
} from "reducers/credits";
import BuyCreditsModal from "pages/Credits/BuyCreditsModal";
import BuySeatsCheckoutModal from "../pages/Credits/BuyCreditsCheckoutModal";
import ProcessingModal from "pages/Company/ProcessingModal";
import PurchasedModal from "pages/Company/PerchasedModal";
import ConfirmPaymentModal from "pages/Company/ConfirmPaymentModal";
import { getCompanies, updateCompletePaymentModal } from "reducers/company";
import ChangeMembershipRequestModal from "pages/Company/ChangeMembershipRequestModal";
import { isEmpty } from "lodash";

const useStyles = makeStyles(theme => ({
  textField: {
    "& label": {
      color: theme.palette.text.primary
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.textFieldPalette.main
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main
      }
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.secondary
    },
    "& .MuiInput-root.MuiInput-underline": {
      "&::before": {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      }
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      color: theme.palette.text.primary
    }
  },
  icon: {
    "& button": {
      padding: 0,
      minWidth: "auto",
      marginLeft: "32px",
      color: theme.palette.text.primary,
      [theme.breakpoints.down("xl")]: {
        marginLeft: "24px"
      }
    }
  }
}));

function Header({ componentName, isInfoIcon, backBtn, caseData, routePage }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data: { me },
    theme
  } = useSelector(state => state.user);
  const {
    isBuyCreditsModal,
    isBuyCreditsCheckoutModal,
    isProcessingModal,
    isPurchasedModal,
    purchased
  } = useSelector(state => state.credits);
  const { isConfirmPaymentModal, defaultCompanyData } = useSelector(
    state => state.company
  );

  const [currentScrollValue, setCurrentScrollValue] = useState(0);
  const [lastScrollValue, setLastScrollValue] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [creditsCheckout, setCreditsCheckout] = useState({});
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const prefersLightMode = useMediaQuery("(prefers-color-scheme: light)");
  const [isChangeMembershipModal, setIsChangeMembershipModal] = useState(false);

  const handleBuyCredits = () => {
    dispatch(updateBuyCreditsModal(true));
    dispatch(getCreditsBundles());
  };

  useEffect(() => {
    if (!prefersDarkMode && !prefersLightMode) {
      return;
    }

    const storedValue = getLocalStorageItem("theme");
    if (storedValue) {
      dispatch(updateThemeMode(storedValue));
      return;
    }

    if (prefersDarkMode) {
      dispatch(updateThemeMode(DARK));
      setLocalStorageItem("theme", DARK);
    } else if (prefersLightMode) {
      dispatch(updateThemeMode(LIGHT));
      setLocalStorageItem("theme", LIGHT);
    }
  }, [dispatch, prefersDarkMode, prefersLightMode]);

  const windowScrollValue = () => {
    setCurrentScrollValue(window.pageYOffset);
  };

  window.addEventListener("scroll", windowScrollValue);

  useEffect(() => {
    if (currentScrollValue === 0) {
      setIsScroll(false);
    } else if (currentScrollValue > lastScrollValue) {
      setIsScroll(true);
    } else if (currentScrollValue < lastScrollValue) {
      setIsScroll(false);
    }
    setLastScrollValue(currentScrollValue);

    return () => window.removeEventListener("scroll", windowScrollValue);
  }, [currentScrollValue]);

  const handleToggleTheme = () => {
    if (theme === LIGHT) {
      dispatch(updateThemeMode(DARK));
      setLocalStorageItem("theme", DARK);
    } else {
      dispatch(updateThemeMode(LIGHT));
      setLocalStorageItem("theme", LIGHT);
    }
  };

  useEffect(() => {
    if (me?.type === USER_TYPE.COMPANY_OWNER) {
      dispatch(getCompanies(me.company));
    }
  }, [dispatch, me]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={theme => ({
          top: 0,
          [theme.breakpoints.down("lg")]: {
            position: "fixed",
            top: isScroll && "-15%",
            left: 0,
            width: "calc(100% - 48px)",
            padding: "24px",
            zIndex: 9,
            transition: ".5s ease-in-out",
            background: theme.palette.background.default
          }
        })}
      >
        <Stack direction="row" alignItems="center" marginRight="18px">
          <Stack display={{ xs: "block", lg: "none" }}>
            <Link to={ROUTES.LEADS}>
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: "auto",
                  maxHeight: { xs: 25, ssm: 35 },
                  maxWidth: { xs: 120, ssm: 150 }
                }}
                alt="Blabble-logo"
                src="/app/assets/logo.svg"
              />
            </Link>
          </Stack>
          <Stack display={{ xs: "none", lg: "block" }}>
            <Stack direction="row" alignItems="center">
              {backBtn && <BackBtn routePage={routePage} />}
              <Typography variant="h4" color="text.primary">
                {componentName}
              </Typography>
              {isInfoIcon && <CaseInformativePopup caseData={caseData} />}
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          {me?.type === USER_TYPE.COMPANY_OWNER &&
            defaultCompanyData.membershipDetails?.type !==
              SUBSCRIPTION_TYPE.PAY_AS_YOU_GO && (
              <Box display={{ xs: "none", xl: "block" }}>
                {defaultCompanyData.membershipDetails?.type ===
                SUBSCRIPTION_TYPE.SUBSCRIPTION_ONLY ? (
                  <>
                    {isEmpty(
                      defaultCompanyData.membershipDetails?.membershipRequest
                    ) && (
                      <Button
                        variant="outlined"
                        startIcon={<ShoppingCartIcon />}
                        onClick={() => setIsChangeMembershipModal(true)}
                        sx={theme => ({
                          marginRight: "24px",
                          height: "56px",
                          [theme.breakpoints.down("xll")]: {
                            marginRight: "12px"
                          }
                        })}
                      >
                        BUY CREDITS
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<ShoppingCartIcon />}
                    onClick={handleBuyCredits}
                    sx={theme => ({
                      marginRight: "24px",
                      height: "56px",
                      [theme.breakpoints.down("xll")]: {
                        marginRight: "12px"
                      }
                    })}
                  >
                    BUY CREDITS
                  </Button>
                )}
              </Box>
            )}
          <Box sx={{ minWidth: 180 }} display={{ xs: "none", lg: "block" }}>
            <FormControl fullWidth className={classes.textField}>
              <InputLabel shrink={false}>{NameFormatterWithDot(me)}</InputLabel>
              <Select>
                <Link to={ROUTES.SETTINGS} style={{ textDecoration: "none" }}>
                  <MenuItem
                    sx={theme => ({ color: theme.palette.text.primary })}
                  >
                    Settings
                  </MenuItem>
                </Link>
                <MenuItem
                  sx={theme => ({ color: theme.palette.text.primary })}
                  onClick={() => history.replace(ROUTES.LOGOUT)}
                >
                  Logout
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          {me?.type !== USER_TYPE.ADMIN && <TaskDrawer />}
          <NotificationDrawer />
          <IconButton
            sx={theme => ({
              marginLeft: "24px",
              [theme.breakpoints.down("xl")]: {
                marginLeft: "18px"
              }
            })}
            onClick={handleToggleTheme}
          >
            {theme === LIGHT ? (
              <DarkModeIcon
                sx={theme => ({ color: theme.palette.text.primary })}
              />
            ) : (
              <WbSunnyIcon
                sx={theme => ({ color: theme.palette.text.primary })}
              />
            )}
          </IconButton>
        </Stack>
      </Stack>
      {isBuyCreditsModal && (
        <BuyCreditsModal
          isOpen={isBuyCreditsModal}
          handleClose={() => dispatch(updateBuyCreditsModal(false))}
          setCreditsCheckout={setCreditsCheckout}
        />
      )}
      {isBuyCreditsCheckoutModal && (
        <BuySeatsCheckoutModal
          isOpen={isBuyCreditsCheckoutModal}
          handleClose={() => dispatch(updateBuyCreditsCheckoutModal(false))}
          creditsCheckoutDetail={creditsCheckout}
        />
      )}
      {isProcessingModal && (
        <ProcessingModal
          isOpen={isProcessingModal}
          handleClose={() => dispatch(updateProcessingModal(false))}
        />
      )}
      {isPurchasedModal && (
        <PurchasedModal
          isOpen={isPurchasedModal}
          handleClose={() => dispatch(updatePurchasedModal(false))}
          purchased={purchased}
          isCreditsPage={true}
        />
      )}
      {isConfirmPaymentModal && (
        <ConfirmPaymentModal
          isOpen={isConfirmPaymentModal}
          handleClose={() => dispatch(updateCompletePaymentModal(false))}
        />
      )}
      {isChangeMembershipModal && (
        <ChangeMembershipRequestModal
          isOpen={isChangeMembershipModal}
          handleClose={() => setIsChangeMembershipModal(false)}
        />
      )}
    </>
  );
}

export default Header;
