import React from "react";
import Modal from "components/Modals/index";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography, Box, Stack, Button, Link } from "@mui/material";

function PurchasedModal({ isOpen, handleClose, purchased, isCreditsPage }) {
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Purchased">
      <Box
        sx={theme => ({
          background: theme.palette.text.dark.primary,
          padding: "20px",
          borderRadius: "8px",
          [theme.breakpoints.down("sm")]: {
            background: theme.palette.background.default
          }
        })}
      >
        <Stack direction="row" alignItems="center">
          <CheckCircleIcon color="primary" />
          <Typography variant="h6" color="text.primary" margin="0 16px">
            {isCreditsPage
              ? purchased?.creditsPurchased
              : purchased?.seatsPurchased}
          </Typography>
          <Typography variant="body1" color="text.primary">
            {isCreditsPage ? "credits" : "seat(s)"}
          </Typography>
        </Stack>
      </Box>
      <Stack direction="row" marginTop="32px" flexWrap="wrap">
        <Button
          color="primary"
          variant="contained"
          onClick={handleClose}
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          })}
        >
          CONTINUE
        </Button>
        <Link
          href={purchased?.invoiceUrl}
          target="_blabble"
          color="primary"
          sx={theme => ({
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 16px",
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "4px",
            marginLeft: "16px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              marginLeft: 0,
              marginTop: "16px"
            }
          })}
        >
          INVOICE
        </Link>
      </Stack>
    </Modal>
  );
}

export default PurchasedModal;
