import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 320,
      ssm: 375,
      sm: 575,
      md: 768,
      lg: 992,
      llg: 1081,
      xl: 1200,
      xll: 1400,
      xxl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#53E79C",
      dark: "#0D2F2F",
      light: "#75EBAF",
      contrastText: "#000000",
    },
    secondary: {
      main: "#3C67FF",
      dark: "#0D2452",
      light: "#C4D1FF",
      contrastText: "#000000",
    },
    active: {
      active: "#C4D1FF",
      hover: "#C4D1FF",
      selected: "rgba(196, 209, 255, 0.16)",
      focus: "#FFFFFF",
      disabledBackground: "#C4D1FF",
      disabled: "#FFFFFF",
    },
    text: {
      primary: "#C4D1FF",
      secondary: "rgba(196, 209, 255, 0.7)",
      disabled: "rgba(196, 209, 255, 0.5)",
      dark: {
        primary: "#010810",
        secondary: "rgba(7, 13, 19, 0.6)",
        disabled: "rgba(7, 13, 19, 0.26)",
      },
    },
    error: {
      main: "#F44336",
      dark: "#D32F2F",
      light: "#E57373",
      alertText: "#FAB3AE",
      contrastText: "#ffffff",
    },
    warning: {
      main: "#FF9800",
      dark: "#F57C00",
      light: "#FFB74D",
      alertText: "#FFD599",
      contrastText: "#000000",
    },
    info: {
      main: "#2196F3",
      dark: "#1976D2",
      light: "#64B5F6",
      alertText: "#A6D5FA",
      contrastText: "#000000",
    },
    success: {
      main: "#4CAF50",
      dark: "#388E3C",
      light: "#81C784",
      alertText: "#B7DFB9",
      contrastText: "#FFFFFF",
    },
    background: {
      paper: "#021327",
      default: "#021327",
    },
    grey: {
      300: "#D8D8D8",
      400: "#BDBDBD",
      600: "#757575",
    },
    red: {
      light: "#f7836e",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    divider: "rgba(196, 209, 255, 0.3)",
    sideMenuPalette: {
      main: "#53E79C",
      dark: "#0D2F2F",
      borderColor: "#53E79C",
      contrastText: "#000000",
    },
    paginationPalette: {
      dark: "rgba(83, 231, 156, 0.12)",
    },
    textFieldPalette: {
      main: "rgba(196, 209, 255, 0.23)",
      dark: "#010810",
      disabled: "rgba(196, 209, 255, 0.3)",
    },
    progressBarPalette: {
      main: "#53E79C",
      dark: "#0D2F2F",
    },
    boxShadowPalette: {
      primary: "rgba(7, 27, 61, 0.08)",
      main: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.12)",
      light: "rgba(0, 0, 0, 0.2)",
    },
    borderColorPalette: {
      main: "rgba(196, 209, 255, 0.12)",
      secondary: "rgba(45, 54, 71, .4)",
    },
    modalColorPalette: {
      primary: "#021327",
      secondary: "#010810",
      tertiary: "#98A4CA",
      light: "#53E79C",
      dark: "#010810",
      text: "#C4D1FF",
      chip: "#010810"
    }
  },
  typography: {
    h1: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "76px",
    },
    h2: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "60px",
    },
    h3: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "48px",
    },
    h4: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "34px",
    },
    h5: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "24px",
    },
    h6: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "20px",
    },
    subtitle1: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      fontSize: "16px",
    },
    subtitle2: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      fontSize: "14px",
    },
    body1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: "16px",
    },
    body2: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: "14px",
    },
    button: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: "15px",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: "12px",
    },
    overline: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: "12px",
    },
    link1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: "16px",
    },
    link2: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: "13px",
    },
  },
  background: "transparent",
  textColor: "#C4D1FF",
  fontSize: 13,
  axis: {
    domain: {
      line: {
        stroke: "#202F41",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 13,
        fill: "#8997BE",
      },
    },
    ticks: {
      line: {
        stroke: "#202F41",
        strokeWidth: 0,
      },
      text: {
        fontSize: 11,
        fill: "#C4D1FF",
      },
    },
  },
  grid: {
    line: {
      stroke: "#202F41",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: "#8997BE",
      },
    },
    text: {
      fontSize: 11,
      fill: "#8997BE",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: "#8997BE",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#8997BE",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: "#021327",
      color: "#C4D1FF",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
});

export const lightTheme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: 320,
      ssm: 375,
      sm: 575,
      md: 768,
      lg: 992,
      llg: 1081,
      xl: 1200,
      xll: 1400,
      xxl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#3C67FF",
      dark: "#19347D",
      light: "#6385FF",
      contrastText: "#fff",
    },
    secondary: {
      main: "#53E79C",
      dark: "#318A5D",
      light: "#75EBAF",
      contrastText: "#010810",
    },
    active: {
      active: "rgba(2, 19, 39, 0.54)",
      hover: "rgba(2, 19, 39, 0.04)",
      selected: "rgba(2, 19, 39, 0.08)",
      focus: "rgba(2, 19, 39, 0.12)",
      disabledBackground: "rgba(2, 19, 39, 0.12)",
      disabled: "rgba(2, 19, 39, 0.26)",
    },
    text: {
      primary: "rgba(1, 8, 16, 0.87)",
      secondary: "rgba(7, 13, 19, 0.7)",
      disabled: "rgba(7, 13, 19, 0.6)",
      dark: {
        primary: "#FFFFFF",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(255, 255, 255, 0.5)",
      },
    },
    error: {
      main: "#F44336",
      dark: "#D32F2F",
      light: "#E57373",
      alertText: "#611A15",
      contrastText: "#fff",
    },
    warning: {
      main: "#FF9800",
      dark: "#F57C00",
      light: "#FFB74D",
      alertText: "#663C00",
      contrastText: "#fff",
    },
    info: {
      main: "#2196F3",
      dark: "#1976D2",
      light: "#64B5F6",
      alertText: "#0D3C61",
      contrastText: "#010810",
    },
    success: {
      main: "#4CAF50",
      dark: "#388E3C",
      light: "#81C784",
      alertText: "#1E4620",
      contrastText: "#010810",
    },
    background: {
      paper: "#FFFFFF",
      default: "#F4F6FF",
    },
    grey: {
      300: "#D8D8D8",
      400: "#BDBDBD",
      600: "#757575",
    },
    red: {
      light: "#f7836e",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#010810",
    },
    divider: "rgba(45, 54, 71, .4)",
    sideMenuPalette: {
      main: "#fff",
      dark: "#3C67FF",
      borderColor: "#3C67FF",
      contrastText: "#ffffff",
    },
    paginationPalette: {
      dark: "#C4D1FF",
    },
    textFieldPalette: {
      main: "rgba(0, 0, 0, 0.23)",
      dark: "#F4F6FF",
      disabled: "rgba(7, 13, 19, 0.26)",
    },
    progressBarPalette: {
      main: "#3C67FF",
      dark: "#C4D1FF",
    },
    boxShadowPalette: {
      primary: "rgba(7, 27, 61, 0.08)",

      main: "rgba(0, 0, 0, 0.14)",
      dark: "rgba(0, 0, 0, 0.12)",
      light: "rgba(0, 0, 0, 0.2)",
    },
    borderColorPalette: {
      main: "rgba(2, 19, 39, 0.26)",
      secondary: "rgba(45, 54, 71, .4)",
    },
    modalColorPalette: {
      primary: "#f4f6ff",
      secondary: "#ffffff",
      tertiary: "#606469",
      light: "#FFFFFF",
      dark: "#3C67FF",
      text: "#FFFFFF",
      chip: "#010810"
    }
  },
  typography: {
    h1: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "76px",
    },
    h2: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "60px",
    },
    h3: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "48px",
    },
    h4: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "34px",
    },
    h5: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "24px",
    },
    h6: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 700,
      fontSize: "20px",
    },
    subtitle1: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      fontSize: "16px",
    },
    subtitle2: {
      fontFamily: "'Montserrat', sans-serif",
      fontWeight: 400,
      fontSize: "14px",
    },
    body1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: "16px",
    },
    body2: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: "14px",
    },
    button: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: "15px",
      textTransform: "uppercase",
    },
    caption: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: "12px",
    },
    overline: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 400,
      fontSize: "12px",
    },
    link1: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: "16px",
    },
    link2: {
      fontFamily: "'Nunito Sans', sans-serif",
      fontWeight: 600,
      fontSize: "13px",
    },
  },
  background: "transparent",
  textColor: "#010810",
  fontSize: 13,
  axis: {
    domain: {
      line: {
        stroke: "#E0E0E0",
        strokeWidth: 1,
      },
    },
    legend: {
      text: {
        fontSize: 13,
        fill: "#010810",
      },
    },
    ticks: {
      line: {
        stroke: "#BFC0C2",
        strokeWidth: 0,
      },
      text: {
        fontSize: 11,
        fill: "#6A6E72",
      },
    },
  },
  grid: {
    line: {
      stroke: "#E0E0E0",
      strokeWidth: 1,
    },
  },
  legends: {
    title: {
      text: {
        fontSize: 11,
        fill: "#010810",
      },
    },
    text: {
      fontSize: 11,
      fill: "#010810",
    },
    ticks: {
      line: {},
      text: {
        fontSize: 10,
        fill: "#010810",
      },
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      fill: "#8997BE",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    link: {
      stroke: "#000000",
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    outline: {
      stroke: "#000000",
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
    symbol: {
      fill: "#000000",
      outlineWidth: 2,
      outlineColor: "#ffffff",
      outlineOpacity: 1,
    },
  },
  tooltip: {
    container: {
      background: "#F4F6FF",
      color: "#010810",
      fontSize: 12,
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
});
