import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import MenuIcon from "@mui/icons-material/Menu";
import { bottomNavigation } from "utils";

const useStyles = makeStyles(theme => ({
  bottomNavigation: {
    boxShadow: `0px 4px 7px ${theme.palette.boxShadowPalette.light}, 0px 5px 22px ${theme.palette.boxShadowPalette.dark}, 0px 5px 16px ${theme.palette.boxShadowPalette.main}`,
    "&.MuiBottomNavigation-root": {
      background: theme.palette.text.dark.primary,
      borderRadius: "32px",
      padding: "8px",
      "& .MuiButtonBase-root": {
        minWidth: "auto",
        padding: 0,
        color: theme.palette.text.primary,
        "&.Mui-selected": {
          position: "relative",
          color: theme.palette.text.dark.primary,
          "&::before": {
            content: '""',
            position: "absolute",
            width: "36px",
            height: "36px",
            borderRadius: "50%",
            background: theme.palette.primary.main
          },
          "& svg": {
            zIndex: 1,
            color: "unset"
          }
        }
      }
    }
  }
}));

function ResponsiveBottomNavigation({ openResponsiveMenu, userType }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();

  const handleChange = (e, activePage) => {
    history.push(activePage);
  };

  return (
    <BottomNavigation
      value={pathname}
      onChange={handleChange}
      className={classes.bottomNavigation}
    >
      {bottomNavigation(userType)?.map(item => (
        <BottomNavigationAction
          key={item.id}
          value={item.pageRoute}
          icon={item.icon}
          disableRipple
        />
      ))}
      <BottomNavigationAction
        onClick={openResponsiveMenu(true)}
        icon={<MenuIcon />}
        disableRipple
      />
    </BottomNavigation>
  );
}

export default ResponsiveBottomNavigation;
