import React from "react";
import { Typography, Box, Stack, Button, Popover } from "@mui/material";

const popoverStyle = {
  borderRadius: "8px",
  padding: "16px",
  width: 220
};

const DeletePopover = ({
  text,
  anchorEl,
  handleClose,
  invoiceId,
  handleCancelInvoice,
  bgColor
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    >
      <Box sx={{ ...popoverStyle, bgcolor: bgColor }}>
        <Typography
          variant="subtitle1"
          color="text.primary"
          component="h2"
          gutterBottom
        >
          Are you sure you want to cancel this {text}?
        </Typography>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color="error"
            onClick={() => handleCancelInvoice(invoiceId)}
            sx={{ flex: "0 0 48%" }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            sx={{
              flex: "1",
              color: "text.primary",
              borderColor: "text.primary"
            }}
            onClick={() => handleClose()}
          >
            No
          </Button>
        </Stack>
      </Box>
    </Popover>
  );
};

export default DeletePopover;
