import React, { useState } from "react";
import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Typography,
  Stack,
  IconButton,
  Box,
  Popover,
  Chip
} from "@mui/material";

const CHIP_STYLE = theme => {
  return {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.active.selected,
    fontSize: "13px",
    fontWeight: "700",
    fontFamily: "'Nunito Sans', sans-serif",
    marginRight: "24px",
    marginBottom: "8px"
  };
};

const popoverStyle = {
  borderRadius: "8px",
  padding: "16px",
  maxWidth: 380
};

function CaseInformativePopup({ caseData }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Stack direction="row" alignItems="center">
      <IconButton size="large" onClick={handleClick}>
        <InfoOutlinedIcon sx={{ fontSize: 34 }} color="primary" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <Box
          sx={theme => ({
            ...popoverStyle,
            bgcolor: theme.palette.background.default
          })}
        >
          <Typography variant="subtitle1" color="text.disabled" component="h2">
            Created by:
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.primary"
            component="h2"
            gutterBottom
          >
            {caseData?.brokerFullName}
          </Typography>
          <Typography variant="subtitle1" color="text.disabled" component="h2">
            Created on:
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="subtitle1"
              color="text.primary"
              component="h2"
              gutterBottom
            >
              {moment(caseData?.lead?.created).format("ddd D MMM YYYY HH:mm")}
            </Typography>
            <Chip
              label={moment(caseData?.lead?.created).fromNow()}
              sx={theme => CHIP_STYLE(theme)}
              style={{ marginLeft: "24px" }}
            />
          </Stack>
          <Typography variant="subtitle1" color="text.disabled" component="h2">
            Updated on:
          </Typography>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="subtitle1"
              color="text.primary"
              component="h2"
              gutterBottom
            >
              {moment(caseData?.lead?.modified).format("ddd D MMM YYYY HH:mm")}
            </Typography>
            <Chip
              label={moment(caseData?.lead?.modified).fromNow()}
              sx={theme => CHIP_STYLE(theme)}
              style={{ marginLeft: "24px" }}
            />
          </Stack>
        </Box>
      </Popover>
    </Stack>
  );
}

export default CaseInformativePopup;
