import { formatFilters } from "utils";
import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getCasesAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_CASES,
    params: formatFilters(data)
  });

export const getStatusesAPI = () =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_STATUSES
  });

export const addNewCaseAPI = ({ data }) =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.NEW_CASE,
    data
  });

export const getCaseAPI = id =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.CASE}${id}/`
  });

export const getCaseStatusAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.CASE_STATUS}`,
    params: formatFilters(params)
  });

export const getCaseProductsAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.CASE_PRODUCTS}`,
    params: formatFilters(params)
  });

export const getCaseTaskAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.GET_TASKS}`,
    params: formatFilters(params)
  });

export const removeCaseStatusAPI = ({ id }) =>
  apiCaller({
    method: REQUEST_TYPES.DELETE,
    url: `${ENDPOINTS.CASE_STATUS}${id}/`
  });

export const removeCaseProductAPI = ({ id }) =>
  apiCaller({
    method: REQUEST_TYPES.DELETE,
    url: `${ENDPOINTS.CASE_PRODUCTS}${id}/`
  });

export const removeCaseTaskAPI = ({ id }) =>
  apiCaller({
    method: REQUEST_TYPES.DELETE,
    url: `${ENDPOINTS.TASK}${id}/`
  });

export const updateCaseTaskAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.TASK}${id}/`,
    data
  });

export const updateCaseAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.CASE}${id}/update/`,
    data
  });

export const addCaseStatusAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.CASE_STATUS}`,
    data
  });

export const updateCaseStatusAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.CASE_STATUS}${id}/`,
    data
  });

export const createCaseTaskAPI = ({ data }) =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.TASK}`,
    data
  });

export const getProvidersAPI = () =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.GET_PROVIDERS}`
  });

export const addCaseProductAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.CASE_PRODUCT,
    data
  });

export const getLendersAPI = () =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_LENDERS
  });

export const updateCaseProductAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.CASE_PRODUCT}${id}/`,
    data
  });

export const getProductsAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_PRODUCTS,
    params
  });

export const updateCaseStateAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.CASE}${id}/change-state/`,
    data
  });

export const getCaseProductAPI = id =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.CASE_PRODUCTS}${id}/`
  });

export const assignCaseAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.CASE}${id}/assign/`,
    data
  });
