import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateCaseDetailActiveTab } from "reducers/cases";
import Task from "../Tasks/Task";
import TaskTab from "./TaskTab";
import StatusTab from "./StatusTab";
import ProductTab from "./ProductTab";
import AddEditStatusModal from "./AddEditStatusModal";
import { CASE_DETAIL_TABS, USER_TYPE } from "utils/constants";
import { BASE_URL } from "../../api/constants";
import { toast } from "react-toastify";
import { getAccessToken } from "utils";
import {
  Box,
  Tab,
  Tabs,
  Stack,
  ButtonGroup,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Link,
  Button,
  Paper
} from "@mui/material";
import { addCaseStatus, createCaseTask } from "reducers/cases";
import AddIcon from "@mui/icons-material/Add";
import ModeIcon from "@mui/icons-material/Mode";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function TabPanel(props) {
  const { children, index } = props;
  const { caseDetailActiveTab } = useSelector(state => state.cases);

  return <div>{caseDetailActiveTab === index && <Box> {children} </Box>}</div>;
}

const options = ["Status", "Tasks", "Products"];

export default function CaseDetailTabs() {
  const [task, setTask] = useState({});
  const [status, setStatus] = useState({});
  const [caseStatusDetail, setCaseStatusDetail] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isTaskModal, setIsTaskModal] = useState(false);
  const [isStatusModal, setIsStatusModal] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const {
    caseDetailActiveTab,
    caseStatuses,
    caseProducts,
    caseTasks
  } = useSelector(state => state.cases);
  const { me } = useSelector(state => state.user.data);

  const token = getAccessToken();

  const handleChange = (e, value) => {
    dispatch(updateCaseDetailActiveTab(value));
  };

  const handleTask = data => {
    dispatch(createCaseTask({ id, data }));
    setIsTaskModal(false);
  };

  const handleClick = () => {
    if (options[selectedIndex] === "Tasks") {
      setIsTaskModal(true);
    }
    if (options[selectedIndex] === "Products") {
      history.push(`/cases/${id}/add-product`);
    }
    if (options[selectedIndex] === "Status") {
      setIsStatusModal(true);
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleCaseStatus = data => {
    dispatch(addCaseStatus(data));
    setIsStatusModal(false);
  };

  useEffect(() => {
    if (caseStatuses?.results?.length) {
      setCaseStatusDetail(caseStatuses.results);
    } else {
      setCaseStatusDetail([]);
    }
  }, [caseStatuses]);

  useEffect(() => {
    return () => dispatch(updateCaseDetailActiveTab("status"));
  }, [dispatch]);

  return (
    <>
      <Box>
        <Box
          sx={theme => ({
            marginBottom: "40px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down("xl")]: {
              flexWrap: "wrap"
            }
          })}
        >
          <Tabs
            value={caseDetailActiveTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            sx={theme => ({
              marginRight: "12px",
              [theme.breakpoints.down("xl")]: {
                marginBottom: "24px"
              },
              "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                color: `${theme.palette.primary.main} !important`
              }
            })}
          >
            <Tab
              label="STATUS"
              value={CASE_DETAIL_TABS.STATUS}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "32px",
                [theme.breakpoints.down("xll")]: {
                  marginRight: "4px"
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="TASKS"
              value={CASE_DETAIL_TABS.TASKS}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "32px",
                [theme.breakpoints.down("xll")]: {
                  marginRight: "4px"
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="PRODUCTS"
              value={CASE_DETAIL_TABS.PRODUCTS}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "32px",
                [theme.breakpoints.down("xll")]: {
                  marginRight: "4px"
                },
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
          </Tabs>
          <Stack
            direction="row"
            spacing={{ xs: 0, lg: 2 }}
            sx={theme => ({
              [theme.breakpoints.down("lg")]: {
                marginTop: "24px",
                flexWrap: "wrap",
                width: "100%"
              }
            })}
          >
            <Link
              href={`${BASE_URL}/cases/${id}/export/?token=${token}`}
              download="filename.csv"
              underline="none"
              onClick={() => toast.success("Exporting the case...")}
              variant="button"
              sx={theme => ({
                padding: "8px 22px",
                display: "inline-flex",
                alignItems: "center",
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: "4px",
                [theme.breakpoints.down("lg")]: {
                  flex: 1,
                  justifyContent: "center",
                  marginBottom: "16px",
                  marginRight: "16px"
                },
                [theme.breakpoints.down("ssm")]: {
                  marginRight: 0,
                  flex: "unset",
                  width: "100%"
                }
              })}
            >
              <DownloadIcon sx={{ marginRight: "6px" }} /> EXPORT
            </Link>
            {me?.type !== USER_TYPE.ADMIN && (
              <>
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<ModeIcon />}
                  onClick={() => {
                    history.push(`/edit-case/${id}`);
                  }}
                  sx={theme => ({
                    [theme.breakpoints.down("xll")]: {
                      marginLeft: "8px !important"
                    },
                    [theme.breakpoints.down("lg")]: {
                      flex: "0 0 100%",
                      order: 3,
                      marginLeft: "0 !important"
                    }
                  })}
                >
                  EDIT
                </Button>
                <>
                  <ButtonGroup
                    variant="outlined"
                    ref={anchorRef}
                    sx={theme => ({
                      [theme.breakpoints.down("xll")]: {
                        marginLeft: "8px !important"
                      },
                      [theme.breakpoints.down("lg")]: {
                        flex: "0 0 40%"
                      },
                      [theme.breakpoints.down("ssm")]: {
                        flex: 1,
                        width: "100%",
                        margin: "0 !important",
                        "& button:first-child": {
                          width: "100%"
                        }
                      }
                    })}
                  >
                    <Button
                      size="large"
                      onClick={handleClick}
                      startIcon={<AddIcon />}
                      sx={theme => ({
                        [theme.breakpoints.down("lg")]: {
                          marginBottom: "16px",
                          width: "100%"
                        }
                      })}
                    >
                      {options[selectedIndex]}
                    </Button>
                    <Button
                      size="small"
                      onClick={handleToggle}
                      sx={theme => ({
                        [theme.breakpoints.down("lg")]: {
                          marginBottom: "16px"
                        }
                      })}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper open={open} anchorEl={anchorRef.current} transition>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom"
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              id="split-button-menu"
                              autoFocusItem
                              sx={theme => ({
                                color: theme.palette.text.primary,
                                fontSize: "24px",
                                backgroundColor:
                                  theme.palette.text.dark.primary,
                                width: "130px"
                              })}
                            >
                              {options.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  selected={index === selectedIndex}
                                  onClick={event =>
                                    handleMenuItemClick(event, index)
                                  }
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>
              </>
            )}
          </Stack>
        </Box>
        <TabPanel index={CASE_DETAIL_TABS.STATUS}>
          <StatusTab />
        </TabPanel>
        <TabPanel index={CASE_DETAIL_TABS.TASKS}>
          <TaskTab />
        </TabPanel>
        <TabPanel index={CASE_DETAIL_TABS.PRODUCTS}>
          <ProductTab />
        </TabPanel>
      </Box>
      {isTaskModal && (
        <Box>
          <Task
            isOpen={isTaskModal}
            handleClose={() => setIsTaskModal(false)}
            handleTask={handleTask}
            task={task}
          />
        </Box>
      )}
      {isStatusModal && (
        <Box>
          <AddEditStatusModal
            add
            title="Add status"
            isOpen={isStatusModal}
            handleClose={() => setIsStatusModal(false)}
            handleCaseStatus={handleCaseStatus}
            status={status}
            isDisabled={false}
          />
        </Box>
      )}
    </>
  );
}
