export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const ENDPOINTS = {
  LOGIN: `${BASE_URL}/users/login/`,
  LOGOUT: `${BASE_URL}/users/logout/`,
  FORGOT_PASSWORD: `${BASE_URL}/users/password-reset/`,
  CONFIRM_PASSWORD: `${BASE_URL}/users/password-reset/confirm/`,
  CHANGE_PASSWORD: `${BASE_URL}/users/password/change/`,
  ME: `${BASE_URL}/users/me/`,
  GET_BROKERS: `${BASE_URL}/users/brokers/`,
  GET_LEADS: `${BASE_URL}/leads/list/`,
  BUY_LEAD: `${BASE_URL}/leads/buy/`,
  MARK_FAVOURITE_LEAD: `${BASE_URL}/users/mark-favourite-lead/`,
  GET_TASKS: `${BASE_URL}/tasks/`,
  TASK: `${BASE_URL}/tasks/`,
  GET_NOTIFICATIONS: `${BASE_URL}/communications/notifications/`,
  REMOVE_NOTIFICATIONS: `${BASE_URL}/communications/notifications/bulk-delete/`,
  UPDATE_NOTIFICATIONS: `${BASE_URL}/communications/notifications/bulk-update/`,
  POST_ONESIGNAL_PLAYER_ID: `${BASE_URL}/communications/onesignal-player/`,
  PATCH_PUSH_NOTIFICATION_PREFERENCE: `${BASE_URL}/communications/push-notification-preference/`,
  PATCH_SMS_PREFERENCE: `${BASE_URL}/communications/sms-preference/`,
  GET_STATUSES: `${BASE_URL}/cases/statuses/`,
  GET_CASES: `${BASE_URL}/cases/list/`,
  NEW_CASE: `${BASE_URL}/cases/create/`,
  CASE: `${BASE_URL}/cases/`,
  CASE_STATUS: `${BASE_URL}/cases/case-status/`,
  CASE_PRODUCTS: `${BASE_URL}/cases/case-products/`,
  GET_PROVIDERS: `${BASE_URL}/cases/providers/`,
  CASE_PRODUCT: `${BASE_URL}/cases/case-products/`,
  GET_LENDERS: `${BASE_URL}/cases/lenders/`,
  GET_PRODUCTS: `${BASE_URL}/cases/products/`,
  UPDATE_TASKS: `${BASE_URL}/tasks/bulk-update/`,
  REMOVE_TASKS: `${BASE_URL}/tasks/bulk-delete/`,
  LEAD_DETAIL: `${BASE_URL}/leads/detail/`,
  GET_CREDIT_PRODUCTS: `${BASE_URL}/stripe/products/`,
  UPDATE_LEAD: `${BASE_URL}/leads/update/`,
  GET_DASHBOARD: `${BASE_URL}/dashboards/`,
  GET_LEADERBOARD: `${BASE_URL}/dashboards/leaderboard/`,
  GET_LEAD_CONVERSIONS: `${BASE_URL}/reports/lead-conversions/`,
  GET_MORTGAGES_SOLD: `${BASE_URL}/reports/mortgages-sold/`,
  GET_INSURANCES_SOLD: `${BASE_URL}/reports/insurances-sold/`,
  GET_CREDITS_USED: `${BASE_URL}/reports/credits-used/`,
  GET_PRODUCTS_EARNINGS: `${BASE_URL}/reports/products-earnings/`,
  GET_PRODUCTS_SOLD: `${BASE_URL}/reports/products-sold/`,
  GET_CONTACT_RATE: `${BASE_URL}/reports/contact-rate/`,
  GET_COMPANIES: `${BASE_URL}/users/companies/`,
  REGISTRATION_PROCESS: `${BASE_URL}/users/registration-process/`,
  REGISTRATION_COMPLETE: `${BASE_URL}/users/registration-complete/`,
  REGISTRATION_CHECKOUT_BILL: `${BASE_URL}/users/registration-checkout-bill/`,
  SIGNUP_REQUEST: `${BASE_URL}/users/signup-request/`,
  GET_COMPANY_BROKERS: `${BASE_URL}/users/company-brokers/`,
  PAYMENT_METHOD: `${BASE_URL}/stripe/payment-methods/`,
  STRIPE_INVOICES: `${BASE_URL}/stripe/invoices/`,
  ADDITIONAL_SEATS: `${BASE_URL}/users/add-seat/`,
  CANCEL_SUBSCRIPTION: `${BASE_URL}/users/cancel-subscription/`,
  CREDITS_BUNDLES: `${BASE_URL}/credits/bundles/`,
  CREDITS_UPDATE: `${BASE_URL}/accounts/update/`,
  CREDITS_BUY_BUNDLE: `${BASE_URL}/accounts/buy-bundle/`,
  GET_CLIENTS: `${BASE_URL}/cases/clients/`,
  UPDATE_EMAIL_PREFERENCE: `${BASE_URL}/communications/email-preference/`,
  GET_EMAILS_CONTENT_TYPES: `${BASE_URL}/communications/email-content-types/`,
  EMAIL_CONTENT: `${BASE_URL}/communications/email-content/`,
  UPDATE_ADVERSE_CREDIT: `${BASE_URL}/users/client/`,
  REMOVE_SEAT: `${BASE_URL}/users/remove-seat/`,
  CHANGE_MEMBERSHIP_REQUEST: `${BASE_URL}/users/change-membership-request/`
};

export const REQUEST_TYPES = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE"
};
