import Axios from "axios";
import axios from "axios";
import { getAccessToken, removeLocalStorageItem } from "utils";
import * as caseConverter from "change-object-case";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { startsWith } from "lodash";
import * as Sentry from "@sentry/browser";
import {
  TOKEN,
  NETWORK_ERROR,
  ERROR_4XX,
  ERROR_5XX,
  ERROR_413
} from "../utils/constants";

export function apiCaller({
  method = "GET",
  url = "",
  params = {},
  data = {},
  token = getAccessToken(),
  showErrors = true
} = {}) {
  // set-up case conversion configurations
  caseConverter.options = { recursive: true, arrayRecursive: true };
  return Axios({
    method,
    url,
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: "comma" }),
    data,
    transformResponse: [
      data => caseConverter.toCamel(JSON.parse(data || "{}"))
    ],
    transformRequest: [
      // this check will not stringify already stringify object
      reqData => {
        if (reqData instanceof FormData) {
          const profileImage = reqData.get("profileImage");
          const businessDetails = JSON.parse(reqData.get("businessDetails"));
          const legalText = reqData.get("legalText");
          const otherLeadsName = reqData.get("otherLeadsName");
          const firstName = reqData.get("firstName");
          const lastName = reqData.get("lastName");
          const mobilePhone = reqData.get("mobilePhone");
          const jobTitle = reqData.get("jobTitle");

          reqData.delete("profileImage");
          reqData.delete("businessDetails");
          reqData.delete("legalText");
          reqData.delete("otherLeadsName");
          reqData.delete("firstName");
          reqData.delete("lastName");
          reqData.delete("mobilePhone");
          reqData.delete("jobTitle");

          if (profileImage !== "") {
            reqData.set("profile_image", profileImage);
          }
          if (legalText) {
            reqData.set("legal_text", legalText);
          }
          if (otherLeadsName) {
            reqData.set("other_leads_name", otherLeadsName);
          }
          if (businessDetails) {
            reqData.set(
              "business_details",
              JSON.stringify(caseConverter.snakeKeys(businessDetails))
            );
          }
          if (firstName) {
            reqData.set("first_name", firstName);
          }
          if (lastName) {
            reqData.set("last_name", lastName);
          }
          if (mobilePhone) {
            reqData.set("mobile_phone", mobilePhone);
          }
          if (jobTitle) {
            reqData.set("job_title", jobTitle);
          }
          return reqData;
        } else if (typeof reqData === "string") {
          return reqData;
        } else {
          return JSON.stringify(
            caseConverter.snakeKeys(reqData, {
              exclude: ["new_password1", "new_password2", "address2"]
            })
          );
        }
      }
    ],
    headers: {
      Authorization: token ? `Token ${token}` : "",
      "Content-Type": "application/json"
    },
    responseType: "json",
    validateStatus: status => status >= 200 && status < 300,
    showErrors
  })
    .then(resp => resp)
    .catch(error => {
      throw error;
    });
}

Axios.interceptors.response.use(
  response => response,
  error => {
    const { config, response: { status, data } = {} } = error;
    const { showErrors } = config;
    if (error.message === NETWORK_ERROR) {
      toast.error(error.message);
    }
    // this will check for token expired specific case in all api's error response
    if (status === 401) {
      removeLocalStorageItem(TOKEN);
    }
    if (showErrors && startsWith(status, 4)) {
      if (status === 413) {
        toast.error(ERROR_413);
      } else {
        data?.detail?.map(msg => toast.error(msg || ERROR_4XX));
      }
    } else if (startsWith(status, 5)) {
      toast.error(ERROR_5XX);
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  }
);

export const request = () => {
  const token = getAccessToken();
  const withAuthToken = token && { Authorization: "Token " + token };
  const params = {
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: { ...withAuthToken }
  };
  return axios.create(params);
};
