import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinearProgressBar from "./LinearProgressBar";
import CaseDetailPagination from "./CaseDetailPagination";
import {
  ADD_PRODUCT,
  INSURANCE,
  MORTGAGE,
  FIRST_PAGE,
  LIFE_AND_CRITICAL_ILLNESS_TEXT
} from "utils/constants";
import DeletePopover from "../../components/DeletePopover";
import { expiryDueDate } from "utils";
import moment from "moment";
import {
  Box,
  Button,
  Typography,
  Stack,
  Divider,
  Chip,
  List,
  ListItem
} from "@mui/material";
import {
  updateFilters,
  changecaseProductPage,
  removeCaseProduct,
  updateCaseProduct,
  getCaseProducts
} from "reducers/cases";

const BOX_STYLE = theme => {
  return {
    backgroundColor: theme.palette.text.dark.primary,
    borderRadius: "16px",
    boxShadow: `0px 3px 5px -1px ${theme.palette.boxShadowPalette.primary}`,
    padding: "24px 32px",
    marginBottom: "24px",
    [theme.breakpoints.down("lg")]: {
      padding: "24px 18px"
    }
  };
};

const CHIP_STYLE = (theme, isExpired) => {
  return {
    color: isExpired
      ? theme.palette.error.contrastText
      : theme.palette.text.primary,
    backgroundColor: isExpired
      ? theme.palette.error.main
      : theme.palette.active.selected,
    fontSize: "13px",
    fontWeight: "700",
    fontFamily: "'Nunito Sans', sans-serif",
    height: "24px"
  };
};

function ProductTab() {
  const { id } = useParams();
  const { filters, caseProducts, rowsPerPage, caseProductPage } = useSelector(
    state => state.cases
  );
  const [itemId, setItemId] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (id) {
      let filters_ = { ...filters, case: id };
      dispatch(updateFilters(filters_));
      dispatch(getCaseProducts());
    }
  }, [dispatch, id, caseProductPage]);

  useEffect(() => {
    return () => dispatch(changecaseProductPage(FIRST_PAGE));
  }, [dispatch]);

  const handleOnPageChange = (e, newPage) => {
    dispatch(changecaseProductPage(newPage));
  };

  const handleRemoveProductCase = (e, id) => {
    setItemId(id);
    setAnchorEl(e.currentTarget);
  };

  const feePaidToggle = (value, productFeePaid, isProductFeePaid) => {
    const productDetail = {
      id: value.id,
      data: {
        product: value.product.id,
        provider: value.provider?.name,
        lender: value.lender?.name,
        [productFeePaid]: isProductFeePaid
      }
    };

    if (value.type === INSURANCE) {
      delete productDetail.data["lender"];
    }
    if (value.type === MORTGAGE) {
      delete productDetail.data["provider"];
    }

    dispatch(updateCaseProduct(productDetail));
  };

  return (
    <>
      {caseProducts.count > 0 ? (
        <>
          {caseProducts.results?.map(product => (
            <Box sx={theme => BOX_STYLE(theme)} key={product.id}>
              <Stack
                direction="row"
                justifyContent="space-between"
                marginBottom="16px"
                flexWrap="wrap"
              >
                <Stack
                  spacing={1}
                  sx={theme => ({
                    [theme.breakpoints.down("sm")]: {
                      order: 2
                    }
                  })}
                >
                  <Typography variant="h6" color="text.primary">
                    {product.type}
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <CalendarTodayIcon
                      sx={{ color: "text.secondary" }}
                      fontSize="18px"
                    />
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      marginLeft="8px"
                    >
                      {moment(product.created).format("ddd D MMM YYYY")}
                    </Typography>
                    <Chip
                      label={expiryDueDate(product.expiresAt)}
                      sx={theme => CHIP_STYLE(theme, product.isExpired)}
                      style={{ marginLeft: "24px" }}
                    />
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  spacing={{ xs: 1, lg: 4 }}
                  sx={theme => ({
                    [theme.breakpoints.down("lg")]: {
                      margin: "0 0 18px auto"
                    },
                    [theme.breakpoints.down("sm")]: {
                      marginRight: "24px"
                    }
                  })}
                >
                  <Button
                    variant="text"
                    size="large"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={e => handleRemoveProductCase(e, product.id)}
                  >
                    DELETE
                  </Button>
                  <Button
                    variant="text"
                    size="large"
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      history.push(`/cases/${id}/edit-product/${product.id}`);
                    }}
                  >
                    EDIT
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" flexWrap="wrap">
                <List
                  sx={{
                    display: "flex",
                    minWidth: "350px",
                    marginRight: "16px",
                    alignItems: "flex-start"
                  }}
                >
                  <ListItem disablePadding sx={{ width: "auto" }}>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      component="p"
                      sx={{
                        fontWeight: "700",
                        marginRight: "16px"
                      }}
                    >
                      {product?.type === INSURANCE ? "Provider:" : "Lender:"}
                    </Typography>
                  </ListItem>
                  <ListItem
                    disablePadding
                    sx={{
                      width: "auto"
                    }}
                  >
                    <Typography variant="body1" color="text.primary">
                      {product?.type === INSURANCE
                        ? `${product?.provider?.name}`
                        : `${product?.lender?.name}`}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    minWidth: "250px",
                    alignItems: "flex-start"
                  }}
                >
                  <ListItem disablePadding sx={{ width: "auto" }}>
                    <Typography
                      component="p"
                      variant="body1"
                      color="text.secondary"
                      sx={{
                        fontWeight: "700",
                        marginRight: "16px"
                      }}
                    >
                      {product?.type}:
                    </Typography>
                  </ListItem>
                  <ListItem disablePadding sx={{ width: "auto" }}>
                    <Typography variant="body1" color="text.primary">
                      {product?.product?.name}
                    </Typography>
                  </ListItem>
                </List>
              </Stack>
              {product.type !== MORTGAGE && product.insuranceValue1 && (
                <Stack direction="row" alignItems="center" flexWrap="wrap">
                  <List
                    sx={{
                      display: "flex",
                      minWidth: "350px",
                      marginRight: "16px",
                      alignItems: "flex-start"
                    }}
                  >
                    <ListItem disablePadding sx={{ width: "auto" }}>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        component="p"
                        sx={{
                          fontWeight: "700",
                          marginRight: "16px"
                        }}
                      >
                        {product?.product?.name ===
                        LIFE_AND_CRITICAL_ILLNESS_TEXT
                          ? "Life"
                          : product?.product?.name}
                        :
                      </Typography>
                    </ListItem>
                    <ListItem disablePadding sx={{ width: "auto" }}>
                      <Typography variant="body1" color="text.primary">
                        {product.insuranceValue1}
                      </Typography>
                    </ListItem>
                  </List>
                  {product.insuranceValue2 && (
                    <List
                      sx={{
                        display: "flex",
                        minWidth: "250px",
                        alignItems: "flex-start"
                      }}
                    >
                      <ListItem disablePadding sx={{ width: "auto" }}>
                        <Typography
                          component="p"
                          variant="body1"
                          color="text.secondary"
                          sx={{
                            fontWeight: "700",
                            marginRight: "16px"
                          }}
                        >
                          Critical illness:
                        </Typography>
                      </ListItem>
                      <ListItem disablePadding sx={{ width: "auto" }}>
                        <Typography variant="body1" color="text.primary">
                          {product?.insuranceValue2}
                        </Typography>
                      </ListItem>
                    </List>
                  )}
                </Stack>
              )}
              <LinearProgressBar value={product?.paidPercentage} />
              <Stack direction="row" margin="16px 0" flexWrap="wrap">
                <Stack
                  spacing={1}
                  sx={theme => ({
                    marginRight: "78px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "140px",
                      marginRight: "10px",
                      marginBottom: "24px"
                    }
                  })}
                >
                  <Typography variant="caption" color="text.secondary">
                    Pending
                  </Typography>
                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={theme => ({
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "15px"
                      }
                    })}
                  >
                    {`£${product.amountPending}`}
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  sx={theme => ({
                    marginRight: "78px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "140px",
                      marginRight: "10px",
                      marginBottom: "24px"
                    }
                  })}
                >
                  <Typography variant="caption" color="text.secondary">
                    Cleared
                  </Typography>
                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={theme => ({
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "15px"
                      }
                    })}
                  >
                    {`£${product.amountPaid}`}
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  sx={theme => ({
                    marginRight: "78px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "140px",
                      marginRight: "10px",
                      marginBottom: "24px"
                    }
                  })}
                >
                  <Typography variant="caption" color="text.secondary">
                    Application Fee
                  </Typography>
                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={theme => ({
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "15px"
                      }
                    })}
                  >
                    {`£${product.applicationFee}`}
                    <Chip
                      label={product.isApplicationFeePaid ? "Paid" : "Unpaid"}
                      onClick={() =>
                        feePaidToggle(
                          product,
                          "isApplicationFeePaid",
                          !product.isApplicationFeePaid
                        )
                      }
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: "13px",
                        fontWeight: "600",
                        marginLeft: "8px"
                      }}
                      variant="outlined"
                      color={product.isApplicationFeePaid ? "primary" : "error"}
                    />
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  sx={theme => ({
                    marginRight: "78px",
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "140px",
                      marginRight: "10px",
                      marginBottom: "24px"
                    }
                  })}
                >
                  <Typography variant="caption" color="text.secondary">
                    Offer/Completion Fee
                  </Typography>
                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={theme => ({
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "15px"
                      }
                    })}
                  >
                    {`£${product.offerCompletionFee}`}
                    <Chip
                      label={
                        product.isOfferCompletionFeePaid ? "Paid" : "Unpaid"
                      }
                      onClick={() =>
                        feePaidToggle(
                          product,
                          "isOfferCompletionFeePaid",
                          !product.isOfferCompletionFeePaid
                        )
                      }
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: "13px",
                        fontWeight: "600",
                        marginLeft: "8px"
                      }}
                      variant="outlined"
                      color={
                        product.isOfferCompletionFeePaid ? "primary" : "error"
                      }
                    />
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  sx={theme => ({
                    [theme.breakpoints.down("lg")]: {
                      minWidth: "140px"
                    }
                  })}
                >
                  <Typography variant="caption" color="text.secondary">
                    Proc Fee
                  </Typography>
                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={theme => ({
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "15px"
                      }
                    })}
                  >
                    {`£${product.procFee}`}
                    <Chip
                      label={product.isProcFeePaid ? "Paid" : "Unpaid"}
                      onClick={() =>
                        feePaidToggle(
                          product,
                          "isProcFeePaid",
                          !product.isProcFeePaid
                        )
                      }
                      style={{
                        fontFamily: "'Nunito Sans', sans-serif",
                        fontSize: "13px",
                        fontWeight: "600",
                        marginLeft: "8px"
                      }}
                      variant="outlined"
                      color={product.isProcFeePaid ? "primary" : "error"}
                    />
                  </Typography>
                </Stack>
              </Stack>
              <Divider sx={{ margin: "24px 0" }} />
              <Typography
                variant="body2"
                color="text.secondary"
                style={{ wordBreak: "break-word" }}
              >
                {product.note ? product.note : "N/A"}
              </Typography>
            </Box>
          ))}
        </>
      ) : (
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          color="text.secondary"
          textAlign="center"
        >
          There is no product yet
        </Typography>
      )}
      <CaseDetailPagination
        addNewBtn={ADD_PRODUCT}
        data={caseProducts}
        rowsPerPage={rowsPerPage}
        page={caseProductPage}
        handleOnPageChange={handleOnPageChange}
      />
      <DeletePopover
        text="product"
        anchorEl={anchorEl}
        itemId={itemId}
        handleClose={() => setAnchorEl(null)}
        removeItem={removeCaseProduct}
      />
    </>
  );
}

export default ProductTab;
