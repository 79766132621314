import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/constants";
import { Typography, Box, Grid } from "@mui/material";

const PurchasedDetails = () => {
  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Box sx={{ marginTop: "48px" }}>
          <Box
            component="img"
            sx={{
              height: "auto",
              width: "auto",
              maxHeight: { xs: 64, lg: 64 },
              maxWidth: { xs: 64, lg: 64 }
            }}
            alt="Blabble"
            src="/app/assets/purchased.png"
          />
          <Typography
            variant="h4"
            color="text.primary"
            gutterBottom
            margin="26px 0"
            sx={theme => ({
              [theme.breakpoints.down("sm")]: {
                fontSize: "28px"
              }
            })}
          >
            Purchased
          </Typography>
          <Typography variant="body1" color="text.primary" marginTop="24">
            A registration email has been sent to you and your brokers. Click{" "}
            <Link to={ROUTES.LOGIN}>here</Link> to login.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PurchasedDetails;
