import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  Stack
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import LoginImage from "components/NonAuthenticateLoginImage";
import { useDispatch } from "react-redux";
import { signUpRequest } from "reducers/registration";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.text.dark.primary)
);

const SignUpRequest = () => {
  const [isResponsive, setIsResponsive] = useState(false);

  const windowSizeHandler = () => {
    setIsResponsive(window.innerWidth < 991);
  };

  useEffect(() => {
    window.addEventListener("resize", windowSizeHandler);
    windowSizeHandler();

    return () => window.removeEventListener("resize", windowSizeHandler);
  }, []);

  const dispatch = useDispatch();
  const classes = useStyles({ isResponsive });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = value => {
    const signUpRequestDetails = {
      data: {
        email: value.email.toLowerCase(),
        telephone: value.telephone,
        fcaNumber: value.fcaNumber
      }
    };
    dispatch(signUpRequest(signUpRequestDetails));
  };

  return (
    <Box sx={{ marginBottom: "48px" }}>
      <Grid container spacing={6} sx={{ alignItems: "center" }}>
        <Grid item xs={12} lg={6}>
          <Box
            m={"auto"}
            sx={theme => ({
              maxWidth: 500,
              height: 370,
              padding: "56px",
              borderRadius: 4,
              boxShadow: 3,
              bgcolor: "text.dark.primary",
              [theme.breakpoints.down("xl")]: {
                padding: "28px"
              },
              [theme.breakpoints.down("lg")]: {
                padding: 0,
                boxShadow: 0,
                maxWidth: "100%",
                height: "100%",
                bgcolor: "background.default"
              }
            })}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="h4"
                gutterBottom
                component="h4"
                color="text.primary"
                mb={4}
              >
                Sign Up Request
              </Typography>
              <Stack spacing={4}>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Company Owner Email"
                    type="email"
                    error={!!errors.email}
                    placeholder="Company Owner Email"
                    {...register("email", {
                      required: true,
                      maxLength: 255,
                      pattern: /^\S+@\S+$/i
                    })}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Company Owner Mobile Phone"
                    type="tel"
                    error={!!errors.telephone}
                    placeholder="+441234567890"
                    {...register("telephone", {
                      required: true,
                      minLength: 4,
                      pattern: /^(?:\+44|0)\d{9,10}$/,
                    })}
                  />
                  {errors.telephone && errors.telephone.type === "pattern" && (
                    <div className="validation-error">Valid UK number required!</div>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="FCA Number"
                    type="number"
                    placeholder="FCA Number"
                    error={!!errors.fcaNumber}
                    {...register("fcaNumber", {
                      required: true
                    })}
                    inputProps={{ inputMode: "numeric" }}
                  />
                </FormControl>
              </Stack>
              <Stack
                mt={4}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={theme => ({
                  [theme.breakpoints.down("lg")]: {
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center"
                  }
                })}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={theme => ({
                    [theme.breakpoints.down("lg")]: {
                      width: "100%",
                      marginBottom: "32px"
                    }
                  })}
                >
                  SUBMIT
                </Button>
              </Stack>
            </form>
          </Box>
        </Grid>
        <Grid item xs={0} lg={6} display={{ xs: "none", lg: "block" }}>
          <LoginImage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUpRequest;
