import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  Stack
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { forgotPassword } from "reducers/users";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import LoginImage from "components/NonAuthenticateLoginImage";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.text.dark.primary)
);

const ForgotPassword = () => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = data => {
    const forgotPasswordData = {
      email: data.email.toLowerCase(),
    }
    dispatch(forgotPassword(forgotPasswordData));
  };

  return (
    <Box sx={{ marginBottom: "48px" }}>
      <Grid container spacing={6} sx={{ alignItems: "center" }}>
        <Grid item xs={12} lg={6}>
          <Box
            m={"auto"}
            sx={theme => ({
              maxWidth: 500,
              height: 215,
              padding: "56px",
              borderRadius: 4,
              boxShadow: 3,
              bgcolor: "text.dark.primary",
              [theme.breakpoints.down("xl")]: {
                padding: "28px"
              },
              [theme.breakpoints.down("lg")]: {
                padding: 0,
                boxShadow: 0,
                maxWidth: "100%",
                height: "100%",
                bgcolor: "background.default"
              }
            })}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="h4"
                gutterBottom
                component="h4"
                color="text.primary"
                mb={4}
              >
                Forgot password
              </Typography>
              <Stack spacing={4}>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Email"
                    type="text"
                    error={!!errors.email}
                    placeholder="email"
                    autoComplete="off"
                    {...register("email", {
                      required: true,
                      maxLength: 255,
                      pattern: /^\S+@\S+$/i
                    })}
                  />
                  {errors.email && (
                    <div className="validation-error">E-mail is not valid!</div>
                  )}
                </FormControl>
                <Stack
                  mt={3}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={theme => ({
                    [theme.breakpoints.down("lg")]: {
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "center"
                    }
                  })}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={theme => ({
                      [theme.breakpoints.down("lg")]: {
                        width: "100%",
                        marginBottom: "32px"
                      }
                    })}
                  >
                    SUBMIT
                  </Button>
                  <Link to={ROUTES.LOGIN} style={{ textDecoration: "none" }}>
                    <Typography variant="subtitle1" color="primary.main">
                      Login
                    </Typography>
                  </Link>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Grid>
        <Grid item xs={0} lg={6} display={{ xs: "none", lg: "block" }}>
          <LoginImage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
