import React, { useEffect } from "react";
import { logout } from "reducers/users";
import { useDispatch, useSelector } from "react-redux";

function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);
  return <></>;
}

export default Logout;
