import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  isBuyCreditsModal: false,
  isBuyCreditsCheckoutModal: false,
  isProcessingModal: false,
  isPurchasedModal: false,
  companiesData: [],
  creditsBundle: [],
  purchased: {},
  error: null
};

export const credits = createSlice({
  name: "credits",
  initialState,
  reducers: {
    updateBuyCreditsModal: (state, action) => {
      state.isBuyCreditsModal = action.payload;
    },
    getCreditsBundles: state => {
      state.loading = true;
    },
    getCreditsBundlesSucceed: (state, action) => {
      state.loading = false;
      state.creditsBundle = action.payload;
    },
    getCreditsBundlesFailed: (state, action) => {
      state.loading = false;
    },
    creditsUpdate: state => {
      state.loading = true;
    },
    creditsUpdateSucceed: (state, action) => {
      state.loading = false;
    },
    creditsUpdateFailed: (state, action) => {
      state.loading = false;
    },
    updateBuyCreditsCheckoutModal: (state, action) => {
      state.isBuyCreditsCheckoutModal = action.payload;
    },
    creditBuyBundle: (state, action) => {
      state.loading = true;
    },
    creditBuyBundleSucceed: (state, action) => {
      state.purchased = action.payload;
      state.loading = false;
      state.isProcessingModal = false;
      state.isPurchasedModal = true;
    },
    creditBuyBundleFailed: (state, action) => {
      state.loading = false;
      state.isProcessingModal = false;
    },
    updateProcessingModal: (state, action) => {
      state.isProcessingModal = action.payload;
    },
    updatePurchasedModal: (state, action) => {
      state.isPurchasedModal = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    }
  }
});

export const {
  updateFilters,
  updateBuyCreditsModal,
  getCreditsBundles,
  getCreditsBundlesSucceed,
  getCreditsBundlesFailed,
  creditsUpdate,
  creditsUpdateSucceed,
  creditsUpdateFailed,
  updateBuyCreditsCheckoutModal,
  creditBuyBundle,
  creditBuyBundleSucceed,
  creditBuyBundleFailed,
  updateProcessingModal,
  updatePurchasedModal,
  changePage
} = credits.actions;

export default credits.reducer;
