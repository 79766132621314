import React from "react";
import { Drawer, Box, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function NonAuthenticateResponsiveMenu({ position, toggleMenu }) {
  return (
    <Drawer
      anchor={"left"}
      open={position["left"]}
      onClose={toggleMenu(false)}
      sx={{
        "& .MuiDrawer-paper": {
          bgcolor: "text.dark.primary",
          width: "350px"
        }
      }}
    >
      <Box
        sx={{
          height: "100%",
          padding: "56px 32px"
        }}
        role="presentation"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            component="img"
            display={{ xs: "block", lg: "none" }}
            sx={{
              height: "auto",
              width: "auto",
              maxHeight: { xs: 25, lg: 25 },
              maxWidth: { xs: 120, lg: 120 }
            }}
            alt="Blabble-logo"
            src="/app/assets/logo.svg"
          />
          <CloseIcon
            onClick={toggleMenu(false)}
            sx={{ color: "text.primary" }}
          />
        </Stack>
        <Stack sx={{ position: "absolute", bottom: 0 }}>
          <Typography variant="link1" color="text.primary" marginBottom="24px">
            Privacy Policy
          </Typography>
          <Typography variant="link1" color="text.primary" marginBottom="24px">
            Terms of Use
          </Typography>
        </Stack>
      </Box>
    </Drawer>
  );
}

export default NonAuthenticateResponsiveMenu;
