export const SORTING = {
  NONE: 1,
  ASCENDING: 2,
  DESCENDING: 3
};

export const SORTING_PARAM = {
  MODIFIED: "modified",
  PROPERTY_AMOUNT: "property_amount",
  MORTGAGE_AMOUNT: "mortgage_amount",
  FAVOURITE_LEAD: "is_favourite",
  IS_ACTIVE: "is_active",
  NAME: "name",
  DUE_DATE: "due_date",
  CASE: "case",
  EMAIL: "email",
  FIRST_NAME: "first_name",
  BLABBLE_COMMISSION_PERCENTAGE: "blabble_commission_percentage",
  PERSONAL_COMMISSION_PERCENTAGE: "personal_commission_percentage",
  OTHER_COMMISSION_PERCENTAGE: "other_commission_percentage",
  CREATED: "created",
  STATUS: "status",
  TYPE: "type",
  AMOUNT: "amount",
  BALANCE: "balance",
  CREDIT_EXPIRED_DATE: "credit_expiry_date",
  USED_CREDITS: "used_credits",
  USED_CREDITS_THIS_MONTH: "used_credits_this_month",
  CLIENT_NAME: "client_name",
  ID: "id",
  CASES_COUNT: "cases_count",
  ADVERSE_CREDIT: "is_adverse_credit"
};

export const initialLeadSorting = {
  [SORTING_PARAM.MODIFIED]: SORTING.DESCENDING,
  [SORTING_PARAM.PROPERTY_AMOUNT]: SORTING.NONE,
  [SORTING_PARAM.MORTGAGE_AMOUNT]: SORTING.NONE,
  [SORTING_PARAM.FAVOURITE_LEAD]: SORTING.NONE,
  [SORTING_PARAM.IS_ACTIVE]: SORTING.NONE
};

export const defaultLeadsSorting = {
  [SORTING_PARAM.MODIFIED]: SORTING.ASCENDING,
  [SORTING_PARAM.PROPERTY_AMOUNT]: SORTING.NONE,
  [SORTING_PARAM.MORTGAGE_AMOUNT]: SORTING.NONE,
  [SORTING_PARAM.FAVOURITE_LEAD]: SORTING.NONE,
  [SORTING_PARAM.IS_ACTIVE]: SORTING.NONE
};

export const defaultTasksSorting = {
  [SORTING_PARAM.NAME]: SORTING.NONE,
  [SORTING_PARAM.DUE_DATE]: SORTING.ASCENDING,
  [SORTING_PARAM.CASE]: SORTING.NONE,
  [SORTING_PARAM.CLIENT_NAME]: SORTING.NONE
};

export const defaultCompanySorting = {
  [SORTING_PARAM.EMAIL]: SORTING.ASCENDING,
  [SORTING_PARAM.FIRST_NAME]: SORTING.NONE,
  [SORTING_PARAM.IS_ACTIVE]: SORTING.NONE,
  [SORTING_PARAM.BLABBLE_COMMISSION_PERCENTAGE]: SORTING.NONE,
  [SORTING_PARAM.PERSONAL_COMMISSION_PERCENTAGE]: SORTING.NONE,
  [SORTING_PARAM.OTHER_COMMISSION_PERCENTAGE]: SORTING.NONE,
  [SORTING_PARAM.BALANCE]: SORTING.NONE,
  [SORTING_PARAM.CREDIT_EXPIRED_DATE]: SORTING.NONE,
  [SORTING_PARAM.USED_CREDITS]: SORTING.NONE,
  [SORTING_PARAM.USED_CREDITS_THIS_MONTH]: SORTING.NONE
};

export const initialInvoicesSorting = {
  [SORTING_PARAM.CREATED]: SORTING.DESCENDING,
  [SORTING_PARAM.STATUS]: SORTING.NONE,
  [SORTING_PARAM.TYPE]: SORTING.NONE,
  [SORTING_PARAM.AMOUNT]: SORTING.NONE
};

export const defaultInvoicesSorting = {
  [SORTING_PARAM.CREATED]: SORTING.ASCENDING,
  [SORTING_PARAM.STATUS]: SORTING.NONE,
  [SORTING_PARAM.TYPE]: SORTING.NONE,
  [SORTING_PARAM.AMOUNT]: SORTING.NONE
};

export const defaultClientSorting = {
  [SORTING_PARAM.ID]: SORTING.NONE,
  [SORTING_PARAM.FIRST_NAME]: SORTING.ASCENDING,
  [SORTING_PARAM.CASES_COUNT]: SORTING.NONE,
  [SORTING_PARAM.ADVERSE_CREDIT]: SORTING.NONE
};

export const TASK_TABS = {
  ALL: "all",
  TODO: "todo",
  COMPLETED: "completed"
};

export const NOTIFICATION_TABS = {
  UNREAD: "unread",
  READ: "read"
};

export const TOKEN = "TOKEN";

export const CASES_TABS = {
  ALL: "all",
  ACTIVE: "active",
  COMPLETE: "complete",
  ARCHIVED: "archived"
};

export const ENTER_KEY = 13;

export const CASE_DETAIL_TABS = {
  STATUS: "status",
  TASKS: "tasks",
  PRODUCTS: "products"
};

export const INSURANCE = "Insurance";
export const INSURANCE_KEY = 1;
export const MORTGAGE = "Mortgage";
export const MORTGAGE_KEY = 2;
export const PRODUCT_TYPE_KEY = "productType";
export const PRODUCT_TYPE_TEXT = "Product Type";
export const PROVIDER_TEXT = "Provider";
export const LENDER_TEXT = "Lender";

export const PROVIDER = "provider";
export const OTHER = "Other";
export const BUILDINGS_AND_CONTENTS = "Buildings & Contents";
export const SOLICITOR_REFERRALS = "Solicitor Referrals";
export const LIFE_AND_CRITICAL_ILLNESS_TEXT = "Life & Critical illness";

export const BUILDINGS_AND_CONTENTS_VALUE = -1;
export const SOLICITOR_REFERRALS_VALUE = -2;
export const OTHER_VALUE = -3;
export const LIFE_AND_CRITICAL_ILLNESS = 5;

export const ADD_PRODUCT = "ADD PRODUCT";
export const ADD_TASK = "ADD TASK";
export const ADD_STATUS = "ADD STATUS";

export const LENDER = "lender";
export const PRODUCT = "product";

export const NETWORK_ERROR = "Network Error";
export const ERROR_4XX = "Something went wrong!";
export const ERROR_5XX =
  "Uh oh, it seems that something has gone wrong on our end.";
export const ERROR_413 = "File size too large.";

export const DESELECT_ALL = "DESELECT All";
export const SELECT_ALL = "SELECT All";

export const ARCHIVED = "archived";

export const FCR_CLIENT_NOT_READY_SCHEDULE = 1;
export const FCR_UNABLE_TO_HELP = 2;
export const APPLICATION_FEE_MADE = 3;
export const CONTACT_MADE_AWAITING_DOCS = 4;
export const CONTACT_MADE_MEETING_SCHEDULED = 5;
export const INSURANCE_APPLICATION_SUBMITTED = 6;
export const REFERRED_TO_3RD_PARTY = 8;
export const CLIENT_PROCEEDING = 9;
export const CONTACT_ATTEMPT_1 = 10;
export const CONTACT_ATTEMPT_2 = 11;
export const CONTACT_ATTEMPT_3 = 12;
export const CONTACT_MADE_FACTFIND_COMPLETE = 13;
export const INVALID_NUMBER = 14;
export const NO_SALE_DECLINED_TO_PAY_FEE = 15;
export const NO_SALE_NO_FURTHER_CONTACT_REQUESTED = 16;
export const NO_SALE_WENT_AWOL = 17;
export const NO_SALE_WENT_ELSEWHERE = 18;
export const SALE_MADE_AIP_APPROVED = 19;
export const SALE_MADE_FULL_APPLICATION = 20;
export const SALE_MADE_MORTGAGE_COMPLETED = 21;
export const SALE_MADE_MORTGAGE_OFFERED = 22;
export const DOCUMENTS_RECEIVED = 23;
export const CHECK_MY_FILE = 24;
export const CASE_NOTE = 26;

export const REASON_FILED_STATUSES = [
  REFERRED_TO_3RD_PARTY,
  NO_SALE_NO_FURTHER_CONTACT_REQUESTED
];
export const DUE_DATE_FIELD_STATUSES = [
  FCR_CLIENT_NOT_READY_SCHEDULE,
  FCR_UNABLE_TO_HELP,
  CONTACT_MADE_AWAITING_DOCS,
  CONTACT_MADE_MEETING_SCHEDULED
];

export const USER_TYPE = {
  ADMIN: "ADMIN",
  COMPANY_OWNER: "COMPANY_OWNER",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  LEAD_BROKER: "LEAD_BROKER",
  BROKER: "BROKER"
};

export const WORDS_110 = 110;
export const WORDS_100 = 100;
export const WORDS_45 = 45;
export const WORDS_30 = 30;
export const FIRST_PAGE = 1;

export const DARK = "dark";
export const LIGHT = "light";

export const TIME_DURATION = [
  {
    id: 1,
    name: "All Time",
    value: "ALL_TIME"
  },
  {
    id: 2,
    name: "This Month",
    value: "THIS_MONTH"
  },
  {
    id: 3,
    name: "This Quarter",
    value: "THIS_QUARTER"
  },
  {
    id: 4,
    name: "This Year",
    value: "THIS_YEAR"
  },
  {
    id: 5,
    name: "Last 30 Days",
    value: "LAST_30_DAYS"
  },
  {
    id: 6,
    name: "Last 60 Days",
    value: "LAST_60_DAYS"
  },
  {
    id: 7,
    name: "Last 90 Days",
    value: "LAST_90_DAYS"
  }
];

export const REPORTS_TABS = {
  LEAD_CONVERSION: "leadConversion",
  MORTGAGE_SOLD: "mortgageSold",
  INSURANCE_SOLD: "insuranceSold",
  CREDITS_USED: "creditUsed",
  PRODUCTS_EARNINGS: "productsEarnings",
  PRODUCTS_SOLD: "productsSold",
  CONTACT_RATE: "contactRate"
};

export const PROFILE_TABS = {
  PROFILE: "profile",
  EMAIL_PREFERENCE: "emailPreference",
  PUSH_NOTIFICATION_PREFERENCE: "pushNotificationPreference",
  SMS_PREFERENCE: "smsPreference",
  SECURITY: "security"
};

export const LEAD_TYPES = [
  {
    id: 1,
    name: "Blabble Lead",
    value: "BLABBLE"
  },
  {
    id: 2,
    name: "Personal Lead",
    value: "PERSONAL"
  },
  {
    id: 3,
    name: "Other Lead",
    value: "OTHER"
  }
];

export const COMPANY = {
  BROKERS: "brokers",
  INFO: "info",
  BILLING: "billing",
  ADDRESS: "address",
  MEMBERSHIP: "membership"
};

export const CASE_STATE = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED"
};
export const ARCHIVE_TEXT = "Archive";
export const UNARCHIVE_TEXT = "Unarchive";

export const ONESIGNAL_PLAYER_ID = "ONESIGNAL_PLAYER_ID";

export const STAGE = "stage";
export const PRODUCTION = "prod";

export const ERROR = "error";
export const REGISTERED = "REGISTERED";

export const SUBSCRIPTION_TYPE = {
  SUBSCRIPTION_ONLY: "Subscription Only",
  SUBSCRIPTION_AND_CREDITS: "Subscription and Credits",
  PAY_AS_YOU_GO: "Pay As You Go"
};

export const LEAD_CATEGORY = {
  HOT: "HOT"
};

export const INVOICES_TYPE = {
  PAID: "paid",
  OPEN: "open",
  DRAFT: "draft",
  UNCOLLECTIBLE: "uncollectible",
  VOID: "void"
};

export const LEAD_CATEGORY_FILTER_DROPDOWN = [
  {
    id: 1,
    name: "Hot",
    value: "HOT"
  }
];

export const PROPERTY_TYPE_FILTER_DROPDOWN = [
  {
    id: 1,
    name: "Residential",
    value: "RESIDENTIAL"
  },
  {
    id: 2,
    name: "Commercial",
    value: "COMMERCIAL"
  },
  {
    id: 3,
    name: "Buy to let",
    value: "BUY_TO_LET"
  }
];

export const FINANCE_TYPE = [
  {
    id: 1,
    name: "Remortgage",
    value: "REMORTGAGE"
  },
  {
    id: 2,
    name: "Purchase",
    value: "PURCHASE"
  },
];

export const REGISTRATION_URL = {
  BUSINESS_DETAIL: "business-details",
  BILLING_DETAIL: "billing-details"
};

export const STEPS = {
  BUSINESS_DETAILS: 0,
  BILLING_DETAILS: 1
};

export const CREDITS_DURATION_DROP_DOWN = [
  {
    id: 1,
    label: "Monthly",
    value: "MONTHLY"
  },
  {
    id: 2,
    label: "All Time",
    value: "ALL_TIME"
  }
];

export const CREDITS_DURATION = {
  ALL_TIME: "ALL_TIME",
  MONTHLY: "MONTHLY"
};
