import { ROUTES } from "routes/constants";
import { buyLead, updateLead } from "reducers/leads";

export const handleShare = (event, id) => {
  event.stopPropagation();
  navigator.clipboard.writeText(
    `${window.location.origin}${ROUTES.LEADS}/${id}`
  );
};

export const handleBuyLead = (lead, dispatch, handleClose) => {
  dispatch(buyLead({ id: lead.id }));
  handleClose();
};

export const handleUpdateLead = (event, lead, dispatch, isActive) => {
  event.stopPropagation();
  const { id } = lead;
  let data = {
    isActive
  };
  dispatch(updateLead({ id, data }));
};
