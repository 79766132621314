import { formatFilters } from "utils";
import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getLeadConversionsAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_LEAD_CONVERSIONS,
    params: formatFilters({ ...data })
  });

export const getMortgageSoldAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_MORTGAGES_SOLD,
    params: formatFilters({ ...data })
  });

export const getInsuranceSoldAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_INSURANCES_SOLD,
    params: formatFilters({ ...data })
  });

export const getCreditsUsedAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_CREDITS_USED,
    params: formatFilters({ ...data })
  });

export const getProductsEarningsAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_PRODUCTS_EARNINGS,
    params: formatFilters({ ...data })
  });

export const getProductsSoldAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_PRODUCTS_SOLD,
    params: formatFilters({ ...data })
  });

export const getContactRateAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_CONTACT_RATE,
    params: formatFilters({ ...data })
  });
