import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { USER_TYPE } from "utils/constants";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { dateStyle } from "utils/DatePickerStyle";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import {
  TextField,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
  Typography,
  Drawer,
  Box
} from "@mui/material";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.text.dark.primary)
);

function FilterDrawer({
  position,
  toggleDrawer,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCompany,
  handleCampanyChange,
  selectedBrokers,
  handleBrokerChange,
  handleFilter
}) {
  const themeMode = useTheme();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        width: 250,
        overflowX: "auto",
        backgroundColor: themeMode.palette.text.dark.primary
      }
    }
  };
  const classes = useStyles();
  const { data: userData, theme } = useSelector(state => state.user);

  return (
    <Drawer
      anchor={"right"}
      open={position["right"]}
      onClose={toggleDrawer(false)}
      sx={theme => ({
        [theme.breakpoints.down("sm")]: {
          "& .MuiDrawer-paper": {
            width: "100%",
            bgcolor: theme.palette.text.dark.primary
          }
        }
      })}
    >
      <Box
        sx={theme => ({
          width: 400,
          padding: "56px 32px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0
          }
        })}
        role="presentation"
      >
        <Box
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              padding: "56px 32px"
            }
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: "24px" }}
          >
            <Typography variant="h4" color="text.primary" component="div">
              FILTER
            </Typography>
            <CloseIcon
              onClick={toggleDrawer(false)}
              sx={{ color: "text.primary" }}
            />
          </Stack>
          <Stack direction="row" flexWrap="wrap" marginBottom="24px">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                closeOnSelect={true}
                label={"Start Date"}
                value={startDate}
                onChange={setStartDate}
                maxDate={new Date()}
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    sx={theme => ({
                      width: "100%",
                      marginRight: 0,
                      marginBottom: "16px"
                    })}
                  />
                )}
                PopperProps={{
                  sx: dateStyle(theme)
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="End Date"
                value={endDate}
                onChange={setEndDate}
                maxDate={new Date()}
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    sx={theme => ({
                      width: "100%",
                      marginRight: 0,
                      marginBottom: "16px"
                    })}
                  />
                )}
                PopperProps={{
                  sx: dateStyle(theme)
                }}
              />
            </LocalizationProvider>
            {userData?.me?.type === USER_TYPE.ADMIN && (
              <FormControl
                className={classes.textField}
                sx={theme => ({
                  width: "100%"
                })}
              >
                <InputLabel>Company</InputLabel>
                <Select
                  sx={{
                    width: "100%"
                  }}
                  value={selectedCompany}
                  onChange={handleCampanyChange}
                  input={<OutlinedInput label="Company" />}
                  MenuProps={MenuProps}
                >
                  {userData.companies?.map(({ id, name }) => (
                    <MenuItem
                      key={id}
                      value={id}
                      sx={theme => ({
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.text.dark.primary
                      })}
                    >
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {userData.brokers.length > 0 && (
              <FormControl
                className={classes.textField}
                sx={{
                  width: "100%",
                  marginTop: "16px"
                }}
              >
                <InputLabel>Brokers</InputLabel>
                <Select
                  multiple
                  sx={{
                    width: "100%"
                  }}
                  value={selectedBrokers}
                  onChange={handleBrokerChange}
                  input={<OutlinedInput label="Brokers" />}
                  renderValue={selected =>
                    selected.map(broker => broker.email).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {userData.brokers?.map(broker => (
                    <MenuItem
                      key={broker.id}
                      value={broker}
                      sx={theme => ({
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.text.dark.primary
                      })}
                    >
                      <Checkbox
                        checked={
                          !!selectedBrokers.find(
                            data => data.email === broker.email
                          )
                        }
                        sx={{ color: "text.secondary" }}
                      />
                      <ListItemText primary={broker.email} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              variant="contained"
              sx={{
                width: "100%",
                marginTop: "16px"
              }}
              onClick={handleFilter}
            >
              Apply
            </Button>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
}

export default FilterDrawer;
