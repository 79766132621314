import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Alert,
  Box,
  FormControl,
  Stack
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ROUTES } from "../../routes/constants";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import LoginImage from "components/NonAuthenticateLoginImage";
import { useDispatch } from "react-redux";
import { login } from "reducers/users";
import { getAccessToken } from "utils";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.text.dark.primary)
);

const Login = () => {
  const [isResponsive, setIsResponsive] = useState(false);

  const windowSizeHandler = () => {
    setIsResponsive(window.innerWidth < 991);
  };

  useEffect(() => {
    window.addEventListener("resize", windowSizeHandler);
    windowSizeHandler();

    return () => window.removeEventListener("resize", windowSizeHandler);
  }, []);

  const dispatch = useDispatch();
  const classes = useStyles({ isResponsive });
  const history = useHistory();
  const token = getAccessToken();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [error, setError] = useState(null);

  const onSubmit = data => {
  const loginData = {
    email: data.email.toLowerCase(),
    password: data.password
  }
    dispatch(login(loginData));
  };

  if (token) {
    history.push(ROUTES.LEADS);
  }
  return (
    <Box sx={{ marginBottom: "48px" }}>
      <Grid container spacing={6} sx={{ alignItems: "center" }}>
        <Grid item xs={12} lg={6}>
          <Box
            m={"auto"}
            sx={theme => ({
              maxWidth: 500,
              height: 300,
              padding: "56px",
              borderRadius: 4,
              boxShadow: 3,
              bgcolor: "text.dark.primary",
              [theme.breakpoints.down("xl")]: {
                padding: "28px"
              },
              [theme.breakpoints.down("lg")]: {
                padding: 0,
                boxShadow: 0,
                maxWidth: "100%",
                height: "100%",
                bgcolor: "background.default"
              }
            })}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="h4"
                gutterBottom
                component="h4"
                color="text.primary"
                mb={4}
              >
                Login
              </Typography>
              <Stack spacing={4}>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Email"
                    type="email"
                    error={!!errors.email}
                    placeholder="email"
                    {...register("email", {
                      required: true,
                      maxLength: 255,
                      pattern: /^\S+@\S+$/i
                    })}
                  />
                  {errors.email && (
                    <div className="validation-error">E-mail is not valid!</div>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Password"
                    type="password"
                    placeholder="Password"
                    error={!!errors.password}
                    {...register("password", {
                      required: true
                    })}
                  />
                </FormControl>
              </Stack>
              <Stack
                mt={4}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={theme => ({
                  [theme.breakpoints.down("lg")]: {
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center"
                  }
                })}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  flexWrap="wrap"
                  justifyContent="center"
                  sx={theme => ({
                    [theme.breakpoints.down("lg")]: {
                      marginBottom: "32px",
                      width: "100%"
                    }
                  })}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={theme => ({
                      marginRight: "16px",
                      [theme.breakpoints.down("lg")]: {
                        width: "100%",
                        marginBottom: "24px",
                        marginRight: 0
                      }
                    })}
                  >
                    LOGIN
                  </Button>
                  <Link
                    to={ROUTES.SIGNUP_REQUEST}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                  >
                    <Typography
                      variant="subtitle1"
                      color="primary.main"
                      sx={theme => ({
                        [theme.breakpoints.down("xl")]: {
                          fontSize: "14px"
                        }
                      })}
                    >
                      Sign Up
                    </Typography>
                  </Link>
                </Stack>
                <Link
                  to={ROUTES.FORGOT_PASSWORD}
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="subtitle1"
                    color="primary.main"
                    sx={theme => ({
                      [theme.breakpoints.down("xl")]: {
                        fontSize: "14px"
                      }
                    })}
                  >
                    Forgot your password?
                  </Typography>
                </Link>
              </Stack>
              {error && (
                <Alert severity="error" style={{ marginTop: 20 }}>
                  {error}
                </Alert>
              )}
            </form>
          </Box>
        </Grid>
        <Grid item xs={0} lg={6} display={{ xs: "none", lg: "block" }}>
          <LoginImage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
