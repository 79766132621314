import React, { useState, useEffect } from "react";
import Header from "components/Header";
import { useSelector, useDispatch } from "react-redux";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BuySeatsModal from "./BuySeatsModal";
import BuySeatsCheckoutModal from "./BuySeatsCheckoutModal";
import ProcessingModal from "./ProcessingModal";
import {
  updateActiveTab,
  createBrokers,
  getCompanies,
  updateBuySeatsCheckoutModal,
  updateprocessingModal,
  updatePurchasedModal,
  updateBuySeatsModal,
  getStripeInvoices,
  resetCompanySorting,
  resetInvoiceSorting
} from "reducers/company";
import {
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  Stack,
  Alert
} from "@mui/material";

import { COMPANY } from "utils/constants";
import AddBrokerModal from "./AddBrokerModal";
import BrokersTab from "./BrokersTab";
import InfoTab from "./InfoTab";
import BillingTab from "./BillingTab";
import AddressTab from "./AddressTab";
import PurchasedModal from "./PerchasedModal";
import MembershipTab from "./MembershipTab";

function TabPanel(props) {
  const { children, index } = props;
  const { activeTab } = useSelector(state => state.company);

  return <div>{activeTab === index && <Box> {children} </Box>}</div>;
}

function Company() {
  const dispatch = useDispatch();
  const [isAddUserModal, setIsAddUserModal] = useState(false);

  const {
    activeTab,
    defaultCompanyData,
    isBuySeatsCheckoutModal,
    isProcessingModal,
    isPurchasedModal,
    isBuySeatsModal,
    purchased
  } = useSelector(state => state.company);
  const { data: userData } = useSelector(state => state.user);

  const handleActiveTab = (e, value) => {
    dispatch(updateActiveTab(value));
  };

  const handleAddUserModal = data => {
    dispatch(createBrokers(data));
    setIsAddUserModal(false);
  };

  useEffect(() => {
    if (userData?.me?.company) {
      dispatch(getCompanies(userData.me.company));
    }
  }, [dispatch, userData]);
  useEffect(() => {
    return () => {
      dispatch(resetCompanySorting());
      dispatch(resetInvoiceSorting());
    };
  }, [dispatch]);

  return (
    <>
      <Header componentName={userData?.me?.companyName} />
      <div>
        <Typography
          variant="h4"
          color="text.primary"
          marginTop="16px"
          component="h2"
          display={{ xs: "block", lg: "none" }}
        >
          {userData?.me?.companyName}
        </Typography>
        <div>
          <Stack
            direction="row"
            sx={{
              marginTop: "40px",
              marginBottom: "16px",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <Tabs
              value={activeTab}
              onChange={handleActiveTab}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={theme => ({
                marginBottom: "24px",
                [theme.breakpoints.down("lg")]: {
                  marginBottom: "16px"
                },
                "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  color: `${theme.palette.primary.main} !important`
                }
              })}
            >
              <Tab
                label="BROKERS"
                value={COMPANY.BROKERS}
                sx={theme => ({
                  color: "text.secondary",
                  fontSize: "16px",
                  marginRight: "16px",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: 0,
                    fontSize: "14px"
                  }
                })}
              />
              <Tab
                label="INFO"
                value={COMPANY.INFO}
                sx={theme => ({
                  color: "text.secondary",
                  fontSize: "16px",
                  marginRight: "16px",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: 0,
                    fontSize: "14px"
                  }
                })}
              />
              <Tab
                label="BILLING"
                value={COMPANY.BILLING}
                sx={theme => ({
                  color: "text.secondary",
                  fontSize: "16px",
                  marginRight: "16px",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: 0,
                    fontSize: "14px"
                  }
                })}
              />
              <Tab
                label="ADDRESS"
                value={COMPANY.ADDRESS}
                sx={theme => ({
                  color: "text.secondary",
                  fontSize: "16px",
                  marginRight: "16px",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: 0,
                    fontSize: "14px"
                  }
                })}
              />
              <Tab
                label="MEMBERSHIP"
                value={COMPANY.MEMBERSHIP}
                sx={theme => ({
                  color: "text.secondary",
                  fontSize: "16px",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: 0,
                    fontSize: "14px"
                  }
                })}
              />
            </Tabs>
            {activeTab === COMPANY.BROKERS && (
              <Box
                sx={theme => ({
                  display: "flex",
                  marginBottom: "24px",
                  [theme.breakpoints.down("lg")]: {
                    flexWrap: "wrap",
                    width: "100%"
                  }
                })}
              >
                {defaultCompanyData.planPrice && (
                  <Alert
                    variant="outlined"
                    severity="info"
                    icon={false}
                    sx={theme => ({
                      marginRight: "16px",
                      alignItems: "center",
                      ".MuiAlert-action": {
                        marginRight: 0,
                        padding: 0
                      },
                      [theme.breakpoints.down("sm")]: {
                        marginRight: "8px"
                      },
                      [theme.breakpoints.down("ssm")]: {
                        width: "100%",
                        marginBottom: "16px",
                        marginRight: 0,
                        paddingTop: 0,
                        paddingBottom: 0
                      }
                    })}
                    action={
                      <Typography
                        variant="body2"
                        color="primary"
                        sx={{ cursor: "pointer", marginLeft: "14px" }}
                        onClick={() => {
                          dispatch(updateBuySeatsModal(true));
                        }}
                      >
                        BUY
                      </Typography>
                    }
                  >
                    <Typography variant="body2" color="text.primary">
                      {defaultCompanyData.usedSeatsCount} of{" "}
                      {defaultCompanyData.seatsCount} seats
                    </Typography>
                  </Alert>
                )}
                <Button
                  variant="outlined"
                  sx={theme => ({
                    [theme.breakpoints.down("ssm")]: {
                      width: "100%"
                    }
                  })}
                  onClick={() => setIsAddUserModal(true)}
                  startIcon={<PersonAddIcon />}
                >
                  ADD BROKER
                </Button>
              </Box>
            )}
          </Stack>
          <TabPanel index={COMPANY.BROKERS}>
            <BrokersTab />
          </TabPanel>
          <TabPanel index={COMPANY.INFO}>
            <InfoTab />
          </TabPanel>
          <TabPanel index={COMPANY.BILLING}>
            <BillingTab />
          </TabPanel>
          <TabPanel index={COMPANY.ADDRESS}>
            <AddressTab />
          </TabPanel>
          <TabPanel index={COMPANY.MEMBERSHIP}>
            <MembershipTab />
          </TabPanel>
        </div>
      </div>
      {isAddUserModal && (
        <Box>
          <AddBrokerModal
            isOpen={isAddUserModal}
            handleAddUserModal={handleAddUserModal}
            handleClose={() => setIsAddUserModal(false)}
          />
        </Box>
      )}
      {isBuySeatsModal && (
        <Box>
          <BuySeatsModal
            isOpen={isBuySeatsModal}
            handleClose={() => dispatch(updateBuySeatsModal(false))}
          />
        </Box>
      )}
      {isBuySeatsCheckoutModal && (
        <Box>
          <BuySeatsCheckoutModal
            isOpen={isBuySeatsCheckoutModal}
            handleClose={() => dispatch(updateBuySeatsCheckoutModal(false))}
          />
        </Box>
      )}
      {isProcessingModal && (
        <Box>
          <ProcessingModal
            isOpen={isProcessingModal}
            handleClose={() => dispatch(updateprocessingModal(false))}
          />
        </Box>
      )}
      {isPurchasedModal && (
        <Box>
          <PurchasedModal
            isOpen={isPurchasedModal}
            handleClose={() => {
              dispatch(updatePurchasedModal(false));
              dispatch(getStripeInvoices());
            }}
            purchased={purchased}
          />
        </Box>
      )}
    </>
  );
}

export default Company;
