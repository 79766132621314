export const textFieldStyle = (theme, bgColorTextField) => {
  return {
    textField: {
      color: theme?.palette?.text.primary,
      "& textarea": {
        color: theme?.palette?.text.primary
      },
      "& label": {
        color: theme?.palette?.text.primary
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: theme.palette.textFieldPalette.main
        },
        "&:hover fieldset": {
          borderColor: theme?.palette?.primary.main
        }
      },
      "& input": {
        color: theme?.palette?.text.primary,
        "&:-webkit-autofill": {
          "-webkit-box-shadow": ({ isResponsive }) =>
            isResponsive
              ? `0 0 0 100px ${theme.palette.background.default} inset`
              : `0 0 0 100px ${bgColorTextField} inset`,
          "-webkit-text-fill-color": theme?.palette?.text.primary
        },
        "&[type=number]": {
          "-moz-appearance": "textfield"
        },
        "&::-webkit-outer-spin-button": {
          "-webkit-appearance": "none",
          margin: 0
        },
        "&::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: 0
        }
      },
      "& .MuiOutlinedInput-root .MuiInputBase-root": {
        color: theme?.palette?.text.primary
      },
      "& .MuiTypography-root": {
        color: theme?.palette?.text.primary
      },
      "& .MuiSvgIcon-root": {
        color: theme?.palette?.text.secondary
      },
      "& .MuiInput-root.MuiInput-underline": {
        "&::before": {
          borderBottom: `1px solid ${theme?.palette?.text.primary}`
        }
      },
      "& .MuiSelect-select.MuiSelect-outlined": {
        color: theme?.palette?.text.primary
      },
      "& .Mui-disabled.MuiInputBase-formControl": {
        border: `1px dashed ${theme.palette.active.selected}`,
        "& .Mui-disabled.MuiOutlinedInput-input": {
          "-webkitTextFillColor": theme.palette.text.disabled
        },
        "& svg": {
          color: theme?.palette?.text.primary
        }
      },
      "& label.Mui-disabled": {
        color: theme.palette.text.disabled
      }
    }
  };
};
