import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "routes/constants";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Stack,
  Box
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useParams, Link } from "react-router-dom";
import { updateCase } from "reducers/cases";
import Header from "components/Header";
import { getCase } from "reducers/cases";
import SelectField from "components/SelectField";
import BackBtn from "./BackBtn";
import { FINANCE_TYPE, LEAD_TYPES, PROPERTY_TYPE_FILTER_DROPDOWN } from "utils/constants";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);
const formStyle = theme => {
  return {
    width: "450px",
    marginBottom: "48px",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginTop: "24px"
    }
  };
};

function EditCase() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.cases);
  const [caseDetail, setCaseDetail] = useState({});
  const [leadId, setLeadId] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [financeId, setFinanceId] = useState("");
  const { data: user } = useSelector(state => state.user);

  useEffect(() => {
    dispatch(getCase(id));
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    setCaseDetail({
      ...caseDetail,
      firstName: data?.lead?.client?.firstName,
      lastName: data.lead?.client?.lastName,
      email: data.lead?.client?.email,
      address: data.lead?.client?.address?.address,
      postCode: data.lead?.client?.address?.postCode,
      mobilePhone: data.lead?.client?.mobilePhone,
      age: data.lead?.client?.age,
      propertyAmount: data.lead?.propertyAmount,
      mortgageAmount: data.lead?.mortgageAmount,
      message: data.lead?.message
    });
    reset({
      firstName: data?.lead?.client?.firstName,
      lastName: data.lead?.client?.lastName,
      email: data.lead?.client?.email,
      address: data.lead?.client?.address?.address,
      postCode: data.lead?.client?.address?.postCode,
      mobilePhone: data.lead?.client?.mobilePhone,
      age: data.lead?.client?.age,
      propertyAmount: data.lead?.propertyAmount,
      mortgageAmount: data.lead?.mortgageAmount,
      message: data.lead?.message
    });

    switch (data?.lead?.type) {
      case LEAD_TYPES[0].value:
        setLeadId(LEAD_TYPES[0].id);
        break;
      case LEAD_TYPES[1].value:
        setLeadId(LEAD_TYPES[1].id);
        break;
      case LEAD_TYPES[2].value:
        setLeadId(LEAD_TYPES[2].id);
        break;
      default:
        setLeadId("");
        break;
    }

    switch (data?.lead?.propertyType) {
      case PROPERTY_TYPE_FILTER_DROPDOWN[0].value:
        setPropertyId(PROPERTY_TYPE_FILTER_DROPDOWN[0].id);
        break;
      case PROPERTY_TYPE_FILTER_DROPDOWN[1].value:
        setPropertyId(PROPERTY_TYPE_FILTER_DROPDOWN[1].id);
        break;
      case PROPERTY_TYPE_FILTER_DROPDOWN[2].value:
        setPropertyId(PROPERTY_TYPE_FILTER_DROPDOWN[2].id);
        break;
      default:
        setPropertyId("");
        break;
    }

    switch (data?.lead?.financing) {
      case FINANCE_TYPE[0].value:
        setFinanceId(FINANCE_TYPE[0].id);
        break;
      case FINANCE_TYPE[1].value:
        setFinanceId(FINANCE_TYPE[1].id);
        break;
      default:
        setFinanceId("");
        break;
    }

  }, [data]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = value => {
    let leadType = LEAD_TYPES.find(item => item.id === leadId);
    let propertyType = PROPERTY_TYPE_FILTER_DROPDOWN.find(item => item.id === propertyId);
    let finance = FINANCE_TYPE.find(item => item.id === financeId);
    let caseData = {
      id,
      data: {
        client: {
          firstName: value.firstName,
          lastName: value.lastName,
          email: value.email,
          mobilePhone: value.mobilePhone,
          age: value.age || null
        },
        address: {
          postCode: value.postCode,
          address: value.address
        },
        lead: {
          propertyAmount: value.propertyAmount || null,
          mortgageAmount: value.mortgageAmount || null,
          message: value.message,
          type: leadType.value,
          propertyType: propertyType.value,
          financing: finance.value
        }
      }
    };
    dispatch(updateCase(caseData));
  };

  const handleChange = (key, value) => {
    setCaseDetail({ ...caseDetail, [key]: value });
  };

  const handleLeadType = e => {
    setLeadId(e.target.value);
  };

  const handlePropertyType = e => {
    setPropertyId(e.target.value);
  };

  const handleFinance = e => {
    setFinanceId(e.target.value);
  };

  return (
    <>
      <Header
        componentName={`Edit Case #${id}`}
        backBtn={true}
        routePage={`/cases/${id}`}
      />
      <Box sx={theme => formStyle(theme)}>
        <Stack display={{ xs: "block", lg: "none" }} marginBottom="24px">
          <Stack direction="row" alignItems="center">
            <BackBtn routePage={`/cases/${id}`} />
            <Typography variant="h3" color="text.primary" fontSize="38px">
              {`Edit Case #${id}`}
            </Typography>
          </Stack>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            variant="h4"
            color="text.primary"
            gutterBottom
            marginTop="26px"
            sx={theme => ({
              [theme.breakpoints.down("lg")]: {
                fontSize: "28px"
              }
            })}
          >
            Client
          </Typography>
          <Stack spacing={3}>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label={"First name *"}
                type="text"
                placeholder="First name"
                autoComplete="off"
                error={!!errors.firstName}
                {...register("firstName", {
                  required: true,
                  onChange: e => handleChange("firstName", e.target.value)
                })}
                InputLabelProps={{
                  shrink: !!caseDetail.firstName
                }}
              />
              {errors.firstName && (
                <div className="validation-error">First Name required!</div>
              )}
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Last name"
                type="text"
                placeholder="Last name"
                autoComplete="off"
                {...register("lastName", {
                  required: false,
                  onChange: e => handleChange("lastName", e.target.value)
                })}
                InputLabelProps={{ shrink: !!caseDetail.lastName }}
              />
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Email *"
                type="email"
                placeholder="Email"
                autoComplete="off"
                error={!!errors.email}
                {...register("email", {
                  required: true,
                  onChange: e => handleChange("email", e.target.value)
                })}
                InputLabelProps={{ shrink: !!caseDetail.email }}
              />
              {errors.email && (
                <div className="validation-error">email required!</div>
              )}
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Address"
                type="text"
                placeholder="Address"
                autoComplete="off"
                {...register("address", {
                  required: false,
                  onChange: e => handleChange("address", e.target.value)
                })}
                InputLabelProps={{ shrink: !!caseDetail.address }}
              />
            </FormControl>
            <FormControl
              sx={theme => ({
                width: "160px",
                [theme.breakpoints.down("lg")]: {
                  width: "300px"
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              })}
            >
              <TextField
                className={classes.textField}
                fullWidth
                label="Post code"
                type="text"
                placeholder="Post code"
                autoComplete="off"
                {...register("postCode", {
                  required: false,
                  onChange: e => handleChange("postCode", e.target.value)
                })}
                InputLabelProps={{ shrink: !!caseDetail.postCode }}
              />
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Mobile *"
                type="tel"
                placeholder="Mobile"
                autoComplete="off"
                error={!!errors.mobilePhone}
                {...register("mobilePhone", {
                  required: true,
                  minLength: 4,
                  onChange: e => handleChange("mobilePhone", e.target.value)
                })}
                InputLabelProps={{
                  shrink: !!caseDetail.mobilePhone
                }}
              />
              {errors.mobilePhone && (
                <div className="validation-error">mobile Phone required!</div>
              )}
            </FormControl>
            <FormControl
              sx={theme => ({
                width: "160px",
                [theme.breakpoints.down("lg")]: {
                  width: "300px"
                },
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              })}
            >
              <TextField
                className={classes.textField}
                fullWidth
                label="Age"
                type="number"
                placeholder="Age"
                autoComplete="off"
                {...register("age", {
                  required: false,
                  onChange: e => handleChange("age", e.target.value)
                })}
                InputLabelProps={{ shrink: !!caseDetail.age }}
              />
            </FormControl>
          </Stack>
          <Typography
            variant="h4"
            color="text.primary"
            gutterBottom
            marginTop="26px"
          >
            Lead
          </Typography>
          <Stack spacing={3}>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Property value *"
                type="number"
                placeholder="Property value"
                autoComplete="off"
                error={!!errors.propertyAmount}
                {...register("propertyAmount", {
                  required: true,
                  onChange: e => handleChange("propertyAmount", e.target.value)
                })}
                InputLabelProps={{
                  shrink: !!caseDetail.propertyAmount
                }}
              />
              {errors.propertyAmount && (
                <div className="validation-error">Property value required!</div>
              )}
            </FormControl>
            <FormControl>
              <SelectField
                sx={theme => ({
                  width: 450,
                  [theme.breakpoints.down("lg")]: {
                    width: "100%"
                  }
                })}
                label="Property Type"
                value={propertyId}
                onChange={handlePropertyType}
                options={PROPERTY_TYPE_FILTER_DROPDOWN.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </FormControl>
            <FormControl>
              <SelectField
                sx={theme => ({
                  width: 450,
                  [theme.breakpoints.down("lg")]: {
                    width: "100%"
                  }
                })}
                label="Financing"
                value={financeId}
                onChange={handleFinance}
                options={FINANCE_TYPE.map(({ id, name }) => ({
                  value: id,
                  label: name
                }))}
              />
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Mortgage value *"
                type="number"
                placeholder="Mortgage value"
                autoComplete="off"
                error={!!errors.mortgageAmount}
                {...register("mortgageAmount", {
                  required: true,
                  onChange: e => handleChange("mortgageAmount", e.target.value)
                })}
                InputLabelProps={{
                  shrink: !!caseDetail.mortgageAmount
                }}
              />
              {errors.mortgageAmount && (
                <div className="validation-error">Mortgage value required!</div>
              )}
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Notes"
                type="text"
                placeholder="Notes"
                autoComplete="off"
                {...register("message", {
                  required: false,
                  onChange: e => handleChange("message", e.target.value)
                })}
                InputLabelProps={{ shrink: !!caseDetail.message }}
                multiline
                rows={6}
              />
            </FormControl>
            <Stack sx={{ margin: "26px 0" }}>
              <Typography variant="h4" color="text.primary" gutterBottom>
                Additional Info
              </Typography>
              <FormControl>
                <SelectField
                  sx={theme => ({
                    width: 450,
                    [theme.breakpoints.down("lg")]: {
                      width: "100%"
                    }
                  })}
                  label="Lead Type"
                  value={leadId}
                  onChange={handleLeadType}
                  options={LEAD_TYPES.map(({ id, name }) => ({
                    value: id,
                    label:
                      id === 3
                        ? name.replace("Other", `${user.me?.otherLeadsName}`)
                        : name
                  }))}
                />
              </FormControl>
            </Stack>
            <Stack spacing={4} direction="row">
              <Button type="submit" variant="contained">
                UPDATE
              </Button>
              <Link
                to={`${ROUTES.CASES}/${id}`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="text" size="large">
                  CANCEL
                </Button>
              </Link>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  );
}

export default EditCase;
