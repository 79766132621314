import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Stack,
  InputAdornment,
  Grid,
  Box,
  List,
  ListItem,
  Chip
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useParams, Link } from "react-router-dom";
import SelectField from "components/SelectField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Header from "components/Header";
import AlertComponent from "components/AlertComponent";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BackBtn from "./BackBtn";
import {
  getCaseProduct,
  getProviders,
  updateCaseProduct,
  getLenders,
  getProducts,
  updateCaseDetailActiveTab,
  getCase
} from "reducers/cases";
import {
  MORTGAGE,
  INSURANCE,
  INSURANCE_KEY,
  MORTGAGE_KEY,
  PROVIDER,
  OTHER,
  LENDER,
  PRODUCT_TYPE_KEY,
  PRODUCT_TYPE_TEXT,
  PROVIDER_TEXT,
  LENDER_TEXT,
  BUILDINGS_AND_CONTENTS,
  SOLICITOR_REFERRALS,
  BUILDINGS_AND_CONTENTS_VALUE,
  SOLICITOR_REFERRALS_VALUE,
  OTHER_VALUE,
  PRODUCT,
  LIFE_AND_CRITICAL_ILLNESS,
  LIFE_AND_CRITICAL_ILLNESS_TEXT
} from "utils/constants";
import {
  expiryDueDate,
  ltvConverter,
  moneyFieldConverterTBC,
  handleRestrictAlphabets
} from "utils";
import { dateStyle } from "utils/DatePickerStyle";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const PRODUCT_TYPE = [
  {
    id: 1,
    productType: "Insurance"
  },
  {
    id: 2,
    productType: "Mortgage"
  }
];

const CHIP_STYLE = (theme, isExpired) => {
  return {
    color: isExpired
      ? theme.palette.error.contrastText
      : theme.palette.text.primary,
    backgroundColor: isExpired
      ? theme.palette.error.main
      : theme.palette.active.selected,
    fontSize: "13px",
    fontWeight: "700",
    fontFamily: "'Nunito Sans', sans-serif",
    height: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      "& .MuiChip-label": {
        paddingLeft: "8px",
        paddingRight: "8px"
      }
    }
  };
};

function EditProduct() {
  const classes = useStyles();
  const { caseId, productId } = useParams();
  const dispatch = useDispatch();
  const { providers, lenders, products, caseProduct, data } = useSelector(
    state => state.cases
  );
  const { theme } = useSelector(state => state.user);

  const [providerData, setProviderData] = useState([]);
  const [lenderProducts, setLenderProducts] = useState([]);
  const [lenderData, setLenderData] = useState([]);
  const [isInsuranceProvider, setIsInsuranceProvider] = useState(false);
  const [isInsuranceValue1, setIsInsuranceValue1] = useState(false);
  const [isInsuranceValue2, setIsInsuranceValue2] = useState(false);

  const [isLender, setIsLender] = useState(false);
  const [date, setDate] = useState(new Date(caseProduct?.expiresAt));

  const [isProductTypeChanged, setIsProductTypeChanged] = useState(false);
  const [isProductChanged, setIsProductChanged] = useState(false);
  const [isInsuranceValue1Select, setIsInsuranceValue1Select] = useState(false);
  const [isInsuranceValue2Select, setIsInsuranceValue2Select] = useState(false);

  let defaultProductType = PRODUCT_TYPE.find(
    item => item.productType === caseProduct?.type
  );

  const [productData, setProductData] = useState({});
  const [formData, setFormData] = useState({
    applicationFee: caseProduct?.applicationFee,
    isApplicationFeePaid: caseProduct?.isApplicationFeePaid,
    offerCompletionFee: caseProduct?.offerCompletionFee,
    procFee: caseProduct?.procFee,
    expiresAt: caseProduct?.expiresAt,
    note: caseProduct?.note
  });
  const [isNote, setIsNote] = useState(false);

  useEffect(() => {
    setIsNote(!!caseProduct?.note);
    setProductData({
      ...productData,
      productType: defaultProductType?.id,
      provider: caseProduct?.provider?.id,
      product: caseProduct?.product?.id,
      lender: caseProduct?.lender?.id,
      isApplicationFeePaid: caseProduct?.isApplicationFeePaid,
      isOfferCompletionFeePaid: caseProduct?.isOfferCompletionFeePaid,
      isProcFeePaid: caseProduct?.isProcFeePaid,
      insuranceValue: caseProduct?.insuranceValue1,
      insuranceValue2: caseProduct?.insuranceValue2
    });
    reset({
      applicationFee: caseProduct?.applicationFee,
      isApplicationFeePaid: caseProduct?.isApplicationFeePaid,
      offerCompletionFee: caseProduct?.offerCompletionFee,
      procFee: caseProduct?.procFee,
      expiresAt: caseProduct?.expiresAt,
      note: caseProduct?.note,
      insuranceValue: caseProduct?.insuranceValue1,
      insuranceValue2: caseProduct?.insuranceValue2
    });
    setDate(caseProduct?.expiresAt);
    if (caseProduct.insuranceValue1) {
      setIsInsuranceValue1(true);
      if (caseProduct.insuranceValue2) {
        setIsInsuranceValue2(true);
      } else {
        setIsInsuranceValue2(false);
      }
    } else {
      setIsInsuranceValue1(false);
      setIsInsuranceValue2(false);
    }
  }, [caseProduct]);

  const [error, setError] = useState({
    productType: false,
    provider: false,
    product: false,
    lender: false
  });

  useEffect(() => {
    dispatch(getCaseProduct(productId));
    dispatch(getProviders());
    dispatch(getLenders());
    dispatch(getCase(caseId));
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (isProductTypeChanged) {
      setIsProductTypeChanged(!isProductTypeChanged);
      setProductData({
        ...productData,
        product: "",
        lender: "",
        provider: "",
        insuranceValue: "",
        insuranceValue2: ""
      });
      reset({
        insuranceValue: "",
        insuranceValue2: ""
      });
    }
  }, [isProductTypeChanged]);

  useEffect(() => {
    if (isProductChanged) {
      setIsProductChanged(!isProductChanged);
      setProductData({
        ...productData,
        insuranceValue: "",
        insuranceValue2: ""
      });
      reset({
        insuranceValue: "",
        insuranceValue2: ""
      });
    }
  }, [isProductChanged]);

  useEffect(() => {
    if (productData?.productType === INSURANCE_KEY) {
      dispatch(getProducts(INSURANCE.toUpperCase()));
    } else if (productData?.productType === MORTGAGE_KEY) {
      dispatch(getProducts(MORTGAGE.toUpperCase()));
    }
  }, [dispatch, productData.productType]);

  useEffect(() => {
    if (providers.length > 0) {
      setProviderData([...providers, { name: OTHER, id: OTHER_VALUE }]);
    }
    if (lenders.length > 0) {
      setLenderData([
        ...lenders,
        { name: BUILDINGS_AND_CONTENTS, id: BUILDINGS_AND_CONTENTS_VALUE },
        { name: SOLICITOR_REFERRALS, id: SOLICITOR_REFERRALS_VALUE },
        { name: OTHER, id: OTHER_VALUE }
      ]);
    }
  }, [providers, lenders]);

  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      newInsuranceProvider: "",
      insuranceValue: "",
      insuranceValue2: "",
      newLender: "",
      applicationFee: formData.applicationFee,
      offerCompletionFee: formData.offerCompletionFee,
      procFee: formData.procFee,
      isApplicationFeePaid: formData.isApplicationFeePaid,
      note: formData.note
    }
  });

  const watchNewInsuranceProvider = watch("newInsuranceProvider");
  const watchInsuranceField = watch("insuranceValue");
  const watchInsuranceField2 = watch("insuranceValue2");
  const watchNewLender = watch("newLender");
  const watchapplicationFee = watch("applicationFee");
  const watchOfferCompletionFee = watch("offerCompletionFee");
  const watchProcFee = watch("procFee");

  let clearedValue =
    productData.isApplicationFeePaid * watchapplicationFee +
    productData.isOfferCompletionFeePaid * watchOfferCompletionFee +
    productData.isProcFeePaid * watchProcFee;

  let pendingValue =
    !productData.isApplicationFeePaid * watchapplicationFee +
    !productData.isOfferCompletionFeePaid * watchOfferCompletionFee +
    !productData.isProcFeePaid * watchProcFee;

  const onError = (error, productItem) => {
    let arr = ["productType", "product"];
    if (productItem?.productType === INSURANCE) {
      arr.push("provider");
    }
    if (productItem?.productType === MORTGAGE) {
      arr.push("lender");
    }
    let obj = {};
    for (let item of arr) {
      if ([undefined, null, ""].includes(productData[item])) {
        obj[item] = true;
      }
    }
    setError(obj);
    return obj;
  };

  let productTypeValue = PRODUCT_TYPE.find(
    item => item.id === productData?.productType
  );
  let providerValue = providerData.find(
    item => item.id === productData?.provider
  );
  let productValue = products?.find(item => item.id === productData?.product);
  let lenderValue = lenderData.find(item => item.id === productData?.lender);

  const onSubmit = value => {
    let editProductData = {
      id: productId,
      data: {
        productType: productTypeValue?.productType?.toUpperCase(),
        provider:
          providerValue?.name === OTHER
            ? value?.newInsuranceProvider
            : providerValue?.name,
        product: productValue?.id,
        lender: [OTHER, BUILDINGS_AND_CONTENTS, SOLICITOR_REFERRALS].includes(
          lenderValue?.name
        )
          ? value.newLender
          : lenderValue?.name,
        insuranceValue1: value.insuranceValue,
        insuranceValue2: value.insuranceValue2,
        applicationFee: value.applicationFee,
        offerCompletionFee: value.offerCompletionFee,
        procFee: value.procFee,
        isOfferCompletionFeePaid: productData.isOfferCompletionFeePaid,
        isProcFeePaid: productData.isProcFeePaid,
        isApplicationFeePaid: productData.isApplicationFeePaid,
        expiresAt: moment(date).format(moment.HTML5_FMT.DATE),
        note: value.note
      }
    };

    let errorObj = onError(null, productTypeValue);

    errorObj = Object.values(errorObj).some(item => item === true);
    if (errorObj) return;

    if (editProductData?.data?.productType === INSURANCE.toUpperCase()) {
      delete editProductData.data["lender"];
    }
    if (editProductData?.data?.productType === MORTGAGE.toUpperCase()) {
      delete editProductData.data["provider"];
      delete editProductData.data["insuranceValue1"];
      delete editProductData.data["insuranceValue2"];
    }

    dispatch(updateCaseProduct(editProductData));
  };

  const handleChange = (key, value) => {
    setProductData({ ...productData, [key]: value });

    let err = { ...error };
    delete err[key];
    setError(err);

    if (key === PRODUCT_TYPE_KEY) {
      setIsProductTypeChanged(!isProductTypeChanged);
      if (value === MORTGAGE_KEY) {
        setIsInsuranceValue1(false);
        setIsInsuranceValue2(false);
      }
    }

    if (key === PROVIDER) {
      const insuranceProducts = providerData.find(item => item.id === value);
      if (insuranceProducts.name === OTHER) {
        setIsInsuranceProvider(true);
      } else {
        setIsInsuranceProvider(false);
      }
    }

    if (key === LENDER) {
      const lenderItem = lenderData.find(item => item.id === value);
      if (
        [OTHER, BUILDINGS_AND_CONTENTS, SOLICITOR_REFERRALS].includes(
          lenderItem.name
        )
      ) {
        setIsLender(true);
      } else {
        setIsLender(false);
      }
    }
    if (key === PRODUCT && productTypeValue.productType === INSURANCE) {
      setIsProductChanged(true);
      setIsInsuranceValue1(true);

      if (value === LIFE_AND_CRITICAL_ILLNESS) {
        setIsInsuranceValue2(true);
      } else {
        setIsInsuranceValue2(false);
      }
    }
  };

  const handleCancelProduct = () => {
    dispatch(updateCaseDetailActiveTab("products"));
  };

  useEffect(() => {
    if (
      [BUILDINGS_AND_CONTENTS_VALUE, SOLICITOR_REFERRALS_VALUE].includes(
        productData.lender
      )
    ) {
      setLenderProducts(
        products.filter(product =>
          [BUILDINGS_AND_CONTENTS, SOLICITOR_REFERRALS].includes(product.name)
        )
      );
    } else {
      setLenderProducts(products);
    }
  }, [productData.lender, productData.provider, products]);

  return (
    <>
      <Header
        componentName={`Edit Product #${productId}`}
        backBtn={true}
        routePage={`/cases/${caseId}`}
      />
      <div style={{ marginTop: "24px" }}>
        <Stack display={{ xs: "block", lg: "none" }} marginBottom="24px">
          <Stack direction="row" alignItems="center">
            <BackBtn routePage={`/cases/${caseId}`} fontSize="18px" />
            <Typography variant="h3" color="text.primary" fontSize="26px">
              {`Edit Product #${productId}`}
            </Typography>
          </Stack>
        </Stack>
        <Grid container sx={{ margin: "34px 0" }}>
          <Grid item xs={12} md={7} lg={12} xl={7}>
            <form
              onSubmit={handleSubmit(onSubmit, () =>
                onError(null, productData)
              )}
              style={{ maxWidth: "550px", marginRight: "32px" }}
            >
              <Stack spacing={3}>
                <FormControl>
                  <SelectField
                    fullWidth
                    label="Product Type"
                    defaultValue={""}
                    value={+productData.productType || ""}
                    error={error.productType}
                    onChange={e => handleChange("productType", e.target.value)}
                    options={PRODUCT_TYPE?.map(({ id, productType }) => ({
                      value: id,
                      label: productType
                    }))}
                  />
                </FormControl>

                {productData.productType === INSURANCE_KEY && (
                  <>
                    <FormControl>
                      <SelectField
                        fullWidth
                        label="Provider"
                        defaultValue={""}
                        value={productData.provider || ""}
                        error={error.provider}
                        onChange={e => handleChange("provider", e.target.value)}
                        options={providerData?.map(({ id, name }) => ({
                          value: id,
                          label: name
                        }))}
                      />
                    </FormControl>
                    {isInsuranceProvider && (
                      <>
                        <FormControl>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            label="New Insurance Provider"
                            type="text"
                            placeholder="New Insurance Provider"
                            autoComplete="off"
                            onKeyDown={handleRestrictAlphabets}
                            error={!!errors.newInsuranceProvider}
                            {...register("newInsuranceProvider", {
                              required: true
                            })}
                          />
                        </FormControl>
                        <AlertComponent productType="insurance provider" />
                      </>
                    )}
                  </>
                )}

                {productData.productType === MORTGAGE_KEY && (
                  <>
                    <FormControl>
                      <SelectField
                        fullWidth
                        label="Lender"
                        defaultValue={""}
                        value={productData.lender || ""}
                        error={error.lender}
                        onChange={e => handleChange("lender", e.target.value)}
                        options={lenderData?.map(({ id, name }) => ({
                          value: id,
                          label: name
                        }))}
                      />
                    </FormControl>
                    {isLender && (
                      <>
                        <FormControl>
                          <TextField
                            className={classes.textField}
                            fullWidth
                            label="New Lender"
                            type="text"
                            placeholder="New Lender"
                            autoComplete="off"
                            onKeyDown={handleRestrictAlphabets}
                            error={!!errors.newLender}
                            {...register("newLender", {
                              required: true
                            })}
                          />
                        </FormControl>
                        <AlertComponent productType="mortgage lender" />
                      </>
                    )}
                  </>
                )}
                <FormControl>
                  <SelectField
                    fullWidth
                    label="Product"
                    defaultValue={""}
                    value={+productData.product || ""}
                    error={error.product}
                    onChange={e => handleChange("product", e.target.value)}
                    options={lenderProducts?.map(({ id, name }) => ({
                      value: id,
                      label: name
                    }))}
                  />
                </FormControl>
                {caseProduct.type !== MORTGAGE && isInsuranceValue1 && (
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label={
                        productData.product === LIFE_AND_CRITICAL_ILLNESS
                          ? "Life"
                          : productValue?.name
                      }
                      type="text"
                      placeholder={
                        productData.product === LIFE_AND_CRITICAL_ILLNESS
                          ? "Life"
                          : productValue?.name
                      }
                      autoComplete="off"
                      onClick={() => setIsInsuranceValue1Select(true)}
                      {...register("insuranceValue", {
                        onChange: e =>
                          handleChange("insuranceValue", e.target.value),
                        onBlur: () => setIsInsuranceValue1Select(false)
                      })}
                      InputLabelProps={{
                        shrink: !!(
                          productData.insuranceValue || isInsuranceValue1Select
                        )
                      }}
                    />
                  </FormControl>
                )}
                {caseProduct.type !== MORTGAGE && isInsuranceValue2 && (
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Critical illness"
                      type="text"
                      placeholder="Critical illness"
                      autoComplete="off"
                      onClick={() => setIsInsuranceValue2Select(true)}
                      {...register("insuranceValue2", {
                        onChange: e =>
                          handleChange("insuranceValue2", e.target.value),
                        onBlur: () => setIsInsuranceValue2Select(false)
                      })}
                      InputLabelProps={{
                        shrink: !!(
                          productData.insuranceValue2 || isInsuranceValue2Select
                        )
                      }}
                    />
                  </FormControl>
                )}
              </Stack>
              <Typography
                variant="h4"
                color="text.primary"
                gutterBottom
                marginTop="26px"
                sx={theme => ({
                  [theme.breakpoints.down("sm")]: {
                    margin: "16px 0",
                    fontSize: "26px"
                  }
                })}
              >
                Fees
              </Typography>
              <Stack spacing={3}>
                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    className={classes.textField}
                    style={{ width: "300px" }}
                    placeholder="Application fee"
                    label="Application fee"
                    type="number"
                    autoComplete="off"
                    InputProps={{
                      inputProps: { step: "any" },
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                    error={!!errors.applicationFee}
                    {...register("applicationFee", {
                      required: true
                    })}
                  />
                  <FormGroup style={{ marginLeft: "24px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productData.isApplicationFeePaid || false}
                          onChange={e =>
                            handleChange(
                              "isApplicationFeePaid",
                              e.target.checked
                            )
                          }
                          sx={theme => ({
                            color: theme.palette.text.secondary
                          })}
                        />
                      }
                      sx={theme => ({ color: theme.palette.text.primary })}
                      label="Paid"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    className={classes.textField}
                    style={{ width: "300px" }}
                    label="Offer/Completion fee"
                    type="number"
                    placeholder="Offer/Completion fee"
                    autoComplete="off"
                    InputProps={{
                      inputProps: { step: "any" },
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                    error={!!errors.offerCompletionFee}
                    {...register("offerCompletionFee", {
                      required: true
                    })}
                  />
                  <FormGroup style={{ marginLeft: "24px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            productData.isOfferCompletionFeePaid || false
                          }
                          onChange={e =>
                            handleChange(
                              "isOfferCompletionFeePaid",
                              e.target.checked
                            )
                          }
                          sx={theme => ({
                            color: theme.palette.text.secondary
                          })}
                        />
                      }
                      sx={theme => ({ color: theme.palette.text.primary })}
                      label="Paid"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <TextField
                    className={classes.textField}
                    style={{ width: "300px" }}
                    label="Proc fee"
                    type="number"
                    placeholder="Proc fee"
                    autoComplete="off"
                    InputProps={{
                      inputProps: { step: "any" },
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                    error={!!errors.procFee}
                    {...register("procFee", {
                      required: true
                    })}
                  />
                  <FormGroup style={{ marginLeft: "24px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productData.isProcFeePaid || false}
                          onChange={e =>
                            handleChange("isProcFeePaid", e.target.checked)
                          }
                          sx={theme => ({
                            color: theme.palette.text.secondary
                          })}
                        />
                      }
                      sx={theme => ({ color: theme.palette.text.primary })}
                      label="Paid"
                    />
                  </FormGroup>
                </FormControl>
                <FormControl style={{ maxWidth: "300px" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Expiry"
                      value={date}
                      onChange={setDate}
                      renderInput={params => (
                        <TextField {...params} className={classes.textField} />
                      )}
                      PopperProps={{
                        sx: dateStyle(theme)
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Notes"
                    type="text"
                    placeholder="Notes"
                    autoComplete="off"
                    multiline
                    rows={6}
                    error={!!errors.note}
                    {...register("note", {
                      required: false,
                      onChange: e => setIsNote(e.target.value)
                    })}
                    InputLabelProps={{ shrink: isNote }}
                  />
                </FormControl>
                <Stack spacing={4} direction="row">
                  <Button type="submit" size="large" variant="contained">
                    SAVE
                  </Button>
                  <Link
                    to={`/cases/${caseId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="text"
                      onClick={handleCancelProduct}
                      size="large"
                    >
                      CANCEL
                    </Button>
                  </Link>
                </Stack>
              </Stack>
            </form>
          </Grid>
          <Grid item xs={12} md={5} lg={12} xl={5}>
            <Box
              sx={theme => ({
                bgcolor: "text.dark.primary",
                padding: "24px",
                maxWidth: "430px",
                borderRadius: "16px",
                marginLeft: "auto",
                [theme.breakpoints.down("xl")]: {
                  margin: "unset",
                  marginTop: "36px"
                },
                [theme.breakpoints.down("lg")]: {
                  marginTop: 0
                },
                [theme.breakpoints.down("md")]: {
                  padding: 0,
                  margin: "36px 0 ",
                  maxWidth: "550px"
                }
              })}
            >
              <Box
                sx={theme => ({
                  [theme.breakpoints.down("md")]: {
                    padding: "24px"
                  }
                })}
              >
                <Typography variant="h6" color="text.primary" gutterBottom>
                  Lead
                </Typography>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      Property
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="body1" color="text.primary">
                      {moneyFieldConverterTBC(data?.lead?.propertyAmount)}
                    </Typography>
                  </ListItem>
                </List>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      Mortgage
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="body1" color="text.primary">
                      {moneyFieldConverterTBC(data?.lead?.mortgageAmount)}
                    </Typography>
                  </ListItem>
                </List>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      LTV
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="body1" color="text.primary">
                      {ltvConverter(data?.lead?.ltv)}
                    </Typography>
                  </ListItem>
                </List>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  {productTypeValue?.productType
                    ? productTypeValue?.productType
                    : PRODUCT_TYPE_TEXT}
                </Typography>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <CalendarTodayIcon
                      sx={{ color: "text.secondary" }}
                      style={{ marginRight: "10px", fontSize: "20px" }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      {moment(date).format("ddd D MMM YYYY")}
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Chip
                      label={expiryDueDate(date)}
                      sx={theme => CHIP_STYLE(theme, caseProduct?.isExpired)}
                    />
                  </ListItem>
                </List>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      {productTypeValue?.productType
                        ? productTypeValue?.productType === INSURANCE
                          ? PROVIDER_TEXT
                          : LENDER_TEXT
                        : "Providers/Lender"}
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    {productTypeValue?.productType === INSURANCE ? (
                      <Typography variant="body1" color="text.primary">
                        {providerValue?.name === OTHER
                          ? watchNewInsuranceProvider
                            ? watchNewInsuranceProvider
                            : "N/A"
                          : providerValue?.name}
                      </Typography>
                    ) : (
                      <Typography variant="body1" color="text.primary">
                        {[
                          OTHER,
                          BUILDINGS_AND_CONTENTS,
                          SOLICITOR_REFERRALS
                        ].includes(lenderValue?.name)
                          ? watchNewLender
                            ? watchNewLender
                            : "N/A"
                          : lenderValue?.name}
                      </Typography>
                    )}
                  </ListItem>
                </List>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      Product
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="body1" color="text.primary">
                      {productValue?.name ? productValue?.name : "N/A"}
                    </Typography>
                  </ListItem>
                </List>
                {isInsuranceValue1 && (
                  <List style={{ display: "flex" }}>
                    <ListItem style={{ alignItems: "center", padding: 0 }}>
                      <Typography variant="caption" color="text.secondary">
                        {productValue?.name === LIFE_AND_CRITICAL_ILLNESS_TEXT
                          ? "Life"
                          : productValue?.name}
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={theme => ({
                        alignItems: "center",
                        padding: "0 0 0 24px",
                        [theme.breakpoints.down("md")]: {
                          paddingLeft: "12px"
                        }
                      })}
                    >
                      <Typography variant="body1" color="text.primary">
                        {watchInsuranceField || "N/A"}
                      </Typography>
                    </ListItem>
                  </List>
                )}
                {isInsuranceValue1 && isInsuranceValue2 && (
                  <List style={{ display: "flex" }}>
                    <ListItem style={{ alignItems: "center", padding: 0 }}>
                      <Typography variant="caption" color="text.secondary">
                        Critical illness
                      </Typography>
                    </ListItem>
                    <ListItem
                      sx={theme => ({
                        alignItems: "center",
                        padding: "0 0 0 24px",
                        [theme.breakpoints.down("md")]: {
                          paddingLeft: "12px"
                        }
                      })}
                    >
                      <Typography variant="body1" color="text.primary">
                        {watchInsuranceField2 || "N/A"}
                      </Typography>
                    </ListItem>
                  </List>
                )}
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      Pending
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="h6" color="text.primary">
                      {pendingValue ? `£${pendingValue}` : "£0.00"}
                    </Typography>
                  </ListItem>
                </List>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      Cleared
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="h6" color="text.primary">
                      {clearedValue ? `£${clearedValue}` : "£0.00"}
                    </Typography>
                  </ListItem>
                </List>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      Application Fee
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="h6" color="text.primary">
                      {`£${watchapplicationFee}`}
                    </Typography>
                  </ListItem>
                </List>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      Offer/Completion Fee
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="h6" color="text.primary">
                      {`£${watchOfferCompletionFee}`}
                    </Typography>
                  </ListItem>
                </List>
                <List style={{ display: "flex" }}>
                  <ListItem style={{ alignItems: "center", padding: 0 }}>
                    <Typography variant="caption" color="text.secondary">
                      Proc Fee
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={theme => ({
                      alignItems: "center",
                      padding: "0 0 0 24px",
                      [theme.breakpoints.down("md")]: {
                        paddingLeft: "12px"
                      }
                    })}
                  >
                    <Typography variant="h6" color="text.primary">
                      {`£${watchProcFee}`}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default EditProduct;
