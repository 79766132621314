import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Pagination,
  Stack,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Task from "./Task";
import { horizontalScrollBarStyle, showNote } from "utils";
import DeletePopover from "components/DeletePopover";
import Header from "components/Header";
import AutoCompleteField from "components/AutoCompleteField";
import { getBrokers } from "reducers/users";
import ResetFilterAlert from "components/ResetFilterAlert";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterDrawer from "./FilterDrawer";
import { isEmpty } from "lodash";
import {
  getTasks,
  updateFilters,
  updateActiveTab,
  changePage,
  updateTask,
  updateTasksBulk,
  removeTasksBulk,
  resetBrokerFilter
} from "reducers/tasks";
import {
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getSortingColor,
  getSortingIcon,
  dueDays,
  dueDaysDiff,
  toggleSelectDeselectAll,
  paginationStyle
} from "../../utils";
import {
  defaultTasksSorting,
  DESELECT_ALL,
  TASK_TABS,
  WORDS_30,
  WORDS_45,
  DARK,
  USER_TYPE
} from "utils/constants";
import { ROUTES } from "routes/constants";

const useStyles = makeStyles(theme => ({
  borderRight: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "2px",
      height: "14px",
      background: theme.palette.grey[300],
      top: "50%",
      transform: "translateY(-50%)",
      right: "0"
    },
    "& button": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Montserrat', sans-serif"
    }
  }
}));

const CHIP_STYLE_LATEST = theme => {
  return {
    color: theme.palette.text.primary,
    bgcolor: theme.palette.active.selected
  };
};

const CHIP_STYLE_LATE = theme => {
  return {
    color: theme.palette.error.contrastText,
    bgcolor: theme.palette.error.main
  };
};

const Tasks = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data, activeTab, page, rowsPerPage, filters } = useSelector(
    state => state.tasks
  );
  const { theme, data: userData } = useSelector(state => state.user);
  const [isTaskModal, setIsTaskModal] = useState(false);
  const [task, setTask] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [brokerValue, setBrokerValue] = useState({});
  const [broker, setBroker] = useState("");
  const [filters_, setFilters_] = useState(filters);
  const [filterPosition, setFilterPosition] = useState({ right: false });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTasks());
  }, [dispatch, activeTab, filters, page]);

  useEffect(() => {
    return () => {
      dispatch(updateActiveTab(TASK_TABS.ALL));
      dispatch(resetBrokerFilter());
    };
  }, [dispatch]);

  const handleOnActiveTabChange = (e, value) => {
    dispatch(updateActiveTab(value));
    setSelectedItems([]);
    dispatch(resetBrokerFilter());
    setFilters_({ ...filters_, broker: null });
    setBroker("");
  };

  const selectTasks = (e, id) => {
    setSelectedItems(state => {
      const idx = state.indexOf(id);
      if (idx === -1) return [...state, id];
      else return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  };

  const selectAllTasks = () => {
    if (toggleSelectDeselectAll(selectedItems, data) === DESELECT_ALL) {
      return setSelectedItems([]);
    }
    setSelectedItems(data.results.map(item => item.id));
  };

  const markHandler = isRead => {
    dispatch(
      updateTasksBulk({
        ids: selectedItems,
        isCompleted: isRead
      })
    );
    setSelectedItems([]);
  };

  const onRemoveTasks = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleOnSortFilterChange = sortingParam => {
    let filters_ = { ...filters, sorting: { ...filters.sorting } };
    filters_.sorting[sortingParam] = getNextSortingState(
      filters_.sorting[sortingParam]
    );
    filters_.sorting = clearOtherSortingParams(filters_.sorting, sortingParam);
    if (isNoSortingParams(filters_.sorting)) {
      filters_.sorting = defaultTasksSorting;
    }
    dispatch(updateFilters(filters_));
  };

  const handleRemoveTask = (e, id) => {
    setSelectedItems([id]);
    setAnchorEl(e.currentTarget);
  };

  const handleUpdateTask = data => {
    dispatch(updateTask({ id: task.id, data }));
    setIsTaskModal(false);
  };

  const handleOnPageChange = (e, newPage) => {
    dispatch(changePage(newPage));
  };

  const handleOnFilterChange = (name, value) => {
    setFilters_({ ...filters, [name]: value });
  };

  const toggleFilterDrawer = open => () => {
    setFilterPosition({ ...filterPosition, right: open });
  };

  const applyFilters = () => {
    dispatch(changePage(1));
    dispatch(updateFilters(filters_));
    toggleFilterDrawer(false)();
  };

  const handleOnResetFilter = () => {
    dispatch(resetBrokerFilter());
    setFilters_({ broker: null });
    setBroker("");
  };

  const handleNavigateToCaseDetail = caseId => {
    history.push(`${ROUTES.CASES}/${caseId}`);
  };

  useEffect(() => {
    if (
      userData?.me?.type === USER_TYPE.ADMIN ||
      userData?.me?.type === USER_TYPE.COMPANY_OWNER
    ) {
      dispatch(getBrokers());
    }
  }, [dispatch, userData?.me]);

  useEffect(() => {
    setBrokerValue(userData.brokers?.find(item => item.id === broker));
  }, [broker]);

  const actionButton = !!selectedItems.length && (
    <Stack
      direction="row"
      sx={{
        margin: "32px 0",
        flexWrap: "wrap"
      }}
    >
      {(activeTab === TASK_TABS.ALL || activeTab === TASK_TABS.TODO) && (
        <Button
          variant="contained"
          onClick={() => {
            markHandler(true);
          }}
          sx={theme => ({
            marginRight: "16px",
            [theme.breakpoints.down("lg")]: {
              marginBottom: "16px"
            }
          })}
        >
          Mark Complete
        </Button>
      )}

      {(activeTab === TASK_TABS.ALL || activeTab === TASK_TABS.COMPLETED) && (
        <Button
          variant="contained"
          onClick={() => {
            markHandler(false);
          }}
          sx={theme => ({
            marginRight: "16px",
            [theme.breakpoints.down("lg")]: {
              marginBottom: "16px"
            }
          })}
        >
          Mark incomplete
        </Button>
      )}
      <Button
        variant="contained"
        color="error"
        onClick={e => onRemoveTasks(e)}
        sx={theme => ({
          [theme.breakpoints.down("lg")]: {
            marginBottom: "16px"
          }
        })}
      >
        Remove
      </Button>
    </Stack>
  );

  return (
    <>
      <Header componentName="Tasks" />
      <div>
        <Typography
          variant="h4"
          color="text.primary"
          marginTop="24px"
          component="h2"
          display={{ xs: "block", lg: "none" }}
        >
          Tasks
        </Typography>
        <Box sx={{ marginTop: "28px", marginBottom: "24px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Tabs
              value={activeTab}
              onChange={handleOnActiveTabChange}
              textColor="primary"
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              sx={theme => ({
                "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  color: `${theme.palette.primary.main} !important`
                }
              })}
            >
              <Tab
                label="ALL"
                value={TASK_TABS.ALL}
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  marginRight: "16px",
                  fontSize: "16px",
                  fontWeight: "600"
                })}
              />
              <Tab
                label="TO DO"
                value={TASK_TABS.TODO}
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  marginRight: "16px",
                  fontSize: "16px",
                  fontWeight: "600"
                })}
              />
              <Tab
                label="COMPLETED"
                value={TASK_TABS.COMPLETED}
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  marginRight: "16px",
                  fontSize: "16px",
                  fontWeight: "600"
                })}
              />
            </Tabs>
            <Stack display={{ xs: "none", md: "block", lg: "none" }}>
              {!isEmpty(data.results) && (
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "24px",
                    alignItems: "center",
                    flexWrap: "wrap"
                  }}
                >
                  <Button
                    variant="contained"
                    sx={theme => ({
                      padding: "14px 26px"
                    })}
                    onClick={selectAllTasks}
                    startIcon={<AddIcon />}
                  >
                    {toggleSelectDeselectAll(selectedItems, data)}
                  </Button>
                  {(userData?.me?.type === USER_TYPE.ADMIN ||
                    userData?.me?.type === USER_TYPE.COMPANY_OWNER) && (
                    <Button
                      variant="outlined"
                      sx={theme => ({
                        color: "text.primary",
                        borderColor: "text.primary",
                        padding: "14px 32px",
                        fontSize: "13px",
                        marginLeft: "24px",
                        opacity: 0.5
                      })}
                      onClick={toggleFilterDrawer(true)}
                    >
                      <FilterListIcon />
                      FILTER
                    </Button>
                  )}
                </Box>
              )}
            </Stack>
          </Stack>
        </Box>
        {!isEmpty(data.results) && (
          <Box
            sx={theme => ({
              display: "flex",
              marginBottom: "24px",
              alignItems: "center",
              flexWrap: "wrap",
              [theme.breakpoints.down("lg")]: {
                display: "none"
              },
              [theme.breakpoints.down("md")]: {
                display: "block",
                display: "flex"
              }
            })}
          >
            <Button
              variant="contained"
              sx={theme => ({
                padding: "14px 18px",
                [theme.breakpoints.down("ssm")]: {
                  width: "100%",
                  marginBottom: "16px"
                }
              })}
              onClick={selectAllTasks}
              startIcon={<AddIcon />}
            >
              {toggleSelectDeselectAll(selectedItems, data)}
            </Button>
            {(userData?.me?.type === USER_TYPE.ADMIN ||
              userData?.me?.type === USER_TYPE.COMPANY_OWNER) && (
              <Stack
                display={{ xs: "block", lg: "none" }}
                sx={theme => ({
                  [theme.breakpoints.down("ssm")]: {
                    width: "100%"
                  }
                })}
              >
                <Button
                  variant="outlined"
                  sx={theme => ({
                    color: "text.primary",
                    borderColor: "text.primary",
                    padding: "14px 32px",
                    fontSize: "13px",
                    marginLeft: "18px",
                    opacity: 0.5,
                    [theme.breakpoints.down("ssm")]: {
                      width: "100%",
                      marginLeft: 0
                    }
                  })}
                  onClick={toggleFilterDrawer(true)}
                >
                  <FilterListIcon />
                  FILTER
                </Button>
              </Stack>
            )}
            <Stack
              display={{ xs: "none", lg: "block" }}
              sx={theme => ({
                [theme.breakpoints.down("lg")]: {
                  width: "100%"
                }
              })}
            >
              {(userData?.me?.type === USER_TYPE.ADMIN ||
                userData?.me?.type === USER_TYPE.COMPANY_OWNER) && (
                <Stack direction="row">
                  <AutoCompleteField
                    sx={theme => ({
                      width: 250,
                      margin: "0 24px",
                      [theme.breakpoints.down("llg")]: {
                        width: 230,
                        margin: "0 16px"
                      },
                      [theme.breakpoints.down("lg")]: {
                        width: "100%",
                        marginTop: "16px",
                        marginRight: 0
                      }
                    })}
                    label={"Select Broker"}
                    value={brokerValue?.email || ""}
                    options={userData.brokers?.map(({ id, email }) => ({
                      value: id,
                      label: email
                    }))}
                    onChange={(e, value) => {
                      setBroker(value?.value || "");
                      handleOnFilterChange("broker", value?.value);
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={theme => ({
                      padding: "14px 26px",
                      [theme.breakpoints.down("lg")]: {
                        padding: "10px 12px",
                        fontSize: "13px",
                        marginRight: "24px"
                      }
                    })}
                    onClick={applyFilters}
                  >
                    Apply
                  </Button>
                </Stack>
              )}
            </Stack>
          </Box>
        )}
        {filters.broker && (
          <ResetFilterAlert
            data={data?.results}
            text="tasks"
            handleOnResetFilter={handleOnResetFilter}
          />
        )}
        {!isEmpty(data.results) && actionButton}
        {data.results.length > 0 ? (
          <>
            <TableContainer
              component={Paper}
              sx={theme => horizontalScrollBarStyle(theme)}
            >
              <Table sx={{ minWidth: 550 }} stickyHeader>
                <TableHead>
                  <TableRow
                    sx={theme => ({
                      bgcolor: theme.palette.background.default,
                      "& td,th": {
                        borderBottom: `1px solid ${theme.palette.text.disabled}`
                      }
                    })}
                  >
                    <TableCell
                      sx={{ width: "25%" }}
                      className={classes.borderRight}
                    >
                      <Button
                        onClick={() => handleOnSortFilterChange("name")}
                        variant="text"
                        sx={{
                          color: getSortingColor(filters.sorting.name)
                        }}
                        endIcon={getSortingIcon(filters.sorting.name)}
                      >
                        Tasks
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{ width: "20%", whiteSpace: "pre" }}
                      className={classes.borderRight}
                      align="center"
                    >
                      <Button
                        onClick={() => handleOnSortFilterChange("due_date")}
                        variant="text"
                        sx={{
                          color: getSortingColor(filters.sorting.due_date)
                        }}
                        endIcon={getSortingIcon(filters.sorting.due_date)}
                      >
                        Due date
                      </Button>
                    </TableCell>
                    <TableCell className={classes.borderRight}>
                      <Button variant="text" sx={{ color: "text.primary" }}>
                        Notes
                      </Button>
                    </TableCell>
                    <TableCell
                      sx={{ width: "10%" }}
                      className={classes.borderRight}
                    >
                      <Button
                        onClick={() => handleOnSortFilterChange("case")}
                        variant="text"
                        sx={{
                          whiteSpace: "pre",
                          color: getSortingColor(filters.sorting.case),
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "'Montserrat', sans-serif"
                        }}
                        endIcon={getSortingIcon(filters.sorting.case)}
                      >
                        Case
                      </Button>
                    </TableCell>
                    <TableCell sx={{ width: "17%" }}>
                      <Button
                        onClick={() => handleOnSortFilterChange("client_name")}
                        variant="text"
                        sx={{
                          whiteSpace: "pre",
                          color: getSortingColor(filters.sorting.client_name),
                          fontSize: "14px",
                          fontWeight: "500",
                          fontFamily: "'Montserrat', sans-serif"
                        }}
                        endIcon={getSortingIcon(filters.sorting.client_name)}
                      >
                        Client Name
                      </Button>
                    </TableCell>
                    <TableCell sx={{ width: "5%" }}></TableCell>
                    <TableCell
                      sx={{ width: "5%" }}
                      className={classes.borderRight}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.results.map(row => (
                    <TableRow
                      key={row.id}
                      sx={theme => ({
                        bgcolor: theme.palette.background.default,
                        "& td,th": {
                          borderBottom: `1px solid ${theme.palette.text.disabled}`
                        }
                      })}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontSize: "16px",
                          color: "text.primary",
                          width: "25%"
                        }}
                      >
                        <Stack direction="row" alignItems="center">
                          <Checkbox
                            checked={selectedItems.includes(row.id)}
                            sx={theme => ({
                              color: "text.primary",
                              marginRight: "12px",
                              wordBreak: "break-word",
                              [theme.breakpoints.down("lg")]: {
                                marginRight: 0
                              }
                            })}
                            onChange={e => {
                              selectTasks(e, row.id);
                            }}
                          />
                          <Typography className="singleLineTruncate">
                            {showNote(row.name, WORDS_30)}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "14px",
                          color: "text.disabled",
                          width: "20%"
                        }}
                      >
                        <Chip
                          label={dueDays(row.dueDate)}
                          sx={theme =>
                            dueDaysDiff(row.dueDate) >= 0
                              ? CHIP_STYLE_LATEST(theme)
                              : CHIP_STYLE_LATE(theme)
                          }
                          style={{
                            marginBottom: "5px",
                            fontWeight: "700",
                            fontSize: "13px",
                            fontFamily: "'Nunito Sans', sans-serif"
                          }}
                        />
                        <div>
                          {moment(row.dueDate).format("ddd D MMM YYYY")}
                        </div>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          color: "text.primary"
                        }}
                      >
                        <Typography className="singleLineTruncate">
                          {showNote(row.note, WORDS_45)}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "10%" }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            padding: "12px",
                            cursor: "pointer",
                            color: "primary.main",
                            textDecoration: "underline",
                            ":hover": {
                              opacity: 0.8
                            }
                          }}
                          onClick={() => handleNavigateToCaseDetail(row.case)}
                        >
                          {row.case}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "17%" }}>
                        <Typography
                          sx={{
                            fontSize: "16px"
                          }}
                        >
                          {row.clientName}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "5%" }} align="right">
                        <IconButton
                          sx={{ color: "text.primary" }}
                          onClick={() => {
                            setIsTaskModal(true);
                            setTask(row);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{ width: "5%", paddingRight: "0" }}
                        align="right"
                      >
                        <IconButton
                          color="error"
                          onClick={e => handleRemoveTask(e, row.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={Math.ceil(data.count / rowsPerPage)}
              page={page}
              onChange={handleOnPageChange}
              sx={theme => paginationStyle(theme)}
            />
          </>
        ) : (
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            color="text.primary"
            textAlign="center"
          >
            There are no tasks yet
          </Typography>
        )}
      </div>
      {isTaskModal && (
        <Box>
          <Task
            edit
            isOpen={isTaskModal}
            handleClose={() => setIsTaskModal(false)}
            handleTask={handleUpdateTask}
            task={task}
          />
        </Box>
      )}
      <DeletePopover
        text="task(s)"
        anchorEl={anchorEl}
        itemId={selectedItems}
        handleClose={() => setAnchorEl(null)}
        removeItem={removeTasksBulk}
        bgColor={theme === DARK ? "#010810" : "#FFFFFF"}
        setSelectedItems={setSelectedItems}
      />
      <FilterDrawer
        setBroker={setBroker}
        changePage={changePage}
        brokerValue={brokerValue}
        position={filterPosition}
        applyFilters={applyFilters}
        updateFilters={updateFilters}
        toggleDrawer={toggleFilterDrawer}
        handleOnFilterChange={handleOnFilterChange}
      />
    </>
  );
};

export default Tasks;
