import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "components/Modals/index";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { Button, TextField, Alert } from "@mui/material";
import { cancelSubscription } from "reducers/company";
import moment from "moment";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const CancelSubscriptionModal = ({ isOpen, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { cancelSubscription: cancelSubscriptionDate } = useSelector(
    state => state.company
  );

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onSubmit = value => {
    dispatch(cancelSubscription(value));
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Cancel Subscription"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Alert
          variant="outlined"
          severity="error"
          sx={{ color: "error.alertText", marginBottom: "24px" }}
        >
          By cancelling your subscription you will no longer be able to purchase
          leads, or work on cases, all your previous data will be accessible
          till{" "}
          {moment(cancelSubscriptionDate?.cancelAt).format(
            moment.HTML5_FMT.DATE
          )}
          .
        </Alert>
        <TextField
          fullWidth
          type="password"
          className={classes.textField}
          label="Password"
          placeholder="Password"
          error={!!errors.password}
          {...register("password", {
            required: true
          })}
        />
        <Button
          sx={theme => ({
            marginTop: 3,
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          })}
          color="error"
          type="submit"
          variant="contained"
        >
          CANCEL SUBSCRIPTION
        </Button>
      </form>
    </Modal>
  );
};

export default CancelSubscriptionModal;
