import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Loader from "components/Loader";
import LeadModal from "./LeadModal";
import { getLeadDetail } from "reducers/leads";
import LeadCard from "./LeadCard";
import Header from "components/Header";

const Lead = () => {
  const { id } = useParams();

  const [open, setOpen] = useState(true);
  const { loading, lead } = useSelector(state => state.leads);
  const dispatch = useDispatch();

  useEffect(() => {
    if (lead) {
      dispatch(getLeadDetail(id));
    }
  }, [dispatch]);

  if (loading) return <Loader />;

  return (
    <>
      <Header componentName="Lead" />
      <Typography
        variant="h4"
        color="text.primary"
        marginTop="24px"
        component="h2"
        display={{ xs: "block", lg: "none" }}
      >
        Lead
      </Typography>
      <Grid
        container
        mt={4}
        sx={theme => ({
          [theme.breakpoints.down("lg")]: {
            minHeight: "auto !important"
          }
        })}
      >
        <Grid
          item
          xs={12}
          sx={theme => ({
            "& .MuiBox-root": {
              paddingBottom: "24px",
              maxWidth: 400
            },
            [theme.breakpoints.down("lg")]: {
              maxWidth: "450px",
              marginLeft: "auto",
              marginRight: "auto"
            }
          })}
        >
          <LeadCard
            lead={lead}
            setLead={lead}
            openModal={() => setOpen(true)}
          />
        </Grid>
      </Grid>
      {open && (
        <LeadModal
          isOpen={open}
          lead={lead}
          handleClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default Lead;
