import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: "16px",
  margin: "24px 0",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.progressBarPalette.dark
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.progressBarPalette.main
  }
}));

function LinearProgressBar({ value }) {
  return <BorderLinearProgress variant="determinate" value={parseInt(value)} />;
}

export default LinearProgressBar;
