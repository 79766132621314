import { formatFilters } from "utils";
import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getNotificationsAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_NOTIFICATIONS,
    params: formatFilters({ ...params })
  });

export const removeNotificationAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.REMOVE_NOTIFICATIONS}`,
    data
  });

export const updateNotificationAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.UPDATE_NOTIFICATIONS}`,
    data
  });
export const getDrawerNotificationsAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_NOTIFICATIONS,
    params: formatFilters({ ...params })
  });

export const postOnesignalPlayerIdAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.POST_ONESIGNAL_PLAYER_ID}`,
    data
  });

export const patchPushNotificationPreferenceAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.PATCH_PUSH_NOTIFICATION_PREFERENCE}${id}/`,
    data
  });

export const patchSmsPreferenceAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.PATCH_SMS_PREFERENCE}${id}/`,
    data
  });
