import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/reports";
import * as actions from "reducers/reports";

function* getLeadConversionsSaga() {
  const {
    reports: { filters }
  } = yield select();
  try {
    const resp = yield call(Api.getLeadConversionsAPI, { ...filters });
    yield put(actions.getLeadConversionsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getLeadConversionsFailed(error));
  }
}

function* getMortgageSoldSaga() {
  const {
    reports: { filters }
  } = yield select();
  try {
    const resp = yield call(Api.getMortgageSoldAPI, { ...filters });
    yield put(actions.getMortgageSoldSucceed(resp.data));
  } catch (error) {
    yield put(actions.getMortgageSoldFailed(error));
  }
}

function* getInsuranceSoldSaga() {
  const {
    reports: { filters }
  } = yield select();
  try {
    const resp = yield call(Api.getInsuranceSoldAPI, { ...filters });
    yield put(actions.getInsuranceSoldSucceed(resp.data));
  } catch (error) {
    yield put(actions.getInsuranceSoldFailed(error));
  }
}

function* getCreditsUsedSaga() {
  const {
    reports: { filters }
  } = yield select();
  try {
    const resp = yield call(Api.getCreditsUsedAPI, { ...filters });
    yield put(actions.getCreditsUsedSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCreditsUsedFailed(error));
  }
}

function* getProductsEarningsSaga() {
  const {
    reports: { filters }
  } = yield select();
  try {
    const resp = yield call(Api.getProductsEarningsAPI, { ...filters });
    yield put(actions.getProductsEarningsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getProductsEarningsFailed(error));
  }
}

function* getProductsSoldSaga() {
  const {
    reports: { filters }
  } = yield select();
  try {
    const resp = yield call(Api.getProductsSoldAPI, { ...filters });
    yield put(actions.getProductsSoldSucceed(resp.data));
  } catch (error) {
    yield put(actions.getProductsSoldFailed(error));
  }
}

function* getContactRateSaga() {
  const {
    reports: { filters }
  } = yield select();
  try {
    const resp = yield call(Api.getContactRateAPI, { ...filters });
    yield put(actions.getContactRateSucceed(resp.data));
  } catch (error) {
    yield put(actions.getContactRateFailed(error));
  }
}

export function* reportsSaga() {
  yield takeLatest(actions.getLeadConversions.type, getLeadConversionsSaga);
  yield takeLatest(actions.getMortgageSold.type, getMortgageSoldSaga);
  yield takeLatest(actions.getInsuranceSold.type, getInsuranceSoldSaga);
  yield takeLatest(actions.getCreditsUsed.type, getCreditsUsedSaga);
  yield takeLatest(actions.getProductsEarnings.type, getProductsEarningsSaga);
  yield takeLatest(actions.getProductsSold.type, getProductsSoldSaga);
  yield takeLatest(actions.getContactRate.type, getContactRateSaga);
}
