import { apiCaller, request } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const loginAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.LOGIN,
    data: {
      ...data
    }
  });

export const forgotPasswordAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.FORGOT_PASSWORD,
    data: {
      ...data
    }
  });

export const confirmPasswordAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.CONFIRM_PASSWORD,
    data
  });

export const changePasswordAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.CHANGE_PASSWORD,
    data
  });

export const getUserAPI = () =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.ME
  });

export const getBrokersAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_BROKERS,
    params
  });

export const changePassword = async variables => {
  try {
    const response = await request().post("/auth/password/change/", {
      ...variables
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const logoutAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: ENDPOINTS.LOGOUT,
    data
  });

export const getCompaniesAPI = () =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_COMPANIES
  });

export const updateEmailPreferenceAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.UPDATE_EMAIL_PREFERENCE}${id}/`,
    data
  });

export const updateUserAPI = ({ data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.ME}`,
    data
  });
