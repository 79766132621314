import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/credits";
import * as actions from "reducers/credits";
import * as userActions from "reducers/users";
import * as companyActions from "reducers/company";
import { toast } from "react-toastify";
import { ERROR } from "utils/constants";

function* getCreditsBundlesSaga({ payload }) {
  try {
    const resp = yield call(Api.getCreditsBundlesAPI, payload);
    yield put(actions.getCreditsBundlesSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCreditsBundlesFailed(error));
  }
}

function* creditsUpdateSaga({ payload }) {
  const {
    user: { data }
  } = yield select();
  try {
    yield call(Api.creditsUpdateAPI, payload);
    yield put(actions.creditsUpdateSucceed());
    yield put(companyActions.getCompanyBrokers());
    yield put(companyActions.getCompanies(data.me.company));
    yield put(userActions.getUser());
    toast.success("You have successfully updated the credits.");
  } catch (error) {
    yield put(actions.creditsUpdateFailed(error));
  }
}

function* creditBuyBundleSaga({ payload }) {
  try {
    const resp = yield call(Api.creditBuyBundleAPI, payload);
    if (resp.data.status === ERROR) {
      yield put(companyActions.updateCompletePaymentModal(true));
      yield put(companyActions.updateConfirmationPaymentModal(resp.data));
      yield put(actions.updateProcessingModal(false));
    } else {
      yield put(actions.creditBuyBundleSucceed(resp.data));
      yield put(userActions.getUser());
      toast.success("You have successfully purchased credits.");
    }
  } catch (error) {
    yield put(actions.creditBuyBundleFailed(error));
  }
}

export function* creditsSaga() {
  yield takeLatest(actions.getCreditsBundles.type, getCreditsBundlesSaga);
  yield takeLatest(actions.creditsUpdate.type, creditsUpdateSaga);
  yield takeLatest(actions.creditBuyBundle.type, creditBuyBundleSaga);
}
