import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  data: {
    dashboard: {},
    leaderboard: {}
  },
  brokerDetail: {},
  error: null,
  rowsPerPage: 6,
  page: 1
};

export const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    getDashboard: state => {
      state.loading = true;
    },
    getDashboardSucceed: (state, action) => {
      state.loading = false;
      state.data.dashboard = action.payload;
    },
    getDashboardFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getLeaderboard: state => {
      state.loading = true;
    },
    getLeaderboardSucceed: (state, action) => {
      state.loading = false;
      state.data.leaderboard = action.payload;
    },
    getLeaderboardFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    getBrokerDashboard: state => {
      state.loading = true;
    },
    getBrokerDashboardSucceed: (state, action) => {
      state.loading = false;
      state.brokerDetail = action.payload;
    },
    getBrokerDashboardFailed: (state, action) => {
      state.loading = false;
    }
  }
});

export const {
  getDashboard,
  getDashboardSucceed,
  getDashboardFailed,
  getLeaderboard,
  getLeaderboardSucceed,
  getLeaderboardFailed,
  changePage,
  getBrokerDashboard,
  getBrokerDashboardSucceed,
  getBrokerDashboardFailed
} = dashboard.actions;

export default dashboard.reducer;
