import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLeads } from "reducers/leads";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import LineGraph from "./LineGraph";
import LeadCard from "pages/Leads/LeadCard";
import LeadModal from "pages/Leads/LeadModal";
import {
  checkPositiveNegetiveValue,
  moneyFieldConverter,
  grossEarningTransformerForLineChart
} from "utils";
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  Stack,
  Button,
  Paper
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

function BrokerDetails({ data, boxStyle, lead, setLead, open, setOpen }) {
  const dispatch = useDispatch();
  const { data: user, theme } = useSelector(state => state.user);
  const { data: leadData } = useSelector(state => state.leads);
  useEffect(() => {
    dispatch(getLeads());
  }, [dispatch]);
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={theme => ({
          marginTop: "8px",
          [theme.breakpoints.down("sm")]: {
            marginTop: 0
          }
        })}
      >
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Typography variant="h6" color="text.primary">
              Leads
            </Typography>
            <Typography variant="body2" color="text.secondary" margin="14px 0">
              THIS MONTH
            </Typography>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Acquired
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Stack direction="row" alignItems="center">
                  {data.leads.leadsAcquiredPercentChangeThisMonth !== null && (
                    <Typography
                      variant="caption"
                      color={
                        data.leads.leadsAcquiredPercentChangeThisMonth >= 0
                          ? "success.main"
                          : "error.main"
                      }
                      sx={{
                        fontWeight: "700",
                        position: "relative",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          border: "3px solid transparent",
                          left: "-12px",
                          top: "40%",
                          borderTopColor:
                            data.leads.leadsAcquiredPercentChangeThisMonth >= 0
                              ? "success.main"
                              : "error.main",
                          transform:
                            data.leads.leadsAcquiredPercentChangeThisMonth >= 0
                              ? "translateY(-50%) rotate(180deg)"
                              : "unset"
                        }
                      }}
                    >
                      {checkPositiveNegetiveValue(
                        data.leads.leadsAcquiredPercentChangeThisMonth
                      )}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      fontWeight: "700",
                      minWidth: " 25px",
                      textAlign: "end",
                      marginLeft: "5px"
                    }}
                  >
                    {data.leads.leadsAcquiredThisMonth}
                  </Typography>
                </Stack>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Converted
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Stack direction="row" alignItems="center">
                  {data.leads.leadsConvertedPercentChangeThisMonth !== null && (
                    <Typography
                      variant="caption"
                      color={
                        data.leads.leadsConvertedPercentChangeThisMonth >= 0
                          ? "success.main"
                          : "error.main"
                      }
                      sx={{
                        fontWeight: "700",
                        position: "relative",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          border: "3px solid transparent",
                          left: "-12px",
                          top: "40%",
                          borderTopColor:
                            data.leads.leadsConvertedPercentChangeThisMonth >= 0
                              ? "success.main"
                              : "error.main",
                          transform:
                            data.leads.leadsConvertedPercentChangeThisMonth >= 0
                              ? "translateY(-50%) rotate(180deg)"
                              : "unset"
                        }
                      }}
                    >
                      {checkPositiveNegetiveValue(
                        data.leads.leadsConvertedPercentChangeThisMonth
                      )}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      fontWeight: "700",
                      minWidth: " 25px",
                      textAlign: "end",
                      marginLeft: "5px"
                    }}
                  >
                    {data.leads.leadsConvertedThisMonth}
                  </Typography>
                </Stack>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Pending
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.leads.leadsPendingThisMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Not Proceeding
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.leads.leadsNotProceedingThisMonth}
                </Typography>
              </ListItem>
            </List>
            <List sx={{ display: "flex", padding: 0 }}>
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Conversion Rate
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.leads.leadsConversionRateThisMonth}%
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body2" color="text.secondary" margin="14px 0">
              LAST MONTH
            </Typography>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Acquired
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      fontWeight: "700",
                      minWidth: " 25px",
                      textAlign: "end",
                      marginLeft: "5px"
                    }}
                  >
                    {data.leads.leadsAcquiredLastMonth}
                  </Typography>
                </Stack>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Converted
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      fontWeight: "700",
                      minWidth: " 25px",
                      textAlign: "end",
                      marginLeft: "5px"
                    }}
                  >
                    {data.leads.leadsConvertedLastMonth}
                  </Typography>
                </Stack>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Pending
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.leads.leadsPendingLastMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Not Proceeding
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.leads.leadsNotProceedingLastMonth}
                </Typography>
              </ListItem>
            </List>
            <List sx={{ display: "flex", padding: 0 }}>
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Conversion Rate
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.leads.leadsConversionRateLastMonth}%
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)} style={{ minHeight: "418px" }}>
            <Typography variant="h6" color="text.primary">
              Mortgages
            </Typography>
            <Typography variant="body2" color="text.secondary" margin="14px 0">
              THIS MONTH
            </Typography>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Completed
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.mortgages.completedMortgagesThisMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  In Progress
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.mortgages.inProgressMortgagesThisMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Commission
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {moneyFieldConverter(
                    data.mortgages.commissionEarningsThisMonth
                  )}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Pending Commission
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {moneyFieldConverter(
                    data.mortgages.pendingCommissionEarningsThisMonth
                  )}
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body2" color="text.secondary" margin="14px 0">
              LAST MONTH
            </Typography>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Completed
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.mortgages.completedMortgagesLastMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  In Progress
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.mortgages.inProgressMortgagesLastMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Commission
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {moneyFieldConverter(
                    data.mortgages.commissionEarningsLastMonth
                  )}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Pending Commission
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {moneyFieldConverter(
                    data.mortgages.pendingCommissionEarningsLastMonth
                  )}
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)} style={{ minHeight: "418px" }}>
            <Typography variant="h6" color="text.primary">
              Insurance
            </Typography>
            <Typography variant="body2" color="text.secondary" margin="14px 0">
              THIS MONTH
            </Typography>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Sold
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.insurances.insurancesSoldThisMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  In Progress
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.insurances.inProgressInsurancesThisMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Commission
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {moneyFieldConverter(
                    data.insurances.commissionEarningsThisMonth
                  )}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Pending Commission
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {moneyFieldConverter(
                    data.insurances.pendingCommissionEarningsThisMonth
                  )}
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body2" color="text.secondary" margin="14px 0">
              LAST MONTH
            </Typography>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Sold
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.insurances.insurancesSoldLastMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  In Progress
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {data.insurances.inProgressInsurancesLastMonth}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Commission
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {moneyFieldConverter(
                    data.insurances.commissionEarningsLastMonth
                  )}
                </Typography>
              </ListItem>
            </List>
            <List
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 0,
                marginBottom: "6px"
              }}
            >
              <ListItem style={{ padding: 0, marginRight: "12px" }}>
                <Typography variant="body1" color="text.primary">
                  Pending Commission
                </Typography>
              </ListItem>
              <ListItem sx={{ padding: 0, width: "unset" }}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{ fontWeight: "700" }}
                >
                  {moneyFieldConverter(
                    data.insurances.pendingCommissionEarningsLastMonth
                  )}
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        color="text.primary"
        sx={{ margin: "32px 0 24px 0" }}
      >
        Earnings
        <Typography
          color="text.primary"
          sx={{
            display: "inline",
            fontWeight: "300",
            fontSize: "20px",
            marginLeft: "4px"
          }}
        >
          (From Blabble Leads)
        </Typography>
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: "32px" }}>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  This Month
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data?.blabbleLeadsEarnings.grossEarningsThisMonth
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Month
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.blabbleLeadsEarnings.grossEarningsLastMonth
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.blabbleLeadsEarnings
                      .grossEarningsPercentChangeThisMonth >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.blabbleLeadsEarnings
                          ?.grossEarningsPercentChangeThisMonth >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.blabbleLeadsEarnings
                          ?.grossEarningsPercentChangeThisMonth >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.blabbleLeadsEarnings
                      ?.grossEarningsPercentChangeThisMonth
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", lg: "70px", xxl: "120px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.blabbleLeadsEarnings.grossEarningsLastMonth,
                      data.blabbleLeadsEarnings.grossEarningsThisMonth
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  This Quarter
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.blabbleLeadsEarnings.grossEarningsThisQuarter
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Quarter
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.blabbleLeadsEarnings.grossEarningsLastQuarter
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.blabbleLeadsEarnings
                      .grossEarningsPercentChangeThisQuarter >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.blabbleLeadsEarnings
                          .grossEarningsPercentChangeThisQuarter >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.blabbleLeadsEarnings
                          .grossEarningsPercentChangeThisQuarter >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.blabbleLeadsEarnings
                      .grossEarningsPercentChangeThisQuarter
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", lg: "70px", xxl: "120px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.blabbleLeadsEarnings.grossEarningsLastQuarter,
                      data.blabbleLeadsEarnings.grossEarningsThisQuarter
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  This Year
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.blabbleLeadsEarnings.grossEarningsThisYear
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Year
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.blabbleLeadsEarnings.grossEarningsLastYear
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.blabbleLeadsEarnings
                      .grossEarningsPercentChangeThisYear >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.blabbleLeadsEarnings
                          .grossEarningsPercentChangeThisYear >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.blabbleLeadsEarnings
                          .grossEarningsPercentChangeThisYear >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.personalLeadsEarnings
                      .grossEarningsPercentChangeThisYear
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", lg: "70px", xxl: "120px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.blabbleLeadsEarnings.grossEarningsLastYear,
                      data.blabbleLeadsEarnings.grossEarningsThisYear
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        color="text.primary"
        sx={{ margin: "32px 0 24px 0" }}
      >
        Earnings
        <Typography
          color="text.primary"
          sx={{
            display: "inline",
            fontWeight: "300",
            fontSize: "20px",
            marginLeft: "4px"
          }}
        >
          (From Personal Leads)
        </Typography>
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack marginRight="8px">
                <Typography variant="body2" color="text.secondary">
                  This Month
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.personalLeadsEarnings.grossEarningsThisMonth
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Month
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.personalLeadsEarnings.grossEarningsLastMonth
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.personalLeadsEarnings
                      .grossEarningsPercentChangeThisMonth >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.personalLeadsEarnings
                          .grossEarningsPercentChangeThisMonth >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.personalLeadsEarnings
                          .grossEarningsPercentChangeThisMonth >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.personalLeadsEarnings
                      .grossEarningsPercentChangeThisMonth
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", xl: "100px", xxl: "140px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.personalLeadsEarnings.grossEarningsLastMonth,
                      data.personalLeadsEarnings.grossEarningsThisMonth
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack marginRight="8px">
                <Typography variant="body2" color="text.secondary">
                  This Quarter
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.personalLeadsEarnings.grossEarningsThisQuarter
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Quarter
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.personalLeadsEarnings.grossEarningsLastQuarter
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.personalLeadsEarnings
                      .grossEarningsPercentChangeThisQuarter >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.personalLeadsEarnings
                          .grossEarningsPercentChangeThisQuarter >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.personalLeadsEarnings
                          .grossEarningsPercentChangeThisQuarter >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.personalLeadsEarnings
                      .grossEarningsPercentChangeThisQuarter
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", lg: "70px", xxl: "120px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.personalLeadsEarnings.grossEarningsLastQuarter,
                      data.personalLeadsEarnings.grossEarningsThisQuarter
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  This Year
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.personalLeadsEarnings.grossEarningsThisYear
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Year
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.personalLeadsEarnings.grossEarningsLastYear
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.personalLeadsEarnings
                      .grossEarningsPercentChangeThisYear >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.personalLeadsEarnings
                          ?.grossEarningsPercentChangeThisYear >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.personalLeadsEarnings
                          ?.grossEarningsPercentChangeThisYear >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.personalLeadsEarnings
                      ?.grossEarningsPercentChangeThisYear
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", lg: "70px", xxl: "120px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.personalLeadsEarnings.grossEarningsLastYear,
                      data.personalLeadsEarnings.grossEarningsThisYear
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        color="text.primary"
        sx={{ margin: "32px 0 24px 0" }}
      >
        Earnings
        <Typography
          color="text.primary"
          sx={{
            display: "inline",
            fontWeight: "300",
            fontSize: "20px",
            marginLeft: "4px"
          }}
        >
          (From {user.me?.otherLeadsName} Leads)
        </Typography>
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: "32px" }}>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  This Month
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data?.otherLeadsEarnings.grossEarningsThisMonth
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Month
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.otherLeadsEarnings.grossEarningsLastMonth
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.otherLeadsEarnings
                      .grossEarningsPercentChangeThisMonth >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.otherLeadsEarnings
                          ?.grossEarningsPercentChangeThisMonth >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.otherLeadsEarnings
                          ?.grossEarningsPercentChangeThisMonth >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.otherLeadsEarnings?.grossEarningsPercentChangeThisMonth
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", lg: "70px", xxl: "120px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.otherLeadsEarnings.grossEarningsLastMonth,
                      data.otherLeadsEarnings.grossEarningsThisMonth
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  This Quarter
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.otherLeadsEarnings.grossEarningsThisQuarter
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Quarter
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.otherLeadsEarnings.grossEarningsLastQuarter
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.otherLeadsEarnings
                      .grossEarningsPercentChangeThisQuarter >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.otherLeadsEarnings
                          .grossEarningsPercentChangeThisQuarter >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.otherLeadsEarnings
                          .grossEarningsPercentChangeThisQuarter >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.otherLeadsEarnings
                      .grossEarningsPercentChangeThisQuarter
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", lg: "70px", xxl: "120px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.otherLeadsEarnings.grossEarningsLastQuarter,
                      data.otherLeadsEarnings.grossEarningsThisQuarter
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Box sx={theme => boxStyle(theme)}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack>
                <Typography variant="body2" color="text.secondary">
                  This Year
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.otherLeadsEarnings.grossEarningsThisYear
                  )}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Last Year
                </Typography>
                <Typography variant="h6" color="text.primary">
                  {moneyFieldConverter(
                    data.otherLeadsEarnings.grossEarningsLastYear
                  )}
                </Typography>
                <Typography
                  variant="caption"
                  color={
                    data.otherLeadsEarnings
                      .grossEarningsPercentChangeThisYear >= 0
                      ? "success.main"
                      : "error.main"
                  }
                  sx={{
                    fontWeight: "700",
                    marginLeft: "12px",
                    marginTop: "6px",
                    position: "relative",
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      border: "3px solid transparent",
                      left: "-12px",
                      top: "40%",
                      borderTopColor:
                        data.otherLeadsEarnings
                          .grossEarningsPercentChangeThisYear >= 0
                          ? "success.main"
                          : "error.main",
                      transform:
                        data.otherLeadsEarnings
                          .grossEarningsPercentChangeThisYear >= 0
                          ? "translateY(-50%) rotate(180deg)"
                          : "unset"
                    }
                  }}
                >
                  {checkPositiveNegetiveValue(
                    data.personalLeadsEarnings
                      .grossEarningsPercentChangeThisYear
                  )}
                </Typography>
              </Stack>
              <Paper
                sx={theme => ({
                  width: { xs: "120px", lg: "70px", xxl: "120px" },
                  bgcolor: theme.palette.text.dark.primary
                })}
                elevation={0}
              >
                <Box height="60px">
                  <LineGraph
                    data={grossEarningTransformerForLineChart(
                      data.otherLeadsEarnings.grossEarningsLastYear,
                      data.otherLeadsEarnings.grossEarningsThisYear
                    )}
                    theme={theme}
                  />
                </Box>
              </Paper>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      {leadData.results.length > 0 && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ margin: "0 0 24px 0", width: "100%" }}
          >
            <Typography variant="h6" color="text.primary">
              New Leads
            </Typography>
            <Link to={ROUTES.LEADS} style={{ textDecoration: "none" }}>
              <Button variant="text" startIcon={<VisibilityIcon />}>
                View all
              </Button>
            </Link>
          </Stack>
          <Grid container spacing={2}>
            {leadData.results[0] && (
              <Grid item xs={12} sm={6} xl={4} marginBottom="32px">
                <LeadCard
                  lead={leadData.results[0]}
                  setLead={setLead}
                  openModal={() => setOpen(true)}
                />
              </Grid>
            )}
            {leadData.results[1] && (
              <Grid item xs={12} sm={6} xl={4} marginBottom="32px">
                <LeadCard
                  lead={leadData.results[1]}
                  setLead={setLead}
                  openModal={() => setOpen(true)}
                />
              </Grid>
            )}
            {leadData.results[2] && (
              <Grid item xs={12} sm={6} xl={4} marginBottom="32px">
                <LeadCard
                  lead={leadData.results[2]}
                  setLead={setLead}
                  openModal={() => setOpen(true)}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {open && (
        <LeadModal
          isOpen={open}
          lead={lead}
          handleClose={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default BrokerDetails;
