import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import Modal from "components/Modals/index";
import { updateCompanies } from "reducers/company";
import { Box, Button, FormControl, TextField, Stack } from "@mui/material";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);

function EditBillingAddressModal({ isOpen, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [billingAddress, setBillingAddress] = useState({});

  const { me: user } = useSelector(state => state.user.data);
  const { defaultCompanyData } = useSelector(state => state.company);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = value => {
    const billingAddressDetail = {
      id: user.company,
      data: {
        billingAddress: {
          address: value.addressLine1,
          address2: value.addressLine2,
          town: value.town,
          postCode: value.postCode
        }
      }
    };

    dispatch(updateCompanies(billingAddressDetail));
    handleClose();
  };

  useEffect(() => {
    setBillingAddress({
      ...billingAddress,
      addressLine1: defaultCompanyData?.billingAddress?.address,
      addressLine2: defaultCompanyData?.billingAddress?.address2,
      town: defaultCompanyData?.billingAddress?.town,
      postCode: defaultCompanyData?.billingAddress?.postCode
    });
    reset({
      addressLine1: defaultCompanyData?.billingAddress?.address,
      addressLine2: defaultCompanyData?.billingAddress?.address2,
      town: defaultCompanyData?.billingAddress?.town,
      postCode: defaultCompanyData?.billingAddress?.postCode
    });
  }, [defaultCompanyData]);

  const handleChange = (key, value) => {
    setBillingAddress({ ...billingAddress, [key]: value });
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Update Billing Address"
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Address Line 1"
                type="text"
                placeholder="Address Line 1"
                defaultValue={defaultCompanyData?.billingAddress?.address || ""}
                error={!!errors.addressLine1}
                {...register("addressLine1", {
                  required: true,
                  onChange: e => handleChange("addressLine1", e.target.value)
                })}
                InputLabelProps={{
                  shrink: billingAddress.addressLine1 ? true : false
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                className={classes.textField}
                fullWidth
                label="Address Line 2"
                type="text"
                placeholder="Address Line 2"
                defaultValue={
                  defaultCompanyData?.billingAddress?.address2 || ""
                }
                error={!!errors.addressLine2}
                {...register("addressLine2", {
                  required: true,
                  onChange: e => handleChange("addressLine2", e.target.value)
                })}
                InputLabelProps={{
                  shrink: billingAddress.addressLine2 ? true : false
                }}
              />
            </FormControl>
            <FormControl
              sx={theme => ({
                width: "300px",
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              })}
            >
              <TextField
                className={classes.textField}
                fullWidth
                label="City"
                type="text"
                placeholder="City"
                defaultValue={defaultCompanyData?.billingAddress?.town || ""}
                error={!!errors.town}
                {...register("town", {
                  required: true,
                  onChange: e => handleChange("town", e.target.value)
                })}
                InputLabelProps={{
                  shrink: billingAddress.town ? true : false
                }}
              />
            </FormControl>
            <FormControl
              sx={theme => ({
                width: "300px",
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              })}
            >
              <TextField
                className={classes.textField}
                label="Postcode"
                type="text"
                placeholder="Postcode"
                defaultValue={
                  defaultCompanyData?.billingAddress?.postCode || ""
                }
                error={!!errors.postCode}
                {...register("postCode", {
                  required: true,
                  onChange: e => handleChange("postCode", e.target.value)
                })}
                InputLabelProps={{
                  shrink: billingAddress.postCode ? true : false
                }}
              />
            </FormControl>
          </Stack>
          <Button
            type="submit"
            variant="contained"
            sx={theme => ({
              margin: "24px 0",
              [theme.breakpoints.down("sm")]: {
                width: "100%"
              }
            })}
          >
            UPDATE
          </Button>
        </form>
      </Box>
    </Modal>
  );
}

export default EditBillingAddressModal;
