import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  getClients,
  changePage,
  resetClientsFilters,
  updateFilters,
  handleSearch,
  updateAdverseCredit
} from "reducers/clients";
import FilterListIcon from "@mui/icons-material/FilterList";
import Header from "components/Header";
import FilterDrawer from "./FIlterDrawer";
import { makeStyles } from "@mui/styles";
import Loader from "components/Loader";
import _ from "lodash";
import {
  Box,
  Pagination,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Stack,
  FormControl,
  TextField,
  Chip
} from "@mui/material";
import {
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getSortingColor,
  getSortingIcon,
  paginationStyle,
  horizontalScrollBarStyle
} from "utils";
import { USER_TYPE, defaultClientSorting, ENTER_KEY } from "utils/constants";
import ResetFilterAlert from "components/ResetFilterAlert";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  borderRight: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "2px",
      height: "14px",
      background: theme.palette.grey[300],
      top: "50%",
      transform: "translateY(-50%)",
      right: "0"
    },
    "& button": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Montserrat', sans-serif"
    }
  },
  searchBtn: {
    marginLeft: "18px !important",
    padding: "8px 22px !important"
  },
  textField: {
    color: theme.palette.text.primary,
    "& textarea": {
      color: theme.palette.text.primary
    },
    "& label": {
      color: theme.palette.text.primary
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.textFieldPalette.main
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main
      }
    },
    "& input": {
      color: theme.palette.text.primary,
      "&:-webkit-autofill": {
        "-webkit-box-shadow": `0 0 0 100px ${theme.palette.background.default} inset`,
        "-webkit-text-fill-color": theme.palette.text.primary
      }
    },
    "& .MuiOutlinedInput-root .MuiInputBase-root": {
      color: theme.palette.text.primary
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.secondary
    },
    "& .MuiInput-root.MuiInput-underline": {
      "&::before": {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      }
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      color: theme.palette.text.primary
    }
  }
}));

function Clients() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data, rowsPerPage, page, filters, search } = useSelector(
    state => state.clients
  );
  const { data: userData } = useSelector(state => state.user);

  const [clientSearch, setClientSearch] = useState(search);
  const [selectedBrokers, setSelectedBrokers] = useState([]);
  const [filterPosition, setFilterPosition] = useState({ right: false });

  const handleOnPageChange = (e, newPage) => {
    dispatch(changePage(newPage));
  };

  const toggleFilterDrawer = open => () => {
    setFilterPosition({ ...filterPosition, right: open });
  };

  const handleBrokerFilterChange = e => {
    const eventValue = e.target.value;
    if (eventValue[eventValue.length - 1] === "all") {
      setSelectedBrokers(
        selectedBrokers.length === userData.brokers.length
          ? []
          : userData.brokers
      );
      return;
    }
    setSelectedBrokers(eventValue);
  };

  const handleBrokerFilterDelete = (e, email) => {
    const filteredBrokersValues = selectedBrokers.filter(
      v => v.email !== email
    );

    setSelectedBrokers(filteredBrokersValues);
  };

  const handleOnSearchChange = clientSearch => {
    if (clientSearch) {
      dispatch(handleSearch(clientSearch));
    }
  };
  const handleSearchBtn = () => {
    handleOnSearchChange(clientSearch);
  };
  const handleKeyChange = e => {
    if (e.keyCode === ENTER_KEY) {
      handleOnSearchChange(clientSearch);
    }
  };

  const handleOnResetFilter = () => {
    dispatch(resetClientsFilters());
    setSelectedBrokers([]);
    setClientSearch("");
  };

  const handleOnSortFilterChange = sortingParam => {
    let filters_ = { ...filters, sorting: { ...filters.sorting } };
    filters_.sorting[sortingParam] = getNextSortingState(
      filters_.sorting[sortingParam]
    );
    filters_.sorting = clearOtherSortingParams(filters_.sorting, sortingParam);
    if (isNoSortingParams(filters_.sorting)) {
      filters_.sorting = defaultClientSorting;
    }
    dispatch(updateFilters(filters_));
  };

  const handleOnCheckChange = (status, id) => {
    dispatch(updateAdverseCredit({ id, data: { isAdverseCredit: status } }));
  };

  const isAllSelected =
    userData.brokers.length > 0 &&
    selectedBrokers.length === userData.brokers.length;

  useEffect(() => {
    dispatch(getClients());
  }, [dispatch, page, filters, search]);

  useEffect(() => {
    return () => dispatch(resetClientsFilters());
  }, [dispatch]);

  return (
    <>
      <Header componentName="Clients" />
      <div style={{ marginTop: "32px" }}>
        {userData?.me?.type === USER_TYPE.COMPANY_OWNER && (
          <>
            {!_.isEmpty(data.results) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "24px"
                }}
              >
                <Stack display={{ xs: "none", lg: "block" }}>
                  <Stack direction="row">
                    <FormControl sx={{ width: "260px" }}>
                      <TextField
                        className={classes.textField}
                        label="Search Client"
                        type="text"
                        value={clientSearch}
                        placeholder="Search Client"
                        onChange={e => setClientSearch(e.target.value)}
                        onKeyDown={handleKeyChange}
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      className={classes.searchBtn}
                      onClick={handleSearchBtn}
                    >
                      SEARCH
                    </Button>
                  </Stack>
                </Stack>
                <Typography
                  variant="h4"
                  color="text.primary"
                  component="h2"
                  marginRight="16px"
                  display={{ xs: "block", lg: "none" }}
                >
                  Clients
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%"
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      color: "text.primary",
                      borderColor: "text.primary",
                      opacity: 0.5,
                      padding: "5px 24px"
                    }}
                    onClick={toggleFilterDrawer(true)}
                  >
                    <FilterListIcon />
                    FILTER
                  </Button>
                </Box>
              </Box>
            )}
            {(!_.isEmpty(filters.brokers) || search) && (
              <ResetFilterAlert
                data={data?.results}
                text="clients"
                handleOnResetFilter={handleOnResetFilter}
              />
            )}
          </>
        )}
        {loading ? (
          <Loader />
        ) : (
          <div style={{ marginTop: "24px" }}>
            {!_.isEmpty(data.results) ? (
              <>
                <TableContainer
                  component={Paper}
                  sx={theme => horizontalScrollBarStyle(theme)}
                >
                  <Table sx={{ minWidth: 550 }} stickyHeader>
                    <TableHead>
                      <TableRow
                        sx={theme => ({
                          bgcolor: theme.palette.background.default,
                          "& td,th": {
                            borderBottom: `1px solid ${theme.palette.text.disabled}`
                          }
                        })}
                      >
                        <TableCell className={classes.borderRight}>
                          <Button
                            onClick={() =>
                              handleOnSortFilterChange("first_name")
                            }
                            variant="text"
                            sx={{
                              color: getSortingColor(filters.sorting.first_name)
                            }}
                            endIcon={getSortingIcon(filters.sorting.first_name)}
                          >
                            Name
                          </Button>
                        </TableCell>
                        <TableCell
                          sx={{ width: "25%", whiteSpace: "pre" }}
                          className={classes.borderRight}
                          align="center"
                        >
                          <Button
                            onClick={() =>
                              handleOnSortFilterChange("cases_count")
                            }
                            variant="text"
                            sx={{
                              color: getSortingColor(
                                filters.sorting.cases_count
                              )
                            }}
                            endIcon={getSortingIcon(
                              filters.sorting.cases_count
                            )}
                          >
                            Case Count
                          </Button>
                        </TableCell>
                        <TableCell
                          sx={{ width: "25%", whiteSpace: "pre" }}
                          className={classes.borderRight}
                          align="center"
                        >
                          <Button
                            onClick={() =>
                              handleOnSortFilterChange("is_adverse_credit")
                            }
                            variant="text"
                            sx={{
                              color: getSortingColor(
                                filters.sorting.is_adverse_credit
                              )
                            }}
                            endIcon={getSortingIcon(
                              filters.sorting.is_adverse_credit
                            )}
                          >
                            Adverse Credit
                          </Button>
                        </TableCell>
                        <TableCell sx={{ width: "25%" }} align="center">
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: "'Montserrat', sans-serif",
                              fontWeight: "500",
                              fontSize: "14px",
                              textTransform: "uppercase"
                            }}
                          >
                            Action
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.results.map(row => (
                        <TableRow
                          key={row.id}
                          sx={theme => ({
                            bgcolor: theme.palette.background.default,
                            "& td,th": {
                              borderBottom: `1px solid ${theme.palette.text.disabled}`
                            }
                          })}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: "16px",
                              color: "text.primary",
                              whiteSpace: "pre"
                            }}
                          >
                            <Typography>
                              {_.isEmpty(row.fullName) ? "N/A" : row.fullName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: "16px",
                              color: "text.primary",
                              width: "25%"
                            }}
                            align="center"
                          >
                            <Typography>{row.casesCount}</Typography>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: "16px",
                              color: "text.primary",
                              width: "20%"
                            }}
                            align="center"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              {
                                <Chip
                                  label={row.isAdverseCredit ? "Yes" : "No"}
                                  onClick={() =>
                                    handleOnCheckChange(
                                      !row.isAdverseCredit,
                                      row.id
                                    )
                                  }
                                  style={{
                                    fontFamily: "'Nunito Sans', sans-serif",
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    marginLeft: "8px"
                                  }}
                                  variant="outlined"
                                  color={
                                    row.isAdverseCredit ? "error" : "primary"
                                  }
                                />
                              }
                            </Stack>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: "16px",
                              color: "text.primary",
                              width: "25%"
                            }}
                            align="center"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Link
                                to={`/clients/${row.id}`}
                                style={{ textDecoration: "none" }}
                              >
                                <Button
                                  variant="contained"
                                  startIcon={<RemoveRedEyeIcon />}
                                >
                                  View
                                </Button>
                              </Link>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={Math.ceil(data.count / rowsPerPage)}
                  page={page}
                  onChange={handleOnPageChange}
                  sx={theme => paginationStyle(theme)}
                />
              </>
            ) : (
              <Typography variant="h5" color="text.primary" component="h5">
                There are no clients yet
              </Typography>
            )}
          </div>
        )}
      </div>
      <FilterDrawer
        position={filterPosition}
        toggleDrawer={toggleFilterDrawer}
        selectedBrokers={selectedBrokers}
        handleBrokerFilterChange={handleBrokerFilterChange}
        handleBrokerFilterDelete={handleBrokerFilterDelete}
        isAllSelected={isAllSelected}
        clientSearch={clientSearch}
        setClientSearch={setClientSearch}
      />
    </>
  );
}

export default Clients;
