import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MoneyIcon from "@mui/icons-material/Money";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CommissionSplitModal from "./CommissionSplitModal";
import ConfirmPaymentModal from "./ConfirmPaymentModal";
import { defaultCompanySorting, FIRST_PAGE } from "utils/constants";
import {
  updateBrokersFilters,
  updateBrokers,
  changePage,
  getCompanyBrokers,
  updateCompletePaymentModal
} from "reducers/company";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Pagination
} from "@mui/material";
import {
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getSortingColor,
  getSortingIcon,
  paginationStyle,
  horizontalScrollBarStyle
} from "utils";
import Loader from "components/Loader";
import SignatureModal from "pages/Settings/SignatureModal";

const useStyles = makeStyles(theme => ({
  borderRight: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "2px",
      height: "14px",
      background: theme.palette.grey[300],
      top: "50%",
      transform: "translateY(-50%)",
      right: "0"
    }
  }
}));

function BrokersTab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCommissionPercentage, setIsCommissionPercentageModal] = useState(
    false
  );
  const [isSignatureModal, setIsSignatureModal] = useState(false);
  const [companyBrokers, setCompanyBrokers] = useState({});
  const {
    filtersBrokers,
    data,
    page,
    rowsPerPage,
    isConfirmPaymentModal
  } = useSelector(state => state.company);
  const { data: user } = useSelector(state => state.user);
  const open = Boolean(anchorEl);

  const handleOnSortFilterBrokersChange = sortingParam => {
    let filters_ = {
      ...filtersBrokers,
      sorting: { ...filtersBrokers.sorting }
    };
    filters_.sorting[sortingParam] = getNextSortingState(
      filters_.sorting[sortingParam]
    );
    filters_.sorting = clearOtherSortingParams(filters_.sorting, sortingParam);
    if (isNoSortingParams(filters_.sorting)) {
      filters_.sorting = defaultCompanySorting;
    }
    dispatch(updateBrokersFilters(filters_));
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCommissionPercentageModal = data => {
    const commissionPercentage = {
      id: companyBrokers.id,
      data
    };
    dispatch(updateBrokers(commissionPercentage));
    setIsCommissionPercentageModal(false);
  };

  const handleOnPageChange = (e, newPage) => {
    dispatch(changePage(newPage));
  };

  const handleActiveAndDeactive = () => {
    const activeStatus = {
      id: companyBrokers.id,
      data: {
        isActive: !companyBrokers.isActive
      }
    };
    dispatch(updateBrokers(activeStatus));
  };

  useEffect(() => {
    dispatch(getCompanyBrokers());
  }, [dispatch, filtersBrokers, page]);

  useEffect(() => {
    return () => dispatch(changePage(FIRST_PAGE));
  }, [dispatch]);

  return (
    <>
      {data?.results?.length > 0 ? (
        <>
          <TableContainer
            component={Paper}
            sx={theme => horizontalScrollBarStyle(theme)}
          >
            <Table sx={{ minWidth: 550 }} stickyHeader>
              <TableHead>
                <TableRow
                  sx={theme => ({
                    bgcolor: theme.palette.background.default,
                    "& th": { padding: "10px" },
                    "& td,th": {
                      borderBottom: `1px solid ${theme.palette.text.disabled}`
                    }
                  })}
                >
                  <TableCell className={classes.borderRight}>
                    <Button
                      onClick={() => handleOnSortFilterBrokersChange("email")}
                      variant="text"
                      sx={{
                        color: getSortingColor(filtersBrokers.sorting.email)
                      }}
                      startIcon={getSortingIcon(filtersBrokers.sorting.email)}
                    >
                      Email
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "15%" }}
                    className={classes.borderRight}
                  >
                    <Button
                      onClick={() =>
                        handleOnSortFilterBrokersChange("first_name")
                      }
                      variant="text"
                      sx={{
                        color: getSortingColor(
                          filtersBrokers.sorting.first_name
                        )
                      }}
                      startIcon={getSortingIcon(
                        filtersBrokers.sorting.first_name
                      )}
                    >
                      Name
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "7%", textAlign: "center" }}
                    className={classes.borderRight}
                  >
                    <Button
                      onClick={() =>
                        handleOnSortFilterBrokersChange("is_active")
                      }
                      variant="text"
                      sx={{
                        color: getSortingColor(filtersBrokers.sorting.is_active)
                      }}
                      startIcon={getSortingIcon(
                        filtersBrokers.sorting.is_active
                      )}
                    >
                      Active
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "13%", textAlign: "center" }}
                    className={classes.borderRight}
                  >
                    <Button
                      onClick={() =>
                        handleOnSortFilterBrokersChange(
                          "blabble_commission_percentage"
                        )
                      }
                      variant="text"
                      sx={{
                        color: getSortingColor(
                          filtersBrokers.sorting.blabble_commission_percentage
                        ),
                        whiteSpace: "pre"
                      }}
                      startIcon={getSortingIcon(
                        filtersBrokers.sorting.blabble_commission_percentage
                      )}
                    >
                      Blabble %
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "14%", textAlign: "center" }}
                    className={classes.borderRight}
                  >
                    <Button
                      onClick={() =>
                        handleOnSortFilterBrokersChange(
                          "personal_commission_percentage"
                        )
                      }
                      variant="text"
                      sx={{
                        color: getSortingColor(
                          filtersBrokers.sorting.personal_commission_percentage
                        ),
                        whiteSpace: "pre"
                      }}
                      startIcon={getSortingIcon(
                        filtersBrokers.sorting.personal_commission_percentage
                      )}
                    >
                      Personal %
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "13%", textAlign: "center" }}
                    className={classes.borderRight}
                  >
                    <Button
                      onClick={() =>
                        handleOnSortFilterBrokersChange(
                          "other_commission_percentage"
                        )
                      }
                      variant="text"
                      sx={{
                        color: getSortingColor(
                          filtersBrokers.sorting.other_commission_percentage
                        ),
                        whiteSpace: "pre"
                      }}
                      startIcon={getSortingIcon(
                        filtersBrokers.sorting.other_commission_percentage
                      )}
                    >
                      {user?.me?.otherLeadsName} %
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "15%", textAlign: "center" }}
                    className={classes.borderRight}
                  >
                    <Button variant="text" sx={{ color: "text.primary" }}>
                      Actions
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.results?.map(row => (
                  <TableRow
                    key={row.id}
                    sx={theme => ({
                      bgcolor: theme.palette.background.default,
                      "& td,th": {
                        borderBottom: `1px solid ${theme.palette.text.disabled}`
                      }
                    })}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: "16px",
                        color: "text.primary"
                      }}
                    >
                      <Typography variant="body1">{row.email}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "14px",
                        width: "15%"
                      }}
                    >
                      <Typography variant="body1">
                        {row.fullName ? row.fullName : "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        color: "text.primary",
                        width: "7%",
                        textAlign: "center"
                      }}
                    >
                      {row.isActive ? (
                        <DoneIcon color="primary" />
                      ) : (
                        <ClearIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell sx={{ width: "13%", textAlign: "center" }}>{`${
                      row.blabbleCommissionPercentage
                        ? `${row.blabbleCommissionPercentage}%`
                        : "N/A"
                    }`}</TableCell>
                    <TableCell sx={{ width: "14%", textAlign: "center" }}>{`${
                      row.personalCommissionPercentage
                        ? `${row.personalCommissionPercentage}%`
                        : "N/A"
                    }`}</TableCell>
                    <TableCell sx={{ width: "13%", textAlign: "center" }}>{`${
                      row.otherCommissionPercentage
                        ? `${row.otherCommissionPercentage}%`
                        : "N/A"
                    }`}</TableCell>
                    <TableCell sx={{ width: "15%", textAlign: "center" }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={e => {
                            handleClick(e);
                            setCompanyBrokers(row);
                          }}
                          startIcon={<MoreVertIcon />}
                        >
                          Manage
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          className={classes.menu}
                          sx={theme => ({
                            "& .MuiMenu-paper": {
                              backgroundColor: theme.palette.text.dark.primary,
                              boxShadow: "unset"
                            }
                          })}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setIsCommissionPercentageModal(true);
                            }}
                          >
                            <ListItemIcon>
                              <MoneyIcon sx={{ color: "text.primary" }} />
                            </ListItemIcon>
                            <ListItemText>Change Commission %</ListItemText>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setIsSignatureModal(true);
                            }}
                          >
                            <ListItemIcon>
                              <AssignmentIndIcon
                                sx={{ color: "text.primary" }}
                              />
                            </ListItemIcon>
                            <ListItemText>View Signature</ListItemText>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              handleActiveAndDeactive();
                            }}
                          >
                            <ListItemIcon>
                              <PersonOffIcon sx={{ color: "text.primary" }} />
                            </ListItemIcon>
                            <ListItemText>
                              {companyBrokers.isActive ? "Deactive" : "Active"}
                            </ListItemText>
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(data.count / rowsPerPage)}
            page={page}
            onChange={handleOnPageChange}
            sx={theme => paginationStyle(theme)}
          />
        </>
      ) : (
        <Loader />
      )}

      {isCommissionPercentage && (
        <Box>
          <CommissionSplitModal
            isOpen={isCommissionPercentage}
            companyBrokers={companyBrokers}
            handleCommissionPercentageModal={handleCommissionPercentageModal}
            handleClose={() => setIsCommissionPercentageModal(false)}
          />
        </Box>
      )}
      {isSignatureModal && (
        <Box>
          <SignatureModal
            isOpen={isSignatureModal}
            handleClose={() => setIsSignatureModal(false)}
            signature={companyBrokers.renderedSignature}
          />
        </Box>
      )}
      {isConfirmPaymentModal && (
        <Box>
          <ConfirmPaymentModal
            isOpen={isConfirmPaymentModal}
            handleClose={() => dispatch(updateCompletePaymentModal(false))}
          />
        </Box>
      )}
    </>
  );
}

export default BrokersTab;
