import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Modal from "components/Modals/index";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { Button, Box, ButtonGroup, Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { creditsUpdate } from "reducers/credits";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

function UpdateCreditsModal({ isOpen, handleClose, broker }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      quantity: broker.balance
    }
  });
  const watchQuantity = watch("quantity");

  const onSubmit = value => {
    const shareDetail = {
      ...value,
      broker: broker.id
    };
    dispatch(creditsUpdate(shareDetail));
    handleClose();
  };

  const increment = () => setValue("quantity", +watchQuantity + 1);

  const decrement = () => setValue("quantity", +watchQuantity - 1);

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Update Credits">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <ButtonGroup
            sx={theme => ({
              "& input": {
                paddingTop: "10px",
                paddingBottom: "8px"
              },
              "& button": {
                borderRadius: 0,
                borderColor: `${theme.palette.primary.main} !important`
              },
              "& .MuiInputBase-formControl": {
                borderRadius: 0,
                borderColor: "primary.main"
              },
              "& fieldset": {
                borderRight: 0
              },
              "& button.Mui-disabled": {
                opacity: 0.2,
                color: "primary.main",
                borderColor: "primary.main"
              }
            })}
          >
            <Button disabled={watchQuantity < 1} onClick={decrement}>
              <RemoveIcon />
            </Button>
            <TextField
              type="number"
              className={classes.textField}
              style={{ width: "90px" }}
              placeholder="Quantity"
              error={!!errors.quantity}
              {...register("quantity", {
                required: true,
                min: 0
              })}
              InputProps={{
                inputProps: { step: "any" }
              }}
            />
            <Button onClick={increment}>
              <AddIcon />
            </Button>
          </ButtonGroup>
          <Box>
            <Button
              sx={theme => ({
                marginTop: 3,
                [theme.breakpoints.down("sm")]: {
                  width: "100%"
                }
              })}
              type="submit"
              variant="contained"
            >
              UPDATE
            </Button>
          </Box>
        </Stack>
      </form>
    </Modal>
  );
}

export default UpdateCreditsModal;
