import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "components/Loader";
import SelectField from "components/SelectField";
import { Link } from "react-router-dom";
import LineGraph from "./LineGraph";
import {
  paginationStyle,
  checkPositiveNegetiveValue,
  moneyFieldConverter,
  grossEarningTransformerForLineChart
} from "utils";
import { getDashboard, getLeaderboard, changePage } from "reducers/dashboard";
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  Stack,
  Chip,
  Divider,
  Pagination,
  Paper
} from "@mui/material";
import { TIME_DURATION } from "utils/constants";
import { ROUTES } from "routes/constants";

const CompanyOwnerDashboard = ({ boxStyle, chipStyle }) => {
  const dispatch = useDispatch();
  const { loading, data, rowsPerPage, page } = useSelector(
    state => state.dashboard
  );
  const { theme } = useSelector(state => state.user);
  const [durationId, setDurationId] = useState(TIME_DURATION[1].id);

  useEffect(() => {
    dispatch(getDashboard());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLeaderboard(TIME_DURATION[1].value));
  }, [dispatch, page]);

  const handleChange = e => {
    setDurationId(e.target.value);

    let result = TIME_DURATION.find(item => item.id === e.target.value);
    dispatch(getLeaderboard(result.value));
  };

  const handleOnPageChange = (e, newPage) => {
    dispatch(changePage(newPage));
  };

  return (
    <>
      {!!data.dashboard.earnings ? (
        <>
          <Grid
            container
            spacing={2}
            sx={theme => ({
              marginTop: "24px",
              [theme.breakpoints.down("sm")]: {
                marginTop: 0
              }
            })}
          >
            <Grid item xs={12} sm={6} xl={4}>
              <Box sx={theme => boxStyle(theme)} style={{ minHeight: "460px" }}>
                <Typography variant="h6" color="text.primary">
                  Leads
                </Typography>
                <Typography
                  variant="caption"
                  color="text.disabled"
                  sx={{
                    textTransform: "uppercase",
                    margin: "8px 0",
                    display: "block"
                  }}
                >
                  THIS MONTH
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Acquired
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="caption"
                        color={
                          data.dashboard.leads
                            .leadsAcquiredPercentChangeThisMonth >= 0
                            ? "success.main"
                            : "error.main"
                        }
                        sx={{
                          fontWeight: "700",
                          position: "relative",
                          "&::before": {
                            content: "''",
                            position: "absolute",
                            border: "3px solid transparent",
                            left: "-12px",
                            top: "40%",
                            borderTopColor:
                              data.dashboard.leads
                                .leadsAcquiredPercentChangeThisMonth >= 0
                                ? "success.main"
                                : "error.main",
                            transform:
                              data.dashboard.leads
                                .leadsAcquiredPercentChangeThisMonth >= 0
                                ? "translateY(-50%) rotate(180deg)"
                                : "unset"
                          }
                        }}
                      >
                        {checkPositiveNegetiveValue(
                          data.dashboard.leads
                            .leadsAcquiredPercentChangeThisMonth
                        )}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                          fontWeight: "700",
                          minWidth: " 25px",
                          textAlign: "end",
                          marginLeft: "5px"
                        }}
                      >
                        {data.dashboard.leads.leadsAcquiredThisMonth}
                      </Typography>
                    </Stack>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Converted
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Stack direction="row" alignItems="center">
                      <Typography
                        variant="caption"
                        color={
                          data.dashboard.leads
                            .leadsConvertedPercentChangeThisMonth >= 0
                            ? "success.main"
                            : "error.main"
                        }
                        sx={{
                          fontWeight: "700",
                          position: "relative",
                          "&::before": {
                            content: "''",
                            position: "absolute",
                            border: "3px solid transparent",
                            left: "-12px",
                            top: "40%",
                            borderTopColor:
                              data.dashboard.leads
                                .leadsConvertedPercentChangeThisMonth >= 0
                                ? "success.main"
                                : "error.main",
                            transform:
                              data.dashboard.leads
                                .leadsConvertedPercentChangeThisMonth >= 0
                                ? "translateY(-50%) rotate(180deg)"
                                : "unset"
                          }
                        }}
                      >
                        {checkPositiveNegetiveValue(
                          data.dashboard.leads
                            .leadsConvertedPercentChangeThisMonth
                        )}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                          fontWeight: "700",
                          minWidth: " 25px",
                          textAlign: "end",
                          marginLeft: "5px"
                        }}
                      >
                        {data.dashboard.leads.leadsConvertedThisMonth}
                      </Typography>
                    </Stack>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Pending
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.leads.leadsPendingThisMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Not Proceeding
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.leads.leadsNotProceedingThisMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List sx={{ display: "flex", padding: 0 }}>
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Conversion Rate
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.leads.leadsConversionRateThisMonth}%
                    </Typography>
                  </ListItem>
                </List>
                <Typography
                  variant="caption"
                  color="text.disabled"
                  sx={{
                    textTransform: "uppercase",
                    margin: "8px 0",
                    display: "block"
                  }}
                >
                  Last Month
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Acquired
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{
                        fontWeight: "700",
                        minWidth: " 25px",
                        textAlign: "end",
                        marginLeft: "5px"
                      }}
                    >
                      {data.dashboard.leads.leadsAcquiredLastMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Converted
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{
                        fontWeight: "700",
                        minWidth: " 25px",
                        textAlign: "end",
                        marginLeft: "5px"
                      }}
                    >
                      {data.dashboard.leads.leadsConvertedLastMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Pending
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.leads.leadsPendingLastMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Not Proceeding
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.leads.leadsNotProceedingLastMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List sx={{ display: "flex", padding: 0 }}>
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Conversion Rate
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.leads.leadsConversionRateLastMonth}%
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <Box sx={theme => boxStyle(theme)}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  Mortgages
                </Typography>
                <Typography
                  variant="caption"
                  color="text.disabled"
                  sx={{
                    textTransform: "uppercase",
                    margin: "8px 0",
                    display: "block"
                  }}
                >
                  THIS MONTH
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Completed
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.mortgages.completedMortgagesThisMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      In Progress
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.mortgages.inProgressMortgagesThisMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Gross
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard?.mortgages?.grossEarningsThisMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Commission
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.mortgages.commissionEarningsThisMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Net
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.mortgages.netEarningsThisMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Pending (Net)
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.mortgages
                          .netPendingEarningsMortgageThisMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <Typography
                  variant="caption"
                  color="text.disabled"
                  sx={{
                    textTransform: "uppercase",
                    margin: "8px 0",
                    display: "block"
                  }}
                >
                  LAST MONTH
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Completed
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.mortgages.completedMortgagesLastMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      In Progress
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.mortgages.inProgressMortgagesLastMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Gross
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.mortgages.grossEarningsLastMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Commission
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.mortgages.commissionEarningsLastMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Net
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.mortgages.netEarningsLastMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Pending (Net)
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.mortgages
                          .netPendingEarningsMortgageLastMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <Box sx={theme => boxStyle(theme)}>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  Insurance
                </Typography>
                <Typography
                  variant="caption"
                  color="text.disabled"
                  sx={{
                    textTransform: "uppercase",
                    margin: "8px 0",
                    display: "block"
                  }}
                >
                  THIS MONTH
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Sold
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.insurances.insurancesSoldThisMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      In Progress
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.insurances.inProgressInsurancesThisMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Gross
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard?.insurances?.grossEarningsThisMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Commission
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.insurances.commissionEarningsThisMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Net
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.insurances.netEarningsThisMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Pending (Net)
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.insurances
                          .netPendingEarningsInsurancesThisMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <Typography
                  variant="caption"
                  color="text.disabled"
                  sx={{
                    textTransform: "uppercase",
                    margin: "8px 0",
                    display: "block"
                  }}
                >
                  LAST MONTH
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Sold
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.insurances.insurancesSoldLastMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      In Progress
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {data.dashboard.insurances.inProgressInsurancesLastMonth}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Gross
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.insurances.grossEarningsLastMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Commission
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.insurances.commissionEarningsLastMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Net
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.insurances.netEarningsLastMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 0,
                    marginBottom: "6px"
                  }}
                >
                  <ListItem style={{ padding: 0, marginRight: "12px" }}>
                    <Typography variant="body1" color="text.primary">
                      Pending (Net)
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: 0, width: "unset" }}>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{ fontWeight: "700" }}
                    >
                      {moneyFieldConverter(
                        data.dashboard.insurances
                          .netPendingEarningsInsurancesLastMonth
                      )}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
          <Typography
            variant="h6"
            color="text.primary"
            sx={{ margin: "32px 0" }}
          >
            Earnings
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} xl={4}>
              <Box sx={theme => boxStyle(theme)}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack marginRight="8px">
                    <Typography variant="body2" color="text.secondary">
                      This Month
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {moneyFieldConverter(
                        data.dashboard?.earnings?.grossEarningsThisMonth
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Last Month
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {moneyFieldConverter(
                        data.dashboard?.earnings?.grossEarningsLastMonth
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={
                        data.dashboard.earnings
                          .grossEarningsPercentChangeThisMonth >= 0
                          ? "success.main"
                          : "error.main"
                      }
                      sx={{
                        fontWeight: "700",
                        marginLeft: "12px",
                        position: "relative",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          border: "3px solid transparent",
                          left: "-12px",
                          top: "40%",
                          borderTopColor:
                            data.dashboard.earnings
                              .grossEarningsPercentChangeThisMonth >= 0
                              ? "success.main"
                              : "error.main",
                          transform:
                            data.dashboard.earnings
                              .grossEarningsPercentChangeThisMonth >= 0
                              ? "translateY(-50%) rotate(180deg)"
                              : "unset"
                        }
                      }}
                    >
                      {checkPositiveNegetiveValue(
                        data.dashboard.earnings
                          .grossEarningsPercentChangeThisMonth
                      )}
                    </Typography>
                  </Stack>
                  <Paper
                    sx={theme => ({
                      width: { xs: "120px", lg: "70px", xxl: "120px" },
                      bgcolor: theme.palette.text.dark.primary
                    })}
                    elevation={0}
                  >
                    <Box height="60px">
                      <LineGraph
                        data={grossEarningTransformerForLineChart(
                          data.dashboard.earnings.grossEarningsLastMonth,
                          data.dashboard.earnings.grossEarningsThisMonth
                        )}
                        theme={theme}
                      />
                    </Box>
                  </Paper>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <Box sx={theme => boxStyle(theme)}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack marginRight="8px">
                    <Typography variant="body2" color="text.secondary">
                      This Quarter
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {moneyFieldConverter(
                        data.dashboard.earnings.grossEarningsThisQuarter
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Last Quarter
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {moneyFieldConverter(
                        data.dashboard.earnings.grossEarningsLastQuarter
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={
                        data.dashboard.earnings
                          .grossEarningsPercentChangeThisQuarter >= 0
                          ? "success.main"
                          : "error.main"
                      }
                      sx={{
                        fontWeight: "700",
                        marginLeft: "12px",
                        position: "relative",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          border: "3px solid transparent",
                          left: "-12px",
                          top: "40%",
                          borderTopColor:
                            data.dashboard.earnings
                              .grossEarningsPercentChangeThisQuarter >= 0
                              ? "success.main"
                              : "error.main",
                          transform:
                            data.dashboard.earnings
                              .grossEarningsPercentChangeThisQuarter >= 0
                              ? "translateY(-50%) rotate(180deg)"
                              : "unset"
                        }
                      }}
                    >
                      {checkPositiveNegetiveValue(
                        data.dashboard.earnings
                          .grossEarningsPercentChangeThisQuarter
                      )}
                    </Typography>
                  </Stack>
                  <Paper
                    sx={theme => ({
                      width: { xs: "120px", lg: "70px", xxl: "120px" },
                      bgcolor: theme.palette.text.dark.primary
                    })}
                    elevation={0}
                  >
                    <Box height="60px">
                      <LineGraph
                        data={grossEarningTransformerForLineChart(
                          data.dashboard.earnings.grossEarningsLastQuarter,
                          data.dashboard.earnings.grossEarningsThisQuarter
                        )}
                        theme={theme}
                      />
                    </Box>
                  </Paper>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} xl={4}>
              <Box sx={theme => boxStyle(theme)}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack marginRight="8px">
                    <Typography variant="body2" color="text.secondary">
                      This Year
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {moneyFieldConverter(
                        data.dashboard.earnings.grossEarningsThisYear
                      )}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Last Year
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {moneyFieldConverter(
                        data.dashboard.earnings.grossEarningsLastYear
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={
                        data.dashboard.earnings
                          .grossEarningsPercentChangeThisYear >= 0
                          ? "success.main"
                          : "error.main"
                      }
                      sx={{
                        fontWeight: "700",
                        marginLeft: "12px",
                        position: "relative",
                        "&::before": {
                          content: "''",
                          position: "absolute",
                          border: "3px solid transparent",
                          left: "-12px",
                          top: "40%",
                          borderTopColor:
                            data.dashboard.earnings
                              .grossEarningsPercentChangeThisYear >= 0
                              ? "success.main"
                              : "error.main",
                          transform:
                            data.dashboard.earnings
                              .grossEarningsPercentChangeThisYear >= 0
                              ? "translateY(-50%) rotate(180deg)"
                              : "unset"
                        }
                      }}
                    >
                      {checkPositiveNegetiveValue(
                        data.dashboard.earnings
                          .grossEarningsPercentChangeThisYear
                      )}
                    </Typography>
                  </Stack>
                  <Paper
                    sx={theme => ({
                      width: { xs: "120px", lg: "70px", xxl: "120px" },
                      bgcolor: theme.palette.text.dark.primary
                    })}
                    elevation={0}
                  >
                    <Box height="60px">
                      <LineGraph
                        data={grossEarningTransformerForLineChart(
                          data.dashboard.earnings.grossEarningsLastYear,
                          data.dashboard.earnings.grossEarningsThisYear
                        )}
                        theme={theme}
                      />
                    </Box>
                  </Paper>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ margin: "32px 0" }}
          >
            <Typography variant="h6" color="text.primary">
              Users
            </Typography>
            <SelectField
              sx={{ width: 200 }}
              label="Duration"
              defaultValue={""}
              value={durationId}
              onChange={e => handleChange(e)}
              options={TIME_DURATION.map(({ id, name }) => ({
                value: id,
                label: name
              }))}
            />
          </Stack>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Grid
                container
                spacing={2}
                sx={theme => ({
                  marginBottom: "24px",
                  [theme.breakpoints.down("sm")]: {
                    marginBottom: 0
                  }
                })}
              >
                {data.leaderboard?.results?.map(item => (
                  <Grid item xs={12} sm={6} xl={4} key={item.id}>
                    <Link
                      to={`${ROUTES.DASHBOARD}/${item.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Box sx={theme => boxStyle(theme)}>
                        <Chip
                          label={`Rank # ${item.rank}`}
                          sx={theme => chipStyle(theme, item.rank)}
                        />
                        <Typography
                          variant="h6"
                          color="text.primary"
                          sx={{ minHeight: "65px" }}
                        >
                          {item.fullName}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            wordBreak: "break-all",
                            minHeight: "40px",
                            fontWeight: "700"
                          }}
                        >
                          {item.email}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.disabled"
                          sx={{
                            textTransform: "uppercase",
                            margin: "12px 0",
                            display: "block"
                          }}
                        >
                          leads
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Acquired
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {item.leadsAcquired}
                            </Typography>
                          </ListItem>
                        </List>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Converted
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {item.leadsConverted}
                            </Typography>
                          </ListItem>
                        </List>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Rate
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {item.leadsConversionRate.toFixed(2)}%
                            </Typography>
                          </ListItem>
                        </List>
                        <Typography
                          variant="caption"
                          color="text.disabled"
                          sx={{
                            textTransform: "uppercase",
                            margin: "12px 0",
                            display: "block"
                          }}
                        >
                          Mortgage
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Completed
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {item.mortgagesCompletions}
                            </Typography>
                          </ListItem>
                        </List>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Earnings
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {moneyFieldConverter(item.mortgagesGrossEarnings)}
                            </Typography>
                          </ListItem>
                        </List>
                        <Divider sx={{ margin: "8px 0" }} />
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Pending
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {item.mortgagesPending}
                            </Typography>
                          </ListItem>
                        </List>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Earnings
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {moneyFieldConverter(
                                item.mortgagesGrossPendingEarnings
                              )}
                            </Typography>
                          </ListItem>
                        </List>
                        <Typography
                          variant="caption"
                          color="text.disabled"
                          sx={{
                            textTransform: "uppercase",
                            margin: "12px 0",
                            display: "block"
                          }}
                        >
                          Insurance
                        </Typography>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Completed
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {item.insurancesSold}
                            </Typography>
                          </ListItem>
                        </List>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Earnings
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {moneyFieldConverter(
                                item.insurancesGrossEarnings
                              )}
                            </Typography>
                          </ListItem>
                        </List>
                        <Divider sx={{ margin: "8px 0" }} />
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Pending
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {item.insurancesPending}
                            </Typography>
                          </ListItem>
                        </List>
                        <List
                          sx={{
                            display: "flex",
                            padding: 0,
                            marginBottom: "6px"
                          }}
                        >
                          <ListItem style={{ padding: 0, marginRight: "12px" }}>
                            <Typography variant="body1" color="text.primary">
                              Earnings
                            </Typography>
                          </ListItem>
                          <ListItem
                            sx={{ padding: 0, justifyContent: "flex-end" }}
                          >
                            <Typography
                              variant="body1"
                              color="text.primary"
                              sx={{ fontWeight: "700" }}
                            >
                              {moneyFieldConverter(
                                item.insurancesGrossPendingEarnings
                              )}
                            </Typography>
                          </ListItem>
                        </List>
                      </Box>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              <Pagination
                count={Math.ceil(data.leaderboard.count / rowsPerPage)}
                page={page}
                onChange={handleOnPageChange}
                sx={theme => paginationStyle(theme)}
              />
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CompanyOwnerDashboard;
