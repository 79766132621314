import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { FIRST_PAGE } from "utils/constants";

const initialState = {
  loading: false,
  data: { results: [] },
  notificationsDrawer: {
    count: null,
    unreadCount: null,
    results: []
  },
  filters: {
    broker: null,
    date: null,
    status: null,
    company: null
  },
  activeTab: "unread",
  page: 1,
  notificationDrawerPage: 1,
  rowsPerPage: 12,
  error: null
};

export const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    getNotifications: (state, action) => {
      state.loading = true;
    },
    getNotificationsSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getNotificationsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changePageNotificationDrawer: (state, action) => {
      state.notificationDrawerPage = action.payload;
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
      state.page = 1;
    },
    removeNotifications: (state, action) => {
      state.loading = true;
    },
    removeNotificationsSucceed: (state, action) => {
      state.loading = false;
    },
    removeNotificationsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateNotifications: (state, action) => {
      state.loading = true;
    },
    updateNotificationsSucceed: (state, action) => {
      state.loading = false;
    },
    updateNotificationsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetFilters: (state, action) => {
      state.filters = {
        broker: null,
        date: null,
        status: null,
        company: null
      };
    },
    getDrawerNotifications: (state, action) => {
      state.loading = true;
    },
    getDrawerNotificationsSucceed: (state, action) => {
      state.loading = false;
      state.notificationsDrawer.count = action.payload.count;
      state.notificationsDrawer.unreadCount = action.payload.unreadCount;
      if (state.notificationDrawerPage === FIRST_PAGE) {
        state.notificationsDrawer.results = action.payload.results;
      } else {
        state.notificationsDrawer.results = _.uniqBy(
          [...state.notificationsDrawer.results, ...action.payload.results],
          "id"
        );
      }
    },
    getDrawerNotificationsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetNotificationDrawer: state => {
      state.notificationsDrawer.count = 0;
      state.notificationsDrawer.results = [];
      state.notificationDrawerPage = FIRST_PAGE;
    },
    postOnesignalPlayerId: (state, action) => {
      state.loading = true;
    },
    postOnesignalPlayerIdSucceed: (state, action) => {
      state.loading = false;
    },
    postOnesignalPlayerIdFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    patchPushNotificationPreference: (state, action) => {
      state.loading = true;
    },
    patchPushNotificationPreferenceSucceed: (state, action) => {
      state.loading = false;
    },
    patchPushNotificationPreferenceFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    patchSmsPreference: (state, action) => {
      state.loading = true;
    },
    patchSmsPreferenceSucceed: (state, action) => {
      state.loading = false;
    },
    patchSmsPreferenceFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  getNotifications,
  getNotificationsSucceed,
  getNotificationsFailed,
  updateFilters,
  updateActiveTab,
  changePage,
  changePageNotificationDrawer,
  removeNotifications,
  removeNotificationsSucceed,
  removeNotificationsFailed,
  updateNotifications,
  updateNotificationsSucceed,
  updateNotificationsFailed,
  resetFilters,
  getDrawerNotifications,
  getDrawerNotificationsSucceed,
  getDrawerNotificationsFailed,
  resetNotificationDrawer,
  postOnesignalPlayerId,
  postOnesignalPlayerIdSucceed,
  postOnesignalPlayerIdFailed,
  patchPushNotificationPreference,
  patchPushNotificationPreferenceSucceed,
  patchPushNotificationPreferenceFailed,
  patchSmsPreference,
  patchSmsPreferenceSucceed,
  patchSmsPreferenceFailed
} = notifications.actions;

export default notifications.reducer;
