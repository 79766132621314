import React from "react";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import ResetFilterAlert from "components/ResetFilterAlert";
import SelectField from "components/SelectField";
import { dateStyle } from "utils/DatePickerStyle";
import { makeStyles } from "@mui/styles";
import { USER_TYPE } from "utils/constants";
import { useTheme } from "@mui/material/styles";
import {
  TextField,
  Stack,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox
} from "@mui/material";
import _ from "lodash";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

function Filters({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCompany,
  handleCampanyChange,
  selectedBrokers,
  handleBrokerChange,
  handleFilter,
  resetFilterBtn,
  reportData
}) {
  const classes = useStyles();
  const themeMode = useTheme();
  const MenuProps = {
    PaperProps: {
      sx: {
        maxHeight: 280,
        backgroundColor: themeMode.palette.text.dark.primary
      }
    }
  };
  const { isFilterApplied, loading } = useSelector(state => state.reports);
  const { theme, data: userData } = useSelector(state => state.user);

  return (
    <>
      {loading || (
        <Stack display={{ xs: "none", lg: "block" }}>
          <Stack direction="row" flexWrap="wrap">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={"Start Date"}
                value={startDate}
                onChange={setStartDate}
                maxDate={new Date()}
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    sx={{
                      width: 200,
                      marginRight: "24px",
                      marginBottom: "24px"
                    }}
                  />
                )}
                PopperProps={{
                  sx: dateStyle(theme)
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="End Date"
                value={endDate}
                onChange={setEndDate}
                maxDate={new Date()}
                renderInput={params => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    sx={theme => ({
                      width: 200,
                      marginRight: "24px",
                      marginBottom: "24px"
                    })}
                  />
                )}
                PopperProps={{
                  sx: dateStyle(theme)
                }}
              />
            </LocalizationProvider>
            {userData?.me?.type === USER_TYPE.ADMIN && (
              <FormControl className={classes.textField}>
                <SelectField
                  sx={theme => ({
                    width: 200,
                    marginRight: "24px",
                    marginBottom: "24px"
                  })}
                  label={"Company"}
                  defaultValue={""}
                  value={selectedCompany}
                  onChange={handleCampanyChange}
                  options={userData.companies?.map(({ id, name }) => ({
                    value: id,
                    label: name
                  }))}
                />
              </FormControl>
            )}
            {!_.isEmpty(userData.brokers) && (
              <FormControl className={classes.textField}>
                <InputLabel>Brokers</InputLabel>
                <Select
                  multiple
                  sx={theme => ({
                    width: 200,
                    marginRight: "24px",
                    marginBottom: "24px"
                  })}
                  value={selectedBrokers}
                  onChange={handleBrokerChange}
                  input={<OutlinedInput label="Brokers" />}
                  renderValue={selected =>
                    selected.map(broker => broker.email).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {userData.brokers?.map(broker => (
                    <MenuItem
                      className="brokerData"
                      key={broker.id}
                      value={broker}
                      sx={theme => ({
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.text.dark.primary
                      })}
                    >
                      <Checkbox
                        checked={
                          !!selectedBrokers.find(
                            data => data.email === broker.email
                          )
                        }
                        sx={{ color: "text.secondary" }}
                      />
                      <ListItemText primary={broker.email} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              variant="contained"
              sx={theme => ({
                width: "120px",
                marginBottom: "24px"
              })}
              onClick={handleFilter}
            >
              Apply
            </Button>
          </Stack>
        </Stack>
      )}
      {isFilterApplied && (
        <ResetFilterAlert
          data={reportData}
          text="report"
          handleOnResetFilter={resetFilterBtn}
        />
      )}
    </>
  );
}

export default Filters;
