import React, { useState, useEffect } from "react";
import {
  Drawer,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  Typography,
  Button,
  Box,
  ListItemText,
  Stack,
  Chip,
  MenuItem,
  FormControlLabel,
  TextField,
  ListItemIcon
} from "@mui/material";
import { getStatuses, updateFilters, changePage } from "reducers/cases";
import { getBrokers } from "reducers/users";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { FIRST_PAGE, PROPERTY_TYPE_FILTER_DROPDOWN, USER_TYPE } from "utils/constants";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(theme => ({
  textField: {
    color: theme.palette.text.primary,
    borderColor: `${theme.palette.textFieldPalette.main} !important`,
    "& textarea": {
      color: theme.palette.text.primary
    },
    "& label": {
      color: theme.palette.text.primary
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.textFieldPalette.main
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main
      }
    },
    "& input": {
      color: theme.palette.text.primary,
      "&:-webkit-autofill": {
        "-webkit-box-shadow": `0 0 0 100px ${theme.palette.text.dark.primary} inset`,
        "-webkit-text-fill-color": theme?.palette?.text.primary
      }
    },
    "& .MuiOutlinedInput-root .MuiInputBase-root": {
      color: theme.palette.text.primary
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.secondary
    },
    "& .MuiInput-root.MuiInput-underline": {
      "&::before": {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      }
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      color: theme.palette.text.primary
    }
  }
}));

function FilterDrawer({
  setCaseSearch,
  toggleDrawer,
  selectedStatus,
  selectedBrokers,
  selectedPropertyType,
  handleStatusFilterChange,
  handleBrokerFilterChange,
  handlePropertyTypeFilterChange,
  position,
  handleStatusFilterDelete,
  handleBrokerFilterDelete,
  handlePropertyTypeFilterDelete,
  handleKeyChange,
  handleSearchBtn,
  isAllSelected,
  caseSearch
}) {
  const theme = useTheme();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        width: 250,
        overflowX: "auto",
        backgroundColor: theme.palette.text.dark.primary
      }
    }
  };
  const history = useHistory();
  const classes = useStyles();
  const { statuses, filters } = useSelector(state => state.cases);
  const { data: userData } = useSelector(state => state.user);
  const [onCurrentStatus, setOnCurrentStatus] = useState(
    filters.onCurrentStatus
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStatuses());
  }, [dispatch, filters]);

  useEffect(() => {
    if (userData?.me?.type === USER_TYPE.COMPANY_OWNER) {
      dispatch(getBrokers());
    }
  }, [dispatch, userData?.me]);

  const handleFilter = () => {
    if (!_.isEmpty(selectedStatus) || !_.isEmpty(selectedBrokers) || !_.isEmpty(selectedPropertyType)) {
      history.push({
        state: { page: FIRST_PAGE }
      });
      dispatch(changePage(FIRST_PAGE));
      dispatch(
        updateFilters({
          ...filters,
          status: selectedStatus,
          onCurrentStatus,
          brokers: selectedBrokers,
          propertyType: selectedPropertyType
        })
      );
      toggleDrawer(false)();
    }
  };

  return (
    <Drawer
      anchor={"right"}
      open={position["right"]}
      onClose={toggleDrawer(false)}
      sx={theme => ({
        [theme.breakpoints.down("sm")]: {
          "& .MuiDrawer-paper": {
            width: "100%",
            bgcolor: theme.palette.text.dark.primary
          }
        }
      })}
    >
      <Box
        sx={theme => ({
          width: 400,
          padding: "56px 32px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0
          }
        })}
        role="presentation"
      >
        <Box
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              padding: "56px 32px"
            }
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" color="text.primary" component="div">
              FILTER
            </Typography>
            <CloseIcon
              onClick={toggleDrawer(false)}
              sx={{ color: "text.primary", cursor: "pointer" }}
            />
          </Stack>
          <Box display={{ xs: "block", lg: "none" }}>
            <Stack sx={{ marginTop: "16px" }}>
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.textField}
                  label="Search case"
                  type="text"
                  value={caseSearch}
                  placeholder="Search case"
                  onChange={e => setCaseSearch(e.target.value)}
                  onKeyDown={handleKeyChange}
                />
              </FormControl>
              <Button
                variant="contained"
                onClick={handleSearchBtn}
                sx={{
                  marginTop: "16px"
                }}
              >
                SEARCH
              </Button>
            </Stack>
          </Box>
          {!_.isEmpty(selectedStatus) && (
            <FormControlLabel
              label="On Current Status"
              control={
                <Checkbox
                  sx={{ color: "text.secondary" }}
                  checked={onCurrentStatus}
                  onChange={e => setOnCurrentStatus(e.target.checked)}
                />
              }
              sx={{
                color: "text.secondary",
                marginTop: "16px"
              }}
            />
          )}
          <FormControl
            sx={{ width: "100%", marginTop: "16px" }}
            className={classes.textField}
          >
            <InputLabel>Status</InputLabel>
            <Select
              multiple
              fullWidth
              value={selectedStatus}
              onChange={handleStatusFilterChange}
              input={<OutlinedInput label="Status" />}
              renderValue={selected => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map(status => (
                    <Chip
                      key={status.id}
                      label={status.value}
                      sx={theme => ({
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.active.selected,
                        height: "24px"
                      })}
                      clickable
                      deleteIcon={
                        <CancelIcon
                          sx={theme => ({
                            fontSize: "14px",
                            color: theme.palette.text.disabled
                          })}
                          onMouseDown={event => event.stopPropagation()}
                        />
                      }
                      onDelete={e => handleStatusFilterDelete(e, status.value)}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {statuses?.map(status => (
                <MenuItem
                  key={status.id}
                  value={status}
                  sx={theme => ({
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.text.dark.primary
                  })}
                >
                  <Checkbox
                    checked={
                      !!selectedStatus.find(data => data.value === status.value)
                    }
                    sx={{ color: "text.secondary" }}
                  />
                  <ListItemText primary={status.value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {userData?.me?.type === USER_TYPE.COMPANY_OWNER && (
            <FormControl
              sx={{ width: "100%", marginTop: "16px" }}
              className={classes.textField}
            >
              <InputLabel>Brokers</InputLabel>
              <Select
                multiple
                fullWidth
                value={selectedBrokers}
                onChange={handleBrokerFilterChange}
                input={<OutlinedInput label="Brokers" />}
                renderValue={selected => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map(broker => (
                      <Chip
                        key={broker.id}
                        label={broker.email}
                        sx={theme => ({
                          color: theme.palette.text.primary,
                          backgroundColor: theme.palette.active.selected,
                          height: "24px"
                        })}
                        clickable
                        deleteIcon={
                          <CancelIcon
                            sx={theme => ({
                              fontSize: "14px",
                              color: theme.palette.text.disabled
                            })}
                            onMouseDown={event => event.stopPropagation()}
                          />
                        }
                        onDelete={e =>
                          handleBrokerFilterDelete(e, broker.email)
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  sx={theme => ({
                    backgroundColor: theme.palette.text.dark.primary
                  })}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selectedBrokers.length > 0 &&
                        selectedBrokers.length < userData?.brokers.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="All" />
                </MenuItem>
                {userData?.brokers?.map(broker => (
                  <MenuItem
                    key={broker.id}
                    value={broker}
                    sx={theme => ({
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.text.dark.primary
                    })}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedBrokers.indexOf(broker) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={broker.email} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl
            sx={{ width: "100%", marginTop: "16px" }}
            className={classes.textField}
          >
            <InputLabel>Property Type</InputLabel>
            <Select
              multiple
              fullWidth
              value={selectedPropertyType}
              onChange={handlePropertyTypeFilterChange}
              input={<OutlinedInput label="Property Type" />}
              renderValue={selected => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map(status => (
                    <Chip
                      key={status.id}
                      label={status.name}
                      sx={theme => ({
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.active.selected,
                        height: "24px"
                      })}
                      clickable
                      deleteIcon={
                        <CancelIcon
                          sx={theme => ({
                            fontSize: "14px",
                            color: theme.palette.text.disabled
                          })}
                          onMouseDown={event => event.stopPropagation()}
                        />
                      }
                      onDelete={e => handlePropertyTypeFilterDelete(e, status.value)}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {PROPERTY_TYPE_FILTER_DROPDOWN?.map(status => (
                <MenuItem
                  key={status.id}
                  value={status}
                  sx={theme => ({
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.text.dark.primary
                  })}
                >
                  <Checkbox
                    checked={
                      !!selectedPropertyType.find(data => data.value === status.value)
                    }
                    sx={{ color: "text.secondary" }}
                  />
                  <ListItemText primary={status.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            style={{
              width: "100%",
              marginTop: "24px"
            }}
            onClick={handleFilter}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default FilterDrawer;
