import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  Stack
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import LoginImage from "components/NonAuthenticateLoginImage";
import { useDispatch } from "react-redux";
import { confirmPassword } from "reducers/users";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.text.dark.primary)
);

const SetPassword = () => {
  const { token } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = data => {
    dispatch(confirmPassword({ ...data, token }));
  };

  return (
    <Box sx={{ marginBottom: "48px" }}>
      <Grid container spacing={6} sx={{ alignItems: "center" }}>
        <Grid item xs={12} lg={6}>
          <Box
            m={"auto"}
            sx={theme => ({
              maxWidth: 500,
              height: 300,
              padding: "56px",
              borderRadius: 4,
              boxShadow: 3,
              bgcolor: "text.dark.primary",
              [theme.breakpoints.down("xl")]: {
                padding: "28px"
              },
              [theme.breakpoints.down("lg")]: {
                padding: 0,
                boxShadow: 0,
                maxWidth: "100%",
                height: "100%",
                bgcolor: "background.default"
              }
            })}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="h4"
                gutterBottom
                component="h4"
                color="text.primary"
                mb={4}
              >
                Set Password
              </Typography>
              <Stack spacing={4}>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Password"
                    type="password"
                    placeholder="password"
                    error={!!errors.password}
                    autoComplete="off"
                    {...register("password", {
                      required: true
                    })}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    placeholder="Confirm password"
                    error={!!errors.confirmPassword}
                    autoComplete="off"
                    {...register("confirmPassword", {
                      required: true
                    })}
                  />
                </FormControl>
              </Stack>
              <Stack
                mt={4}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={theme => ({
                    [theme.breakpoints.down("lg")]: {
                      width: "100%",
                      marginBottom: "32px"
                    }
                  })}
                >
                  SUBMIT
                </Button>
              </Stack>
            </form>
          </Box>
        </Grid>
        <Grid item xs={0} lg={6} display={{ xs: "none", lg: "block" }}>
          <LoginImage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetPassword;
