import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/clients";
import * as actions from "reducers/clients";
import { toast } from "react-toastify";

function* getClientsSaga() {
  try {
    const {
      clients: { filters, page, search }
    } = yield select();
    const resp = yield call(Api.getClientsAPI, {
      ...filters,
      page,
      search
    });
    yield put(actions.getClientsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getClientsFailed(error));
  }
}

function* updateAdverseCreditSaga({ payload }) {
  try {
    yield call(Api.updateAdverseCreditAPI, payload);
    yield put(actions.updateAdverseCreditSucceed());
    yield put(actions.getClients());
    toast.success("You have successfully updated adverse credit.");
  } catch (error) {
    yield put(actions.updateAdverseCreditFailed(error));
  }
}

export function* clientsSaga() {
  yield takeLatest(actions.getClients.type, getClientsSaga);
  yield takeLatest(actions.updateAdverseCredit.type, updateAdverseCreditSaga);
}
