import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { profileImageUploaderStyles } from "utils";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import PanoramaIcon from "@mui/icons-material/Panorama";
import CloseIcon from "@mui/icons-material/Close";
import {
  updateUserRegistrationProcess,
  getRegistration,
  updateProfileImage
} from "reducers/registration";
import { useHistory, useLocation } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  FormControl,
  Stack,
  Card,
  Box,
  Grid,
  Modal,
  Checkbox,
  Link,
} from "@mui/material";
import Steps from "./Steps";
import blabblePdf from "../../userAgreement/Blabble-User-Agreement.pdf";
import { REGISTRATION_URL, STEPS } from "utils/constants";
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);

const modalStyles = theme => {
  return {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: "1000px",
    width: "100%",
    height: "100%",
    overflowY: "auto",
    bgcolor: "modalColorPalette.primary",
    borderRadius: 0,
    boxShadow: `0px 24px 38px 3px ${theme.palette.boxShadowPalette.main},0px 9px 46px 8px ${theme.palette.boxShadowPalette.dark},0px 11px 15px -7px ${theme.palette.boxShadowPalette.light}`,
    padding: "32px 40px",
    outline: "unset",
    [theme.breakpoints.down("lg")]: {
      bgcolor: "text.dark.primary",
      width: "100%"
    }
  };
};

const formStyle = theme => {
  return {
    margin: "0 48px 48px 0",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginTop: "24px"
    }
  };
};

const BusinessDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(history.location.search);
  const parsed = queryParams.get("token");
  const [token, setToken] = useState(parsed);
  const [businessDetails, setBusinessDetails] = useState({});
  const [isError, setIsError] = useState(false);
  const [activeStep, setActiveStep] = useState(STEPS.BUSINESS_DETAILS);
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [agreementDocumentUrl, setAgreementDocumentUrl] = useState(`${process.env.REACT_APP_FRONTEND_BASE_URL}${blabblePdf}`)
  const { data, profileImage } = useSelector(state => state.registration);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const uploadProfilePhoto = e => {
    let imgWrapper = document.querySelector(".profilePhoto");
    let img = imgWrapper.querySelector("img");
    if (img) {
      img.remove();
    }
    img = document.createElement("img");
    img.src = URL.createObjectURL(e.target.files[0]);
    imgWrapper.appendChild(img);
    setIsError(false);

    dispatch(updateProfileImage(e.target.files[0]));
  };

  const handleCheckbox = event => {
    setIsChecked(event.target.checked);
  };

  const onSubmit = value => {
    if (!profileImage && !data.profileImage) {
      setIsError(true);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return;
    }

    const formData = new FormData();

    formData.append("profileImage", profileImage);
    formData.append("step", 1);
    formData.append(
      "businessDetails",
      JSON.stringify({
        name: value.name,
        telephone: value.telephone,
        email: value.email,
        fcaNumber: value.fcaNumber,
        address: {
          address: value.addressLine1,
          address2: value.addressLine2,
          town: value.town,
          postCode: value.postCode
        }
      })
    );

    dispatch(
      updateUserRegistrationProcess({ data: formData, params: { token } })
    );
  };

  useEffect(() => {
    if (token) {
      dispatch(getRegistration({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    setBusinessDetails({
      ...businessDetails,
      name: data?.businessDetails?.name,
      addressLine1: data.businessDetails?.address?.address,
      addressLine2: data.businessDetails?.address?.address2,
      town: data.businessDetails?.address?.town,
      postCode: data.businessDetails?.address?.postCode,
      telephone: data.businessDetails?.telephone,
      email: data.businessDetails?.email,
      fcaNumber:
        data.businessDetails?.fcaNumber || data?.signupRequest?.fcaNumber
    });
    reset({
      name: data?.businessDetails?.name,
      addressLine1: data.businessDetails?.address?.address,
      addressLine2: data.businessDetails?.address?.address2,
      town: data.businessDetails?.address?.town,
      postCode: data.businessDetails?.address?.postCode,
      telephone: data.businessDetails?.telephone,
      email: data.businessDetails?.email,
      fcaNumber:
        data.businessDetails?.fcaNumber || data?.signupRequest?.fcaNumber
    });
  }, [data]);

  const handleChange = (key, value) => {
    setBusinessDetails({ ...businessDetails, [key]: value });
  };

  const onError = () => {
    if (!profileImage && !data.profileImage) {
      setIsError(true);
    }
  };

  useEffect(() => {
    let pathName = location?.pathname.split("/")[2];
    if (pathName === REGISTRATION_URL.BUSINESS_DETAIL) {
      setActiveStep(STEPS.BUSINESS_DETAILS);
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Box sx={theme => formStyle(theme)}>
            <form
              onSubmit={handleSubmit(onSubmit, () => onError(null))}
              id="businessDetailForm"
            >
              <Typography
                variant="h4"
                color="text.primary"
                gutterBottom
                marginBottom="26px"
                sx={theme => ({
                  [theme.breakpoints.down("lg")]: {
                    fontSize: "28px"
                  }
                })}
              >
                Business
              </Typography>
              <Stack
                spacing={2}
                sx={theme => ({
                  width: "170px",
                  marginBottom: "24px",
                  [theme.breakpoints.down("lg")]: {
                    marginLeft: "auto",
                    marginRight: "auto"
                  }
                })}
              >
                <Card
                  className={`profilePhoto ${(data.profileImage ||
                    profileImage) &&
                    "upload"} ${isError && "required"}`}
                  sx={theme => profileImageUploaderStyles(theme)}
                >
                  {data.profileImage && (
                    <img src={`${data.profileImage}`} alt="" />
                  )}
                </Card>
                {isError && (
                  <div className="validation-error">Image required!</div>
                )}
                <Button
                  variant="text"
                  component="label"
                  startIcon={<PanoramaIcon />}
                >
                  Upload
                  <input
                    hidden
                    type="file"
                    accept="image/*"
                    onChange={uploadProfilePhoto}
                  />
                </Button>
              </Stack>
              <Stack spacing={3}>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Company Name *"
                    type="text"
                    defaultValue={data?.businessDetails?.name || ""}
                    placeholder="Company Name"
                    error={!!errors.name}
                    {...register("name", {
                      required: true,
                      onChange: e => handleChange("name", e.target.value)
                    })}
                    InputLabelProps={{
                      shrink: businessDetails.name ? true : false
                    }}
                  />
                  {errors.name && (
                    <div className="validation-error">
                      Company Name required!
                    </div>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Address Line 1 *"
                    type="text"
                    placeholder="Address Line 1"
                    defaultValue={data?.businessDetails?.address?.address || ""}
                    error={!!errors.addressLine1}
                    {...register("addressLine1", {
                      required: true,
                      onChange: e =>
                        handleChange("addressLine1", e.target.value)
                    })}
                    InputLabelProps={{
                      shrink: businessDetails.addressLine1 ? true : false
                    }}
                  />
                  {errors.addressLine1 && (
                    <div className="validation-error">
                      Address Line 1 required!
                    </div>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Address Line 2 *"
                    type="text"
                    placeholder="Address Line 2"
                    defaultValue={
                      data?.businessDetails?.address?.address2 || ""
                    }
                    error={!!errors.addressLine2}
                    {...register("addressLine2", {
                      required: true,
                      onChange: e =>
                        handleChange("addressLine2", e.target.value)
                    })}
                    InputLabelProps={{
                      shrink: businessDetails.addressLine2 ? true : false
                    }}
                  />
                  {errors.addressLine2 && (
                    <div className="validation-error">
                      Address Line 2 required!
                    </div>
                  )}
                </FormControl>
                <FormControl
                  sx={theme => ({
                    width: "350px",
                    [theme.breakpoints.down("sm")]: {
                      width: "100%"
                    }
                  })}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="City *"
                    type="text"
                    placeholder="City"
                    defaultValue={data?.businessDetails?.address?.town || ""}
                    error={!!errors.town}
                    {...register("town", {
                      required: true,
                      onChange: e => handleChange("town", e.target.value)
                    })}
                    InputLabelProps={{
                      shrink: businessDetails.town ? true : false
                    }}
                  />
                  {errors.town && (
                    <div className="validation-error">City required!</div>
                  )}
                </FormControl>
                <FormControl
                  sx={theme => ({
                    width: "350px",
                    [theme.breakpoints.down("sm")]: {
                      width: "100%"
                    }
                  })}
                >
                  <TextField
                    className={classes.textField}
                    label="Postcode *"
                    type="text"
                    placeholder="Postcode"
                    defaultValue={
                      data?.businessDetails?.address?.postCode || ""
                    }
                    error={!!errors.postCode}
                    {...register("postCode", {
                      required: true,
                      onChange: e => handleChange("postCode", e.target.value)
                    })}
                    InputLabelProps={{
                      shrink: businessDetails.postCode ? true : false
                    }}
                  />
                  {errors.postCode && (
                    <div className="validation-error">Postcode required!</div>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Company Telephone *"
                    type="tel"
                    placeholder="Company Telephone"
                    defaultValue={data?.businessDetails?.telephone || ""}
                    error={!!errors.telephone}
                    {...register("telephone", {
                      required: true,
                      onChange: e => handleChange("telephone", e.target.value)
                    })}
                    InputLabelProps={{
                      shrink: businessDetails.telephone ? true : false
                    }}
                  />
                  {errors.telephone && (
                    <div className="validation-error">Telephone required!</div>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Company Email *"
                    type="email"
                    placeholder="Company Email"
                    defaultValue={data?.businessDetails?.email || ""}
                    error={!!errors.email}
                    {...register("email", {
                      required: true,
                      onChange: e => handleChange("email", e.target.value)
                    })}
                    InputLabelProps={{
                      shrink: businessDetails.email ? true : false
                    }}
                  />
                  {errors.email && (
                    <div className="validation-error">Email required!</div>
                  )}
                </FormControl>
                <FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="FCA Number *"
                    type="number"
                    placeholder="FCA Number"
                    inputProps={{ inputMode: "numeric" }}
                    defaultValue={data?.businessDetails?.fcaNumber || ""}
                    error={!!errors.fcaNumber}
                    {...register("fcaNumber", {
                      required: true,
                      onChange: e => handleChange("fcaNumber", e.target.value)
                    })}
                    InputLabelProps={{
                      shrink: businessDetails.fcaNumber ? true : false
                    }}
                  />
                  {errors.fcaNumber && (
                    <div className="validation-error">FCA Number required!</div>
                  )}
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                sx={{ marginTop: "24px" }}
              >
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckbox}
                  sx={{ padding: 0, marginRight: "4px" }}
                />
                <Stack direction="row">
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "text.primary"
                    }}
                  >
                    I agree to the&nbsp;
                    <Link
                      href="#"
                      variant="body2"
                      sx={{
                        color: "primary.main",
                        textDecoration: "underline",
                        marginRight: "8px",
                        ":hover": {
                          cursor: "pointer",
                          opacity: 0.8
                        }
                      }}
                      onClick={() => setIsOpen(true)}
                    >
                      terms and conditions
                    </Link>
                  </Typography>
                  <Link
                    href={agreementDocumentUrl}
                    download="Blabble User Agreement.pdf"
                    variant="caption"
                    sx={{
                      marginTop: "4px",
                      fontSize: "4px",
                      color: "primary.main",
                      textDecoration: "underline",
                      ":hover": {
                        cursor: "pointer",
                        opacity: 0.8
                      }
                    }}
                  >
                    <DownloadIcon />
                  </Link>
                </Stack>
              </Stack>
              <Stack display={{ xs: "none", lg: "block" }}>
                <Stack spacing={4} direction="row" marginTop="32px">
                  <Button
                    sx={theme => ({
                      backgroundColor: `${theme.palette.text.disabled} !important`,
                      pointerEvents: "none"
                    })}
                    variant="contained"
                  >
                    BACK
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={theme =>
                      !isChecked && {
                        backgroundColor: `${theme.palette.text.disabled} !important`,
                        pointerEvents: "none"
                      }
                    }
                  >
                    NEXT
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={theme => ({
            [theme.breakpoints.down("lg")]: {
              display: "none"
            }
          })}
        >
          <Steps activeStep={activeStep} formId="businessDetailForm" />
        </Grid>
      </Grid>
      <Stack
        display={{ xs: "block", lg: "none" }}
        sx={{
          position: "absolute",
          width: "100%",
          left: 0,
          bottom: 0
        }}
      >
        <Steps
          activeStep={activeStep}
          isChecked={isChecked}
          formId="businessDetailForm"
        />
      </Stack>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={theme => modalStyles(theme)}>
          <Stack alignItems="flex-end" padding="16px" paddingTop="0px">
            <CloseIcon
              sx={{ color: "text.primary", cursor: "pointer" }}
              onClick={() => setIsOpen(false)}
            />
          </Stack>
          <iframe
            src={`https://docs.google.com/viewer?url=${agreementDocumentUrl}&embedded=true`}
            title="Term and conditions"
            style={{ width: "100%", height: "88vh" }}
          ></iframe>
        </Box>
      </Modal>
    </>
  );
};

export default BusinessDetails;
