import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import Modal from "components/Modals/index";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useForm } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useParams } from "react-router-dom";
import { dateStyle } from "utils/DatePickerStyle";
import { useSelector } from "react-redux";
import moment from "moment";

const Task = ({ task, isOpen, handleClose, handleTask, edit }) => {
  const { theme } = useSelector(state => state.user);
  const useStyles = makeStyles(theme =>
    textFieldStyle(theme, theme.palette.background.default)
  );
  const { id } = useParams();
  const { name, dueDate, note } = task;
  const classes = useStyles();

  const [date, setDate] = useState(dueDate ? new Date(dueDate) : null);
  const [isError, setIsError] = useState(false);

  const onError = (error, item) => {
    if ([undefined, null, ""].includes(item)) {
      setIsError(true);
    }
  };

  const handleDateTimepicker = value => {
    setDate(value);
    setIsError(false);
  };

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onTaskSubmit = data => {
    if (!date) {
      setIsError(true);
      return;
    }
    handleTask({
      ...data,
      case: id,
      dueDate: new Date(moment.utc(date)).toISOString()
    });
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={`${edit ? "Edit" : "Add"} Task`}
    >
      <form onSubmit={handleSubmit(onTaskSubmit, () => onError(null, date))}>
        <TextField
          className={classes.textField}
          label="Task"
          type="text"
          multiline
          placeholder="Add text"
          defaultValue={edit ? name : ""}
          rows={1}
          {...register("name", { required: true })}
          fullWidth
        />
        <TextField
          className={classes.textField}
          sx={{ marginTop: "24px" }}
          label="Notes"
          type="text"
          multiline
          placeholder="Add text"
          rows={3}
          error={!!errors.notes}
          defaultValue={edit ? note : ""}
          {...register("note", { required: false })}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Due Datetime"
            ampm={false}
            value={date}
            onChange={handleDateTimepicker}
            disablePast
            renderInput={params => (
              <TextField
                fullWidth
                {...params}
                className={classes.textField}
                style={{ marginTop: "24px" }}
                error={isError}
              />
            )}
            PopperProps={{
              sx: dateStyle(theme)
            }}
          />
        </LocalizationProvider>
        <Box sx={{ marginTop: 3 }}>
          <Button
            sx={{ marginTop: 3, display: "block" }}
            type="submit"
            variant="contained"
          >
            {edit ? "Submit" : "CREATE"}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default Task;
