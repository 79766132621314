import { formatFilters } from "utils";
import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getClientsAPI = params =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.GET_CLIENTS}`,
    params: formatFilters(params)
  });

export const updateAdverseCreditAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.UPDATE_ADVERSE_CREDIT}${id}/`,
    data: { ...data }
  });
