import React, { useEffect } from "react";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordSuccess from "./pages/ForgotPassword/ForgotPasswordSuccess";
import ConfirmPassword from "./pages/ConfirmPassword";
import NonAuthenticatedWrapper from "./components/NonAuthenticatedWrapper";
import AuthenticatedWrapper from "./components/AuthenticatedWrapper";
import BusinessDetails from "pages/Registration/BusinessDetails";
import { ROUTES } from "./routes/constants";
import "./App.scss";
import Login from "./pages/Login";
import { darkTheme, lightTheme } from "styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { DARK, LIGHT, PRODUCTION, STAGE } from "utils/constants";
import BillingDetails from "pages/Registration/BillingDetails";
import ReviewDetails from "pages/Registration/ReviewDetails";
import PurchasedDetails from "pages/Registration/PurchasedDetails";
import SignUpRequest from "pages/Registration/SignUpRequest";
import SignUpRequestSuccess from "pages/Registration/SignUpRequestSuccess";
import SetPassword from "./pages/SetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authenticatedComponentBasesOnUserType } from "utils";
import Loader from "components/Loader";
import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
ReactGA.initialize(TRACKING_ID);

function App() {
  const {
    theme,
    data: { me }
  } = useSelector(state => state.user);

  useEffect(() => {
    document.getElementById("html-root").style.backgroundColor =
      theme === LIGHT
        ? lightTheme.palette.background.default
        : darkTheme.palette.background.default;
  }, [theme]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search
    });
  }, []);

  const APP_ROUTES = {
    NON_AUTHENTICATED: [
      { path: ROUTES.LOGIN, component: Login },
      { path: ROUTES.FORGOT_PASSWORD, component: ForgotPassword },
      {
        path: ROUTES.FORGOT_PASSWORD_SUCCESS,
        component: ForgotPasswordSuccess
      },
      { path: ROUTES.CONFIRM_PASSWORD, component: ConfirmPassword },
      { path: ROUTES.SET_PASSWORD, component: SetPassword },
      {
        path: ROUTES.REGISTRATION_BUSINESS_DETAILS,
        component: BusinessDetails
      },
      { path: ROUTES.REGISTRATION_BILLING_DETAILS, component: BillingDetails },
      { path: ROUTES.REGISTRATION_REVIEW_DETAILS, component: ReviewDetails },
      {
        path: ROUTES.REGISTRATION_PURCHASED_DETAILS,
        component: PurchasedDetails
      },
      { path: ROUTES.SIGNUP_REQUEST, component: SignUpRequest },
      { path: ROUTES.SIGNUP_REQUEST_SUCCESS, component: SignUpRequestSuccess }
    ],
    AUTHENTICATED: authenticatedComponentBasesOnUserType(me?.type)
  };
  const isCacheBusterEnable = [STAGE, PRODUCTION].includes(
    process.env.REACT_APP_ENV
  );

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isCacheBusterEnable}
      isVerboseMode={false}
      loadingComponent={<Loader />}
      metaFileDirectory={"/app"}
    >
      <>
        <ToastContainer theme={theme === LIGHT ? LIGHT : DARK} />
        <ThemeProvider
          theme={theme === LIGHT ? { ...lightTheme } : { ...darkTheme }}
        >
          <Switch>
            {APP_ROUTES.NON_AUTHENTICATED.map((route, index) => (
              <Route exact key={index} {...route}>
                <NonAuthenticatedWrapper>
                  <route.component />
                </NonAuthenticatedWrapper>
              </Route>
            ))}
            {APP_ROUTES.AUTHENTICATED.map((route, index) => (
              <Route exact key={index} {...route}>
                <AuthenticatedWrapper>
                  <route.component />
                </AuthenticatedWrapper>
              </Route>
            ))}
            <Redirect from="/" to="/leads" />
          </Switch>
        </ThemeProvider>
      </>
    </CacheBuster>
  );
}

export default App;
