import { formatFilters } from "utils";
import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getLeadsAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: ENDPOINTS.GET_LEADS,
    params: formatFilters({ ...data })
  });

export const buyLeadAPI = ({ id }) =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.BUY_LEAD}${id}/`
  });

export const getLeadDetailAPI = id =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.LEAD_DETAIL}${id}`
  });

export const updateLeadAPI = ({ id, data }) =>
  apiCaller({
    method: REQUEST_TYPES.PATCH,
    url: `${ENDPOINTS.UPDATE_LEAD}${id}/`,
    data
  });

export const updateFavouriteLeadAPI = ({ data }) =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.MARK_FAVOURITE_LEAD}`,
    data
  });
