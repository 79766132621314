import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { changePassword } from "reducers/users";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const ChangePassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm();

  const watchOldPassword = watch("old_password");
  const watchNewPassword = watch("new_password1");
  const watchConfirmPassword = watch("new_password2");

  const onSubmit = data => {
    dispatch(changePassword(data));

    reset({
      old_password: "",
      new_password1: "",
      new_password2: ""
    });
  };

  return (
    <>
      <Box>
        <Typography
          variant="h4"
          color="text.primary"
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              fontSize: "28px"
            }
          })}
        >
          Change Password
        </Typography>
        <Grid container mt={4}>
          <Grid item xs={12} md={8}>
            <Box m={"auto"}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Old Password"
                      type="password"
                      placeholder="Old Password"
                      error={!!errors.old_password}
                      autoComplete="off"
                      {...register("old_password", {
                        required: true
                      })}
                      InputLabelProps={{
                        shrink: watchOldPassword ? true : false
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="New Password"
                      type="password"
                      placeholder="New Password"
                      error={!!errors.new_password1}
                      autoComplete="off"
                      {...register("new_password1", {
                        required: true
                      })}
                      InputLabelProps={{
                        shrink: watchNewPassword ? true : false
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Confirm Password"
                      type="password"
                      placeholder="Confirm password"
                      error={!!errors.new_password2}
                      autoComplete="off"
                      {...register("new_password2", {
                        required: true
                      })}
                      InputLabelProps={{
                        shrink: watchConfirmPassword ? true : false
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={4}
                >
                  <Button type="submit" variant="contained" size="large">
                    SAVE
                  </Button>
                </Stack>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChangePassword;
