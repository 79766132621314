import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box, Modal, CircularProgress, Link } from "@mui/material";
import {
  getSignupRequest,
  updateCompletePaymentModal
} from "reducers/registration";
import { REGISTERED } from "utils/constants";
import { ROUTES } from "routes/constants";

const modalStyles = theme => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: 400,
    overflowY: "auto",
    bgcolor: "background.default",
    borderRadius: "16px",
    boxShadow: `0px 24px 38px 3px ${theme.palette.boxShadowPalette.main},0px 9px 46px 8px ${theme.palette.boxShadowPalette.dark},0px 11px 15px -7px ${theme.palette.boxShadowPalette.light}`,
    padding: "56px",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      bgcolor: "text.dark.primary",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }
  };
};

function PaymentFailedModal(isOpen) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { registrationCheckout, data } = useSelector(
    state => state.registration
  );
  const queryParams = new URLSearchParams(history.location.search);
  const parsed = queryParams.get("token");

  const [token, setToken] = useState(parsed);

  useEffect(() => {
    if (token) {
      const INTERVAL = setInterval(() => {
        dispatch(getSignupRequest({ token }));
      }, 5000);
    }
    if (data?.state === REGISTERED) {
      dispatch(updateCompletePaymentModal(false));
      history.push(ROUTES.REGISTRATION_PURCHASED_DETAILS);
    }
  }, [dispatch, token, data?.state]);

  return (
    <Modal open={isOpen} title="Buy Seats">
      <Box sx={theme => modalStyles(theme)}>
        <Typography
          variant="h5"
          color="text.primary"
          sx={theme => ({
            marginBottom: "24px",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "12px"
            }
          })}
          component="h2"
        >
          Payment Incomplete
        </Typography>
        <Typography variant="body1" color="text.primary" marginBottom="32px">
          Please click&nbsp;
          <Link
            href={registrationCheckout.invoiceUrl}
            target="_blank"
            rel="noreferrer"
            sx={{
              color: "primary.main",
              fontWeight: "900"
            }}
          >
            here
          </Link>
          &nbsp;to complete payment.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <CircularProgress
            sx={{ height: "60px !important", width: "60px !important" }}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default PaymentFailedModal;
