import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modals/index";
import { moneyFieldConverter } from "utils";
import Loader from "components/Loader";
import {
  updateBuyCreditsModal,
  creditBuyBundle,
  updateProcessingModal
} from "reducers/credits";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography, Box, Button, Grid, Stack } from "@mui/material";
import PaymentCard from "pages/Company/PaymentCard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function BuyCreditsModal({ isOpen, handleClose, creditsCheckoutDetail }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { loading } = useSelector(state => state.credits);
  const { defaultCompanyData } = useSelector(state => state.company);
  const handleCheckout = () => {
    const checkoutDetail = {
      creditBundleId: creditsCheckoutDetail.id
    };
    dispatch(creditBuyBundle(checkoutDetail));
    handleClose();
    dispatch(updateProcessingModal(true));
  };

  const handleBackBtn = () => {
    dispatch(updateBuyCreditsModal(true));
    handleClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      width={isTablet ? "470px" : "600px"}
      title="Buy Credits"
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <Stack display={{ xs: "block", lg: "none" }}>
            <Button
              variant="text"
              sx={{ paddingLeft: 0 }}
              startIcon={<ArrowBackIcon />}
              onClick={handleBackBtn}
            >
              Change
            </Button>
          </Stack>
          <Grid container spacing={2}>
            <Grid item width="100%" lg={4}>
              <Box
                sx={theme => ({
                  background: theme.palette.text.dark.primary,
                  padding: "10px 20px 20px",
                  borderRadius: "8px",
                  marginBottom: "18px",
                  [theme.breakpoints.down("lg")]: {
                    background: theme.palette.background.default,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    padding: "24px"
                  }
                })}
              >
                <Button
                  variant="text"
                  sx={theme => ({
                    marginBottom: "16px",
                    paddingLeft: 0,
                    [theme.breakpoints.down("lg")]: {
                      display: "none"
                    }
                  })}
                  startIcon={<ArrowBackIcon />}
                  onClick={handleBackBtn}
                >
                  Change
                </Button>
                <Stack>
                  <Typography variant="h6" color="text.primary">
                    {creditsCheckoutDetail.quantity}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={theme => ({
                      marginBottom: "16px",
                      [theme.breakpoints.down("lg")]: {
                        marginBottom: 0
                      }
                    })}
                  >
                    credits
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body1" color="text.primary">
                    {moneyFieldConverter(creditsCheckoutDetail.price)}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    sx={theme => ({
                      marginBottom: "20px",
                      [theme.breakpoints.down("lg")]: {
                        marginBottom: 0
                      }
                    })}
                  >{`${moneyFieldConverter(
                    creditsCheckoutDetail.perCreditPrice
                  )} per credit`}</Typography>
                </Stack>
              </Box>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleCheckout}
              >
                Checkout
              </Button>
            </Grid>
            <Grid item width="100%" lg={8}>
              <Typography variant="h6" color="text.primary" marginBottom="8px">
                Billing Address
              </Typography>
              <Typography variant="body1" color="text.primary" gutterBottom>
                {`${defaultCompanyData?.billingAddress?.address} ${defaultCompanyData?.billingAddress?.address2}, ${defaultCompanyData?.billingAddress?.town}, ${defaultCompanyData?.billingAddress?.postCode}`}
              </Typography>
              <Typography
                variant="h6"
                color="text.primary"
                marginTop="44px"
                gutterBottom
              >
                Payment Card
              </Typography>
              <PaymentCard cardDetail={defaultCompanyData} />
            </Grid>
          </Grid>
        </>
      )}
    </Modal>
  );
}

export default BuyCreditsModal;
