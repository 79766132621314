import React, { useState, useRef } from "react";
import {
  Stack,
  IconButton,
  TextField,
  Slide,
  InputAdornment,
  Box
} from "@mui/material";
import { CloseRounded, SearchRounded } from "@mui/icons-material";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { ENTER_KEY } from "utils/constants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const SearchBar = ({ handleOnSearchChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const [search, setSearch] = useState("");

  const handleOpen = () => {
    setOpen(prev => !prev);
  };

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const handleKeyChange = e => {
    if (e.keyCode === ENTER_KEY) {
      handleOnSearchChange(search);
    }
  };

  const handleClear = () => {
    setSearch("");
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box ref={containerRef} sx={{ overflow: "hidden", height: 45 }}>
        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
          <TextField
            className={classes.textField}
            size="small"
            sx={{ width: "150px" }}
            variant="standard"
            label="Search"
            autoFocus
            value={search}
            onChange={handleSearch}
            onKeyDown={handleKeyChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClear}>
                    <CloseRounded fontSize="small" />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Slide>
      </Box>
      <IconButton onClick={handleOpen}>
        <SearchRounded sx={{ color: "text.secondary" }} />
      </IconButton>
    </Stack>
  );
};

export default SearchBar;
