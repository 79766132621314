import { ResponsiveBar } from "@nivo/bar";
import { truncateChartlabel } from "utils";
import { useTheme } from "@mui/material/styles";

export default function BarGraph({ data, indexBy, keys }) {
  const theme = useTheme();
  return (
    <ResponsiveBar
      theme={theme}
      data={data}
      keys={keys}
      indexBy={indexBy}
      layout="horizontal"
      margin={{ top: 50, right: 180, bottom: 50, left: 160 }}
      width={1300}
      padding={0.6}
      enableLabel={false}
      groupMode="grouped"
      colors={[theme.palette.primary.main, theme.palette.secondary.main]}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: false }}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        format: e => Math.floor(e) === e && e
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        renderTick: ({
          textAnchor,
          textBaseline,
          textX,
          textY,
          value,
          x,
          y
        }) => {
          return (
            <g transform={`translate(${x},${y})`}>
              <text
                alignmentBaseline={textBaseline}
                textAnchor={textAnchor}
                transform={`translate(${textX},${textY})`}
              >
                <tspan style={{ fill: theme.palette.text.primary }}>
                  {truncateChartlabel(value, 15)}
                </tspan>
              </text>
            </g>
          );
        }
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 150,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      isInteractive={true}
    />
  );
}
