import React from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modals/index";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { Button, InputAdornment, TextField, Stack } from "@mui/material";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const CommissionSplitModal = ({
  isOpen,
  handleClose,
  handleCommissionPercentageModal,
  companyBrokers
}) => {
  const classes = useStyles();
  const { me: user } = useSelector(state => state.user.data);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      blabbleCommissionPercentage:
        companyBrokers.blabbleCommissionPercentage || "",
      personalCommissionPercentage:
        companyBrokers.personalCommissionPercentage || "",
      otherCommissionPercentage: companyBrokers.otherCommissionPercentage || ""
    }
  });

  const onSubmit = value => {
    handleCommissionPercentageModal({
      ...value,
      blabbleCommissionPercentage: value.blabbleCommissionPercentage,
      personalCommissionPercentage: value.personalCommissionPercentage,
      otherCommissionPercentage: value.otherCommissionPercentage
    });
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Commission %">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="number"
            className={classes.textField}
            label="Blabble"
            placeholder="Blabble"
            error={!!errors.blabbleCommissionPercentage}
            InputProps={{
              inputProps: { step: "any" },
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            {...register("blabbleCommissionPercentage", {
              min: 0,
              required: true
            })}
          />
          <TextField
            fullWidth
            type="number"
            className={classes.textField}
            label="Personal"
            placeholder="Personal"
            error={!!errors.personalCommissionPercentage}
            InputProps={{
              inputProps: { step: "any" },
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            {...register("personalCommissionPercentage", {
              min: 0,
              required: true
            })}
          />
          <TextField
            fullWidth
            type="number"
            className={classes.textField}
            label={user?.otherLeadsName}
            placeholder={user?.otherLeadsName}
            error={!!errors.otherCommissionPercentage}
            InputProps={{
              inputProps: { step: "any" },
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            {...register("otherCommissionPercentage", {
              min: 0,
              required: true
            })}
          />
        </Stack>
        <Button
          sx={theme => ({
            marginTop: 3,
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          })}
          type="submit"
          variant="contained"
        >
          UPDATE
        </Button>
      </form>
    </Modal>
  );
};

export default CommissionSplitModal;
