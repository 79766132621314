import { call, put, takeLatest, select } from "redux-saga/effects";
import * as Api from "api/cases";
import * as ApiTasks from "api/tasks";
import * as actions from "reducers/cases";
import * as tasksActions from "reducers/tasks";
import { push } from "connected-react-router";
import { ROUTES } from "routes/constants";
import { toast } from "react-toastify";

function* getCasesSaga() {
  try {
    const {
      cases: { filters, page, search, activeTab }
    } = yield select();
    const resp = yield call(Api.getCasesAPI, {
      ...filters,
      activeTab,
      search,
      page
    });
    yield put(actions.getSucceed(resp.data));
  } catch (error) {
    yield put(actions.getFailed(error));
  }
}

function* getStatusesSaga() {
  try {
    const resp = yield call(Api.getStatusesAPI);
    yield put(actions.getStatusesSucceed(resp.data));
  } catch (error) {
    yield put(actions.getStatusesFailed(error));
  }
}

function* addNewCaseSaga({ payload }) {
  try {
    const resp = yield call(Api.addNewCaseAPI, payload);
    if (resp) {
      yield put(actions.addNewCaseSucceed());
      yield put(push(ROUTES.CASES));
      toast.success("You have successfully created the case.");
    }
  } catch (error) {
    yield put(actions.addNewCaseFailed(error));
  }
}

function* getCaseSaga({ payload }) {
  try {
    const resp = yield call(Api.getCaseAPI, payload);
    yield put(actions.getCaseSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCaseFailed(error));
  }
}

function* getCaseStatusSaga({ payload }) {
  const {
    cases: { filters, caseStatusPage }
  } = yield select();
  try {
    const resp = yield call(Api.getCaseStatusAPI, {
      ...filters,
      page: caseStatusPage
    });
    yield put(actions.getCaseStatusSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCaseFailed(error));
  }
}

function* getCaseProductsSaga({ payload }) {
  const {
    cases: { filters, caseProductPage }
  } = yield select();
  try {
    const resp = yield call(Api.getCaseProductsAPI, {
      ...filters,
      page: caseProductPage
    });
    yield put(actions.getCaseProductsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCaseProductsFailed(error));
  }
}

function* getCaseTaskSaga() {
  const {
    cases: { filters, caseTaskPage, caseTasktabs }
  } = yield select();
  try {
    const resp = yield call(Api.getCaseTaskAPI, {
      ...filters,
      activeTab: caseTasktabs,
      page: caseTaskPage
    });
    yield put(actions.getCaseTaskSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCaseTaskFailed(error));
  }
}

function* removeCaseStatusSaga({ payload }) {
  try {
    yield call(Api.removeCaseStatusAPI, payload);
    yield put(actions.removeCaseStatusSucceed());
    yield put(actions.getCaseStatus());
    toast.success("You have successfully deleted the status.");
  } catch (error) {
    yield put(actions.removeCaseStatusFailed(error));
  }
}

function* removeCaseProductSaga({ payload }) {
  try {
    yield call(Api.removeCaseProductAPI, payload);
    yield put(actions.removeCaseProductSucceed());
    yield put(actions.getCaseProducts());
    toast.success("You have successfully deleted the product.");
  } catch (error) {
    yield put(actions.removeCaseProductFailed(error));
  }
}

function* removeCaseTaskSaga({ payload }) {
  try {
    yield call(Api.removeCaseTaskAPI, payload);
    yield put(actions.removeCaseTaskSucceed());
    yield put(actions.getCaseTask());
  } catch (error) {
    yield put(actions.removeCaseTaskFailed(error));
  }
}

function* updateCaseTaskSaga({ payload }) {
  try {
    yield call(Api.updateCaseTaskAPI, payload);
    yield put(actions.updateCaseTaskSucceed());
    yield put(actions.getCaseTask());
    yield put(tasksActions.getDrawerTasks());
    toast.success("You have successfully updated the task.");
  } catch (error) {
    yield put(actions.updateCaseTaskFailed(error));
  }
}

function* updateCaseSaga({ payload }) {
  try {
    yield call(Api.updateCaseAPI, payload);
    yield put(actions.updateCaseSucceed());
    yield put(push(`/cases/${payload.id}`));
    toast.success("You have successfully updated the case.");
  } catch (error) {
    yield put(actions.updateCaseFailed(error));
  }
}

function* addCaseStatusSaga({ payload }) {
  try {
    yield call(Api.addCaseStatusAPI, payload);
    yield put(actions.addCaseStatusSucceed());
    yield put(actions.getCaseStatus());
    yield put(tasksActions.getDrawerTasks());
    toast.success("You have successfully set the status.");
  } catch (error) {
    yield put(actions.addCaseStatusFailed(error));
  }
}

function* updateCaseStatusSaga({ payload }) {
  try {
    yield call(Api.updateCaseStatusAPI, payload);
    yield put(actions.updateCaseStatusSucceed());
    yield put(actions.getCaseStatus());
    toast.success("You have successfully updated the status.");
  } catch (error) {
    yield put(actions.updateCaseStatusFailed(error));
  }
}

function* createCaseTaskSaga({ payload }) {
  try {
    yield call(Api.createCaseTaskAPI, payload);
    yield put(actions.createCaseTaskSucceed());
    yield put(actions.getCaseTask());
    yield put(tasksActions.getDrawerTasks());
    toast.success("You have successfully created a task.");
  } catch (error) {
    yield put(actions.createCaseTaskFailed(error));
  }
}

function* getProvidersSaga({ payload }) {
  try {
    const resp = yield call(Api.getProvidersAPI);
    yield put(actions.getProvidersSucceed(resp.data));
  } catch (error) {
    yield put(actions.getProvidersFailed(error));
  }
}

function* addCaseProductSaga({ payload }) {
  try {
    const resp = yield call(Api.addCaseProductAPI, payload);
    if (resp) {
      yield put(actions.addCaseProductSucceed());
      yield put(push(`/cases/${resp.data.case}`));
      toast.success("You have successfully created the product.");
    }
  } catch (error) {
    yield put(actions.addCaseProductFailed(error));
  }
}

function* updateCaseProductSaga({ payload }) {
  try {
    const resp = yield call(Api.updateCaseProductAPI, payload);
    yield put(actions.updateCaseProductSucceed());
    yield put(actions.getCaseWithoutLoading(resp.data.case));
    yield put(actions.getCaseProducts());
    yield put(push(`/cases/${resp.data.case}`));
    toast.success("You have successfully updated the product.");
  } catch (error) {
    yield put(actions.updateCaseProductFailed(error));
  }
}

function* getLendersSaga({ payload }) {
  try {
    const resp = yield call(Api.getLendersAPI, payload);
    if (resp) {
      yield put(actions.getLendersSucceed(resp.data));
    }
  } catch (error) {
    yield put(actions.getLendersFailed(error));
  }
}

function* getProductsSaga({ payload }) {
  try {
    const resp = yield call(Api.getProductsAPI, { type: payload });
    yield put(actions.getProductsSucceed(resp.data));
  } catch (error) {
    yield put(actions.getProductsFailed(error));
  }
}

function* updateCaseStateSaga({ payload }) {
  try {
    yield call(Api.updateCaseStateAPI, payload);
    yield put(actions.updateCaseStateSucceed());
    yield put(actions.getCases());
  } catch (error) {
    yield put(actions.updateCaseStateFailed(error));
  }
}

function* getCaseProductSaga({ payload }) {
  try {
    const resp = yield call(Api.getCaseProductAPI, payload);
    yield put(actions.getCaseProductSucceed(resp.data));
  } catch (error) {
    yield put(actions.getCaseProductFailed(error));
  }
}

function* updateCaseTasksBulkSaga({ payload }) {
  try {
    yield call(ApiTasks.updateTasksBulkAPI, payload);
    yield put(actions.updateCaseTasksBulkSucceed());
    yield put(actions.getCaseTask());
    yield put(tasksActions.getDrawerTasks());
    toast.success(
      `Task has been successfully moved to ${
        payload.isCompleted ? "completed" : "todo"
      }.`
    );
  } catch (error) {
    yield put(actions.updateCaseTasksBulkFailed(error));
  }
}

function* removeCaseTasksBulkSaga({ payload }) {
  try {
    yield call(ApiTasks.removeTasksBulkAPI, payload);
    yield put(actions.removeCaseTasksBulkSucceed());
    yield put(actions.getCaseTask());
    yield put(tasksActions.getDrawerTasks());
    toast.success("You have successfully deleted task(s).");
  } catch (error) {
    yield put(actions.removeCaseTasksBulkFailed(error));
  }
}

function* getCaseWithoutLoadingSaga({ payload }) {
  try {
    const resp = yield call(Api.getCaseAPI, payload);
    yield put(actions.getCaseSucceedWithoutLoading(resp.data));
  } catch (error) {
    yield put(actions.getCaseFailedWithoutLoading(error));
  }
}

function* assignCaseSaga({ payload }) {
  try {
    yield call(Api.assignCaseAPI, payload);
    yield put(actions.assignCaseSucceed());
    yield put(actions.getCases());
  } catch (error) {
    yield put(actions.assignCaseFailed(error));
  }
}

export function* casesSaga() {
  yield takeLatest(actions.getCases.type, getCasesSaga);
  yield takeLatest(actions.getStatuses.type, getStatusesSaga);
  yield takeLatest(actions.addNewCase.type, addNewCaseSaga);
  yield takeLatest(actions.getCase.type, getCaseSaga);
  yield takeLatest(actions.getCaseStatus.type, getCaseStatusSaga);
  yield takeLatest(actions.getCaseProducts.type, getCaseProductsSaga);
  yield takeLatest(actions.getCaseTask.type, getCaseTaskSaga);
  yield takeLatest(actions.removeCaseStatus.type, removeCaseStatusSaga);
  yield takeLatest(actions.removeCaseProduct.type, removeCaseProductSaga);
  yield takeLatest(actions.removeCaseTask.type, removeCaseTaskSaga);
  yield takeLatest(actions.updateCaseTask.type, updateCaseTaskSaga);
  yield takeLatest(actions.updateCase.type, updateCaseSaga);
  yield takeLatest(actions.addCaseStatus.type, addCaseStatusSaga);
  yield takeLatest(actions.updateCaseStatus.type, updateCaseStatusSaga);
  yield takeLatest(actions.createCaseTask.type, createCaseTaskSaga);
  yield takeLatest(actions.getProviders.type, getProvidersSaga);
  yield takeLatest(actions.addCaseProduct.type, addCaseProductSaga);
  yield takeLatest(actions.updateCaseProduct.type, updateCaseProductSaga);
  yield takeLatest(actions.getLenders.type, getLendersSaga);
  yield takeLatest(actions.getProducts.type, getProductsSaga);
  yield takeLatest(actions.updateCaseState.type, updateCaseStateSaga);
  yield takeLatest(actions.getCaseProduct.type, getCaseProductSaga);
  yield takeLatest(actions.updateCaseTasksBulk.type, updateCaseTasksBulkSaga);
  yield takeLatest(actions.removeCaseTasksBulk.type, removeCaseTasksBulkSaga);
  yield takeLatest(
    actions.getCaseWithoutLoading.type,
    getCaseWithoutLoadingSaga
  );
  yield takeLatest(actions.assignCase.type, assignCaseSaga);
}
