import { createSlice } from "@reduxjs/toolkit";
import { defaultTasksSorting } from "utils/constants";

const initialState = {
  loading: true,
  data: {
    results: []
  },
  statuses: [],
  filters: {
    brokers: null,
    status: null,
    propertyType: null,
    onCurrentStatus: true,
    case: null,
    client: null,
    sorting: {
      ...defaultTasksSorting
    }
  },
  activeTab: "active",
  caseDetailActiveTab: "status",
  caseTasktabs: "todo",
  search: "",
  page: 1,
  rowsPerPage: 12,
  error: null,
  caseStatusPage: 1,
  caseProductPage: 1,
  caseTaskPage: 1,
  caseStatuses: [],
  caseProducts: [],
  caseTasks: [],
  providers: [],
  lenders: [],
  products: [],
  caseProduct: {}
};

export const cases = createSlice({
  name: "cases",
  initialState,
  reducers: {
    getCases: state => {
      state.loading = true;
    },
    getSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changePage: (state, action) => {
      state.page = action.payload;
    },
    changecaseStatusPage: (state, action) => {
      state.caseStatusPage = action.payload;
    },
    changecaseProductPage: (state, action) => {
      state.caseProductPage = action.payload;
    },
    changecaseTaskPage: (state, action) => {
      state.caseTaskPage = action.payload;
    },
    changeRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
      state.page = 0;
    },
    getStatuses: (state, action) => {
      state.loading = true;
    },
    getStatusesSucceed: (state, action) => {
      state.loading = false;
      state.statuses = action.payload;
    },
    getStatusesFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
      state.page = 1;
      state.search = "";
    },
    updateCaseDetailActiveTab: (state, action) => {
      state.caseDetailActiveTab = action.payload;
      state.page = 1;
    },
    changeSearch: (state, action) => {
      state.page = 1;
      state.search = action.payload;
    },
    updateFilters: (state, action) => {
      state.filters = action.payload;
    },
    addNewCase: (state, action) => {
      state.loading = true;
    },
    addNewCaseSucceed: (state, action) => {
      state.loading = false;
    },
    addNewCaseFailed: (state, action) => {
      state.loading = true;
    },
    getCase: state => {
      state.loading = true;
    },
    getCaseSucceed: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getCaseFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeCase: (state, action) => {
      state.loading = true;
    },
    removeCaseSucceed: (state, action) => {
      state.loading = false;
    },
    removeCaseFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getCaseStatus: (state, action) => {
      state.loading = true;
    },
    getCaseStatusSucceed: (state, action) => {
      state.loading = false;
      state.caseStatuses = action.payload;
    },
    getCaseStatusFailed: (state, action) => {
      state.loading = true;
    },
    getCaseProducts: (state, action) => {
      state.loading = true;
    },
    getCaseProductsSucceed: (state, action) => {
      state.loading = false;
      state.caseProducts = action.payload;
    },
    getCaseProductsFailed: (state, action) => {
      state.loading = true;
    },
    getCaseTask: (state, action) => { },
    getCaseTaskSucceed: (state, action) => {
      state.caseTasks = action.payload;
    },
    getCaseTaskFailed: (state, action) => { },
    updateCaseTask: (state, action) => {
      state.loading = true;
    },
    updateCaseTaskSucceed: (state, action) => {
      state.loading = false;
    },
    updateCaseTaskFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeCaseStatus: (state, action) => {
      state.loading = true;
    },
    removeCaseStatusSucceed: (state, action) => {
      state.loading = false;
    },
    removeCaseStatusFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeCaseProduct: (state, action) => {
      state.loading = true;
    },
    removeCaseProductSucceed: (state, action) => {
      state.loading = false;
    },
    removeCaseProductFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeCaseTask: (state, action) => {
      state.loading = true;
    },
    removeCaseTaskSucceed: (state, action) => {
      state.loading = false;
    },
    removeCaseTaskFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCase: (state, action) => {
      state.loading = true;
    },
    updateCaseSucceed: (state, action) => {
      state.loading = false;
    },
    updateCaseFailed: (state, action) => {
      state.loading = false;
    },
    addCaseStatus: (state, action) => {
      state.loading = true;
    },
    addCaseStatusSucceed: (state, action) => {
      state.loading = false;
    },
    addCaseStatusFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCaseStatus: (state, action) => {
      state.loading = true;
    },
    updateCaseStatusSucceed: (state, action) => {
      state.loading = false;
    },
    updateCaseStatusFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createCaseTask: (state, action) => {
      state.loading = true;
    },
    createCaseTaskSucceed: (state, action) => {
      state.loading = false;
    },
    createCaseTaskFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getProviders: (state, action) => {
      state.loading = true;
    },
    getProvidersSucceed: (state, action) => {
      state.loading = false;
      state.providers = action.payload;
    },
    getProvidersFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addCaseProduct: (state, action) => {
      state.loading = true;
    },
    addCaseProductSucceed: (state, action) => {
      state.loading = false;
      state.caseDetailActiveTab = "products";
    },
    addCaseProductFailed: (state, action) => {
      state.loading = true;
    },
    getLenders: (state, action) => {
      state.loading = true;
    },
    getLendersSucceed: (state, action) => {
      state.loading = false;
      state.lenders = action.payload;
    },
    getLendersFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCaseProduct: (state, action) => {
      state.loading = true;
    },
    updateCaseProductSucceed: (state, action) => {
      state.loading = false;
      state.caseDetailActiveTab = "products";
    },
    updateCaseProductFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getProducts: (state, action) => {
      state.loading = true;
    },
    getProductsSucceed: (state, action) => {
      state.loading = false;
      state.products = action.payload;
    },
    getProductsFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCaseState: state => {
      state.loading = true;
    },
    updateCaseStateSucceed: state => {
      state.loading = false;
    },
    updateCaseStateFailed: state => {
      state.loading = false;
    },
    getCaseProduct: (state, action) => {
      state.loading = true;
    },
    getCaseProductSucceed: (state, action) => {
      state.loading = false;
      state.caseProduct = action.payload;
    },
    getCaseProductFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateCaseTasksBulk: (state, action) => {
      state.loading = true;
    },
    updateCaseTasksBulkSucceed: (state, action) => {
      state.loading = false;
    },
    updateCaseTasksBulkFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeCaseTasksBulk: (state, action) => {
      state.loading = true;
    },
    removeCaseTasksBulkSucceed: (state, action) => {
      state.loading = false;
    },
    removeCaseTasksBulkFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetCasesFilters: (state, action) => {
      state.search = "";
      state.filters.status = null;
      state.filters.brokers = null;
      state.filters.propertyType = null;
      state.filters.client = null;
    },
    getCaseWithoutLoading: state => { },
    getCaseSucceedWithoutLoading: (state, action) => {
      state.data = action.payload;
    },
    getCaseFailedWithoutLoading: (state, action) => {
      state.error = action.payload;
    },
    assignCase: (state, action) => {
      state.loading = true;
    },
    assignCaseSucceed: (state, action) => {
      state.loading = false;
    },
    assignCaseFailed: (state, action) => {
      state.loading = false;
    },
    updateCaseTaskTab: (state, action) => {
      state.caseTasktabs = action.payload;
    }
  }
});

export const {
  getCases,
  getSucceed,
  getFailed,
  changePage,
  changecaseStatusPage,
  changecaseProductPage,
  changecaseTaskPage,
  changeRowsPerPage,
  getStatuses,
  getStatusesSucceed,
  getStatusesFailed,
  updateActiveTab,
  updateCaseDetailActiveTab,
  changeSearch,
  updateFilters,
  addNewCase,
  addNewCaseSucceed,
  addNewCaseFailed,
  getCase,
  getCaseSucceed,
  getCaseFailed,
  removeCase,
  removeCaseSucceed,
  removeCaseFailed,
  getCaseStatus,
  getCaseStatusSucceed,
  getCaseStatusFailed,
  getCaseProducts,
  getCaseProductsSucceed,
  getCaseProductsFailed,
  removeCaseStatus,
  removeCaseStatusSucceed,
  removeCaseStatusFailed,
  removeCaseProduct,
  removeCaseProductSucceed,
  removeCaseProductFailed,
  getCaseTask,
  getCaseTaskSucceed,
  getCaseTaskFailed,
  updateCaseTask,
  updateCaseTaskSucceed,
  updateCaseTaskFailed,
  removeCaseTask,
  removeCaseTaskSucceed,
  removeCaseTaskFailed,
  updateCase,
  updateCaseSucceed,
  updateCaseFailed,
  addCaseStatus,
  addCaseStatusSucceed,
  addCaseStatusFailed,
  updateCaseStatus,
  updateCaseStatusSucceed,
  updateCaseStatusFailed,
  createCaseTask,
  createCaseTaskSucceed,
  createCaseTaskFailed,
  getProviders,
  getProvidersSucceed,
  getProvidersFailed,
  addCaseProduct,
  addCaseProductSucceed,
  addCaseProductFailed,
  getLenders,
  getLendersSucceed,
  getLendersFailed,
  updateCaseProduct,
  updateCaseProductSucceed,
  updateCaseProductFailed,
  getProducts,
  getProductsSucceed,
  getProductsFailed,
  updateCaseState,
  updateCaseStateSucceed,
  updateCaseStateFailed,
  getCaseProduct,
  getCaseProductSucceed,
  getCaseProductFailed,
  updateCaseTasksBulk,
  updateCaseTasksBulkSucceed,
  updateCaseTasksBulkFailed,
  removeCaseTasksBulk,
  removeCaseTasksBulkSucceed,
  removeCaseTasksBulkFailed,
  resetCasesFilters,
  getCaseWithoutLoading,
  getCaseSucceedWithoutLoading,
  getCaseFailedWithoutLoading,
  assignCase,
  assignCaseSucceed,
  assignCaseFailed,
  updateCaseTaskTab
} = cases.actions;

export default cases.reducer;
