import * as caseConverter from "change-object-case";
import { useState, useEffect, useRef } from "react";
import { ROUTES } from "routes/constants";
import {
  SORTING,
  TASK_TABS,
  NOTIFICATION_TABS,
  CASES_TABS,
  DESELECT_ALL,
  SELECT_ALL,
  USER_TYPE,
  INVOICES_TYPE,
  SUBSCRIPTION_TYPE,
  CREDITS_DURATION
} from "./constants";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TimelineIcon from "@mui/icons-material/Timeline";
import WorkIcon from "@mui/icons-material/Work";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import DomainIcon from "@mui/icons-material/Domain";
import { TOKEN } from "./constants";
import { Tooltip } from "@mui/material";
import moment from "moment";
import _ from "lodash";
import Leads from "pages/Leads";
import Lead from "pages/Leads/Lead";
import Reports from "pages/Reports";
import Notifications from "pages/Notifications";
import Dashboard from "pages/Dashboard";
import BrokerDashboardDetail from "pages/Dashboard/BrokerDashboardDetail";
import Settings from "pages/Settings";
import Cases from "pages/Cases";
import AddNewCase from "pages/Cases/AddNewCase";
import EditCase from "pages/Cases/EditCase";
import Case from "pages/Cases/Case";
import Tasks from "pages/Tasks";
import EditProduct from "pages/Cases/EditProduct";
import AddProduct from "pages/Cases/AddProduct";
import Credits from "pages/Credits";
import Company from "pages/Company";
import Clients from "pages/Clients";
import ClientCaseListing from "pages/Clients/ClientCaseListing";
import EmailsContent from "pages/Emails";
import EmailContent from "pages/Emails/EmailContent";
import Logout from "components/Logout";

export const getLocalStorageItem = (key, defaultValue = null) => {
  return JSON.parse(localStorage.getItem(key, defaultValue));
};

export const setLocalStorageItem = (key, value) => {
  return localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorageItem = key => {
  localStorage.removeItem(key);
};

export const getAccessToken = () => {
  return localStorage.getItem(TOKEN) || "";
};

export const moneyField = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

export const formatFilters = (filters = {}) => {
  let filters_ = { ...filters };
  if (filters.sorting) {
    let ordering = [];
    for (const [key, value] of Object.entries(filters.sorting)) {
      switch (+value) {
        case SORTING.ASCENDING:
          ordering.push(key);
          break;
        case SORTING.DESCENDING:
          ordering.push(`-${key}`);
          break;
        default:
          break;
      }
    }
    filters_["ordering"] = ordering.join(",");
    delete filters_["sorting"];
  }

  if (filters.activeTab) {
    switch (filters.activeTab) {
      case TASK_TABS.ALL:
        break;
      case TASK_TABS.TODO:
        filters_["isCompleted"] = false;
        break;
      case TASK_TABS.COMPLETED:
        filters_["isCompleted"] = true;
        break;
      case NOTIFICATION_TABS.UNREAD:
        filters_["isRead"] = false;
        break;
      case NOTIFICATION_TABS.READ:
        filters_["isRead"] = true;
        break;
      case CASES_TABS.ALL:
        break;
      case CASES_TABS.ACTIVE:
        filters_["state"] = "ACTIVE";
        break;
      case CASES_TABS.COMPLETE:
        filters_["state"] = "COMPLETE";
        break;
      case CASES_TABS.ARCHIVED:
        filters_["state"] = "ARCHIVED";
        break;
      default:
        break;
    }
    delete filters_["activeTab"];
  }
  if (_.isArray(filters.status) && !_.isEmpty(filters.status)) {
    filters_["status"] = filters.status.map(status => status.id);
  }
  if (_.isArray(filters.propertyType) && !_.isEmpty(filters.propertyType)) {
    filters_["propertyType"] = filters.propertyType.map(status => status.value);
  }
  if (filters.date) {
    filters_["date"] = moment(filters.date).format(moment.HTML5_FMT.DATE);
  }
  if (filters.start) {
    filters_["start"] = moment(filters.start).format(moment.HTML5_FMT.DATE);
  }
  if (filters.end) {
    filters_["end"] = moment(filters.end).format(moment.HTML5_FMT.DATE);
  }
  if (filters.company) {
    filters_["company"] = filters.company;
    delete filters_["brokers"];
  }
  if (!_.isEmpty(filters.brokers)) {
    filters_["brokers"] = filters.brokers.map(broker => broker.id);
    delete filters_["company"];
  }
  return caseConverter.snakeKeys(filters_);
};

export const getNextSortingState = currentState => {
  switch (+currentState) {
    case SORTING.NONE:
      return SORTING.ASCENDING;
    case SORTING.ASCENDING:
      return SORTING.DESCENDING;
    case SORTING.DESCENDING:
      return SORTING.NONE;
    default:
      return currentState;
  }
};

export const clearOtherSortingParams = (sorting, currentParam) => {
  // sorting on one column at one time
  for (const [key] of Object.entries(sorting)) {
    if (key !== currentParam) {
      sorting[key] = SORTING.NONE;
    }
  }
  return sorting;
};

export const isNoSortingParams = sorting => {
  for (const [value] of Object.entries(sorting)) {
    if (value !== SORTING.NONE) {
      return false;
    }
  }
  return true;
};

export const getSortingColor = currentState => {
  switch (+currentState) {
    case SORTING.NONE:
      return "text.primary";
    case SORTING.ASCENDING:
    case SORTING.DESCENDING:
      return "primary.main";
    default:
      return "text.primary";
  }
};

export const getSortingIcon = currentState => {
  switch (+currentState) {
    case SORTING.NONE:
      return <ArrowUpwardIcon sx={{ color: "text.primary" }} />;
    case SORTING.ASCENDING:
      return <ArrowUpwardIcon color="primary" />;
    case SORTING.DESCENDING:
      return <ArrowDownwardIcon color="primary" />;
    default:
      return "text.primary";
  }
};

export const dueDays = date => {
  const dueDate = moment(moment(date).format("YYYY-MM-DD"));
  const todayDate = moment(moment(moment()).format("YYYY-MM-DD"));
  const dueDays = dueDate.diff(todayDate, "days");

  if (dueDays === 1 || dueDays === 0) {
    return `${dueDays} day to go`;
  } else if (dueDays > 0) {
    return `${dueDays} days to go`;
  } else if (dueDays < 0) {
    if (Math.abs(dueDays) === 1) {
      return `${Math.abs(dueDays)} day late`;
    }
    return `${Math.abs(dueDays)} days late`;
  }
};

export const inDueDays = date => {
  const dueDate = moment(moment(date).format("YYYY-MM-DD"));
  const todayDate = moment(moment(moment()).format("YYYY-MM-DD"));
  const dueDays = dueDate.diff(todayDate, "days");

  if (dueDays === 1 || dueDays === 0) {
    return `in ${dueDays} day`;
  } else if (dueDays > 0) {
    return `in ${dueDays} days`;
  } else if (dueDays < 0) {
    if (Math.abs(dueDays) === 1) {
      return `${Math.abs(dueDays)} day late`;
    }
    return `${Math.abs(dueDays)} days late`;
  }
};

export const dueDaysDiff = date => {
  const dueDate = moment(moment(date).format("YYYY-MM-DD"));
  const todayDate = moment(moment(moment()).format("YYYY-MM-DD"));
  const dueDays = dueDate.diff(todayDate, "days");
  return dueDays;
};

export const expiryDueDate = date => {
  const dueDate = moment(moment(date).format("YYYY-MM-DD"));
  const todayDate = moment(moment(moment()).format("YYYY-MM-DD"));
  const dueDays = dueDate.diff(todayDate, "days");

  if (dueDays === 0) {
    return `expires in a day`;
  } else if (dueDays > 0) {
    return `expires in ${dueDays + 1} days`;
  } else if (dueDays < 0) {
    if (Math.abs(dueDays) === 1) {
      return `expired ${Math.abs(dueDays)} day ago`;
    }
    return `expired ${Math.abs(dueDays)} days ago`;
  }
};

export const profileNameFormatter = user => {
  if (user.firstName && user.lastName) {
    return `${user.firstName.charAt(0)}.${user.lastName.charAt(0)}`;
  } else if (user.firstName) {
    return `${user.firstName.charAt(0)}`;
  } else {
    return `${user.email.charAt(0)}`;
  }
};

export const NameFormatterWithSpace = client => {
  if (client) {
    if (client.firstName && client.lastName) {
      return `${client.firstName} ${client.lastName.charAt(0)}`;
    } else if (client.firstName) {
      return `${client.firstName}`;
    } else {
      return `${client.email.charAt(0)}`;
    }
  }
};

export const NameFormatterWithDot = user => {
  if (user) {
    if (user.firstName && user.lastName) {
      return `${user.firstName}.${user.lastName.charAt(0)}`;
    } else if (user.firstName) {
      return `${user.firstName}`;
    } else {
      return `${user.email.charAt(0)}`;
    }
  }
};

export const showNote = (str, charLength) => {
  if (str.length > charLength) {
    return (
      <Tooltip title={str} placement="top">
        <span style={{ wordBreak: "break-word" }}>{str}</span>
      </Tooltip>
    );
  }

  return str || "N/A";
};

export const turncateString = (str, charLength) => {
  if (str.length > charLength) {
    return (
      <span style={{ wordBreak: "break-word" }}>
        {str.slice(0, charLength).concat("...")}
      </span>
    );
  }
  return str || "N/A";
};

export const scrollBarStyle = theme => {
  return {
    overflowY: "auto",
    "::-webkit-scrollbar": {
      width: "5px"
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.primary.dark
    }
  };
};

export const toggleSelectDeselectAll = (selectedItems, allItems) => {
  let ids = allItems.results.map(({ id }) => id);
  let isAllSelected = ids.every(id => selectedItems.includes(id));

  return isAllSelected ? DESELECT_ALL : SELECT_ALL;
};

export const moneyFieldConverterTBC = value => {
  const amount = moneyField.format(value);
  return ["£NaN", "£0"].includes(amount) ? "TBC" : moneyField.format(value);
};

export const moneyFieldConverterTBCFormatter = (value, formattedValue) => {
  const amount = moneyField.format(value);
  return ["£NaN", "£0"].includes(amount) ? "TBC" : `£${formattedValue}`;
};

export const moneyFieldConverter = value => {
  const amount = moneyField.format(value);
  return ["£NaN"].includes(amount) ? "N/A" : moneyField.format(value || 0);
};

export const moneyFieldConverterFormatter = (value, formattedValue) => {
  const amount = moneyField.format(value);
  return ["£NaN"].includes(amount) ? "N/A" : `£${formattedValue || 0}`;
};

export const ltvConverter = value => {
  return value === "0" || value === undefined ? "TBC" : `${value}%`;
};

export const checkPositiveNegetiveValue = value => {
  return value < 0 ? `${Math.abs(value)}%` : `${value}%`;
};

export const rankChipBgColor = rankNumber => {
  if (rankNumber === 1) {
    return "primary.main";
  } else if (rankNumber === 2) {
    return "warning.main";
  } else if (rankNumber === 3) {
    return "error.main";
  } else {
    return "active.selected";
  }
};

export const rankChipColor = rankNumber => {
  if (rankNumber > 3) {
    return "text.primary";
  } else {
    return "text.dark.primary";
  }
};

export const paginationStyle = theme => {
  return {
    padding: "12px 0",
    position: "absolute",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "& button": {
      color: theme.palette.text.primary
    },
    "& .MuiPaginationItem-circular.Mui-selected": {
      background: `${theme.palette.paginationPalette.dark} !important`
    },
    "& .MuiPaginationItem-circular": {
      color: theme.palette.text.primary,
      margin: 0
    }
  };
};

export const profileImageUploaderStyles = theme => {
  return {
    position: "relative",
    width: "170px",
    height: "170px",
    borderRadius: "4px",
    background: "transparent",
    border: `1px dashed ${theme.palette.text.disabled}`,
    transition: "0.5s ease all",
    boxShadow: "unset",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&::before": {
      content: '""',
      position: "absolute",
      height: "60px",
      width: "60px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='46px' height='46px' viewBox='0 0 46 46' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E📍Icon%3C/title%3E%3Cg id='Registration' stroke='none' strokeWidth='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Onboarding-&gt;-Business' transform='translate(-137.000000, -285.000000)' fill='%23C4D1FF'%3E%3Cg id='📍Icon' transform='translate(137.500000, 285.500000)'%3E%3Cpath d='M45,40 L45,5 C45,2.25000001 42.7500002,0 40,0 L5,0 C2.25000001,0 0,2.25000001 0,5 L0,40 C0,42.7500002 2.25000001,45 5,45 L40,45 C42.7500002,45 45,42.7500002 45,40 Z M13.7500001,26.2500001 L20,33.7750001 L28.7500001,22.5000001 L40,37.5000001 L5.00000002,37.5000001 L13.7500001,26.2500001 Z' id='ImageFilled'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
    },
    "&.upload": {
      "&::before": {
        display: "none"
      }
    },
    "& input[type='file']": {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      opacity: 0,
      zIndex: 2,
      cursor: "pointer"
    },
    "& img": {
      width: "100%",
      height: "100%",
      objectPosition: "center",
      zIndex: 1,
      position: "relative"
    },
    "&.required": {
      border: "1px solid #d32f2f"
    }
  };
};

export const grossEarningTransformerForLineChart = (lastMonth, thisMonth) => {
  let prevMonth = {
    x: "lastMonth",
    y: lastMonth
  };
  let currentMonth = {
    x: "thisMonth",
    y: thisMonth
  };
  return [{ id: "0", data: [prevMonth, currentMonth] }];
};

export const thirdReportTransformerLineChart = data => {
  let results = _.chain(data)
    .groupBy("id")
    .map((values, key) => ({
      id: values[0].name,
      data: values.map(value => ({
        x: `${value.week}-${value.year}`,
        y: value.creditsUsed
      }))
    }))
    .value();
  return results;
};

export const fourthReportTransformerbarChart = data => {
  const results = [];

  for (const insurance of data?.insurance) {
    for (const mortgage of data?.mortgage) {
      if (insurance.id === mortgage.id) {
        const fourthReportObj = {
          id: insurance.id,
          name: insurance.name,
          insuranceAmount: insurance.amount,
          mortgageAmount: mortgage.amount
        };
        results.push(fourthReportObj);
      }
    }
  }
  return results;
};

export const chartXaxisLabelFirstReport = (userType, selectedCompany) => {
  let returnValue = "email";
  if (userType === USER_TYPE.ADMIN) {
    returnValue = "name";
    if (selectedCompany) {
      returnValue = "email";
    }
  }
  return returnValue;
};

export const truncateChartlabel = (str, charLength) => {
  if (str?.length > charLength) {
    return (
      <Tooltip arrow title={str} placement="top">
        <tspan style={{ wordBreak: "break-word" }}>
          {str.slice(0, charLength).concat("...")}
        </tspan>
      </Tooltip>
    );
  }
  return str;
};

export const handleRestrictAlphabets = e => {
  const regex = /[a-z\b\s]/i;
  if (!regex.test(e.key)) {
    e.preventDefault();
    return false;
  }
};

export const authenticatedComponentBasesOnUserType = userType => {
  const ADMIN = [
    { path: ROUTES.LEADS, component: Leads },
    { path: ROUTES.LEAD, component: Lead },
    { path: ROUTES.REPORTS, component: Reports },
    { path: ROUTES.NOTIFICATIONS, component: Notifications },
    { path: ROUTES.SETTINGS, component: Settings },
    { path: ROUTES.EMAILS, component: EmailsContent },
    { path: ROUTES.EMAIL_CONTENT, component: EmailContent }
  ];
  const COMPANY_OWNER = [
    { path: ROUTES.COMPANY, component: Company },
    { path: ROUTES.EMAILS, component: EmailsContent },
    { path: ROUTES.EMAIL_CONTENT, component: EmailContent }
  ];
  const COMPANY_OWNER_AND_BROKER = [
    { path: ROUTES.DASHBOARD, component: Dashboard },
    { path: ROUTES.BROKER_DASHBOARD, component: BrokerDashboardDetail },
    { path: ROUTES.LEADS, component: Leads },
    { path: ROUTES.SETTINGS, component: Settings },
    { path: ROUTES.CASES, component: Cases },
    { path: ROUTES.ADD_NEW_CASE, component: AddNewCase },
    { path: ROUTES.EDIT_CASE_ID, component: EditCase },
    { path: ROUTES.CASE, component: Case },
    { path: ROUTES.REPORTS, component: Reports },
    { path: ROUTES.NOTIFICATIONS, component: Notifications },
    { path: ROUTES.TASKS, component: Tasks },
    { path: ROUTES.ADD_PRODUCT, component: AddProduct },
    { path: ROUTES.EDIT_PRODUCT, component: EditProduct },
    { path: ROUTES.LEAD, component: Lead },
    { path: ROUTES.CREDITS, component: Credits },
    { path: ROUTES.CLIENTS, component: Clients },
    { path: ROUTES.CASE_CLIENT, component: ClientCaseListing }
  ];
  const COMMON = [
    { path: ROUTES.LEADS, component: Leads },
    { path: ROUTES.LEAD, component: Lead },
    { path: ROUTES.REPORTS, component: Reports },
    { path: ROUTES.NOTIFICATIONS, component: Notifications },
    { path: ROUTES.SETTINGS, component: Settings },
    { path: ROUTES.LOGOUT, component: Logout }
  ];

  switch (userType) {
    case USER_TYPE.ADMIN:
      return _.uniqBy([...COMMON, ...ADMIN], "path");
    case USER_TYPE.COMPANY_OWNER:
      return _.uniqBy(
        [...COMMON, ...COMPANY_OWNER, ...COMPANY_OWNER_AND_BROKER],
        "path"
      );
    case USER_TYPE.BROKER:
      return _.uniqBy([...COMMON, ...COMPANY_OWNER_AND_BROKER], "path");
    default:
      return _.uniqBy([...COMPANY_OWNER_AND_BROKER, ...COMPANY_OWNER], "path");
  }
};

export const sideMenuListing = userType => {
  const ADMIN_LISTING = [
    {
      id: 1,
      name: "Leads",
      pageRoute: ROUTES.LEADS,
      icon: <TimelineIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: true
    },
    {
      id: 5,
      name: "Reports",
      pageRoute: ROUTES.REPORTS,
      icon: <LeaderboardIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    },
    {
      id: 8,
      name: "Emails",
      pageRoute: ROUTES.EMAILS,
      icon: <EmailIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    }
  ];

  const COMPANY_OWNER_LISTING = [
    {
      id: 7,
      name: "Company",
      pageRoute: ROUTES.COMPANY,
      icon: <DomainIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    },
    {
      id: 8,
      name: "Emails",
      pageRoute: ROUTES.EMAILS,
      icon: <EmailIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    }
  ];

  const COMPANY_OWNER_LISTING_AND_BROKER_LISTING = [
    {
      id: 1,
      name: "Leads",
      pageRoute: ROUTES.LEADS,
      icon: <TimelineIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: true
    },
    {
      id: 2,
      name: "Dashboard",
      pageRoute: ROUTES.DASHBOARD,
      icon: <DashboardIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    },
    {
      id: 3,
      name: "Clients",
      pageRoute: ROUTES.CLIENTS,
      icon: <AccountCircleIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    },
    {
      id: 4,
      name: "Cases",
      pageRoute: ROUTES.CASES,
      icon: <WorkIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    },
    {
      id: 5,
      name: "Reports",
      pageRoute: ROUTES.REPORTS,
      icon: <LeaderboardIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    },
    {
      id: 6,
      name: "Tasks",
      pageRoute: ROUTES.TASKS,
      icon: <AssignmentTurnedInIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    },
    {
      id: 9,
      name: "Credits",
      pageRoute: ROUTES.CREDITS,
      icon: <AccountBalanceWalletIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: true,
      showUnreadLeadsCount: false
    }
  ];

  const COMMON_LISTING = [
    {
      id: 1,
      name: "Leads",
      pageRoute: ROUTES.LEADS,
      icon: <TimelineIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: true
    },
    {
      id: 5,
      name: "Reports",
      pageRoute: ROUTES.REPORTS,
      icon: <LeaderboardIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false,
      showUnreadLeadsCount: false
    }
  ];

  let sideMenuListing = null;
  switch (userType) {
    case USER_TYPE.ADMIN:
      sideMenuListing = _.uniqBy([...COMMON_LISTING, ...ADMIN_LISTING], "name");
      return sideMenuListing.sort((a, b) => a.id - b.id);
    case USER_TYPE.COMPANY_OWNER:
      sideMenuListing = _.uniqBy(
        [
          ...COMMON_LISTING,
          ...COMPANY_OWNER_LISTING,
          ...COMPANY_OWNER_LISTING_AND_BROKER_LISTING
        ],
        "name"
      );
      return sideMenuListing.sort((a, b) => a.id - b.id);

    case USER_TYPE.BROKER:
      sideMenuListing = _.uniqBy(
        [...COMMON_LISTING, ...COMPANY_OWNER_LISTING_AND_BROKER_LISTING],
        "name"
      );
      return sideMenuListing.sort((a, b) => a.id - b.id);
    default:
      sideMenuListing = _.uniqBy(
        [
          ...COMMON_LISTING,
          ...COMPANY_OWNER_LISTING,
          ...COMPANY_OWNER_LISTING_AND_BROKER_LISTING
        ],
        "name"
      );
      return sideMenuListing.sort((a, b) => a.id - b.id);
  }
};

export const bottomNavigation = userType => {
  const ADMIN_LISTING = [
    {
      id: 1,
      pageRoute: ROUTES.LEADS,
      icon: <TimelineIcon sx={{ color: "text.primary" }} />
    },
    {
      id: 4,
      pageRoute: ROUTES.REPORTS,
      icon: <LeaderboardIcon sx={{ color: "text.primary" }} />,
      showRemainingCredits: false
    }
  ];

  const COMPANY_OWNER_LISTING_AND_BROKER_LISTING = [
    {
      id: 1,
      pageRoute: ROUTES.LEADS,
      icon: <TimelineIcon sx={{ color: "text.primary" }} />
    },
    {
      id: 2,
      pageRoute: ROUTES.DASHBOARD,
      icon: <DashboardIcon sx={{ color: "text.primary" }} />
    },
    {
      id: 3,
      pageRoute: ROUTES.CASES,
      icon: <WorkIcon sx={{ color: "text.primary" }} />
    },
    {
      id: 4,
      pageRoute: ROUTES.REPORTS,
      icon: <LeaderboardIcon sx={{ color: "text.primary" }} />
    }
  ];
  const COMMON_LISTING = [
    {
      id: 1,
      pageRoute: ROUTES.LEADS,
      icon: <TimelineIcon sx={{ color: "text.primary" }} />
    },
    {
      id: 4,
      pageRoute: ROUTES.REPORTS,
      icon: <LeaderboardIcon sx={{ color: "text.primary" }} />
    }
  ];

  let bottomNavigationListing = null;
  switch (userType) {
    case USER_TYPE.ADMIN:
      bottomNavigationListing = _.uniqBy(
        [...COMMON_LISTING, ...ADMIN_LISTING],
        "id"
      );
      return bottomNavigationListing.sort((a, b) => a.id - b.id);
    case USER_TYPE.COMPANY_OWNER:
    case USER_TYPE.BROKER:
      bottomNavigationListing = _.uniqBy(
        [...COMMON_LISTING, ...COMPANY_OWNER_LISTING_AND_BROKER_LISTING],
        "id"
      );
      return bottomNavigationListing.sort((a, b) => a.id - b.id);
    default:
      bottomNavigationListing = _.uniqBy(
        [...COMMON_LISTING, ...COMPANY_OWNER_LISTING_AND_BROKER_LISTING],
        "id"
      );
      return bottomNavigationListing.sort((a, b) => a.id - b.id);
  }
};

export const firstDateOfCurrentMonth = () => {
  return new Date(
    moment()
      .startOf("month")
      .format("YYYY-MM-DD")
  );
};

export const invoiceStatusColor = status => {
  switch (status) {
    case INVOICES_TYPE.PAID:
      return "primary.contrastText";
    case INVOICES_TYPE.OPEN:
      return "error.contrastText";
    default:
      return "warning.contrastText";
  }
};

export const invoiceStatusBgColor = status => {
  switch (status) {
    case INVOICES_TYPE.PAID:
      return "primary.main";
    case INVOICES_TYPE.OPEN:
      return "error.main";
    default:
      return "warning.main";
  }
};

export const horizontalScrollBarStyle = theme => {
  return {
    boxShadow: "none",
    backgroundColor: "background.default",
    "::-webkit-scrollbar": {
      height: "5px"
    },
    "::-webkit-scrollbar-thumb": {
      background: theme.palette.primary.main
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.primary.dark
    }
  };
};

export function useInterval(callback, delay) {
  const cb = useRef(callback);

  useEffect(() => {
    cb.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) return;

    const id = setInterval(() => cb.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export const usedCreditsBasedOnMembership = (
  companyDetails,
  creditsDuration,
  user
) => {
  if (
    companyDetails?.membershipDetails?.type === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO
  ) {
    if (creditsDuration === CREDITS_DURATION.MONTHLY) {
      return companyDetails?.usedCreditsThisMonth;
    } else {
      return companyDetails?.usedCredits;
    }
  } else if (user?.type === USER_TYPE.COMPANY_OWNER) {
    return companyDetails?.usedCredits;
  } else {
    return user?.usedCredits;
  }
};

export const buyCreditsModalWidth = (isTablet, creditsBundle) => {
  let widthValue = "";

  if (isTablet) {
    isTablet && creditsBundle?.length === 1
      ? (widthValue = "320px")
      : (widthValue = "650px");
  } else if (creditsBundle?.length === 1) {
    widthValue = "320px";
  } else {
    widthValue = "898px";
  }

  return widthValue;
};
