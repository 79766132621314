import { ResponsiveLine } from "@nivo/line";
import { darkTheme, lightTheme } from "styles/theme";
import { DARK } from "utils/constants";

function LineGraph({ data, theme }) {
  return (
    <ResponsiveLine
      theme={theme === DARK ? darkTheme : lightTheme}
      data={data}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto"
      }}
      enableGridX={false}
      enableGridY={false}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      axisLeft={null}
      colors={
        theme === DARK
          ? darkTheme.palette.primary.main
          : lightTheme.palette.primary.main
      }
      enableArea={true}
      enablePoints={false}
      lineWidth={1}
      areaBaselineValue={104}
    />
  );
}

export default LineGraph;
