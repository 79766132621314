import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import Header from "components/Header";
import {
  updateActiveTab,
  updateEmailPreference,
  updateUser,
  updateProfileImage
} from "reducers/users";
import { PROFILE_TABS, USER_TYPE } from "utils/constants";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { profileImageUploaderStyles, getAccessToken, horizontalScrollBarStyle } from "utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Card,
  Box,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Paper,
  Stack,
  FormControl,
  TextField,
  Link,
  Typography
} from "@mui/material";
import ChangePassword from "pages/ChangePassword";
import SignatureModal from "./SignatureModal";
import DownloadIcon from "@mui/icons-material/Download";
import { BASE_URL } from "../../api/constants";
import { toast } from "react-toastify";
import {
  patchPushNotificationPreference,
  patchSmsPreference
} from "../../reducers/notifications";

function TabPanel(props) {
  const { children, index } = props;
  const { activeTab } = useSelector(state => state.user);

  return <div>{activeTab === index && <Box> {children} </Box>}</div>;
}

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: user, activeTab, profileImage } = useSelector(
    state => state.user
  );
  const [isSignatureModal, setIsSignatureModal] = useState(false);
  const [profileDetail, setProfileDetail] = useState({});
  const [isError, setIsError] = useState(false);
  const [date, setDate] = useState(new Date(user.me?.dateJoined));

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const token = getAccessToken();

  const handleActiveTab = (e, value) => {
    dispatch(updateActiveTab(value));
  };

  const handleOnCheckChange = (e, id) => {
    dispatch(
      updateEmailPreference({ id, data: { isActive: e.target.checked } })
    );
  };

  const handlePushNotificationPreferenceChange = (e, id) => {
    dispatch(
      patchPushNotificationPreference({
        id,
        data: { isActive: e.target.checked }
      })
    );
  };

  const handleSmsPreferenceChange = (e, id) => {
    dispatch(
      patchSmsPreference({
        id,
        data: { isActive: e.target.checked }
      })
    );
  };

  const uploadProfilePhoto = e => {
    let imgWrapper = document.querySelector(".profilePhoto");
    let img = imgWrapper.querySelector("img");
    if (img) {
      img.remove();
    }
    img = document.createElement("img");
    img.src = URL.createObjectURL(e.target.files[0]);
    imgWrapper.appendChild(img);
    setIsError(false);

    dispatch(updateProfileImage(e.target.files[0]));
  };

  const onSubmit = value => {
    if (!profileImage && !user.me.profileImage) {
      setIsError(true);
      return;
    }

    const formData = new FormData();
    formData.append("profileImage", profileImage);
    formData.append("firstName", value.firstName);
    formData.append("lastName", value.lastName);
    formData.append("mobilePhone", value.mobilePhone);
    formData.append("telephone", value.telephone);
    formData.append("jobTitle", value.jobTitle);

    const profileData = {
      data: formData
    };

    dispatch(updateUser(profileData));
  };

  const onError = () => {
    if (!profileImage && !user.me.profileImage) {
      setIsError(true);
    }
  };

  const handleChange = (key, value) => {
    setProfileDetail({ ...profileDetail, [key]: value });
  };

  useEffect(() => {
    setProfileDetail({
      ...profileDetail,
      firstName: user?.me?.firstName,
      lastName: user.me?.lastName,
      mobilePhone: user.me?.mobilePhone,
      telephone: user.me?.telephone,
      jobTitle: user.me?.jobTitle,
      email: user.me?.email
    });
    reset({
      firstName: user?.me?.firstName,
      lastName: user.me?.lastName,
      mobilePhone: user.me?.mobilePhone,
      telephone: user.me?.telephone,
      jobTitle: user.me?.jobTitle,
      email: user.me?.email
    });
  }, [user]);

  return (
    <>
      <Header componentName="Settings" />
      <div>
        <Typography
          variant="h4"
          color="text.primary"
          marginTop="24px"
          component="h2"
          display={{ xs: "block", lg: "none" }}
        >
          Settings
        </Typography>
        <Box sx={{ margin: "20px 0 40px 0" }}>
          <Tabs
            value={activeTab}
            onChange={handleActiveTab}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={theme => ({
              "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                color: `${theme.palette.primary.main} !important`
              }
            })}
          >
            <Tab
              label="PROFILE"
              value={PROFILE_TABS.PROFILE}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "32px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="EMAIL PREFERENCE"
              value={PROFILE_TABS.EMAIL_PREFERENCE}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "32px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="PUSH NOTIFICATION PREFERENCE"
              value={PROFILE_TABS.PUSH_NOTIFICATION_PREFERENCE}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "32px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="SMS PREFERENCE"
              value={PROFILE_TABS.SMS_PREFERENCE}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "32px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
            <Tab
              label="SECURITY"
              value={PROFILE_TABS.SECURITY}
              sx={theme => ({
                color: "text.secondary",
                fontSize: "16px",
                marginRight: "32px",
                [theme.breakpoints.down("sm")]: {
                  marginRight: 0,
                  fontSize: "14px"
                }
              })}
            />
          </Tabs>
        </Box>
        <TabPanel index={PROFILE_TABS.PROFILE}>
          <Box
            sx={theme => ({
              maxWidth: "600px",
              [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
                marginTop: "24px"
              }
            })}
          >
            {user.me && (
              <form onSubmit={handleSubmit(onSubmit, () => onError(null))}>
                <Box
                  sx={theme => ({
                    display: "flex",
                    [theme.breakpoints.down("lg")]: {
                      marginTop: "24px"
                    }
                  })}
                >
                  <Stack
                    spacing={2}
                    sx={theme => ({
                      width: "170px",
                      marginBottom: "24px",
                      [theme.breakpoints.down("lg")]: {
                        marginLeft: "auto",
                        marginRight: "auto"
                      }
                    })}
                  >
                    <Card
                      className={`profilePhoto ${(user.me.profileImage ||
                        profileImage) &&
                        "upload"} ${isError && "required"}`}
                      sx={theme => profileImageUploaderStyles(theme)}
                    >
                      {user.me.profileImage && (
                        <img
                          src={`${user.me.profileImage}`}
                          alt="Profile image"
                        />
                      )}
                    </Card>
                    {isError && (
                      <div className="validation-error">Image required!</div>
                    )}
                    <Button
                      variant="text"
                      component="label"
                      startIcon={<PanoramaIcon />}
                    >
                      Upload
                      <input
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={uploadProfilePhoto}
                      />
                    </Button>
                  </Stack>
                </Box>
                <Stack spacing={3}>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label={"First Name"}
                      type="text"
                      placeholder="First Name"
                      autoComplete="off"
                      error={!!errors.firstName}
                      {...register("firstName", {
                        required: true,
                        onChange: e => handleChange("firstName", e.target.value)
                      })}
                      InputLabelProps={{
                        shrink: profileDetail.firstName ? true : false
                      }}
                    />
                    {errors.firstName && (
                      <div className="validation-error">
                        First Name required!
                      </div>
                    )}
                  </FormControl>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label={"Last Name"}
                      type="text"
                      placeholder="Last Name"
                      autoComplete="off"
                      error={!!errors.lastName}
                      {...register("lastName", {
                        required: true,
                        onChange: e => handleChange("lastName", e.target.value)
                      })}
                      InputLabelProps={{
                        shrink: profileDetail.lastName ? true : false
                      }}
                    />
                    {errors.lastName && (
                      <div className="validation-error">
                        Last Name required!
                      </div>
                    )}
                  </FormControl>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Email"
                      type="email"
                      placeholder="Email"
                      disabled
                      {...register("email", {
                        onChange: e => handleChange("email", e.target.value)
                      })}
                      InputLabelProps={{
                        shrink: profileDetail.email ? true : false
                      }}
                    />
                  </FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label={"Joined Date"}
                      value={new Date(user.me?.dateJoined)}
                      onChange={setDate}
                      disabled
                      renderInput={params => (
                        <TextField {...params} className={classes.textField} />
                      )}
                    />
                  </LocalizationProvider>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Mobile Phone"
                      type="tel"
                      placeholder="Mobile Phone"
                      error={!!errors.mobilePhone}
                      autoComplete="off"
                      {...register("mobilePhone", {
                        required: true,
                        onChange: e =>
                          handleChange("mobilePhone", e.target.value)
                      })}
                      InputLabelProps={{
                        shrink: profileDetail.mobilePhone ? true : false
                      }}
                    />
                    {errors.mobilePhone && (
                      <div className="validation-error">
                        mobile Phone required!
                      </div>
                    )}
                  </FormControl>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Telephone"
                      type="tel"
                      placeholder="Telephone"
                      error={!!errors.telephone}
                      autoComplete="off"
                      {...register("telephone", {
                        onChange: e =>
                          handleChange("telephone", e.target.value)
                      })}
                      InputLabelProps={{
                        shrink: profileDetail.telephone ? true : false
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Job Title"
                      type="text"
                      placeholder="Job Title"
                      error={!!errors.jobTitle}
                      autoComplete="off"
                      {...register("jobTitle", {
                        required: true,
                        onChange: e => handleChange("jobTitle", e.target.value)
                      })}
                      InputLabelProps={{
                        shrink: profileDetail.jobTitle ? true : false
                      }}
                    />
                    {errors.jobTitle && (
                      <div className="validation-error">
                        Job Title required!
                      </div>
                    )}
                  </FormControl>
                  <Button type="submit" variant="contained">
                    Update
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ margin: "24px 0", flexWrap: "wrap" }}
                >
                  <Button
                    variant="contained"
                    sx={theme => ({
                      paddingLeft: "18px",
                      paddingRight: "18px",
                      marginRight: "16px",
                      flex: "0 0 49%",
                      [theme.breakpoints.down("sm")]: {
                        flex: 1,
                        minWidth: "310px",
                        marginRight: 0,
                        marginBottom: "24px"
                      }
                    })}
                    onClick={() => setIsSignatureModal(true)}
                  >
                    View Signature
                  </Button>
                  {user?.me?.type === USER_TYPE.COMPANY_OWNER && (
                    <Link
                      href={`${BASE_URL}/users/companies/${user?.me?.company}/export/?token=${token}`}
                      download="filename.csv"
                      underline="none"
                      onClick={() =>
                        toast.success("Exporting the company data...")
                      }
                      variant="button"
                      sx={theme => ({
                        padding: "8px 22px",
                        display: "inline-flex",
                        alignItems: "center",
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: "4px",
                        justifyContent: "center",
                        flex: 1,
                        [theme.breakpoints.down("sm")]: {
                          whiteSpace: "pre"
                        }
                      })}
                    >
                      <DownloadIcon sx={{ marginRight: "6px" }} /> EXPORT
                      COMPANY
                    </Link>
                  )}
                </Stack>
              </form>
            )}
          </Box>
        </TabPanel>
        <TabPanel index={PROFILE_TABS.EMAIL_PREFERENCE}>
          {user?.me?.emailPreferences && (
            <TableContainer
              component={Paper}
              sx={theme => (horizontalScrollBarStyle(theme))}
            >
              <Table sx={{ minWidth: 550 }} stickyHeader>
                <TableBody>
                  {user?.me?.emailPreferences.map(row => (
                    <TableRow
                      key={row.id}
                      sx={theme => ({
                        bgcolor: theme.palette.background.default,
                        "& td,th": {
                          borderBottom: 0
                        }
                      })}
                    >
                      <TableCell
                        scope="row"
                        sx={{
                          fontSize: "16px",
                          color: "text.primary"
                        }}
                      >
                        {row.type.displayName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "14px",
                          color: "text.disabled",
                          width: "5%",
                          textAlign: "right"
                        }}
                      >
                        <Checkbox
                          checked={row.isActive}
                          sx={theme => ({
                            color: "text.primary",
                            wordBreak: "break-word"
                          })}
                          onClick={e => handleOnCheckChange(e, row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
        <TabPanel index={PROFILE_TABS.PUSH_NOTIFICATION_PREFERENCE}>
          {user?.me?.pushNotificationPreferences && (
            <TableContainer
              component={Paper}
              sx={theme => (horizontalScrollBarStyle(theme))}
            >
              <Table sx={{ minWidth: 550 }} stickyHeader>
                <TableBody>
                  {user.me.pushNotificationPreferences.map(row => (
                    <TableRow
                      key={row.id}
                      sx={theme => ({
                        bgcolor: theme.palette.background.default,
                        "& td,th": {
                          borderBottom: 0
                        }
                      })}
                    >
                      <TableCell
                        scope="row"
                        sx={{
                          fontSize: "16px",
                          color: "text.primary"
                        }}
                      >
                        {row.type.displayName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "14px",
                          color: "text.disabled",
                          width: "10%",
                          textAlign: "right"
                        }}
                      >
                        <Checkbox
                          checked={row.isActive}
                          sx={theme => ({
                            color: "text.primary",
                            wordBreak: "break-word"
                          })}
                          onClick={e =>
                            handlePushNotificationPreferenceChange(e, row.id)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
        <TabPanel index={PROFILE_TABS.SMS_PREFERENCE}>
          {user?.me?.smsPreferences && (
            <TableContainer
              component={Paper}
              sx={theme => (horizontalScrollBarStyle(theme))}
            >
              <Table sx={{ minWidth: 550 }} stickyHeader>
                <TableBody>
                  {user.me.smsPreferences.map(row => (
                    <TableRow
                      key={row.id}
                      sx={theme => ({
                        bgcolor: theme.palette.background.default,
                        "& td,th": {
                          borderBottom: 0
                        }
                      })}
                    >
                      <TableCell
                        scope="row"
                        sx={{
                          fontSize: "16px",
                          color: "text.primary"
                        }}
                      >
                        {row.type.displayName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "14px",
                          color: "text.disabled",
                          width: "10%",
                          textAlign: "right"
                        }}
                      >
                        <Checkbox
                          checked={row.isActive}
                          sx={theme => ({
                            color: "text.primary",
                            wordBreak: "break-word"
                          })}
                          onClick={e => handleSmsPreferenceChange(e, row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
        <TabPanel index={PROFILE_TABS.SECURITY}>
          <ChangePassword />
        </TabPanel>
      </div>
      {isSignatureModal && (
        <Box>
          <SignatureModal
            isOpen={isSignatureModal}
            handleClose={() => setIsSignatureModal(false)}
            signature={user?.me?.renderedSignature}
          />
        </Box>
      )}
    </>
  );
};

export default Settings;
