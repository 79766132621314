import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modals/index";
import Loader from "components/Loader";
import { buyCreditsModalWidth, moneyFieldConverter } from "utils";
import {
  updateBuyCreditsModal,
  updateBuyCreditsCheckoutModal,
} from "reducers/credits";
import { Typography, Box, Stack, Button, Grid, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function BuyCreditsModal({ isOpen, handleClose, setCreditsCheckout }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { creditsBundle, loading } = useSelector((state) => state.credits);

  const handleBuyCredits = (data) => {
    dispatch(updateBuyCreditsCheckoutModal(true));
    dispatch(updateBuyCreditsModal(false));
    setCreditsCheckout(data);
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      width={buyCreditsModalWidth(isTablet, creditsBundle)}
      title="Buy Credits"
    >
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={3} alignItems="end" className="cards-container" sx={{
          "& .pricing-card:last-child .credit-label": {
            fontWeight: 400,
            boxSizing: "border-box",
            border:
              `1px solid ${theme.palette.primary.main} `,
            borderRadius: "30px",
            padding: "5px 13px",
            margin: "4px 0px"
          }
        }}>
          {creditsBundle?.map(creditBundle => (
            <Grid
              item
              sm={creditsBundle?.length === 1 ? 12 : 3}
              width="100%"
              key={creditBundle.id}
              className="pricing-card"
            >
              <Box
                sx={(theme) => ({
                  position: "relative",
                  background: `${creditBundle.isPopular
                    ? theme.palette.modalColorPalette.dark + `!important`
                    : theme.palette.modalColorPalette.secondary}`,
                  border: `1px solid ${creditBundle.isPopular
                    ? theme.palette.modalColorPalette.light
                    : "none"
                    }`,
                  padding: "26px 32px",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                  width: creditsBundle?.length === 1 ? '90px' : 'unset',
                  marginLeft: "auto",
                  marginRight: "auto",
                  [theme.breakpoints.down("sm")]: {
                    background: `${creditBundle.isPopular
                      ? theme.palette.modalColorPalette.dark
                      : theme.palette.modalColorPalette.secondary}`,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "unset",
                  },
                })}
              >
                {creditBundle.savings > 0 && (
                  <Chip
                    label={`Save ${Math.round(creditBundle.savings)} %`}
                    sx={(theme) => ({
                      background: creditBundle.isPopular
                        ? theme.palette.modalColorPalette.light
                        : theme.palette.primary.main,
                      color: creditBundle.isPopular
                        ? theme.palette.modalColorPalette.chip
                        : theme.palette.primary.contrastText,
                      marginBottom: "0px",
                      fontSize: "12px",
                      fontWeight: "500",
                      fontFamily: "'Nunito Sans', sans-serif",
                      borderRadius: "10px",
                      height: creditBundle.isPopular
                        ? "32px"
                        : "28px",
                      width: creditBundle.isPopular ? "109px" : "100px",
                      position: "absolute",
                      top: "-12px",
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.32)",
                      [theme.breakpoints.down("sm")]: {
                        position: "absolute",
                        top: "-15px",
                      },
                    })}
                  />
                )}
                <Stack justifyContent="center" alignItems="center">
                  {creditBundle.isPopular && (
                    <Typography
                      variant="body1"
                      color={theme.palette.modalColorPalette.light}
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="22px"
                      paddingTop="10px"
                      sx={(theme) => ({
                        [theme.breakpoints.down("sm")]: {
                          marginBottom: 0,
                        },
                      })}
                    >
                      Popular
                    </Typography>
                  )}
                  <Typography
                    variant="h3"
                    color={
                      creditBundle.isPopular
                        ? theme.palette.modalColorPalette.text
                        : theme.palette.text.primary
                    }
                    lineHeight="58px"
                    sx={{
                      [theme.breakpoints.down("ssm")]: {
                        fontSize: "32px",
                      }
                    }}
                  >
                    {creditBundle.quantity}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={
                      creditBundle.isPopular
                        ? theme.palette.modalColorPalette.text
                        : theme.palette.modalColorPalette.tertiary
                    }
                    lineHeight="22px"
                    sx={theme => ({
                      marginBottom: "24px",
                      [theme.breakpoints.down("sm")]: {
                        marginBottom: 0,
                      }
                    })}
                  >
                    Credits
                  </Typography>
                </Stack>
                <Grid sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    spacing={"2px"}
                    mb="24px"
                  >
                    <Typography
                      variant="body1"
                      color={
                        creditBundle.isPopular
                          ? theme.palette.modalColorPalette.text
                          : theme.palette.modalColorPalette.tertiary
                      }
                      sx={{ fontWeight: "600", lineHeight: "22px" }}
                    >
                      {moneyFieldConverter(creditBundle.price)}
                    </Typography>
                    <Typography
                      className="credit-label"
                      variant="caption"
                      color="#3C67FF"
                      sx={{
                        fontSize: "13px",
                        fontWeight: "700",
                        lineHeight: "18px",
                        color: creditBundle.isPopular
                          ? theme.palette.modalColorPalette.light
                          : theme.palette.primary.main,
                      }}
                    >{`${moneyFieldConverter(
                      creditBundle.perCreditPrice
                    )} per credit`}</Typography>
                    <Typography
                      variant="caption"
                      color={
                        creditBundle.isPopular
                          ? theme.palette.modalColorPalette.text
                          : theme.palette.modalColorPalette.tertiary
                      }
                      sx={(theme) => ({
                        marginBottom: "20px",
                        textAlign: "center",
                        fontSize: "13px",
                        lineHeight: "18px",
                        whiteSpace: "nowrap",
                        [theme.breakpoints.down("sm")]: {
                          marginBottom: 0,
                        }
                      })}
                    >
                      Buy up to {creditBundle.leadsBuyAble} leads
                    </Typography>
                  </Stack>
                  <Button
                    variant="outlined"
                    sx={{
                      width: "64px",
                      height: "38px",
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: "22px",
                      color: creditBundle.isPopular
                        ? theme.palette.modalColorPalette.light
                        : theme.palette.primary.main,
                      borderColor: creditBundle.isPopular
                        ? theme.palette.modalColorPalette.light
                        : theme.palette.primary.main,
                    }}
                    onClick={() => handleBuyCredits(creditBundle)}
                  >
                    BUY
                  </Button>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Modal>
  );
}

export default BuyCreditsModal;
