import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCases, updateFilters } from "reducers/cases";
import CaseListing from "pages/Cases/CaseListing";

const ClientCaseListing = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { data, page, activeTab, search, filters } = useSelector(
    state => state.cases
  );

  useEffect(() => {
    dispatch(
      updateFilters({
        ...filters,
        client: id
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getCases());
  }, [dispatch, activeTab, page, search, filters]);

  return <CaseListing data={data} />;
};

export default ClientCaseListing;
