import React from "react";
import { Alert, Button } from "@mui/material";

export default function ResetFilterAlert({ handleOnResetFilter, text, data }) {
  const ClearButton = () => (
    <Button variant="contained" color="error" onClick={handleOnResetFilter}>
      Clear
    </Button>
  );

  return (
    <Alert
      severity="warning"
      variant="h2"
      sx={theme => ({
        color: theme.palette.text.dark.primary,
        backgroundColor: theme.palette.primary.main,
        fontSize: "16px",
        padding: "14px 32px",
        marginBottom: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      })}
      action={<ClearButton />}
    >
      {data?.length ? "Search result(s)" : `No ${text} match this filter.`}
    </Alert>
  );
}
