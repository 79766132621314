import React, { useState, useEffect } from "react";
import Modal from "components/Modals/index";
import SelectField from "components/SelectField";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { FormControl, Button } from "@mui/material";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

function AssignModal({
  isOpen,
  handleClose,
  ownerId,
  brokerId,
  setBrokerId,
  handleAssignBtn
}) {
  const classes = useStyles();
  const [brokers, setBrokers] = useState([]);
  const { data: userData } = useSelector(state => state.user);

  useEffect(() => {
    setBrokers(userData.brokers.filter(item => item.id !== ownerId));
  }, []);
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Assign Case">
      <FormControl
        sx={{ width: "100%", marginTop: "16px" }}
        className={classes.textField}
      >
        <SelectField
          fullWidth
          label={"Select Broker"}
          defaultValue={""}
          onChange={e => {
            setBrokerId(e.target.value);
          }}
          options={brokers.map(({ id, email }) => ({
            value: id,
            label: email
          }))}
        />
      </FormControl>
      <Button
        type="submit"
        onClick={handleAssignBtn}
        variant="contained"
        sx={{ marginTop: "24px" }}
      >
        Submit
      </Button>
    </Modal>
  );
}

export default AssignModal;
