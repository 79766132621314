import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ModalWrapper = ({
  isOpen,
  handleClose,
  title,
  children,
  styles,
  width = 480
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"));

  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isTablet ? "100%" : width,
    overflowY: "auto",
    maxHeight: 700,
    bgcolor: "modalColorPalette.primary",
    borderRadius: "16px",
    boxShadow: `0px 24px 38px 3px ${theme.palette.boxShadowPalette.main},0px 9px 46px 8px ${theme.palette.boxShadowPalette.dark},0px 11px 15px -7px ${theme.palette.boxShadowPalette.light}`,
    padding: "32px 40px",
    outline: "unset",
    "@media (orientation: landscape)": {
      margin: "50px 0",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      bgcolor: "modalColorPalette.primary",
      height: "100%",
      maxHeight: "100%",
      width: "100%",
      top: "50%",
      transform: "translate(-50%,-50%)",
      padding: "52px 0"
    }
  };

  return (
    <Modal
      open={isOpen || false}
      onClose={handleClose}
      sx={theme => ({
        overflowY: "scroll",
        [theme.breakpoints.down("sm")]: {
          overflowY: "unset"
        }
      })}
    >
      <Box sx={{ ...modalStyles, ...styles }}>
        <Box
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              padding: "56px 32px"
            }
          })}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h5"
              color="text.primary"
              sx={theme => ({
                marginBottom: "24px",
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "18px"
                }
              })}
              component="h2"
            >
              {title}
            </Typography>
            <CloseIcon
              sx={{ color: "text.primary", cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
          {children}
        </Box>
      </Box>
    </Modal>
  );
};

export default React.memo(ModalWrapper);
