import React from "react";
import { useForm } from "react-hook-form";
import Modal from "components/Modals/index";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useSelector, useDispatch } from "react-redux";
import { updateBuySeatsModal } from "reducers/company";
import {
  Button,
  InputAdornment,
  TextField,
  Alert,
  Typography,
  Stack
} from "@mui/material";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const AddBrokerModal = ({ isOpen, handleClose, handleAddUserModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { me: user } = useSelector(state => state.user.data);

  const { defaultCompanyData } = useSelector(state => state.company);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onSubmit = value => {
    handleAddUserModal({
      ...value,
      email: value.email.toLowerCase(),
      company: user.company,
      blabbleCommissionPercentage: value.blabbleCommissionPercentage,
      personalCommissionPercentage: value.personalCommissionPercentage,
      otherCommissionPercentage: value.otherCommissionPercentage
    });
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title="Add Broker">
      <form onSubmit={handleSubmit(onSubmit)}>
        {defaultCompanyData.planPrice && (
          <Alert
            variant="outlined"
            icon={false}
            severity="info"
            sx={{ alignItems: "center" }}
            action={
              <Typography
                variant="body2"
                color="primary"
                sx={{ cursor: "pointer", marginLeft: "14px" }}
                onClick={() => {
                  dispatch(updateBuySeatsModal(true));
                  handleClose();
                }}
              >
                BUY
              </Typography>
            }
          >
            <Typography variant="body2" color="text.primary">
              {defaultCompanyData.usedSeatsCount} of{" "}
              {defaultCompanyData.seatsCount} seats
            </Typography>
          </Alert>
        )}
        <TextField
          fullWidth
          type="email"
          sx={{ marginTop: "16px" }}
          className={classes.textField}
          label="Email"
          placeholder="Email"
          error={!!errors.email}
          {...register("email", {
            required: true
          })}
        />
        <Typography variant="h6" color="text.primary" margin="16px 0">
          Commission %
        </Typography>
        <Stack spacing={3}>
          <TextField
            fullWidth
            type="number"
            className={classes.textField}
            label="Blabble"
            placeholder="Blabble"
            error={!!errors.blabbleCommissionPercentage}
            InputProps={{
              inputProps: { step: "any" },
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            {...register("blabbleCommissionPercentage", {
              min: 0,
              required: true
            })}
          />
          <TextField
            fullWidth
            type="number"
            className={classes.textField}
            label="Personal"
            placeholder="Personal"
            error={!!errors.personalCommissionPercentage}
            InputProps={{
              inputProps: { step: "any" },
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            {...register("personalCommissionPercentage", {
              min: 0,
              required: true
            })}
          />
          <TextField
            fullWidth
            type="number"
            className={classes.textField}
            label={user?.otherLeadsName}
            placeholder={user?.otherLeadsName}
            error={!!errors.otherCommissionPercentage}
            InputProps={{
              inputProps: { step: "any" },
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
            {...register("otherCommissionPercentage", {
              min: 0,
              required: true
            })}
          />
        </Stack>
        <Button
          sx={theme => ({
            marginTop: 3,
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          })}
          type="submit"
          variant="contained"
        >
          ADD
        </Button>
      </form>
    </Modal>
  );
};

export default AddBrokerModal;
