import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  circularProgress: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  foreground: {
    position: "relative",
    zIndex: 2,
    height: "160px !important",
    width: "160px !important",
    [theme.breakpoints.down("xxl")]: {
      height: "130px !important",
      width: "130px !important"
    },
    [theme.breakpoints.down("sm")]: {
      height: "90px !important",
      width: "90px !important"
    },
    "& svg": {
      color: theme.palette.progressBarPalette.main
    }
  },
  background: {
    position: "absolute",
    zIndex: 1,
    right: 0,
    height: "160px !important",
    width: "160px !important",
    [theme.breakpoints.down("xxl")]: {
      height: "130px !important",
      width: "130px !important"
    },
    [theme.breakpoints.down("sm")]: {
      height: "90px !important",
      width: "90px !important"
    },
    "& svg": {
      color: theme.palette.progressBarPalette.dark,
      "& circle": {
        strokeDashoffset: "0px !important"
      }
    }
  }
}));

function CircularProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box className={classes.circularProgress}>
      <CircularProgress
        {...props}
        variant="determinate"
        className={classes.foreground}
      />
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.background}
      />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography
          variant="h6"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function CaseCounterProgress({ progressPercentage }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progressPercentage) {
      const timer = setInterval(() => {
        setProgress(progressPercentage);
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [progressPercentage]);

  return <CircularProgressWithLabel value={parseInt(progress)} />;
}
