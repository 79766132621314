import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { LIGHT } from "utils/constants";

function LoginImage() {
  const { theme } = useSelector(state => state.user);
  return (
    <Box component="div" style={{ textAlign: "center" }}>
      <Box
        component="img"
        sx={{
          height: "auto",
          maxWidth: "100%"
        }}
        alt="Login-in"
        src={
          theme === LIGHT ? "/app/assets/login-light.svg" : "/app/assets/login-dark.svg"
        }
      />
    </Box>
  );
}

export default LoginImage;
