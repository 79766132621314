import React, { useEffect } from "react";
import Modal from "components/Modals/index";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Stack, Link } from "@mui/material";
import { getStripeInvoices } from "reducers/company";

function ConfirmPaymentModal({ isOpen, handleClose }) {
  const dispatch = useDispatch();
  const { paymentConfirmationResponse } = useSelector(state => state.company);

  useEffect(() => {
    dispatch(getStripeInvoices());
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Complete Your Payment"
    >
      <Typography variant="body1" color="text.primary">
        Please go to this invoice.
      </Typography>
      <Stack direction="row" marginTop="32px">
        <Link
          href={paymentConfirmationResponse.invoiceUrl}
          target="_blabble"
          color="primary"
          sx={theme => ({
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 16px",
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "4px",
            [theme.breakpoints.down("sm")]: {
              width: "100%"
            }
          })}
        >
          INVOICE
        </Link>
      </Stack>
    </Modal>
  );
}

export default ConfirmPaymentModal;
