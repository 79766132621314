import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Box, Stack, Typography, Badge } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import { getAccessToken } from "utils";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from "@mui/lab";

import {
  getDrawerNotifications,
  changePageNotificationDrawer,
  resetNotificationDrawer
} from "reducers/notifications";
import DrawerContainer from "components/DrawerContainer";
import { ROUTES } from "routes/constants";
import _ from "lodash";

function NotificationDrawer() {
  const anchor = "right";
  const [state, setState] = useState({
    anchor: false
  });

  const { notificationsDrawer, notificationDrawerPage, loading } = useSelector(
    state => state.notifications
  );

  const dispatch = useDispatch();
  const token = getAccessToken();

  useEffect(() => {
    if (token) {
      dispatch(getDrawerNotifications());
    }
  }, [dispatch, notificationDrawerPage]);

  const fetchData = () => {
    dispatch(changePageNotificationDrawer(notificationDrawerPage + 1));
  };

  const toggleDrawer = (anchor, open) => event => {
    event.stopPropagation();
    setState({ ...state, [anchor]: open });

    if (open) {
      dispatch(getDrawerNotifications());
    } else {
      dispatch(resetNotificationDrawer());
    }
  };

  return (
    <Badge
      color="primary"
      badgeContent={notificationsDrawer.unreadCount}
      onClick={toggleDrawer(anchor, true)}
      sx={theme => ({
        cursor: "pointer",
        "& button": {
          padding: 0,
          minWidth: "auto",
          marginLeft: "32px",
          color: theme.palette.text.primary
        }
      })}
    >
      <DrawerContainer
        anchor={anchor}
        state={state}
        toggleDrawer={toggleDrawer}
        Icon={NotificationsIcon}
        sx={{ overflow: "hidden" }}
      >
        <Box
          sx={theme => ({
            width: 410,
            padding: "56px 32px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              padding: "0"
            }
          })}
          role="presentation"
        >
          <Box
            sx={theme => ({
              [theme.breakpoints.down("sm")]: {
                padding: "56px 32px"
              }
            })}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h4"
                color="text.primary"
                sx={theme => ({
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "32px"
                  }
                })}
              >
                Notifications
              </Typography>
              <CloseIcon
                onClick={toggleDrawer(anchor, false)}
                color="text.primary"
                cursor="pointer"
              />
            </Stack>
            <Link
              to={ROUTES.NOTIFICATIONS}
              style={{
                textDecoration: "none",
                display: "block",
                margin: "16px 0 32px"
              }}
            >
              <Typography
                variant="body2"
                color="primary.main"
                sx={{ fontWeight: "700" }}
                onClick={toggleDrawer(anchor, false)}
              >
                VIEW ALL
              </Typography>
            </Link>
            <Timeline
              sx={theme => ({
                "& .MuiTimelineItem-root": {
                  "&::before": {
                    display: "none"
                  },
                  "& .MuiTimelineDot-root": {
                    margin: "0px"
                  }
                },
                padding: 0,
                "& .infiniteScrollStyle": {
                  overflowY: "auto",
                  "::-webkit-scrollbar": {
                    width: "5px"
                  },
                  "::-webkit-scrollbar-thumb": {
                    background: theme.palette.primary.main
                  },
                  "::-webkit-scrollbar-thumb:hover": {
                    background: theme.palette.primary.dark
                  }
                }
              })}
            >
              <InfiniteScroll
                dataLength={notificationsDrawer?.results?.length}
                next={fetchData}
                hasMore={
                  notificationsDrawer?.results?.length <
                  notificationsDrawer?.count
                }
                height={"calc(100vh - 250px)"}
                className="infiniteScrollStyle"
                loader={
                  <Stack sx={{ alignItems: "center" }}>
                    <CircularProgress />
                  </Stack>
                }
                endMessage={
                  loading && _.isEmpty(notificationsDrawer.results) ? (
                    <Stack sx={{ alignItems: "center" }}>
                      <CircularProgress />
                    </Stack>
                  ) : (
                    <TimelineItem>
                      <TimelineDot color="grey" />
                      <TimelineContent
                        style={{
                          paddingRight: "0px",
                          paddingTop: "0px",
                          marginTop: "-5px"
                        }}
                      >
                        <Typography variant="caption" color="text.secondary">
                          No more notification
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )
                }
              >
                {notificationsDrawer.results?.map(item => (
                  <TimelineItem key={item.id} sx={{ marginRight: "16px" }}>
                    <TimelineSeparator>
                      <TimelineDot color={item.isRead ? "primary" : "grey"} />
                      <TimelineConnector
                        sx={theme => ({
                          bgcolor: item.isRead
                            ? theme.palette.primary.main
                            : theme.palette.grey
                        })}
                      />
                    </TimelineSeparator>
                    <TimelineContent
                      style={{ paddingRight: "0px", paddingTop: "0px" }}
                    >
                      <Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          padding="0px"
                        >
                          <Typography variant="caption" color="primary.light">
                            {moment(item.created).format(
                              "ddd D MMM YYYY hh:mm A"
                            )}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="primary.light"
                            sx={{ fontStyle: "italic" }}
                          >
                            {moment(item.created).fromNow()}
                          </Typography>
                        </Stack>

                        <Typography
                          variant="caption"
                          color="text.primary"
                          marginTop="8px"
                          marginBottom="4px"
                          style={{ wordBreak: "break-word" }}
                        >
                          {item.brokerFullName}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.primary"
                          marginBottom="18px"
                          style={{ wordBreak: "break-word", height: "unset" }}
                          className="multiLineTruncate"
                          sx={theme => ({
                            "& a": {
                              color: theme.palette.primary.main
                            }
                          })}
                          dangerouslySetInnerHTML={{ __html: item.message }}
                        ></Typography>
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </InfiniteScroll>
            </Timeline>
          </Box>
        </Box>
      </DrawerContainer>
    </Badge>
  );
}

export default NotificationDrawer;
