import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Button } from "@mui/material";
import Loader from "components/Loader";
import ChangeMembershipRequestModal from "./ChangeMembershipRequestModal";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";

function MembershipTab() {
  const theme = useTheme();
  const { defaultCompanyData } = useSelector(state => state.company);
  const [isChangeMembershipModal, setIsChangeMembershipModal] = useState(false);

  return (
    <>
      {_.isEmpty(defaultCompanyData?.membershipDetails) ? (
        <Loader />
      ) : (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box
            sx={{
              flex: "0 0 370px",
              marginRight: "24px",
              marginBottom: "24px",
              [theme.breakpoints.down("sm")]: {
                flex: "unset",
                width: "100%",
                marginRight: 0
              }
            }}
          >
            <Box
              sx={theme => ({
                padding: "24px",
                borderRadius: "16px",
                backgroundColor: theme.palette.text.dark.primary,
                border: `1px solid ${theme.palette.text.dark.primary}`,
                boxShadow: `3px 5px 5px -1px ${theme.palette.boxShadowPalette.primary}`
              })}
            >
              <Typography variant="h6" color="text.primary" marginBottom="16px">
                Current Membership
              </Typography>
              <Typography
                variant="body1"
                color="text.primary"
                marginBottom="16px"
              >
                {defaultCompanyData?.membershipDetails?.type}
              </Typography>
              <Typography variant="h6" color="text.primary" marginBottom="16px">
                Started at
              </Typography>
              <Typography variant="body1" color="text.primary">
                {defaultCompanyData?.membershipDetails?.startDate}
              </Typography>
            </Box>
            {!defaultCompanyData?.membershipDetails?.membershipRequest && (
              <Button
                variant="contained"
                sx={{ marginTop: "36px" }}
                size="large"
                onClick={() => setIsChangeMembershipModal(true)}
              >
                Change Membership
              </Button>
            )}
          </Box>
          {defaultCompanyData?.membershipDetails?.membershipRequest && (
            <Box
              sx={{
                flex: "0 0 370px",
                [theme.breakpoints.down("sm")]: {
                  flex: "unset",
                  width: "100%"
                }
              }}
            >
              <Box
                sx={theme => ({
                  padding: "24px",
                  borderRadius: "16px",
                  backgroundColor: theme.palette.text.dark.primary,
                  border: `1px solid ${theme.palette.text.dark.primary}`,
                  boxShadow: `3px 5px 5px -1px ${theme.palette.boxShadowPalette.primary}`
                })}
              >
                <Typography
                  variant="h6"
                  color="text.primary"
                  marginBottom="16px"
                >
                  Change Membership Request
                </Typography>
                <Typography
                  variant="body1"
                  color="text.primary"
                  marginBottom="16px"
                >
                  {
                    defaultCompanyData?.membershipDetails?.membershipRequest
                      .type
                  }
                </Typography>
                <Typography
                  variant="h6"
                  color="text.primary"
                  marginBottom="16px"
                >
                  Requested at
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {
                    defaultCompanyData?.membershipDetails?.membershipRequest
                      .created
                  }
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {isChangeMembershipModal && (
        <Box>
          <ChangeMembershipRequestModal
            isOpen={isChangeMembershipModal}
            handleClose={() => setIsChangeMembershipModal(false)}
          />
        </Box>
      )}
    </>
  );
}

export default MembershipTab;
