import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getEmailContent, updateEmailContent } from "reducers/emails";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import Header from "components/Header";
import Loader from "components/Loader";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { LIGHT } from "utils/constants";
import _ from "lodash";
import {
  Button,
  Typography,
  Stack,
  TextField,
  FormControl,
  Grid,
  IconButton,
  Popover,
  Box
} from "@mui/material";
import { ROUTES } from "routes/constants";
import BackBtn from "pages/Cases/BackBtn";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);

const popoverStyle = {
  borderRadius: "8px",
  padding: "16px",
  maxWidth: 380
};

function EmailContent() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { theme } = useSelector(state => state.user);
  const { loading, emailContent } = useSelector(state => state.emailsContent);

  const [bodyText, setBodyText] = useState("");
  const [emailContentDetails, setEmailContentDetails] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = value => {
    const emailContentData = {
      id,
      data: {
        subject: value.subject,
        body: bodyText
      }
    };
    dispatch(updateEmailContent(emailContentData));
  };

  const handleRichTextChange = value => setBodyText(value);

  const handleChange = (key, value) => {
    setEmailContentDetails({ ...emailContentDetails, [key]: value });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const popUpId = open ? "simple-popover" : undefined;

  useEffect(() => {
    setEmailContentDetails({
      ...emailContentDetails,
      subject: emailContent?.subject
    });
    reset({
      subject: emailContent?.subject
    });
    setBodyText(emailContent?.body);
  }, [emailContent]);

  useEffect(() => {
    if (id) {
      dispatch(getEmailContent(id));
    }
  }, [dispatch, id]);

  return (
    <>
      <Header
        componentName="Email Content"
        backBtn={true}
        routePage={ROUTES.EMAILS}
      />
      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginTop: "48px" }}>
          <Stack display={{ xs: "block", lg: "none" }} marginBottom="24px">
            <Stack direction="row" alignItems="center">
              <BackBtn routePage={ROUTES.EMAILS} />
              <Typography variant="h3" color="text.primary" fontSize="38px">
                Email Content
              </Typography>
            </Stack>
          </Stack>
          {!_.isEmpty(emailContent) ? (
            <Grid container spacing={{ xs: 3, xl: 6 }}>
              <Grid item xs={12} xl={6}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={3}>
                    <FormControl>
                      <Typography
                        color="text.primary"
                        sx={{ fontWeight: "700" }}
                        gutterBottom
                      >
                        Subject
                      </Typography>
                      <TextField
                        fullWidth
                        type="text"
                        placeholder="Subject"
                        error={!!errors.subject}
                        className={classes.textField}
                        defaultValue={emailContent?.subject || ""}
                        {...register("subject", {
                          required: true,
                          onChange: e => handleChange("subject", e.target.value)
                        })}
                        InputLabelProps={{
                          shrink: emailContentDetails.subject ? true : false
                        }}
                      />
                    </FormControl>
                    <Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography
                          color="text.primary"
                          sx={{ fontWeight: "700" }}
                          gutterBottom
                        >
                          Body
                        </Typography>
                        <Stack direction="row" alignItems="center">
                          <IconButton
                            sx={{ marginBottom: "4px" }}
                            onClick={handleClick}
                          >
                            <InfoOutlinedIcon
                              sx={{ fontSize: 20 }}
                              color="primary"
                            />
                          </IconButton>
                          <Popover
                            id={popUpId}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left"
                            }}
                          >
                            <Box
                              sx={theme => ({
                                ...popoverStyle,
                                bgcolor: theme.palette.background.default
                              })}
                            >
                              <Typography
                                variant="body1"
                                color="text.primary"
                                sx={{
                                  margin: "0 !important",
                                  "& p": {
                                    margin: "0 !important"
                                  }
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: emailContent.helpText
                                }}
                              />
                            </Box>
                          </Popover>
                        </Stack>
                      </Stack>
                      <ReactQuill
                        value={bodyText}
                        onChange={handleRichTextChange}
                        placeholder="Body Text"
                        className={
                          theme === LIGHT
                            ? "light-theme-quill"
                            : "dark-theme-quill"
                        }
                      />
                    </Stack>
                  </Stack>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={theme => ({
                      margin: "24px 0",
                      [theme.breakpoints.down("sm")]: {
                        width: "100%"
                      }
                    })}
                  >
                    UPDATE
                  </Button>
                </form>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Typography
                  color="text.primary"
                  gutterBottom
                  sx={{ fontWeight: "700" }}
                >
                  Body
                </Typography>
                <Box
                  sx={theme => ({
                    border: `1px solid ${theme.palette.textFieldPalette.main}`,
                    padding: "24px",
                    borderRadius: "4px"
                  })}
                >
                  <Typography
                    variant="body1"
                    color="text.primary"
                    sx={{
                      margin: "0 !important",
                      "& p": {
                        margin: "0 !important"
                      },
                      "& a": {
                        lineBreak: "anywhere"
                      }
                    }}
                    dangerouslySetInnerHTML={{ __html: bodyText }}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="h5" color="text.primary" component="h5">
              There are no email content yet
            </Typography>
          )}
        </div>
      )}
    </>
  );
}

export default EmailContent;
