import { fork } from "redux-saga/effects";
import { usersSaga } from "./users";
import { leadsSaga } from "./leads";
import { tasksSaga } from "./tasks";
import { notificationsSaga } from "./notifications";
import { casesSaga } from "./cases";
import { creditsSaga } from "./credits";
import { dashboardSaga } from "./dashboard";
import { reportsSaga } from "./reports";
import { registrationSaga } from "./registration";
import { companySaga } from "./company";
import { clientsSaga } from "./clients";
import { emailsContentSaga } from "./emails";

export function* rootSaga() {
  yield fork(usersSaga);
  yield fork(leadsSaga);
  yield fork(tasksSaga);
  yield fork(notificationsSaga);
  yield fork(casesSaga);
  yield fork(creditsSaga);
  yield fork(dashboardSaga);
  yield fork(reportsSaga);
  yield fork(registrationSaga);
  yield fork(companySaga);
  yield fork(clientsSaga);
  yield fork(emailsContentSaga);
}
