import React from "react";

import { Button, Drawer } from "@mui/material";
function DrawerContainer(props) {
  const { anchor, state, toggleDrawer, Icon, children } = props;

  return (
    <React.Fragment key={anchor}>
      <Button>
        <Icon />
      </Button>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        sx={theme => ({
          [theme.breakpoints.down("sm")]: {
            "& .MuiDrawer-paper": {
              width: "100%",
              bgcolor: theme.palette.text.dark.primary
            }
          }
        })}
      >
        {children}
      </Drawer>
    </React.Fragment>
  );
}

export default DrawerContainer;
