import React from "react";
import { Typography, Box, Modal, CircularProgress } from "@mui/material";

const modalStyles = theme => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: 400,
    overflowY: "auto",
    bgcolor: "background.default",
    borderRadius: "16px",
    boxShadow: `0px 24px 38px 3px ${theme.palette.boxShadowPalette.main},0px 9px 46px 8px ${theme.palette.boxShadowPalette.dark},0px 11px 15px -7px ${theme.palette.boxShadowPalette.light}`,
    padding: "52px",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      bgcolor: "text.dark.primary",
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  };
};

function ProcessingModal(isOpen) {
  return (
    <Modal open={isOpen}>
      <Box sx={theme => modalStyles(theme)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <CircularProgress
            sx={{ height: "120px !important", width: "120px !important" }}
          />
          <Typography variant="h6" color="text.primary" marginTop="32px">
            Processing
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
}

export default ProcessingModal;
