import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Box, Button, Typography, Stack, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CaseDetailPagination from "./CaseDetailPagination";
import AddEditStatusModal from "./AddEditStatusModal";
import {
  getCaseStatus,
  updateFilters,
  changecaseStatusPage,
  removeCaseStatus,
  updateCaseStatus,
  addCaseStatus
} from "reducers/cases";
import { ADD_STATUS, FIRST_PAGE } from "utils/constants";
import DeletePopover from "../../components/DeletePopover";

const BOX_STYLE = theme => {
  return {
    backgroundColor: theme.palette.text.dark.primary,
    borderRadius: "16px",
    boxShadow: `0px 3px 5px -1px ${theme.palette.boxShadowPalette.primary}`,
    padding: "24px 32px",
    marginBottom: "24px",
    [theme.breakpoints.down("lg")]: {
      padding: "24px 18px"
    }
  };
};

function StatusTab() {
  const { id } = useParams();

  const [isStatusModal, setIsStatusModal] = useState(false);
  const [isEditStatusModal, setIsEditStatusModal] = useState(false);
  const [caseStatus, setCaseStatus] = useState({});
  const [itemId, setItemId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const { filters, caseStatuses, rowsPerPage, caseStatusPage } = useSelector(
    state => state.cases
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      let filters_ = { ...filters, case: id };

      dispatch(updateFilters(filters_));
      dispatch(getCaseStatus());
    }
  }, [dispatch, id, caseStatusPage]);

  useEffect(() => {
    return () => dispatch(changecaseStatusPage(FIRST_PAGE));
  }, [dispatch]);

  const handleOnPageChange = (e, newPage) => {
    dispatch(changecaseStatusPage(newPage));
  };

  const handleCreateStatus = data => {
    dispatch(addCaseStatus(data));
    setIsStatusModal(false);
  };

  const handleUpdateStatus = data => {
    dispatch(updateCaseStatus({ id: caseStatus.id, data }));
    setIsEditStatusModal(false);
  };

  const handleRemoveStatusCase = (e, id) => {
    setItemId(id);
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      {caseStatuses.count > 0 ? (
        <>
          {caseStatuses?.results?.map(status => (
            <Box sx={theme => BOX_STYLE(theme)} key={status.id}>
              <Stack
                direction={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <Stack
                  spacing={1}
                  sx={theme => ({
                    [theme.breakpoints.down("lg")]: {
                      order: 2
                    }
                  })}
                >
                  <Typography variant="caption" color="text.secondary">
                    {moment(status.created).format("ddd D MMM YYYY hh:mm A")}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {status.status.value}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={{ xs: 1, lg: 4 }}
                  sx={theme => ({
                    [theme.breakpoints.down("lg")]: {
                      margin: "0 0 18px auto"
                    },
                    [theme.breakpoints.down("sm")]: {
                      marginRight: "8px"
                    }
                  })}
                >
                  <Button
                    variant="text"
                    size="large"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={e => handleRemoveStatusCase(e, status.id)}
                    sx={theme => ({
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "13px"
                      }
                    })}
                  >
                    DELETE
                  </Button>
                  <Button
                    variant="text"
                    size="large"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setIsEditStatusModal(true);
                      setCaseStatus(status);
                    }}
                    sx={theme => ({
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "13px"
                      }
                    })}
                  >
                    {status.note ? "EDIT NOTE" : "ADD NOTE"}
                  </Button>
                </Stack>
              </Stack>
              {status.note && (
                <>
                  <Divider sx={{ margin: "20px 0" }} />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ wordBreak: "break-word" }}
                  >
                    {status.note}
                  </Typography>
                </>
              )}
            </Box>
          ))}
        </>
      ) : (
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          color="text.secondary"
          textAlign="center"
        >
          There is no status yet
        </Typography>
      )}
      <CaseDetailPagination
        addNewBtn={ADD_STATUS}
        data={caseStatuses}
        rowsPerPage={rowsPerPage}
        page={caseStatusPage}
        handleOnPageChange={handleOnPageChange}
        handleCaseStatus={handleCreateStatus}
        status={caseStatus}
      />
      {isEditStatusModal && (
        <Box>
          <AddEditStatusModal
            edit
            title="Edit status"
            isOpen={isEditStatusModal}
            handleClose={() => setIsEditStatusModal(false)}
            handleCaseStatus={handleUpdateStatus}
            status={caseStatus}
            isDisabled={true}
          />
        </Box>
      )}
      {isStatusModal && (
        <Box>
          <AddEditStatusModal
            add
            title="Add status"
            isOpen={isStatusModal}
            handleClose={() => setIsStatusModal(false)}
            handleCaseStatus={handleCreateStatus}
            status={caseStatus}
            isDisabled={true}
          />
        </Box>
      )}
      <DeletePopover
        text="status"
        anchorEl={anchorEl}
        itemId={itemId}
        handleClose={() => setAnchorEl(null)}
        removeItem={removeCaseStatus}
      />
    </>
  );
}

export default StatusTab;
