import { ResponsiveLine } from "@nivo/line";
import { darkTheme, lightTheme } from "styles/theme";
import { DARK } from "utils/constants";

export default function LineGraph({ data, theme }) {
  return (
    <ResponsiveLine
      theme={theme === DARK ? darkTheme : lightTheme}
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      enableGridX={false}
      enableGridY={false}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        reverse: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 36,
        legendPosition: "middle"
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: -40,
        legendPosition: "middle",
        format: e => Math.floor(e) === e && e
      }}
      enablePoints={false}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)"
        }
      ]}
    />
  );
}
