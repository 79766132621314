import { apiCaller } from "..";
import { ENDPOINTS, REQUEST_TYPES } from "../constants";

export const getCreditsBundlesAPI = id =>
  apiCaller({
    method: REQUEST_TYPES.GET,
    url: `${ENDPOINTS.CREDITS_BUNDLES}`
  });

export const creditsUpdateAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.CREDITS_UPDATE}`,
    data
  });

export const creditBuyBundleAPI = data =>
  apiCaller({
    method: REQUEST_TYPES.POST,
    url: `${ENDPOINTS.CREDITS_BUY_BUNDLE}`,
    data
  });
