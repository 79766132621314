import React from "react";
import { useDispatch } from "react-redux";
import { handleSearch } from "reducers/emails";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import {
  Drawer,
  FormControl,
  Typography,
  Button,
  Box,
  Stack,
  TextField
} from "@mui/material";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);

function FIlterDrawer({
  toggleDrawer,
  position,
  emailsContentSearch,
  setEmailsContentSearch
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFilter = () => {
    dispatch(handleSearch(emailsContentSearch));
  };

  return (
    <Drawer
      anchor={"right"}
      open={position["right"]}
      onClose={toggleDrawer(false)}
      sx={theme => ({
        [theme.breakpoints.down("sm")]: {
          "& .MuiDrawer-paper": {
            width: "100%",
            bgcolor: theme.palette.text.dark.primary
          }
        }
      })}
    >
      <Box
        sx={theme => ({
          width: 400,
          padding: "56px 32px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0
          }
        })}
        role="presentation"
      >
        <Box
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              padding: "56px 32px"
            }
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
              color="text.primary"
              component="div"
              sx={{ marginBottom: "24px" }}
            >
              FILTER
            </Typography>
            <CloseIcon
              onClick={toggleDrawer(false)}
              sx={{ color: "text.primary", cursor: "pointer" }}
            />
          </Stack>
          <Stack spacing={3}>
            <Stack display={{ xs: "block", lg: "none" }}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Search Email"
                  type="text"
                  value={emailsContentSearch}
                  placeholder="Search Email"
                  onChange={e => setEmailsContentSearch(e.target.value)}
                />
              </FormControl>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            sx={{
              marginTop: "12px",
              width: "100%"
            }}
            onClick={() => {
              handleFilter();
              toggleDrawer(false)();
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default FIlterDrawer;
