import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Stack,
  Tabs,
  Tab,
  Typography,
  Checkbox,
  Badge
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  getDrawerTasks,
  updateTask,
  updateDrawerActiveTab,
  changeTaskDrawerPage,
  getDrawerTaskOnChangeTab
} from "reducers/tasks";
import DrawerContainer from "components/DrawerContainer";
import { dueDays, showNote, dueDaysDiff, getAccessToken } from "utils";
import { FIRST_PAGE, TASK_TABS, WORDS_100, WORDS_30 } from "utils/constants";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";

function TaskDrawer() {
  const anchor = "right";
  const [state, setState] = useState({
    anchor: false
  });
  const { drawerTask, drawerActiveTab, taskDrawerPage } = useSelector(
    state => state.tasks
  );
  const dispatch = useDispatch();
  const token = getAccessToken();

  useEffect(() => {
    if (token) {
      if (drawerActiveTab || taskDrawerPage) {
        dispatch(getDrawerTasks());
      }
    }
  }, [dispatch, drawerActiveTab, taskDrawerPage]);

  const fetchData = () => {
    dispatch(changeTaskDrawerPage(taskDrawerPage + 1));
  };

  const handleOnActiveTabChange = (e, value) => {
    dispatch(updateDrawerActiveTab(value));
    dispatch(getDrawerTaskOnChangeTab());
    let taskDrawer = document.querySelector(".infiniteScrollStyle");
    taskDrawer?.scrollTo(0, 0);
  };

  const handleOnCheckChange = (e, id) => {
    dispatch(updateTask({ id, data: { isCompleted: e.target.checked } }));
  };

  const toggleDrawer = (anchor, open) => event => {
    event.stopPropagation();
    setState({ ...state, [anchor]: open });

    if (open) {
      dispatch(getDrawerTaskOnChangeTab());
    } else {
      dispatch(changeTaskDrawerPage(FIRST_PAGE));
    }
  };

  return (
    <Badge
      color="primary"
      badgeContent={drawerTask.todoCount}
      onClick={toggleDrawer(anchor, true)}
      sx={theme => ({
        cursor: "pointer",
        "& button": {
          padding: 0,
          minWidth: "auto",
          marginLeft: "32px",
          color: theme.palette.text.primary
        }
      })}
    >
      <DrawerContainer
        anchor={anchor}
        state={state}
        toggleDrawer={toggleDrawer}
        Icon={MenuIcon}
      >
        <Box
          sx={theme => ({
            width: 410,
            padding: "56px 32px",
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              padding: "0"
            }
          })}
          role="presentation"
        >
          <Box
            sx={theme => ({
              "& .infiniteScrollStyle": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "5px"
                },
                "::-webkit-scrollbar-thumb": {
                  background: theme.palette.primary.main
                },
                "::-webkit-scrollbar-thumb:hover": {
                  background: theme.palette.primary.dark
                }
              },
              [theme.breakpoints.down("sm")]: {
                padding: "32px"
              }
            })}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="text.primary">
                Tasks
              </Typography>
              <CloseIcon
                onClick={toggleDrawer(anchor, false)}
                color="text.primary"
                cursor="pointer"
              />
            </Stack>
            <Tabs
              value={drawerActiveTab}
              onChange={handleOnActiveTabChange}
              textColor="primary"
              indicatorColor="primary"
              sx={theme => ({
                marginTop: "20px",
                marginBottom: "24px",
                "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  color: `${theme.palette.primary.main} !important`
                }
              })}
            >
              <Tab
                value={TASK_TABS.TODO}
                label="TO DO"
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  marginRight: "42px",
                  fontWeight: "700",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: "16px",
                    fontSize: "14px",
                    fontWeight: "600"
                  }
                })}
              />
              <Tab
                value={TASK_TABS.COMPLETED}
                label="DONE"
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  marginRight: "42px",
                  fontWeight: "700",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: "16px",
                    fontSize: "14px",
                    fontWeight: "600"
                  }
                })}
              />
              <Tab
                value={TASK_TABS.ALL}
                label="VIEW ALL"
                sx={theme => ({
                  color: theme.palette.text.secondary,
                  marginRight: "42px",
                  fontWeight: "700",
                  [theme.breakpoints.down("sm")]: {
                    marginRight: "16px",
                    fontSize: "14px",
                    fontWeight: "600"
                  }
                })}
              />
            </Tabs>
            {!_.isEmpty(drawerTask?.results) ? (
              <InfiniteScroll
                dataLength={drawerTask?.results?.length}
                next={fetchData}
                hasMore={drawerTask?.results?.length < drawerTask?.count}
                height={"calc(100vh - 250px)"}
                className="infiniteScrollStyle"
                loader={
                  <Stack sx={{ alignItems: "center" }}>
                    <CircularProgress />
                  </Stack>
                }
                endMessage={
                  <Typography color="text.primary">No more tasks.</Typography>
                }
              >
                {drawerTask?.results?.map(task => (
                  <Box key={task.id}>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ marginRight: "24px" }}
                    >
                      <Typography variant="caption" color="text.secondary">
                        {moment(task.dueDate).format("ddd D MMM YYYY hh:mm A")}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ fontStyle: "italic" }}
                        sx={theme =>
                          dueDaysDiff(task.dueDate) >= 0
                            ? { color: "text.secondary" }
                            : { color: "error.main" }
                        }
                      >
                        {dueDays(task.dueDate)}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        sx={{ color: "text.secondary", paddingLeft: 0 }}
                        defaultChecked={task.isCompleted || false}
                        onChange={e => handleOnCheckChange(e, task.id)}
                      />
                      <Typography className="singleLineTruncate">
                        {showNote(task.name, WORDS_30)}
                      </Typography>
                    </Stack>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="p"
                      sx={{ wordBreak: "break-all" }}
                      className="multiLineTruncate"
                    >
                      {showNote(task.note, WORDS_100)}
                    </Typography>
                    <Link
                      to={`/cases/${task.case}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        variant="link1"
                        component="p"
                        color="primary"
                        marginBottom="44px"
                        marginTop="12px"
                      >
                        {`CASE #${task.case}`}
                      </Typography>
                    </Link>
                  </Box>
                ))}
              </InfiniteScroll>
            ) : (
              <Typography color="text.primary">No more tasks.</Typography>
            )}
          </Box>
        </Box>
      </DrawerContainer>
    </Badge>
  );
}

export default TaskDrawer;
