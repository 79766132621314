import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@mui/icons-material/Create";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  getEmailsContentTypes,
  handleSearch,
  resetSearch
} from "reducers/emails";
import Header from "components/Header";
import { makeStyles } from "@mui/styles";
import Loader from "components/Loader";
import FilterDrawer from "./FIlterDrawer";
import _ from "lodash";
import {
  Box,
  Button,
  Typography,
  Stack,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead
} from "@mui/material";
import { ENTER_KEY } from "utils/constants";
import ResetFilterAlert from "components/ResetFilterAlert";
import { Link } from "react-router-dom";
import EmailContentModal from "./EmailContentModal";
import { horizontalScrollBarStyle } from "utils";

const useStyles = makeStyles(theme => ({
  borderRight: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "2px",
      height: "14px",
      background: theme.palette.grey[300],
      top: "50%",
      transform: "translateY(-50%)",
      right: "0"
    },
    "& button": {
      fontSize: "14px",
      fontWeight: "500",
      fontFamily: "'Montserrat', sans-serif"
    }
  },
  searchBtn: {
    marginLeft: "18px !important",
    padding: "8px 22px !important"
  },
  textField: {
    color: theme.palette.text.primary,
    "& textarea": {
      color: theme.palette.text.primary
    },
    "& label": {
      color: theme.palette.text.primary
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.textFieldPalette.main
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main
      }
    },
    "& input": {
      color: theme.palette.text.primary,
      "&:-webkit-autofill": {
        "-webkit-box-shadow": `0 0 0 100px ${theme.palette.background.default} inset`,
        "-webkit-text-fill-color": theme.palette.text.primary
      }
    },
    "& .MuiOutlinedInput-root .MuiInputBase-root": {
      color: theme.palette.text.primary
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.secondary
    },
    "& .MuiInput-root.MuiInput-underline": {
      "&::before": {
        borderBottom: `1px solid ${theme.palette.text.primary}`
      }
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      color: theme.palette.text.primary
    }
  }
}));

function Emails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, data, search } = useSelector(state => state.emailsContent);

  const [emailsContentSearch, setEmailsContentSearch] = useState(search);
  const [isEmailContentModal, setIsEmailContentModal] = useState(false);
  const [filterPosition, setFilterPosition] = useState({ right: false });

  const toggleFilterDrawer = open => () => {
    setFilterPosition({ ...filterPosition, right: open });
  };

  const handleOnSearchChange = emailsContentSearch => {
    if (emailsContentSearch) {
      dispatch(handleSearch(emailsContentSearch));
    }
  };
  const handleSearchBtn = () => {
    handleOnSearchChange(emailsContentSearch);
  };
  const handleKeyChange = e => {
    if (e.keyCode === ENTER_KEY) {
      handleOnSearchChange(emailsContentSearch);
    }
  };

  const handleOnResetFilter = () => {
    dispatch(resetSearch());
    setEmailsContentSearch("");
  };

  useEffect(() => {
    dispatch(getEmailsContentTypes());
  }, [dispatch, search]);

  return (
    <>
      <Header componentName="Emails" />
      {loading ? (
        <Loader />
      ) : (
        <div style={{ marginTop: "24px" }}>
          <>
            {!_.isEmpty(data) && (
              <Stack direction="row">
                <Stack display={{ xs: "none", lg: "block" }}>
                  <Stack direction="row" sx={{ marginBottom: "24px" }}>
                    <FormControl sx={{ width: "260px" }}>
                      <TextField
                        className={classes.textField}
                        label="Search Email"
                        type="text"
                        value={emailsContentSearch}
                        placeholder="Search Email"
                        onChange={e => setEmailsContentSearch(e.target.value)}
                        onKeyDown={handleKeyChange}
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      className={classes.searchBtn}
                      onClick={handleSearchBtn}
                    >
                      SEARCH
                    </Button>
                  </Stack>
                </Stack>
                <Typography
                  variant="h4"
                  color="text.primary"
                  component="h2"
                  marginRight="16px"
                  display={{ xs: "block", lg: "none" }}
                >
                  Emails
                </Typography>
                <Box
                  sx={theme => ({
                    display: "none",
                    [theme.breakpoints.down("lg")]: {
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      marginBottom: "24px"
                    }
                  })}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      color: "text.primary",
                      borderColor: "text.primary",
                      opacity: 0.5,
                      padding: "5px 24px"
                    }}
                    onClick={toggleFilterDrawer(true)}
                  >
                    <FilterListIcon />
                    FILTER
                  </Button>
                </Box>
              </Stack>
            )}
            {search && (
              <ResetFilterAlert
                data={data}
                text="emails content"
                handleOnResetFilter={handleOnResetFilter}
              />
            )}
          </>
          {!_.isEmpty(data) ? (
            <TableContainer
              component={Paper}
              sx={theme => (horizontalScrollBarStyle(theme))}
            >
              <Table sx={{ minWidth: 550 }} stickyHeader>
                <TableHead>
                  <TableRow
                    sx={theme => ({
                      bgcolor: theme.palette.background.default,
                      "& td,th": {
                        borderBottom: `1px solid ${theme.palette.text.disabled}`
                      }
                    })}
                  >
                    <TableCell className={classes.borderRight}>
                      <Typography
                        variant="body1"
                        sx={{
                          textTransform: "uppercase",
                          fontWeight: "600",
                          width: "25%"
                        }}
                      >
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.borderRight}>
                      <Typography
                        variant="body1"
                        sx={{ textTransform: "uppercase", fontWeight: "600" }}
                      >
                        Subject
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "10%" }} align="right">
                      <Typography
                        variant="body1"
                        sx={{ textTransform: "uppercase", fontWeight: "600" }}
                      >
                        Action
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map(row => (
                    <TableRow
                      key={row.id}
                      sx={theme => ({
                        bgcolor: theme.palette.background.default,
                        "& td,th": {
                          borderBottom: `1px solid ${theme.palette.text.disabled}`
                        }
                      })}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontSize: "16px",
                          color: "text.primary",
                          width: "25%"
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontSize: "16px",
                          color: "text.primary",
                          width: "25%"
                        }}
                      >
                        {row.subject}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          fontSize: "16px",
                          color: "text.primary",
                          width: "10%"
                        }}
                        align="right"
                      >
                        <Link
                          to={`/emails-content/${row.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            variant="contained"
                            startIcon={<CreateIcon />}
                          >
                            edit
                          </Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="h5" color="text.primary" component="h5">
              There are no emails yet
            </Typography>
          )}
        </div>
      )}
      {isEmailContentModal && (
        <Box>
          <EmailContentModal
            isOpen={isEmailContentModal}
            handleClose={() => setIsEmailContentModal(false)}
          />
        </Box>
      )}
      <FilterDrawer
        position={filterPosition}
        toggleDrawer={toggleFilterDrawer}
        emailsContentSearch={emailsContentSearch}
        setEmailsContentSearch={setEmailsContentSearch}
      />
    </>
  );
}

export default Emails;
