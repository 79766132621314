import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Drawer } from "@mui/material";
import { ROUTES } from "routes/constants";
import { withAuth } from "utils/withAuth";
import { getDrawerNotifications } from "reducers/notifications";
import { getAccessToken, useInterval } from "utils";
import Footer from "./Footer";
import SideMenuItems from "./SideMenuItems";
import { getUnreadLeadCount } from "reducers/users";
import { getDrawerTasks } from "reducers/tasks";
const DRAWER_WIDTH = 400;

const AuthenticatedWrapper = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { me: user } = useSelector(state => state.user.data);

  const token = getAccessToken();
  useInterval(() => {
    if (history?.location?.pathname === ROUTES.LOGOUT) return;
    dispatch(getDrawerNotifications());
    dispatch(getDrawerTasks());
  }, 60000);

  useInterval(() => {
    if (history?.location?.pathname === ROUTES.LOGOUT) return;
    dispatch(getUnreadLeadCount());
  }, 10000);

  useEffect(() => {
    if (!token || history?.location?.pathname === ROUTES.LOGOUT) return;
    dispatch(getDrawerNotifications());
  }, [dispatch]);

  const drawer = (
    <>
      <Box sx={{ maxWidth: 400 }} style={{ margin: "18px auto" }}>
        <Box
          sx={{
            bgcolor: "text.dark.primary",
            borderRadius: "32px",
            boxShadow: 3,
            padding: "56px 16px 58px",
            minWidth: "330px"
          }}
        >
          <Link to={ROUTES.LEADS}>
            <Box component="div" sx={{ paddingLeft: "16px" }}>
              <Box
                component="img"
                sx={{
                  cursor: "pointer",
                  height: "auto",
                  width: "auto",
                  maxHeight: { xs: 25, lg: 25 },
                  maxWidth: { xs: 120, lg: 120 }
                }}
                alt="Blabble-logo"
                src="/app/assets/logo.svg"
              />
            </Box>
          </Link>
          <SideMenuItems userType={user?.type} />
        </Box>
      </Box>
    </>
  );

  const mainStyles = theme => {
    return {
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        textAlign: "left"
      },
      [theme.breakpoints.up("xxl")]: {
        width: "100%",
        maxWidth: "210ch",
        margin: "auto",
        boxSizing: "border-box"
      }
    };
  };

  return (
    <Box sx={theme => mainStyles(theme)} style={{ minHeight: "100vh" }}>
      <Box
        component="nav"
        sx={{ width: { lg: DRAWER_WIDTH }, flexShrink: { lg: 0 } }}
      >
        <Drawer
          variant="permanent"
          sx={theme => ({
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              bgcolor: "background.default",
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
              borderRight: 0,
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px"
              },
              "::-webkit-scrollbar-thumb": {
                background: theme.palette.primary.main
              },
              "::-webkit-scrollbar-thumb:hover": {
                background: theme.palette.primary.dark
              },
              [theme.breakpoints.up("xxl")]: {
                top: "auto",
                left: "auto"
              }
            }
          })}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        sx={theme => ({
          flexGrow: 1,
          p: 3,
          paddingRight: "32px",
          paddingTop: "46px",
          paddingLeft: "24px",
          "& >.MuiGrid-root.MuiGrid-container": {
            position: "relative",
            display: "block",
            minHeight: "78vh",
            paddingBottom: "62px"
          },
          "& >div:nth-of-type(2)": {
            position: "relative",
            minHeight: "72vh",
            paddingBottom: "72px"
          },
          [theme.breakpoints.down("lg")]: {
            padding: "24px",
            paddingBottom: "120px",
            paddingTop: "70px"
          }
        })}
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          overflow: "hidden"
        }}
      >
        {children}
        <Footer />
      </Box>
    </Box>
  );
};

export default withAuth(AuthenticatedWrapper);
