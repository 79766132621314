import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import DeletePopover from "components/DeletePopover";
import ResetFilterAlert from "components/ResetFilterAlert";
import Header from "components/Header";
import { dateStyle } from "utils/DatePickerStyle";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { horizontalScrollBarStyle, paginationStyle } from "utils";
import FilterDrawer from "./FilterDrawer";
import _ from "lodash";
import {
  getNotifications,
  updateActiveTab,
  resetFilters,
  changePage,
  updateFilters,
  removeNotifications,
  updateNotifications
} from "reducers/notifications";
import { getBrokers } from "reducers/users";
import { getStatuses } from "reducers/cases";
import moment from "moment";
import {
  NOTIFICATION_TABS,
  DESELECT_ALL,
  USER_TYPE,
  DARK
} from "utils/constants";
import AutoCompleteField from "components/AutoCompleteField";
import { toggleSelectDeselectAll } from "utils";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const Notifications = () => {
  const classes = useStyles();
  const { me: user, data: userData, theme } = useSelector(state => state.user);
  const { data, activeTab, filters, rowsPerPage, page } = useSelector(
    state => state.notifications
  );
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const [broker, setBroker] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [brokerValue, setBrokerValue] = useState({});
  const [filterPosition, setFilterPosition] = useState({ right: false });
  const [filters_, setFilters_] = useState(filters);

  useEffect(() => {
    dispatch(getNotifications());
    dispatch(getStatuses());
  }, [dispatch, user, activeTab, filters, page]);

  useEffect(() => {
    return () => {
      dispatch(updateActiveTab(NOTIFICATION_TABS.UNREAD));
    };
  }, [dispatch]);
  useEffect(() => {
    if (
      userData?.me?.type === USER_TYPE.ADMIN ||
      userData?.me?.type === USER_TYPE.COMPANY_OWNER
    ) {
      dispatch(getBrokers());
    }
  }, [dispatch, userData?.me]);

  useEffect(() => {
    setBrokerValue(userData.brokers?.find(item => item.id === broker));
  }, [broker]);

  const markHandler = isRead => {
    dispatch(
      updateNotifications({
        ids: selectedItems,
        isRead
      })
    );
    setSelectedItems([]);
  };

  const onRemoveNotifications = e => {
    setAnchorEl(e.currentTarget);
  };

  const selectAllNotification = () => {
    if (toggleSelectDeselectAll(selectedItems, data) === DESELECT_ALL) {
      return setSelectedItems([]);
    }
    setSelectedItems(data.results.map(item => item.id));
  };

  const selectNotification = (e, id) => {
    setSelectedItems(state => {
      const idx = state.indexOf(id);
      if (idx === -1) return [...state, id];
      else return [...state.slice(0, idx), ...state.slice(idx + 1)];
    });
  };

  const onActiveTab = (e, value) => {
    dispatch(updateActiveTab(value));
    setSelectedItems([]);
    dispatch(resetFilters());
    setFilters_({
      broker: null,
      date: null
    });
    setBroker("");
    setDate(null);
  };

  const handleOnResetFilter = () => {
    dispatch(resetFilters());
    setFilters_({
      broker: null,
      date: null
    });
    setBroker("");
    setDate(null);
  };

  const handleOnPageChange = (e, newPage) => {
    dispatch(changePage(newPage));
  };

  const handleOnFilterChange = (name, value) => {
    setFilters_({ ...filters_, [name]: value });
  };

  const handleOnDateFilterChange = value => {
    setDate(value);
    handleOnFilterChange("date", value);
  };

  const handleRemoveNotification = (e, id) => {
    setSelectedItems([id]);
    setAnchorEl(e.currentTarget);
  };

  const toggleFilterDrawer = open => () => {
    setFilterPosition({ ...filterPosition, right: open });
  };

  const applyFilters = () => {
    dispatch(changePage(1));
    dispatch(updateFilters(filters_));
    toggleFilterDrawer(false)();
  };

  const actionButton = !!selectedItems.length && !!data.results.length && (
    <Grid
      container
      columns={{ xs: 4, sm: 8, llg: 12 }}
      sx={{ marginBottom: "24px" }}
    >
      {activeTab === NOTIFICATION_TABS.UNREAD && (
        <Grid item>
          <Button
            variant="contained"
            onClick={() => {
              markHandler(true);
            }}
          >
            Mark read
          </Button>
        </Grid>
      )}
      {activeTab === NOTIFICATION_TABS.READ && (
        <Grid item>
          <Button
            variant="contained"
            onClick={() => {
              markHandler(false);
            }}
          >
            Mark unread
          </Button>
        </Grid>
      )}
      <Grid item sx={{ marginLeft: "24px" }}>
        <Button
          variant="contained"
          color="error"
          onClick={e => onRemoveNotifications(e)}
        >
          Remove
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Header componentName="Notifications" />
      <Typography
        variant="h4"
        color="text.primary"
        component="h2"
        marginTop="24px"
        display={{ xs: "block", lg: "none" }}
      >
        Notifications
      </Typography>
      <Box>
        <Box
          sx={{
            marginTop: "16px",
            marginBottom: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Tabs
            value={activeTab}
            onChange={onActiveTab}
            textColor="primary"
            indicatorColor="primary"
            sx={theme => ({
              marginTop: "20px",
              marginBottom: "24px",
              "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                color: `${theme.palette.primary.main} !important`
              }
            })}
          >
            <Tab
              label="Unread"
              value="unread"
              sx={theme => ({
                color: theme.palette.text.secondary,
                marginRight: "16px"
              })}
            />
            <Tab
              label="Read"
              value="read"
              sx={theme => ({
                color: theme.palette.text.secondary
              })}
            />
          </Tabs>
          {data.results.length > 0 && (
            <Stack display={{ xs: "none", sm: "block", llg: "none" }}>
              <Stack direction="row">
                <Button
                  variant="contained"
                  sx={theme => ({
                    padding: "14px 26px",
                    [theme.breakpoints.down("llg")]: {
                      padding: "10px 12px",
                      fontSize: "13px",
                      marginRight: "24px"
                    }
                  })}
                  onClick={selectAllNotification}
                  startIcon={<AddIcon />}
                >
                  {toggleSelectDeselectAll(selectedItems, data)}
                </Button>
                <Stack>
                  <Button
                    variant="outlined"
                    sx={theme => ({
                      width: "100%",
                      color: "text.primary",
                      borderColor: "text.primary",
                      padding: "10px 16px",
                      fontSize: "13px",
                      opacity: 0.5
                    })}
                    onClick={toggleFilterDrawer(true)}
                  >
                    <FilterListIcon />
                    FILTER
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
        {!_.isEmpty(data.results) && (
          <Box
            sx={{
              display: "flex",
              marginBottom: "24px",
              alignItems: "center",
              flexWrap: "wrap"
            }}
          >
            {data.results.length > 0 && (
              <Stack display={{ xs: "block", sm: "none", llg: "block" }}>
                <Stack
                  direction="row"
                  sx={theme => ({
                    marginRight: "16px",
                    marginBottom: "16px",
                    [theme.breakpoints.down("llg")]: {
                      width: "100%",
                      marginRight: 0
                    }
                  })}
                >
                  <Button
                    variant="contained"
                    sx={theme => ({
                      padding: "14px 26px",
                      [theme.breakpoints.down("llg")]: {
                        padding: "10px 12px",
                        fontSize: "13px",
                        marginRight: "16px"
                      }
                    })}
                    onClick={selectAllNotification}
                    startIcon={<AddIcon />}
                  >
                    {toggleSelectDeselectAll(selectedItems, data)}
                  </Button>
                  <Stack display={{ xs: "block", sm: "none", llg: "none" }}>
                    <Button
                      variant="outlined"
                      sx={theme => ({
                        width: "100%",
                        color: "text.primary",
                        borderColor: "text.primary",
                        padding: "10px 16px",
                        fontSize: "13px",
                        opacity: 0.5
                      })}
                      onClick={toggleFilterDrawer(true)}
                    >
                      <FilterListIcon />
                      FILTER
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )}
            <Stack
              display={{ xs: "none", llg: "block" }}
              sx={theme => ({
                [theme.breakpoints.down("llg")]: {
                  width: "100%"
                }
              })}
            >
              <Stack direction="row" alignItems="center" flexWrap="wrap">
                {(userData?.me?.type === USER_TYPE.ADMIN ||
                  userData?.me?.type === USER_TYPE.COMPANY_OWNER) && (
                  <AutoCompleteField
                    sx={theme => ({
                      width: 240,
                      marginRight: "16px",
                      marginBottom: "16px",
                      [theme.breakpoints.down("llg")]: {
                        width: "100%",
                        marginTop: "16px",
                        marginRight: 0
                      }
                    })}
                    label={"Select Broker"}
                    value={brokerValue?.email || ""}
                    options={userData.brokers?.map(({ id, email }) => ({
                      value: id,
                      label: email
                    }))}
                    onChange={(e, value) => {
                      setBroker(value?.value || "");
                      handleOnFilterChange("broker", value?.value);
                    }}
                  />
                )}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Select Date"
                    value={date}
                    onChange={handleOnDateFilterChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className={classes.textField}
                        sx={theme => ({
                          width: 240,
                          marginRight: "16px",
                          marginBottom: "16px",
                          [theme.breakpoints.down("llg")]: {
                            width: "100%",
                            marginTop: "16px",
                            marginRight: 0
                          }
                        })}
                      />
                    )}
                    PopperProps={{
                      sx: dateStyle(theme)
                    }}
                  />
                </LocalizationProvider>
                <Button
                  variant="contained"
                  sx={theme => ({
                    padding: "14px 26px",
                    marginBottom: "16px",
                    [theme.breakpoints.down("llg")]: {
                      padding: "10px 12px",
                      fontSize: "13px"
                    }
                  })}
                  onClick={applyFilters}
                >
                  Apply
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
        {Object.values(filters).some(Boolean) && (
          <ResetFilterAlert
            data={data?.results}
            text="notifications"
            handleOnResetFilter={handleOnResetFilter}
          />
        )}
        {!_.isEmpty(data.results) && actionButton}
        <Box>
          {data.results.length > 0 ? (
            <>
              <TableContainer
                component={Paper}
                sx={theme => (horizontalScrollBarStyle(theme))}
              >
                <Table sx={{ minWidth: 550 }}>
                  <TableBody
                    sx={theme => ({
                      bgcolor: theme.palette.background.default,
                      "& td,th": {
                        borderBottom: `1px solid ${theme.palette.text.disabled}`
                      }
                    })}
                  >
                    {data.results?.map(row => (
                      <TableRow key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: "16px",
                            color: "text.primary",
                            width: "5%"
                          }}
                        >
                          <Checkbox
                            checked={selectedItems.includes(row.id)}
                            sx={theme => ({
                              color: "text.secondary",
                              marginRight: "12px"
                            })}
                            onChange={e => {
                              selectNotification(e, row.id);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: "16px",
                            color: "text.primary",
                            fontFamily: "'Nunito Sans', sans-serif",
                            wordBreak: "break-word"
                          }}
                        >
                          <Typography
                            dangerouslySetInnerHTML={{ __html: row.message }}
                          ></Typography>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: "14px",
                            color: "text.primary",
                            fontFamily: "'Nunito Sans', sans-serif",
                            width: "20%"
                          }}
                        >
                          {moment(row.created).format("D/MM/YYYY hh:mm")}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: "14px",
                            color: "text.disabled",
                            fontFamily: "'Nunito Sans', sans-serif",
                            width: "15%"
                          }}
                        >
                          {moment(row.created).fromNow()}
                        </TableCell>
                        <TableCell
                          sx={{ width: "5%", paddingRight: "0" }}
                          align="right"
                        >
                          <IconButton
                            color="error"
                            onClick={e => handleRemoveNotification(e, row.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={Math.ceil(data.count / rowsPerPage)}
                page={page}
                onChange={handleOnPageChange}
                sx={theme => paginationStyle(theme)}
              />
            </>
          ) : (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                color="text.primary"
              >
                There are no notifications yet
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <DeletePopover
        text="notification"
        anchorEl={anchorEl}
        itemId={selectedItems}
        handleClose={() => setAnchorEl(null)}
        removeItem={removeNotifications}
        bgColor={theme === DARK ? "#010810" : "#FFFFFF"}
        setSelectedItems={setSelectedItems}
      />
      <FilterDrawer
        date={date}
        setDate={setDate}
        setBroker={setBroker}
        changePage={changePage}
        brokerValue={brokerValue}
        position={filterPosition}
        applyFilters={applyFilters}
        updateFilters={updateFilters}
        toggleDrawer={toggleFilterDrawer}
        handleOnFilterChange={handleOnFilterChange}
        handleOnDateFilterChange={handleOnDateFilterChange}
      />
    </>
  );
};

export default Notifications;
