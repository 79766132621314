import React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(theme => textFieldStyle(theme));

const SelectField = ({ label, options, sx, fullWidth, id, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        overflowX: "auto",
        backgroundColor: theme.palette.text.dark.primary
      }
    }
  };
  return (
    <FormControl
      fullWidth={fullWidth || null}
      sx={sx || null}
      className={classes.textField}
    >
      <InputLabel id={id || null}>{label}</InputLabel>
      <Select label={label} displayEmpty {...props} MenuProps={MenuProps}>
        <MenuItem
          disabled
          style={{ opacity: 0.5 }}
          value=""
          sx={theme => ({ color: theme.palette.text.primary })}
        >
          {label}
        </MenuItem>
        {options?.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
            sx={theme => ({ color: theme.palette.text.primary })}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;
