import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getAccessToken } from "utils";
import { ROUTES } from "../routes/constants";
import { getUser } from "../reducers/users";

export const withAuth = WrappedComponent => props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = getAccessToken();

  const clearStorageAndRedirect = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    history.push(ROUTES.LOGIN);
  }, [history]);

  const checkAutorization = useCallback(async () => {
    if (!token) return clearStorageAndRedirect();
    if (history?.location?.pathname !== ROUTES.LOGOUT) dispatch(getUser());
  }, [clearStorageAndRedirect, token, dispatch]);

  useEffect(() => checkAutorization(), [checkAutorization]);

  return <WrappedComponent {...props} />;
};
