import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import LoginImage from "components/NonAuthenticateLoginImage";

const SignUpRequestSuccess = () => {
  return (
    <Box sx={{ marginBottom: "48px" }}>
      <Grid container spacing={6} sx={{ alignItems: "center" }}>
        <Grid item xs={12} lg={6}>
          <Box
            m={"auto"}
            sx={theme => ({
              maxWidth: 500,
              padding: "56px",
              borderRadius: 4,
              boxShadow: 3,
              bgcolor: "text.dark.primary",
              [theme.breakpoints.down("xl")]: {
                padding: "28px"
              },
              [theme.breakpoints.down("lg")]: {
                padding: 0,
                boxShadow: 0,
                maxWidth: "100%",
                height: "100%",
                bgcolor: "background.default"
              }
            })}
          >
            <Typography
              variant="h4"
              gutterBottom
              component="h4"
              color="text.primary"
              mb={4}
            >
              Verifying Your Application
            </Typography>
            <Typography variant="body1" color="text.primary">
              Your application has been submitted. We will review it, and get
              back to you soon!
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={0} lg={6} display={{ xs: "none", lg: "block" }}>
          <LoginImage />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUpRequestSuccess;
