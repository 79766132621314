import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Header from "components/Header";
import Loader from "components/Loader";
import { getBrokerDashboard } from "reducers/dashboard";
import BrokerDetails from "./BrokerDetails";
import { Stack, Typography, Chip } from "@mui/material";
import SelectField from "components/SelectField";
import { rankChipBgColor, rankChipColor } from "utils";
import { getLeads } from "reducers/leads";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { makeStyles } from "@mui/styles";
import { getBrokers } from "reducers/users";
import { ROUTES } from "routes/constants";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.default)
);

const boxStyle = theme => {
  return {
    [theme.breakpoints.down("xl")]: {
      padding: "20px"
    },
    padding: "32px",
    borderRadius: "16px",
    background: theme.palette.text.dark.primary
  };
};

const chipStyle = (theme, rankNumber) => {
  return {
    color: rankChipColor(rankNumber),
    bgcolor: rankChipBgColor(rankNumber),
    fontSize: "13px",
    fontWeight: "700",
    fontFamily: "'Nunito Sans', sans-serif",
    height: "24px",
    marginBottom: "16px"
  };
};

function BrokerDashboardDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { brokerId } = useParams();
  const { data: userData } = useSelector(state => state.user);
  const { brokerDetail } = useSelector(state => state.dashboard);
  const [lead, setLead] = useState({});
  const [open, setOpen] = useState(false);
  const [selectBrokerId, setSelectBrokerId] = useState(brokerId);

  const handleSelectedBroker = e => {
    setSelectBrokerId(e.target.value);
    history.push(`${ROUTES.DASHBOARD}/${e.target.value}`);
  };

  useEffect(() => {
    dispatch(getLeads());
  }, [dispatch]);

  useEffect(() => {
    if (brokerId) {
      dispatch(getBrokerDashboard({ broker: brokerId }));
    }
  }, [dispatch, brokerId]);

  useEffect(() => {
    if (userData?.me) {
      dispatch(getBrokers());
    }
  }, [dispatch, userData?.me]);

  return (
    <>
      <Header componentName="Dashboard" />
      {!!brokerDetail.personalLeadsEarnings ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          sx={{
            marginTop: "26px"
          }}
        >
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                color="text.primary"
                sx={{ margin: "0 14px 0 6px" }}
              >
                {brokerDetail.rank.firstName}
              </Typography>
              <Chip
                label={`Rank # ${brokerDetail.rank.rank}`}
                sx={theme => chipStyle(theme, brokerDetail.rank.rank)}
                style={{ marginBottom: 0 }}
              />
            </Stack>
            <SelectField
              sx={theme => ({
                width: "250px",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  marginTop: "24px"
                }
              })}
              label={"Select Broker"}
              defaultValue={""}
              value={selectBrokerId}
              className={classes.textField}
              onChange={handleSelectedBroker}
              options={userData.brokers?.map(({ id, email }) => ({
                value: id,
                label: email
              }))}
            />
          </Stack>
          <BrokerDetails
            data={brokerDetail}
            boxStyle={boxStyle}
            lead={lead}
            setLead={setLead}
            open={open}
            setOpen={setOpen}
          />
        </Stack>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default BrokerDashboardDetail;
