import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMortgageSold } from "reducers/reports";
import Loader from "components/Loader";
import BarGraph from "./BarGraph";
import Filters from "./Filters";
import { Paper, Box, Typography } from "@mui/material";
import { horizontalScrollBarStyle } from "utils";

function MortgageSoldReport({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedCompany,
  handleCampanyChange,
  selectedBrokers,
  handleBrokerChange,
  handleFilter,
  resetFilterBtn
}) {
  const { loading, data, filters } = useSelector(state => state.reports);
  const { theme } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMortgageSold());
  }, [dispatch, filters]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Filters
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedCompany={selectedCompany}
            handleCampanyChange={handleCampanyChange}
            selectedBrokers={selectedBrokers}
            handleBrokerChange={handleBrokerChange}
            handleFilter={handleFilter}
            resetFilterBtn={resetFilterBtn}
            reportData={data.mortgageSold.lenders}
          />
          {data.mortgageSold.lenders.length > 0 ? (
            <>
              <Typography
                color="text.primary"
                component="h4"
                variant="h4"
                marginTop="24px"
              >
                Lenders
              </Typography>
              <Paper
                sx={theme => ({ bgcolor: theme.palette.background.default })}
                elevation={0}
              >
                <Box
                  height={data?.mortgageSold?.lenders.length * 30 + 100}
                  style={{ overflowY: "hidden", overflowX: "scroll" }}
                  sx={theme => horizontalScrollBarStyle(theme)}
                >
                  <BarGraph
                    theme={theme}
                    data={data?.mortgageSold?.lenders}
                    user={true}
                    indexBy={"name"}
                    keys={["count"]}
                  />
                </Box>
              </Paper>
            </>
          ) : (
            <Typography variant="h5" color="text.primary" component="h5">
              There is no report yet
            </Typography>
          )}
        </>
      )}
    </>
  );
}

export default MortgageSoldReport;
