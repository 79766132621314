import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters, handleSearch } from "reducers/clients";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import { getBrokers } from "reducers/users";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { useTheme } from "@mui/material/styles";
import {
  Drawer,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  Typography,
  Button,
  Box,
  ListItemText,
  Stack,
  Chip,
  MenuItem,
  ListItemIcon,
  TextField
} from "@mui/material";
import { USER_TYPE } from "utils/constants";
import _ from "lodash";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);

function FIlterDrawer({
  toggleDrawer,
  position,
  selectedBrokers,
  handleBrokerFilterChange,
  handleBrokerFilterDelete,
  isAllSelected,
  clientSearch,
  setClientSearch
}) {
  const classes = useStyles();
  const theme = useTheme();
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 280,
        width: 250,
        overflowX: "auto",
        backgroundColor: theme.palette.text.dark.primary
      }
    }
  };

  const dispatch = useDispatch();
  const { data: userData } = useSelector(state => state.user);
  const { filters } = useSelector(state => state.clients);

  const handleFilter = () => {
    if (!_.isEmpty(selectedBrokers) || clientSearch) {
      dispatch(
        updateFilters({
          ...filters,
          brokers: selectedBrokers
        })
      );
      dispatch(handleSearch(clientSearch));
      toggleDrawer(false)();
    }
  };

  useEffect(() => {
    if (userData?.me?.type === USER_TYPE.COMPANY_OWNER) {
      dispatch(getBrokers());
    }
  }, [dispatch, userData?.me]);

  return (
    <Drawer
      anchor={"right"}
      open={position["right"]}
      onClose={toggleDrawer(false)}
      sx={theme => ({
        [theme.breakpoints.down("sm")]: {
          "& .MuiDrawer-paper": {
            width: "100%",
            bgcolor: theme.palette.text.dark.primary
          }
        }
      })}
    >
      <Box
        sx={theme => ({
          width: 400,
          padding: "56px 32px",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: 0
          }
        })}
        role="presentation"
      >
        <Box
          sx={theme => ({
            [theme.breakpoints.down("sm")]: {
              padding: "56px 32px"
            }
          })}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
              color="text.primary"
              component="div"
              sx={{ marginBottom: "24px" }}
            >
              FILTER
            </Typography>
            <CloseIcon
              onClick={toggleDrawer(false)}
              sx={{ color: "text.primary", cursor: "pointer" }}
            />
          </Stack>
          <Stack spacing={3}>
            <FormControl
              sx={{ width: "100%", marginTop: "16px" }}
              className={classes.textField}
            >
              <InputLabel>Brokers</InputLabel>
              <Select
                multiple
                fullWidth
                value={selectedBrokers}
                onChange={handleBrokerFilterChange}
                input={<OutlinedInput label="Brokers" />}
                renderValue={selected => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map(broker => (
                      <Chip
                        key={broker.id}
                        label={broker.email}
                        sx={theme => ({
                          color: theme.palette.text.primary,
                          backgroundColor: theme.palette.active.selected,
                          height: "24px"
                        })}
                        clickable
                        deleteIcon={
                          <CancelIcon
                            sx={theme => ({
                              fontSize: "14px",
                              color: theme.palette.text.disabled
                            })}
                            onMouseDown={event => event.stopPropagation()}
                          />
                        }
                        onDelete={e =>
                          handleBrokerFilterDelete(e, broker.email)
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                <MenuItem
                  value="all"
                  sx={theme => ({
                    backgroundColor: theme.palette.text.dark.primary
                  })}
                >
                  <ListItemIcon>
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selectedBrokers.length > 0 &&
                        selectedBrokers.length < userData?.brokers.length
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="All" />
                </MenuItem>
                {userData?.brokers?.map(broker => (
                  <MenuItem
                    key={broker.id}
                    value={broker}
                    sx={theme => ({
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.text.dark.primary
                    })}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedBrokers.indexOf(broker) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={broker.email} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack display={{ xs: "block", lg: "none" }}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Search Client"
                  type="text"
                  value={clientSearch}
                  placeholder="Search Client"
                  onChange={e => setClientSearch(e.target.value)}
                />
              </FormControl>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            style={{
              width: "100%",
              marginTop: "24px"
            }}
            onClick={handleFilter}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export default FIlterDrawer;
