import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CREDITS_DURATION,
  defaultCompanySorting,
  FIRST_PAGE,
  SUBSCRIPTION_TYPE
} from "utils/constants";
import {
  updateBrokersFilters,
  getCompanyBrokers,
  changePage
} from "reducers/company";
import { makeStyles } from "@mui/styles";

import Loader from "components/Loader";
import UpdateCreditsModal from "./UpdateCreditsModal";
import {
  getNextSortingState,
  clearOtherSortingParams,
  isNoSortingParams,
  getSortingColor,
  getSortingIcon,
  paginationStyle,
  horizontalScrollBarStyle
} from "utils";
import {
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Pagination
} from "@mui/material";

const useStyles = makeStyles(theme => ({
  borderRight: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      display: "block",
      width: "2px",
      height: "14px",
      background: theme.palette.grey[300],
      top: "50%",
      transform: "translateY(-50%)",
      right: "0"
    }
  }
}));

function CreditsTable({ creditsDuration }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, rowsPerPage, page } = useSelector(state => state.company);
  const { filtersBrokers, defaultCompanyData } = useSelector(
    state => state.company
  );

  const [isSharedModal, setIsSharedModal] = useState(false);
  const [broker, setBroker] = useState(null);

  const handleOnSortFilterChange = sortingParam => {
    let filters_ = {
      ...filtersBrokers,
      sorting: { ...filtersBrokers.sorting }
    };
    filters_.sorting[sortingParam] = getNextSortingState(
      filters_.sorting[sortingParam]
    );
    filters_.sorting = clearOtherSortingParams(filters_.sorting, sortingParam);
    if (isNoSortingParams(filters_.sorting)) {
      filters_.sorting = defaultCompanySorting;
    }
    dispatch(updateBrokersFilters(filters_));
  };

  const handleSharedBtn = data => {
    setIsSharedModal(true);
    setBroker(data);
  };

  const handleOnPageChange = (e, newPage) => {
    dispatch(changePage(newPage));
  };

  useEffect(() => {
    const creditsUpdateDetails = {
      broker: 163,
      quantity: 1
    };
    dispatch(getCompanyBrokers(creditsUpdateDetails));
  }, [dispatch, filtersBrokers, page]);

  useEffect(() => {
    return () => {
      dispatch(changePage(FIRST_PAGE));
    };
  }, [dispatch]);

  return (
    <>
      {data?.results?.length > 0 ? (
        <>
          <TableContainer
            component={Paper}
            sx={theme => horizontalScrollBarStyle(theme)}
          >
            <Table sx={{ minWidth: 550 }} stickyHeader>
              <TableHead>
                <TableRow
                  sx={theme => ({
                    bgcolor: theme.palette.background.default,
                    "& td,th": {
                      borderBottom: `1px solid ${theme.palette.text.disabled}`,
                      zIndex: 0
                    }
                  })}
                >
                  <TableCell className={classes.borderRight}>
                    <Button
                      onClick={() => handleOnSortFilterChange("email")}
                      variant="text"
                      sx={{
                        color: getSortingColor(filtersBrokers.sorting.email)
                      }}
                      startIcon={getSortingIcon(filtersBrokers.sorting.email)}
                    >
                      Email
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "20%" }}
                    className={classes.borderRight}
                  >
                    <Button
                      onClick={() => handleOnSortFilterChange("first_name")}
                      variant="text"
                      sx={{
                        color: getSortingColor(
                          filtersBrokers.sorting.first_name
                        )
                      }}
                      startIcon={getSortingIcon(
                        filtersBrokers.sorting.first_name
                      )}
                    >
                      Name
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{ width: "15%" }}
                    className={classes.borderRight}
                  >
                    <Button
                      onClick={
                        defaultCompanyData.membershipDetails?.type === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO ?
                        creditsDuration === CREDITS_DURATION.MONTHLY ?
                        () => handleOnSortFilterChange("used_credits_this_month") :
                        () => handleOnSortFilterChange("used_credits") :
                        () => handleOnSortFilterChange("balance")
                      }
                      variant="text"
                      sx={defaultCompanyData.membershipDetails?.type === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO ?
                        creditsDuration === CREDITS_DURATION.MONTHLY ?
                        {color: getSortingColor(filtersBrokers.sorting.used_credits_this_month)}:
                        {color: getSortingColor(filtersBrokers.sorting.used_credits)}:
                        {color: getSortingColor(filtersBrokers.sorting.balance)}
                      }
                      startIcon={
                        defaultCompanyData.membershipDetails?.type === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO ?
                        creditsDuration === CREDITS_DURATION.MONTHLY ?
                        getSortingIcon(filtersBrokers.sorting.used_credits_this_month) :
                        getSortingIcon(filtersBrokers.sorting.used_credits) :
                        getSortingIcon(filtersBrokers.sorting.balance)
                      }
                    >
                      Credits
                    </Button>
                  </TableCell>
                  {defaultCompanyData.membershipDetails?.type !== SUBSCRIPTION_TYPE.PAY_AS_YOU_GO &&
                    <TableCell
                      sx={{ width: "25%", whiteSpace: "pre" }}
                      className={classes.borderRight}
                    >
                      <Button
                        onClick={() =>
                          handleOnSortFilterChange("credit_expiry_date")
                        }
                        variant="text"
                        sx={{
                          color: getSortingColor(
                            filtersBrokers.sorting.credit_expiry_date
                          )
                        }}
                        startIcon={getSortingIcon(
                          filtersBrokers.sorting.credit_expiry_date
                        )}
                      >
                        Expired Date
                      </Button>
                    </TableCell>
                  }
                  {defaultCompanyData.membershipDetails?.type ===
                    SUBSCRIPTION_TYPE.SUBSCRIPTION_AND_CREDITS && (
                    <TableCell sx={{ width: "15%" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "15px",
                          fontWeight: "600",
                          textAlign: "center",
                          textTransform: "uppercase"
                        }}
                      >
                        Action
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.results?.map(row => (
                  <TableRow
                    key={row.id}
                    sx={theme => ({
                      bgcolor: theme.palette.background.default,
                      "& td,th": {
                        borderBottom: `1px solid ${theme.palette.text.disabled}`
                      }
                    })}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: "16px",
                        color: "text.primary"
                      }}
                    >
                      <Typography variant="body1">{row.email}</Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: "16px",
                        color: "text.primary",
                        width: "20%"
                      }}
                    >
                      <Typography variant="body1">{row.fullName}</Typography>
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: "16px",
                        color: "text.primary",
                        width: "15%",
                        padding: "16px 32px"
                      }}
                    >
                      <Typography variant="body1">
                        {defaultCompanyData.membershipDetails?.type === SUBSCRIPTION_TYPE.PAY_AS_YOU_GO ?
                          creditsDuration === CREDITS_DURATION.MONTHLY ?
                          row.usedCreditsThisMonth :
                          row.usedCredits :
                          row.balance
                        }
                      </Typography>
                    </TableCell>
                  {defaultCompanyData.membershipDetails?.type !== SUBSCRIPTION_TYPE.PAY_AS_YOU_GO &&
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        fontSize: "16px",
                        color: "text.primary",
                        width: "25%",
                        padding: "16px 32px"
                      }}
                    >
                      <Typography variant="body1">
                        {row.creditExpiryDate ? row.creditExpiryDate : "N/A"}
                      </Typography>
                    </TableCell>
                  }
                    {defaultCompanyData.membershipDetails?.type ===
                      SUBSCRIPTION_TYPE.SUBSCRIPTION_AND_CREDITS && (
                      <TableCell
                        sx={{
                          width: "15%",
                          textAlign: "right",
                          whiteSpace: "pre"
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleSharedBtn(row)}
                        >
                          Update Credits
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(data.count / rowsPerPage)}
            page={page}
            onChange={handleOnPageChange}
            sx={theme => paginationStyle(theme)}
          />
        </>
      ) : (
        <Loader />
      )}
      {isSharedModal && (
        <Box>
          <UpdateCreditsModal
            isOpen={isSharedModal}
            handleClose={() => setIsSharedModal(false)}
            broker={broker}
          />
        </Box>
      )}
    </>
  );
}

export default CreditsTable;
