import { createSlice } from "@reduxjs/toolkit";
import { PROFILE_TABS } from "utils/constants";

const initialState = {
  loading: true,
  theme: "dark",
  data: {
    me: null,
    unreadLeadCount: "",
    statistics: null,
    brokers: [],
    companies: []
  },
  activeTab: PROFILE_TABS.PROFILE,
  profileImage: "",
  error: null,
  email: ""
};

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    login: (state, action) => {
      state.loading = true;
    },
    loginSucceed: (state, action) => {
      state.loading = true;
    },
    loginFailed: (state, action) => {
      state.loading = true;
    },
    getUser: (state, action) => {
      state.loading = true;
    },
    getUserSucceed: (state, action) => {
      state.data.me = action.payload;
      state.loading = false;
    },
    getUserFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    setEmailAddress: (state, action) => {
      state.email = action.payload.email;
    },
    forgotPassword: (state, action) => {
      state.loading = true;
    },
    forgotPasswordSucceed: (state, action) => {
      state.loading = false;
    },
    forgotPasswordFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    confirmPassword: (state, action) => {
      state.loading = true;
    },
    confirmPasswordSucceed: (state, action) => {
      state.loading = false;
    },
    confirmPasswordFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBrokers: (state, action) => {
      state.loading = true;
    },
    getBrokersSucceed: (state, action) => {
      state.data.brokers = action.payload;
      state.loading = false;
    },
    getBrokersFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    changePassword: (state, action) => {
      state.loading = true;
    },
    changePasswordSucceed: (state, action) => {
      state.loading = false;
    },
    changePasswordFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    logout: (state, action) => {
      state.loading = true;
    },
    logoutSucceed: (state, action) => {
      state.loading = true;
    },
    logoutFailed: (state, action) => {
      state.loading = true;
    },
    updateThemeMode: (state, action) => {
      state.theme = action.payload;
    },
    getCompanies: state => {
      state.loading = true;
    },
    getCompaniesSucceed: (state, action) => {
      state.data.companies = action.payload;
      state.loading = false;
    },
    getCompaniesFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetBroker: state => {
      state.data.brokers = [];
    },
    updateActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    updateEmailPreference: (state, action) => {
      state.loading = true;
    },
    updateEmailPreferenceSucceed: (state, action) => {
      state.loading = false;
    },
    updateEmailPreferenceFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateUser: (state, action) => {
      state.loading = true;
    },
    updateUserSucceed: (state, action) => {
      state.loading = false;
    },
    updateUserFailed: (state, action) => {
      state.loading = false;
    },
    updateProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    getUnreadLeadCount: () => {},
    getUnreadLeadCountSucceed: (state, action) => {
      state.data.unreadLeadCount = action.payload;
    },
    getUnreadLeadCountFailed: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const {
  login,
  loginSucceed,
  loginFailed,
  getUser,
  getUserSucceed,
  getUserFailed,
  setEmailAddress,
  forgotPassword,
  forgotPasswordSucceed,
  forgotPasswordFailed,
  confirmPassword,
  confirmPasswordSucceed,
  confirmPasswordFailed,
  changePassword,
  changePasswordSucceed,
  changePasswordFailed,
  getBrokers,
  getBrokersSucceed,
  getBrokersFailed,
  logout,
  logoutSucceed,
  logoutFailed,
  updateThemeMode,
  getCompanies,
  getCompaniesSucceed,
  getCompaniesFailed,
  resetBroker,
  updateActiveTab,
  updateEmailPreference,
  updateEmailPreferenceSucceed,
  updateEmailPreferenceFailed,
  updateUser,
  updateUserSucceed,
  updateUserFailed,
  updateProfileImage,
  getUnreadLeadCount,
  getUnreadLeadCountSucceed,
  getUnreadLeadCountFailed
} = users.actions;

export default users.reducer;
