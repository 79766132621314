import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { textFieldStyle } from "utils/NonAuthenticatedStyle";
import { updateCompanies } from "reducers/company";
import { Box, Button, FormControl, TextField, Stack } from "@mui/material";

const useStyles = makeStyles(theme =>
  textFieldStyle(theme, theme.palette.background.paper)
);

function AddressTab() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [adressDetails, setAddressDetails] = useState({});

  const { me: user } = useSelector(state => state.user.data);
  const { defaultCompanyData } = useSelector(state => state.company);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = value => {
    const addressDetail = {
      id: user.company,
      data: {
        address: {
          address: value.addressLine1,
          address2: value.addressLine2,
          town: value.town,
          postCode: value.postCode
        }
      }
    };

    dispatch(updateCompanies(addressDetail));
  };

  useEffect(() => {
    setAddressDetails({
      ...adressDetails,
      addressLine1: defaultCompanyData?.address?.address,
      addressLine2: defaultCompanyData?.address?.address2,
      town: defaultCompanyData?.address?.town,
      postCode: defaultCompanyData?.address?.postCode
    });
    reset({
      addressLine1: defaultCompanyData?.address?.address,
      addressLine2: defaultCompanyData?.address?.address2,
      town: defaultCompanyData?.address?.town,
      postCode: defaultCompanyData?.address?.postCode
    });
  }, [defaultCompanyData]);

  const handleChange = (key, value) => {
    setAddressDetails({ ...adressDetails, [key]: value });
  };

  return (
    <Box
      sx={theme => ({
        width: "600px",
        [theme.breakpoints.down("md")]: {
          marginTop: "24px",
          width: "100%"
        }
      })}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <FormControl>
            <TextField
              className={classes.textField}
              fullWidth
              label="Address Line 1"
              type="text"
              placeholder="Address Line 1"
              defaultValue={defaultCompanyData?.address?.address || ""}
              error={!!errors.addressLine1}
              {...register("addressLine1", {
                required: true,
                onChange: e => handleChange("addressLine1", e.target.value)
              })}
              InputLabelProps={{
                shrink: adressDetails.addressLine1 ? true : false
              }}
            />
          </FormControl>
          <FormControl>
            <TextField
              className={classes.textField}
              fullWidth
              label="Address Line 2"
              type="text"
              placeholder="Address Line 2"
              defaultValue={defaultCompanyData?.address?.address2 || ""}
              error={!!errors.addressLine2}
              {...register("addressLine2", {
                required: true,
                onChange: e => handleChange("addressLine2", e.target.value)
              })}
              InputLabelProps={{
                shrink: adressDetails.addressLine2 ? true : false
              }}
            />
          </FormControl>
          <FormControl
            sx={theme => ({
              width: "350px",
              [theme.breakpoints.down("md")]: {
                width: "100%"
              }
            })}
          >
            <TextField
              className={classes.textField}
              fullWidth
              label="City"
              type="text"
              placeholder="City"
              defaultValue={defaultCompanyData?.address?.town || ""}
              error={!!errors.town}
              {...register("town", {
                required: true,
                onChange: e => handleChange("town", e.target.value)
              })}
              InputLabelProps={{
                shrink: adressDetails.town ? true : false
              }}
            />
          </FormControl>
          <FormControl
            sx={theme => ({
              width: "350px",
              [theme.breakpoints.down("md")]: {
                width: "100%"
              }
            })}
          >
            <TextField
              className={classes.textField}
              label="Postcode"
              type="text"
              placeholder="Postcode"
              defaultValue={defaultCompanyData?.address?.postCode || ""}
              error={!!errors.postCode}
              {...register("postCode", {
                required: true,
                onChange: e => handleChange("postCode", e.target.value)
              })}
              InputLabelProps={{
                shrink: adressDetails.postCode ? true : false
              }}
            />
          </FormControl>
        </Stack>
        <Button
          type="submit"
          variant="contained"
          sx={theme => ({
            margin: "24px 0",
            [theme.breakpoints.down("md")]: {
              width: "100%"
            }
          })}
        >
          UPDATE
        </Button>
      </form>
    </Box>
  );
}

export default AddressTab;
